import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useStyles from './style';
const TimeLine = (props) => {
  const { data = [], currentIndex = 1 } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Timeline className={classes.timeLineMainWrapper}>
        {data &&
          data.length > 0 &&
          data.map((value, index) => {
            const indexNumber = index + 1;
            return (
              <TimelineItem className={classes.timeLineItemWrapper} key={value.id}>
                <TimelineSeparator>
                  <TimelineDot
                    className={
                      indexNumber === currentIndex
                        ? classes.iconWrapper
                        : indexNumber < currentIndex
                        ? classes.successIconWrapper
                        : classes.fadeIconWrapper
                    }
                  >
                    <Box>
                      {indexNumber < currentIndex ? (
                        <CheckIcon />
                      ) : (
                        <Typography variant="h3" className={classes.iconText}>
                          {indexNumber}
                        </Typography>
                      )}
                    </Box>
                  </TimelineDot>
                  {data.length !== indexNumber && <TimelineConnector className={classes.timeLineConnector} />}
                </TimelineSeparator>
                <TimelineContent className={classes.timeLineContentWrapper}>
                  <>
                    <Typography variant="h2" className={indexNumber <= currentIndex ? '' : classes.titleText}>
                      {value.heading}
                    </Typography>
                    {indexNumber === currentIndex ? value.content : ''}
                  </>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </Box>
  );
};

export default TimeLine;
