import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '../TextField';

const Timepicker = (props) => {
  const { size = 'normal', error = false, helperText = '', ...others } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker
        {...others}
        renderInput={(params) => <TextField size={size} helperText={helperText} {...params} error={error} />}
      />
    </LocalizationProvider>
  );
};

export default Timepicker;
