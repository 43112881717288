import { Box, DialogContentText } from '@mui/material';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Datepicker, GridContainer, GridItem, Modal } from '../../../components/shared';
import { leaseSchema } from '../../../schema/validationSchemas';
import useStyles from './style';

const ApprovalRequestModal = (props) => {
  const classes = useStyles();
  const { isOpen, approveClick = () => {}, onCancelClick = () => {} } = props;
  const leaseState = useSelector((state) => state?.lease?.leaseForms);
  const { leaseDetails } = leaseState;
  const formik = useFormik({
    initialValues: {
      leaseBegins: leaseDetails?.leaseBegins,
      leaseEnds: leaseDetails?.leaseEnds,
    },
    validationSchema: leaseSchema.approvalRequestSchema,
    onSubmit: (values) => {
      approveClick(values);
    },
  });

  useEffect(() => {
    setValues({
      leaseBegins: leaseDetails?.leaseBegins,
      leaseEnds: leaseDetails?.leaseEnds,
    });
  }, [leaseDetails]);

  const { errors, touched, handleSubmit, values, setFieldValue, setValues } = formik;
  const dateChangeHandler = (name, value) => {
    if (dayjs(value).toString() !== 'Invalid Date') {
      setFieldValue(name, dayjs(value).toString());
    } else {
      setFieldValue(name, null);
    }
  };
  return (
    <Modal
      title="Approval Request"
      titleVariant="modalHeading"
      open={isOpen}
      actionButtons={
        <Box mt={3} mb={2}>
          <Button variant="contained" onClick={handleSubmit}>
            Approve
          </Button>
          <Button onClick={onCancelClick}>Cancel</Button>
        </Box>
      }
    >
      <DialogContentText variant="body2" className={classes.dialogContent} mt={1}>
        Are you sure you want to approve the lease and move it to active? Please confirm the start date and end date to
        begin the lease
      </DialogContentText>
      <FormikProvider value={formik}>
        <Box mt={4}>
          <GridContainer rowSpacing={3}>
            <GridItem xs={12} md={12} pl={0} pt={0}>
              <Datepicker
                label="Lease Begins"
                name="leaseBegins"
                value={values.leaseBegins}
                inputFormat="MM/DD/YYYY"
                onChange={(date) => dateChangeHandler('leaseBegins', date)}
                error={Boolean(touched.leaseBegins && errors.leaseBegins)}
                helperText={touched.leaseBegins && errors.leaseBegins}
              />
            </GridItem>
            <GridItem xs={12} md={12} pl={0}>
              <Datepicker
                label="Lease Ends"
                name="leaseEnds"
                value={values.leaseEnds}
                inputFormat="MM/DD/YYYY"
                onChange={(date) => dateChangeHandler('leaseEnds', date)}
                error={Boolean(touched.leaseEnds && errors.leaseEnds)}
                helperText={touched.leaseEnds && errors.leaseEnds}
              />
            </GridItem>
          </GridContainer>
        </Box>
      </FormikProvider>
    </Modal>
  );
};

export default ApprovalRequestModal;
