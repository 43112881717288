import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    importIntercardData: build.mutation({
      query: (data) => ({
        url: `${apiUrl.intercard}/import?startDate=${data?.startDate}&endDate=${data?.endDate}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Intercards'],
    }),
    getIntercardProcesses: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.intercard}/${apiUrl.trackings}?perPage=${perPage}&page=${page}&q=${searchParams}`;
        }
        return `${apiUrl.intercard}/${apiUrl.trackings}`;
      },
      providesTags: ['Intercards'],
    }),
  }),
  overrideExisting: false,
});

export const { useImportIntercardDataMutation, useGetIntercardProcessesQuery } = extendedApi;
