import machinesFilterSlice from './machinesFilters-slice';

export const {
  setActiveMachinesFilters,
  setArchiveMachinesFilters,
  resetState,
  resetActiveMachinesFiltersState,
  resetArchiveMachinesFiltersState,
} = machinesFilterSlice.actions;

export default machinesFilterSlice;
