import { sortIcon } from '../../../assets/images/icons';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import SearchBar from '../../../components/SearchBar';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import SORT_CONSTANT, { DATE_ARCHIVE_DESC } from '../../../constants/SortConstants';
import { useSearchFilterParam } from '../../../hooks';
import {
  useLazyGetAllCountriesQuery,
  useLazyGetCountryStatesByCountryIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import {
  useLazyGetAllLocationOwnersQuery,
  useLazyGetAllLocationTypesQuery,
} from '../../../services/Location/LocationService';
import LocationsTable from './LocationsTable';
import useStyles from './style';
import {
  useLazyGetAllSublocationsQuery,
  useLazyGetProductCategoriesQuery,
} from '../../../services/Sublocation/SublocationService';
import { useDispatch, useSelector } from 'react-redux';
import { resetArchiveLocationFilters, setArchiveLocationFilters } from '../../../redux-slice/locationFilters';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';

const ArchiveTab = ({ onTotalLocationsCount }) => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;

  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.LOCATION.INITIAL_PARAMS);
  const [sort, setSort] = useState(DATE_ARCHIVE_DESC);

  const { archiveLocation } = useSelector((state) => state.locationFilter);
  const dispatch = useDispatch();

  const [getAllLocationTypes] = useLazyGetAllLocationTypesQuery();
  const [getAllLocationOwners] = useLazyGetAllLocationOwnersQuery();
  const [getAllCountries] = useLazyGetAllCountriesQuery();
  const [getStatesByCountryId] = useLazyGetCountryStatesByCountryIdQuery();
  const [getAllSublocations] = useLazyGetAllSublocationsQuery();
  const [getAllProductTypes] = useLazyGetProductCategoriesQuery();

  useEffect(() => {
    const storedFilterParams = { ...FILTER_DATA.LOCATION.INITIAL_PARAMS };
    storedFilterParams.locationTypeId = archiveLocation[FILTER_VALUES.LOCATION_TYPE_ID] ?? [];
    storedFilterParams.ownerId = archiveLocation[FILTER_VALUES.OWNER] ?? [];
    storedFilterParams.countryId = archiveLocation[FILTER_VALUES.COUNTRY] ?? {};
    storedFilterParams.stateId = archiveLocation[FILTER_VALUES.STATE] ?? {};
    storedFilterParams.sublocation = archiveLocation[FILTER_VALUES.COLLECTION_SUBLOCATION] ?? [];
    storedFilterParams.productType = archiveLocation[FILTER_VALUES.PRODUCT_TYPE] ?? [];
    setSearchText(archiveLocation.searchText);
    setSort(archiveLocation.sort);
    setFilterParams(storedFilterParams);
  }, [archiveLocation]);

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.LOCATION_TYPE_ID:
        return getAllLocationTypes;
      case FILTER_VALUES.OWNER:
        return getAllLocationOwners;
      case FILTER_VALUES.COUNTRY:
        return getAllCountries;
      case FILTER_VALUES.STATE:
        return getStatesByCountryId;
      case FILTER_VALUES.COLLECTION_SUBLOCATION:
        return getAllSublocations;
      case FILTER_VALUES.PRODUCT_TYPE:
        return getAllProductTypes;

      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.LOCATION.FILTER.map((item) => {
        const api = getAPI(item.value);

        return {
          ...item,
          api,
        };
      }),
    [],
  );

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.LOCATION,
    filterParams,
    sort,
    searchText,
    extraParam: 'status=false',
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setArchiveLocationFilters({
        ...archiveLocation,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetSearchBarHandler = () => {
    setSearchText('');
    dispatch(
      setArchiveLocationFilters({
        ...archiveLocation,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const applyFilterHandler = (filterValues) => {
    dispatch(
      setArchiveLocationFilters({
        ...archiveLocation,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(filterValues);
  };

  const handleSort = (e) => {
    dispatch(
      setArchiveLocationFilters({
        ...archiveLocation,
        sort: e.target.value,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setSort(e.target.value);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.LOCATION.INITIAL_PARAMS);
    dispatch(resetArchiveLocationFilters());
  };

  return (
    <>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            onSearch={searchHandler}
            onReset={resetSearchBarHandler}
            placeholder="Search by location"
            searchText={searchText}
          />
        </Box>
        <Box px={3}>
          <NewFilterDrawer
            name={TITLE.SORT_BY.toUpperCase()}
            title={TITLE.SORT_BY}
            drawerType={DRAWER_TYPES.SORT_DRAWER}
            startIcon={<>{sortIcon}</>}
            onSortSelect={handleSort}
            selectedSort={sort}
            sortData={SORT_CONSTANT.ARCHIVE_LOCATION_SORT}
          />
        </Box>
        <Box>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={(v) => {
              applyFilterHandler(v);
            }}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.LOCATION.INITIAL_PARAMS).length}
          />
        </Box>
      </Box>
      <LocationsTable
        searchParams={searchParams}
        onTotalLocation={onTotalLocationsCount}
        isArchiveTab={true}
        archiveLocation={archiveLocation}
      />
    </>
  );
};

export default ArchiveTab;
