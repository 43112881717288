import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    display: 'flex',
  },
  radioGroup: {
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'col',
    display: 'flex',
    gap: 18,
    marginTop: '20px',
    '& .MuiSvgIcon-root': { color: `${theme?.palette?.primary?.light} ` },
    '& .MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      margin: 0,
      flexWrap: 'wrap',
      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.palette.grey[800_1],
        fontWeight: '700',
      },
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      width: '32px',
      height: '32px',
      '& .Mui-checked': { color: `${theme?.palette?.primary?.light}` },
    },
  },
}));

export default useStyles;
