import { NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Container, Divider, Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import LeaseDetailsForm from '../../../components/LeaseDetailsForm';
import LoadingSpinner from '../../../components/LoadingSpinner';
import RentDetailsForm from '../../../components/RentDetailsForm';
import { Breadcrumbs, Button } from '../../../components/shared';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import { leaseDetailsFormAction, rentDetailsFormAction } from '../../../redux-slice/lease';
import { useGetLeaseByIdQuery, useUpdateLeaseByIdMutation } from '../../../services/Lease/LeaseService';
import { dateFormatForApi } from '../../../utils/common-methods';
import useStyles from './style';

const EditLeaseAndRent = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const locationId = Number(searchParams.get('location'));
  const subLocationId = Number(searchParams.get('subLocation'));
  const navigate = useNavigate();
  const leaseDetailBtnRef = createRef();
  const rentDetailsBtnRef = createRef();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [modal, setModal] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { currentData: leaseDetail = {}, isLoading, isFetching } = useGetLeaseByIdQuery(id);
  const [updateLease, { isLoading: updateLeaseLoading }] = useUpdateLeaseByIdMutation();

  const {
    LOCATION: { LOCATION, LOCATION_DETAIL, SUB_LOCATION_DETAIL, EDIT_LEASE_AND_RENT },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      { text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` },
      {
        text: LOCATION_DETAIL,
        redirection: `${routeConstants.LOCATION_ROUTE}/${locationId}`,
      },
      {
        text: SUB_LOCATION_DETAIL,
        redirection: `${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}?locationId=${locationId}`,
      },
      { text: EDIT_LEASE_AND_RENT },
    ],
    [id],
  );

  useEffect(() => {
    const subLocations = leaseDetail?.contractSublocations?.map((value) => value?.sublocation);
    const modelTypes = leaseDetail?.contractAssetGameCategoryRates?.map((value) => ({
      id: value?.gameCategory?.id,
      name: value?.gameCategory?.name,
      rate: value?.rentRate,
    }));
    dispatch(
      leaseDetailsFormAction({
        leaseName: leaseDetail?.name,
        location: {
          id: leaseDetail?.location?.id,
          name: leaseDetail?.location?.locationName,
        },
        subLocation: subLocations || [],
        city: leaseDetail?.location?.city?.id || '',
        state: leaseDetail?.location?.state?.id || '',
        zipCode: leaseDetail?.location?.postcode || '',
        address1: leaseDetail?.location?.address1 || '',
        address2: leaseDetail?.location?.address2 || '',
        country: leaseDetail?.location?.country?.id || '',
        boothInfo: '',
        space: '',
        dba: '',
        salesPerson: '',
        leaseType: leaseDetail?.leaseType || '',
        status: leaseDetail?.status || '',
        leaseBegins: leaseDetail?.termStartDate ? dayjs(leaseDetail?.termStartDate).toString() : null,
        leaseEnds: leaseDetail?.termEndDate ? dayjs(leaseDetail?.termEndDate).toString() : null,
      }),
    );

    dispatch(
      rentDetailsFormAction({
        rentType: leaseDetail?.rentType || 'RATE_PLUS_FEE',
        rentFee: leaseDetail?.fee || 0.0,
        rentCalculationTiming: leaseDetail?.rentCalculateInterval || '',
        rentRate: leaseDetail?.rate || 0.0,
        calculationStarts: null, //not available in API
        calculationEnds: null, //not available in API
        breakpointNatural: leaseDetail?.hasNaturalBreakPoint,
        breakpointValue: leaseDetail?.naturalBreakPointValue || 0.0,
        CAMFee: leaseDetail?.CAMFee || '',
        CAMRate: leaseDetail?.CAMRate || '',
        modalTypes: modelTypes,
        thresholdRate: leaseDetail?.thresholdRate,
        thresholdRevenue: leaseDetail?.thresholdRevenue,
        notes: leaseDetail?.notes || '', //not available in API
        coinCost: leaseDetail?.coinCost || 0.0,
        cardCost: leaseDetail?.cardCost || 0.0,
      }),
    );
  }, [JSON.stringify(leaseDetail)]);

  const onUpdateClickHandler = () => {
    setFormData({});
    setLoadingBtn(true);
    leaseDetailBtnRef.current.click();
    rentDetailsBtnRef.current.click();
  };
  const rentDetailsObj = (rentDetails) => {
    const gameCategoryRates = rentDetails?.modalTypes?.map((value) => ({
      gameCategoryId: value?.id,
      rate: value?.rate,
    }));
    switch (rentDetails?.rentType) {
      case 'RATE_PLUS_FEE':
      case 'COMBO':
      case 'RATE_FEE_HIGHER':
      case 'SUM':
      case 'NA':
        return {
          fee: rentDetails?.rentFee,
          rate: rentDetails?.rentRate,
          hasNaturalBreakPoint: rentDetails?.breakpointNatural,
          naturalBreakPointValue: rentDetails?.breakpointValue,
          gameCategoryRates: gameCategoryRates,
        };
      case 'RATE_ONLY':
      case 'BENCHMARK_RENT':
        return {
          rate: rentDetails?.rentRate,
          gameCategoryRates: gameCategoryRates,
        };
      case 'FEE_ONLY':
      case 'FEE_ONLY_ANNUAL':
      case 'FEE_ONLY_YEAR':
      case 'FEE_PER_PIC':
      case 'FEE_ONLY_AFTER_BENCHMARK':
      case 'FEE_ONLY_SLIDING_BENCHMARK':
        return {
          fee: rentDetails?.rentFee,
        };

      default:
        return {};
    }
  };
  useEffect(() => {
    const checkHasRentValues = Object.keys(formData).some((e) => e === 'rentType');
    const checkHasLeaseValues = Object.keys(formData).some((e) => e === 'location');
    if (checkHasRentValues && checkHasLeaseValues) {
      const sublocations = formData?.subLocation?.map((value) => value?.id);
      const leaseData = {
        id: id,
        name: formData?.leaseName,
        sublocationIds: sublocations,
        leaseType: formData?.leaseType,
        status: formData?.status,
        termStartDate: dateFormatForApi(formData?.leaseBegins),
        termEndDate: dateFormatForApi(formData?.leaseEnds),
      };
      const rentDetailsCommon = {
        rentType: formData?.rentType,
        rentCalculateInterval: formData?.rentCalculationTiming,
        CAMFee: formData?.CAMFee,
        CAMRate: formData?.CAMRate,
        thresholdRate: formData?.thresholdRate,
        thresholdRevenue: formData?.thresholdRevenue,
        notes: formData?.notes,
        coinCost: formData?.coinCost,
        cardCost: formData?.cardCost,
      };
      const conditionWiseRentDetailsObj = rentDetailsObj(formData);
      const finalRentDetailsObj = {
        ...rentDetailsCommon,
        ...conditionWiseRentDetailsObj,
      };
      const data = {
        ...leaseData,
        ...finalRentDetailsObj,
      };

      updateLease(data).then((res) => {
        if (res.data) {
          navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}?locationId=${locationId}`, {
            replace: true,
          });
          setLoadingBtn(false);
        }
      });
    } else {
      setLoadingBtn(false);
    }
  }, [formData]);

  const onUpdateLeaseAndRent = (values) => {
    setFormData((prev) => ({ ...prev, ...values }));
  };
  const onCancelClickHandler = () => {
    navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}?locationId=${locationId}`);
  };
  const onConfirmationClickHandler = (value) => {
    switch (value) {
      case 'EDIT':
        setModal(false);
        break;
      case 'CANCEL':
        navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}?locationId=${locationId}`, {
          replace: true,
        });
        break;
      case 'CREATE_NEW':
        navigate(`/${routeConstants.ADD_LEASE_ROUTE}`, { replace: true });
        break;
    }
  };

  if (isLoading || isFetching) {
    return <LoadingSpinner containerHeight={400} />;
  }

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>
      <Box my={4}>
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <Typography variant="h1" data-testid="leaseName">
            {leaseDetail?.name}
          </Typography>
          <Chip
            label={leaseDetail?.leaseStatus}
            color={
              leaseDetail?.leaseStatus === 'PENDING'
                ? 'primary'
                : leaseDetail?.leaseStatus === 'ACTIVE'
                ? 'success'
                : 'error'
            }
            variant="outlined"
            data-testid="status"
          />
        </Stack>
      </Box>
      <Box component={Paper} p={3}>
        <Box mb={3}>
          <Typography variant="h2">Lease Details</Typography>
        </Box>
        <Box mb={4} className={classes.leaseDetailsFormWrapper}>
          <LeaseDetailsForm ref={leaseDetailBtnRef} onUpdateHandler={onUpdateLeaseAndRent} isUpdate={true} />
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box mb={3} mt={3}>
          <Typography variant="h2">Rent Details</Typography>
        </Box>
        <Box mb={4}>
          <RentDetailsForm ref={rentDetailsBtnRef} onUpdateClick={onUpdateLeaseAndRent} isUpdate />
        </Box>
        <Box mb={4}>
          <Stack direction="row" spacing={2} data-testid="update-wrapper">
            <LoadingButton
              loading={loadingBtn || updateLeaseLoading}
              variant="contained"
              onClick={onUpdateClickHandler}
            >
              Update
            </LoadingButton>
            <Button onClick={onCancelClickHandler}>Cancel</Button>
          </Stack>
        </Box>
      </Box>
      <ConfirmationModal
        isOpen={modal}
        title="Confirmation"
        msg="Are you sure you want to edit the Lease & Rent details? These changes will be applied on all assigned Sub locations."
        buttons={[
          { text: 'Edit', value: 'EDIT' },
          { text: 'Cancel', value: 'CANCEL' },
          { text: 'Create New', value: 'CREATE_NEW' },
        ]}
        onClick={onConfirmationClickHandler}
      />
    </Container>
  );
};

export default EditLeaseAndRent;
