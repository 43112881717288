import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  modems: {
    [FILTER_VALUES.TYPE]: [],
    [FILTER_VALUES.UNASSIGNED_ASSET]: {},
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const modemsFilterSlice = createSlice({
  name: 'modemsFilter',
  initialState,
  reducers: {
    setModemsFilters(state, action) {
      state.modems[FILTER_VALUES.TYPE] = action.payload?.type || [];
      state.modems[FILTER_VALUES.UNASSIGNED_ASSET] = action.payload?.unassignedModems || {};
      state.modems.searchText = action.payload?.searchText || '';
      state.modems.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.modems.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetModemsFiltersState(state) {
      state.modems = { ...initialState.modems, searchText: state.modems.searchText };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default modemsFilterSlice;
