import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 24px',
    margin: '16px 0px 24px',
    height: '100%',
    border: 'none',
  },
  searchWrap: {
    width: '100%',
  },
  sublocationName: { backgroundColor: `${theme?.palette?.grey[900_2]}` },
  assetForm: { border: `1px solid ${theme?.palette?.grey[900]}` },
  reportMachineBtn: {
    padding: '8px 14px 8px 14px',
    color: `${theme?.palette.secondary.main}`,
    backgroundColor: `${theme?.palette?.grey[100]}`,
    '&:hover': {
      color: `${theme?.palette?.common?.white}`,
    },
  },
  moneyInput: { color: `${theme?.palette?.common?.black}`, opacity: 0.6 },
  accordionColumns: { backgroundColor: `${theme?.palette?.grey[900_2]}` },
  summaryBackground: { backgroundColor: `${theme?.palette?.grey[900_2]}` },
  collectionFieldItem: {
    '& .Mui-disabled': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
    },
  },
  accordion: {
    '& .Mui-expanded': {
      margin: 0,
    },
  },
  playcardTitleContainer: {
    backgroundColor: theme.palette.grey[900_2],
    padding: 10,
  },
  playcardSaleFromCash: {
    padding: '12px 0px 8px 37px',
  },
  playcardMeterTitle: {
    padding: '12px 10px 8px 0px',
  },
  playcardMeterTotalSales: {
    padding: '0px 10px 8px 37px',
  },
  labelClass: {
    [theme.breakpoints.down('lg')]: {
      [theme.breakpoints.up('md')]: {
        '& .MuiFormLabel-root.MuiInputLabel-root': {
          marginTop: '-12px',
        },
      },
    },
  },
  comment: {
    backgroundColor: `${theme?.palette?.grey[100_1]}`,
    borderRadius: 0,
  },
  commentBox: { maxWidth: '340px' },
  listIcon: {
    color: theme.palette.primary.main,
    width: '0.5rem',
    height: '0.5rem',
  },
  displayCommentContainer: { color: theme.palette.primary.main },
  commentListBox: {
    padding: '18px 24px 0px 24px',
    background: theme.palette.primary.lighter,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 8,
    boxShadow: '0px 1px 4px rgba(100, 116, 139, 0.12)',
    '& .MuiListItem-root': { padding: 0 },
    '& .MuiListItemText-root': { margin: 0 },
  },
  commentIcon: {
    color: theme.palette.common.white,
    padding: 4,
    background: theme.palette.primary.main,
    borderRadius: 4,
  },
  locationHeaderSubmissionDate: {
    minWidth: 170,
  },
  // initialTableCheckbox: {
  //   '& th[class*=MuiTableCell-head]': {
  //     '&:first-of-type': {
  //       padding: '10px 0',
  //     },
  //   },
  //   '& td[class*=MuiTableCell-body]': {
  //     '&:first-of-type': {
  //       padding: '10px 0',
  //     },
  //   },
  // },
  addMissingAssetsContainer: {
    padding: '10px 10px 10px 25px',
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      lineHeight: '1.4375em !important',
    },
    '& .MuiButtonBase-root.MuiButton-root': {
      padding: '16px 24px',
    },
  },
  // Independent Rep
  independentRepContainer: {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      lineHeight: '1.4375em !important',
    },
    '& .MuiButtonBase-root.MuiButton-root': {
      padding: '16px 24px',
    },
  },
  independentRepTitle: {
    backgroundColor: theme.palette.grey[900_2],
    padding: 10,
  },
  independentRepForm: {
    padding: '10px 10px 10px 37px',
  },
}));

export default useStyles;
