import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Autocomplete, Button, Datepicker, GridContainer, GridItem, Select, TextField } from '../shared';
import { Form, FormikProvider, useFormik } from 'formik';
import { AttachMoney } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { leaseSchema } from '../../schema/validationSchemas';
import { checkNumberIsNaNAndInfinity, roundOffValue } from '../../utils/common-methods';
import { useGetGameCategoriesQuery } from '../../services/Models/ModelsService';
import useStyles from './style';
import { LoadingButton } from '@mui/lab';

const displayName = 'RentDetailsForm';
const RentDetailsForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    onNextClick = () => {},
    onBackClick = () => {},
    isUpdate = false,
    isLoading = false,
    onCancelClick = () => {},
    onUpdateClick = () => {},
  } = props;
  const leaseState = useSelector((state) => state?.lease?.leaseForms);
  const { rentDetails } = leaseState;
  const [dynamicTextFields, setDynamicTextFields] = useState([]);
  const { currentData: gameCategoriesData, isSuccess } = useGetGameCategoriesQuery();
  const gameCategories = gameCategoriesData?.data?.gameCategories?.map((value) => ({
    id: value?.id,
    name: value?.name,
    rate: '',
  }));

  const formik = useFormik({
    initialValues: rentDetails,
    validationSchema: leaseSchema.rentDetailsSchema,
    onSubmit: (values) => {
      if (isUpdate) {
        onUpdateClick(values);
      } else {
        onNextClick('rentDetails', values);
      }
    },
  });
  const { errors, values, touched, getFieldProps, handleSubmit, setFieldValue, setValues } = formik;

  const calculateBreakPointValue = (fee, rate) => {
    const formula = fee / rate;
    return roundOffValue(formula, 3);
  };

  useEffect(() => {
    setValues(rentDetails);
    setDynamicTextFields(rentDetails?.modalTypes || []);
  }, [rentDetails]);

  const rentTypeArr = [
    { value: 'RATE_PLUS_FEE', text: 'Rate + Fee' },
    { value: 'FEE_ONLY', text: 'Fee Only' },
    { value: 'RATE_ONLY', text: 'Rate Only' },
    { value: 'BENCHMARK_RENT', text: 'Benchmark Rent' },
    { value: 'COMBO', text: 'Combo' },
    { value: 'FEE_ONLY_ANNUAL', text: 'Fee Only Annual' },
    { value: 'FEE_ONLY_YEAR', text: 'Fee Only Year' },
    { value: 'FEE_PER_PIC', text: 'Fee Per Pic' },
    { value: 'RATE_FEE_HIGHER', text: 'Rate Fee Higher' },
    { value: 'FEE_ONLY_AFTER_BENCHMARK', text: 'Fee Only After Benchmark' },
    { value: 'FEE_ONLY_SLIDING_BENCHMARK', text: 'Fee Only Sliding Benchmark' },
    { value: 'SUM', text: 'Sum' },
    { value: 'NA', text: 'NA' },
  ];
  const calculationTimingArr = [
    { value: 'MONTHLY', text: 'Monthly' },
    { value: 'QUARTERLY', text: 'Quarterly' },
    { value: 'SEMI_ANNUALLY', text: 'Semi-Annually' },
    { value: 'ANNUALLY', text: 'Annually' },
  ];
  const breakPointNaturalArr = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (name === 'rentFee') {
      if (values?.breakpointNatural) {
        setFieldValue('breakpointValue', calculateBreakPointValue(value, values?.rentRate));
      }
    }
    if (name === 'rentRate') {
      if (values?.breakpointNatural) {
        setFieldValue('breakpointValue', calculateBreakPointValue(values?.rentFee, value));
      }
    }
    if (name === 'breakpointNatural') {
      if (value) {
        setFieldValue('breakpointValue', calculateBreakPointValue(values?.rentFee, values?.rentRate));
      } else {
        setFieldValue('breakpointValue', Number(checkNumberIsNaNAndInfinity(rentDetails?.breakpointValue)));
      }
    }
    setFieldValue(name, value);
  };

  const autoCompleteChangeHandler = (name, value) => {
    setFieldValue(name, value);

    setDynamicTextFields(value);
  };

  const dynamicTextFieldChangeHandler = (currentIndex, value) => {
    //shallow copy of array and change/replace object value

    const modalTypeArr = [...dynamicTextFields];
    const currentObj = modalTypeArr[currentIndex];
    const copyObj = { ...currentObj };
    copyObj.rate = value;
    modalTypeArr[currentIndex] = copyObj;
    setDynamicTextFields(modalTypeArr);
    setFieldValue('modalTypes', modalTypeArr);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Box className={classes.rentDetailsForm} data-testid="rentDetailComponent">
          <GridContainer rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 6 }}>
            <GridItem xs={12} md={4} pl={0} order={1}>
              <Select
                inputLabel="Rent Type"
                options={rentTypeArr}
                {...getFieldProps('rentType')}
                data-testid="rentDetailsForm"
                onChange={onChangeHandler}
                error={Boolean(touched.rentType && errors.rentType)}
                helperText={touched.rentType && errors.rentType}
              />
            </GridItem>
            {values.rentType !== 'RATE_ONLY' && values.rentType !== 'BENCHMARK_RENT' && (
              <GridItem xs={12} md={4} pl={0} order={2}>
                <TextField
                  label="Rent Fee"
                  type="number"
                  {...getFieldProps('rentFee')}
                  InputProps={{
                    'data-testid': 'rentDetailsForm',
                    startAdornment: <AttachMoney fontSize="small" />,
                  }}
                  error={Boolean(touched.rentFee && errors.rentFee)}
                  helperText={touched.rentFee && errors.rentFee}
                  onChange={onChangeHandler}
                />
              </GridItem>
            )}
            <GridItem xs={12} md={4} pl={0} order={3}>
              <Select
                inputLabel="Rent Calculation Timing"
                options={calculationTimingArr}
                {...getFieldProps('rentCalculationTiming')}
                data-testid="rentDetailsForm"
                error={Boolean(touched.rentCalculationTiming && errors.rentCalculationTiming)}
                helperText={touched.rentCalculationTiming && errors.rentCalculationTiming}
              />
            </GridItem>

            {values.rentType !== 'FEE_ONLY' &&
              values.rentType !== 'FEE_ONLY_ANNUAL' &&
              values.rentType !== 'FEE_ONLY_YEAR' &&
              values.rentType !== 'FEE_PER_PIC' &&
              values.rentType !== 'FEE_ONLY_AFTER_BENCHMARK' &&
              values.rentType !== 'FEE_ONLY_SLIDING_BENCHMARK' && (
                <GridItem
                  xs={12}
                  md={4}
                  pl={0}
                  order={values.rentType === 'RATE_ONLY' || values.rentType === 'BENCHMARK_RENT' ? 2 : 4}
                >
                  <TextField
                    label="Rent Rate"
                    type="number"
                    {...getFieldProps('rentRate')}
                    InputProps={{
                      'data-testid': 'rentDetailsForm',
                    }}
                    error={Boolean(touched.rentRate && errors.rentRate)}
                    helperText={touched.rentRate && errors.rentRate}
                    onChange={onChangeHandler}
                  />
                </GridItem>
              )}
            <GridItem xs={12} md={4} pl={0} order={5}>
              <Datepicker
                label="Calculation Starts"
                name="calculationStarts"
                value={values.calculationStarts}
                inputFormat="MM/DD/YYYY"
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                }}
                onChange={(date) => setFieldValue('calculationStarts', date)}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0} order={6}>
              <Datepicker
                label="Calculation Ends"
                name="calculationEnds"
                value={values.calculationEnds}
                inputFormat="MM/DD/YYYY"
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                }}
                onChange={(date) => setFieldValue('calculationEnds', date)}
                disabled
              />
            </GridItem>
            {values.rentType !== 'FEE_ONLY' &&
              values.rentType !== 'FEE_ONLY_ANNUAL' &&
              values.rentType !== 'FEE_ONLY_YEAR' &&
              values.rentType !== 'FEE_PER_PIC' &&
              values.rentType !== 'FEE_ONLY_AFTER_BENCHMARK' &&
              values.rentType !== 'FEE_ONLY_SLIDING_BENCHMARK' &&
              values.rentType !== 'RATE_ONLY' &&
              values.rentType !== 'BENCHMARK_RENT' &&
              values?.breakpointNatural !== undefined && (
                <>
                  <GridItem xs={12} md={4} pl={0} order={7}>
                    <Select
                      inputLabel="Breakpoint Natural"
                      options={breakPointNaturalArr}
                      {...getFieldProps('breakpointNatural')}
                      data-testid="rentDetailsForm"
                      error={Boolean(touched.breakpointNatural && errors.breakpointNatural)}
                      helperText={touched.breakpointNatural && errors.breakpointNatural}
                      onChange={onChangeHandler}
                    />
                  </GridItem>
                  <GridItem xs={12} md={4} pl={0} order={8}>
                    <TextField
                      label="Breakpoint Value"
                      type="number"
                      {...getFieldProps('breakpointValue')}
                      InputProps={{
                        'data-testid': 'rentDetailsForm',
                        startAdornment: <AttachMoney fontSize="small" />,
                      }}
                      disabled={values?.breakpointNatural}
                      error={Boolean(touched.breakpointValue && errors.breakpointValue)}
                      helperText={touched.breakpointValue && errors.breakpointValue}
                    />
                  </GridItem>
                </>
              )}
            <GridItem xs={12} md={4} pl={0} order={9}>
              <TextField
                label="CAM Fee"
                type="number"
                {...getFieldProps('CAMFee')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                  startAdornment: <AttachMoney fontSize="small" />,
                }}
                error={Boolean(touched.CAMFee && errors.CAMFee)}
                helperText={touched.CAMFee && errors.CAMFee}
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0} order={10}>
              <TextField
                label="CAM Rate"
                type="number"
                {...getFieldProps('CAMRate')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                }}
                error={Boolean(touched.CAMRate && errors.CAMRate)}
                helperText={touched.CAMRate && errors.CAMRate}
              />
            </GridItem>
            {(values.rentType === 'RATE_ONLY' ||
              values.rentType === 'RATE_PLUS_FEE' ||
              values.rentType === 'BENCHMARK_RENT' ||
              values.rentType === 'COMBO' ||
              values.rentType === 'RATE_FEE_HIGHER' ||
              values.rentType === 'SUM' ||
              values.rentType === 'NA') && (
              <>
                <GridItem xs={12} pl={0} order={11}>
                  <Autocomplete
                    value={values.modalTypes}
                    options={isSuccess && gameCategories}
                    onChange={(event, value) => autoCompleteChangeHandler('modalTypes', value)}
                    label="Products"
                    data-testid="rentDetailsForm"
                    getOptionLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    multiple
                    disableCloseOnSelect
                  />
                </GridItem>
                {dynamicTextFields.length > 0 &&
                  dynamicTextFields.map((value, index) => {
                    const order = index + 1 + 11;
                    return (
                      <GridItem key={order} order={order} xs={12} md={4} pl={0}>
                        <TextField
                          type="number"
                          label={value.name}
                          value={value.rate}
                          InputProps={{
                            'data-testid': 'rentDetailsForm',
                          }}
                          onChange={(e) => {
                            dynamicTextFieldChangeHandler(index, e.target.value);
                          }}
                          error={
                            touched.modalTypes &&
                            errors.modalTypes &&
                            errors.modalTypes[index] &&
                            touched.modalTypes[index] &&
                            Boolean(touched.modalTypes[index]?.rate && errors.modalTypes[index]?.rate)
                          }
                          helperText={
                            touched.modalTypes &&
                            errors.modalTypes &&
                            errors.modalTypes[index] &&
                            touched.modalTypes[index] &&
                            touched.modalTypes[index]?.rate &&
                            errors.modalTypes[index]?.rate
                          }
                        />
                      </GridItem>
                    );
                  })}
              </>
            )}
            <GridItem xs={12} md={4} pl={0} order={dynamicTextFields?.length > 0 ? 12 + dynamicTextFields?.length : 12}>
              <TextField
                label="Threshold Rate"
                type="number"
                {...getFieldProps('thresholdRate')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                  startAdornment: <AttachMoney fontSize="small" />,
                }}
                error={Boolean(touched.thresholdRate && errors.thresholdRate)}
                helperText={touched.thresholdRate && errors.thresholdRate}
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0} order={dynamicTextFields?.length > 0 ? 13 + dynamicTextFields?.length : 13}>
              <TextField
                label="Threshold Revenue"
                type="number"
                {...getFieldProps('thresholdRevenue')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                  startAdornment: <AttachMoney fontSize="small" />,
                }}
                error={Boolean(touched.thresholdRevenue && errors.thresholdRevenue)}
                helperText={touched.thresholdRevenue && errors.thresholdRevenue}
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0} order={dynamicTextFields?.length > 0 ? 14 + dynamicTextFields?.length : 14}>
              <TextField
                label="Notes"
                type="text"
                {...getFieldProps('notes')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                }}
                multiline
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </GridItem>
            <GridItem xs={12} md={3} pl={0} order={dynamicTextFields?.length > 0 ? 15 + dynamicTextFields?.length : 15}>
              <TextField
                label="Coin Cost"
                type="number"
                {...getFieldProps('coinCost')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                  startAdornment: <AttachMoney fontSize="small" />,
                }}
                error={Boolean(touched.coinCost && errors?.coinCost)}
                helperText={touched?.coinCost && errors?.coinCost}
              />
            </GridItem>
            <GridItem xs={12} md={3} pl={0} order={dynamicTextFields?.length > 0 ? 16 + dynamicTextFields?.length : 16}>
              <TextField
                label="Card Cost"
                type="number"
                {...getFieldProps('cardCost')}
                InputProps={{
                  'data-testid': 'rentDetailsForm',
                }}
                error={Boolean(touched.cardCost && errors?.cardCost)}
                helperText={touched?.cardCost && errors?.cardCost}
              />
            </GridItem>
          </GridContainer>
          <Box mt={5} className="next-btn-wrapper">
            <Stack direction="row">
              <LoadingButton loading={isLoading} type="submit" variant="contained" data-testid="nextBtn" ref={ref}>
                {isUpdate ? 'Update' : 'Next'}
              </LoadingButton>
              <Button onClick={isUpdate ? onCancelClick : onBackClick} data-testid="backBtn">
                {isUpdate ? 'Cancel' : 'Back'}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
});
RentDetailsForm.displayName = displayName;
export default RentDetailsForm;
