import { Box, Typography } from '@mui/material';
import { useMemo, useCallback } from 'react';
import { Button, GridContainer, GridItem, PositionedMenu, Link } from '../../../components/shared';
import ReactTable from '../../../components/shared/ReactTable';
import { COLUMNS } from '../../../constants/tableColumns';
import { useGetMachineDetailsByIdQuery } from '../../../services/Machines/MachinesService';
import MachineAccordion from '../../../components/MachineAccordion/MachineAccordion';
import useStyles from './style';
import { routeConstants } from '../../../constants/routeConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useUpdateModemByIdMutation } from '../../../services/Modems/ModemsService';
import { Add } from '@mui/icons-material';

const AssetDetails = ({ assetId, setAssignNewMachine, modemId }) => {
  const classes = useStyles();
  const {
    isFetching,
    isLoading,
    isSuccess,
    currentData: machineDetails,
    isError,
  } = useGetMachineDetailsByIdQuery(Number(assetId));
  const [updateModemApi] = useUpdateModemByIdMutation();

  const titleLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`/${routeConstants.MACHINES_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );
  const COLUMN_POSITIONED_MENU = [
    {
      Header: () => null,
      id: 'more',
      Cell: () => (
        <PositionedMenu
          menuItemData={[
            {
              btnName: 'Remove Machine',
              btnClickFunc: () => {
                onRemoveHandler();
              },
            },
          ]}
        />
      ),
    },
  ];
  const columns = [COLUMNS.EXPAND_ICON_COLUMN, ...COLUMNS.ASSIGN_MACHINE_TABLE, ...COLUMN_POSITIONED_MENU];

  const data = useMemo(() => {
    const tableData = [];
    const sublocationName = machineDetails?.data?.sublocation?.name;
    const modelTitle = machineDetails?.data?.model?.title;
    const statusTitle = machineDetails?.data?.status?.title;
    const machineData = {
      ...machineDetails?.data,
      sublocationName,
      modelTitle,
      statusTitle,
    };
    machineData.title = titleLink(machineData.id, machineData.title);
    tableData.push(machineData);
    return tableData;
  }, [machineDetails, isSuccess]);

  const machineComponent = ({ row }) => {
    const original = row?.original;
    return original && <MachineAccordion machineInfo={original} />;
  };

  const assignMachineHandler = () => {
    setAssignNewMachine(true);
  };
  const onRemoveHandler = () => {
    updateModemApi({
      id: modemId,
      assetId: null,
    });
  };

  return (
    <Box>
      <GridContainer spacing={3} className={classes.machineDetails}>
        <GridItem xs={6} className={classes.machineDetailTitle}>
          <Typography variant="h2">Assigned Machine</Typography>
        </GridItem>
        <GridItem xs={6} className={classes.assignNewBtn}>
          <Button
            variant="outlined"
            onClick={assignMachineHandler}
            size="large"
            startIcon={<Add />}
            data-testid="assign-new-button"
          >
            ASSIGN NEW
          </Button>
        </GridItem>
      </GridContainer>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {!isLoading && !isFetching && !isError && data && (
        <ReactTable
          data={data}
          columns={columns}
          stickyHeader
          tableHeight={300}
          expandable
          renderRowSubComponent={machineComponent}
        />
      )}
    </Box>
  );
};

export default AssetDetails;
