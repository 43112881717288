import { Add, PinDropOutlined } from '@mui/icons-material';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Link, ReactTable } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { COLUMNS, LEASE_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { getDateFormat } from '../../../utils/common-methods';
import LeaseTableAccordion from './LeaseTableAccordion';
import useStyles from './style';

const LeaseTable = (props) => {
  const { locationDetails, disabled } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const COLUMN_LOCATION_NAME = {
    Header: 'LEASE NAME',
    accessor: 'name',
    width: 'auto',
    Cell: ({ row }) =>
      disabled ? (
        <Typography variant="body1">{row?.original?.name}</Typography>
      ) : (
        <Link underline="none" to={`/${routeConstants.LEASE_ROUTE}/${row.original?.id}`}>
          <Box>{row.original?.name}</Box>
        </Link>
      ),
  };
  const columns = useMemo(() => {
    const dynamicColumns = LEASE_TABLE_DYNAMIC(isBelowMD)?.slice(1);
    const serializedActiveColumn = [COLUMNS.EXPAND_ICON_COLUMN, COLUMN_LOCATION_NAME, ...dynamicColumns];
    return serializedActiveColumn;
  }, [isBelowMD]);

  const autoCapitalizedStatus = (status) => {
    switch (status) {
      case 'QUOTE':
        return 'Quote';
      case 'MANAGER_REVIEW':
        return 'Manager Review';
      case 'FOLLOW_UP':
        return 'Follow Up';
      case 'ONGOING':
        return 'Ongoing';
      case 'REJECT_LEASE':
        return 'Rejected';
      case 'EXPIRED':
        return 'Expired';
      case 'TERMINATE':
        return 'Terminated';
      default:
        return 'N/A';
    }
  };

  const transformedData = useMemo(() => {
    const tableBodyTransformed = locationDetails?.contracts?.map((value) => ({
      id: value?.id,
      name: value?.name,
      status: autoCapitalizedStatus(value?.processStatus),
      startDate: value?.termStartDate ? getDateFormat(value?.termStartDate) : 'N/A',
      endDate: value?.termEndDate ? getDateFormat(value?.termEndDate) : 'N/A',
      sublocation: value?.contractSublocations,
      address1: value?.location?.address1 || 'N/A',
      address2: value?.location?.address2 || 'N/A',
      postcode: value?.location?.postcode || 'N/A',
      city: value?.location?.city?.name || 'N/A',
      state: value?.location?.state?.name || 'N/A',
      country: value?.location?.country?.name || 'N/A',
      rentType: value?.rentType || 'N/A',
      fee: value?.fee || 'N/A',
      rate: value?.rate || 'N/A',
    }));
    return tableBodyTransformed;
  }, [locationDetails]);

  const AddBtn = () => (
    <Box className={classes.addBtn}>
      <Button
        variant="outlined"
        size="large"
        data-testid="add-sublocation"
        startIcon={<Add />}
        onClick={() => navigate(`/${routeConstants.ADD_LEASE_ROUTE}`)}
        disabled={disabled}
      >
        ADD NEW
      </Button>
    </Box>
  );
  const leaseAccordion = ({ row }) => {
    const original = row?.original;
    return original && <LeaseTableAccordion leaseInfo={original} disabled={disabled} />;
  };

  return (
    <ReactTable
      data={transformedData}
      columns={columns}
      stickyHeader
      tableHeight={500}
      expandable
      renderRowSubComponent={leaseAccordion}
      localFilter
      noContentIcon={<PinDropOutlined sx={{ color: theme.palette.primary.main }} />}
      searchPlaceHolder="Search by lease name & status"
      extraBtn={<AddBtn />}
    />
  );
};

export default LeaseTable;
