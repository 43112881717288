import React, { useEffect, useMemo, useState } from 'react';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Chip, Paper, Stack, Typography } from '@mui/material';
import { Accordion, Breadcrumbs, ButtonDropdown, PositionedMenu } from '../../../components/shared';
import useStyles from './style';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import RentDetailsForm from '../../../components/RentDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  rentDetailsFormAction,
  generalDetailsFormAction,
  leaseDetailsFormAction,
  setSearchApiParamsAction,
} from '../../../redux-slice/lease';
import GeneralDetailsForm from '../../../components/GeneralDetailsFrom/GeneralDetailsForm';
import LeaseDetailsAccordion from './LeaseDetailsAccordion';
import ApprovalRequestModal from './ApprovalRequestModal';
import TimerModal from '../../../components/TimerModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import TerminationRequestModal from './TerminationRequestModal';
import { useGetLeaseByIdQuery, useUpdateLeaseByIdMutation } from '../../../services/Lease/LeaseService';
import { dateFormatForApi } from '../../../utils/common-methods';
import dayjs from 'dayjs';

const LeaseDetails = () => {
  const {
    LEASE: { LEASE, LEASE_DETAILS },
  } = BREADCRUMB_NAMES;

  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leaseId = Number(params.id);
  const searchApiParams = {
    pending: 'processStatus=&locationId=&leaseStatus=PENDING',
    active: 'processStatus=&locationId=&leaseStatus=ACTIVE',
    archive: 'processStatus=&locationId=&leaseStatus=ARCHIVE',
  };
  const [isUpdate, setIsUpdate] = useState({
    isUpdateRentDetails: false,
    isUpdateGeneralDetails: false,
  });
  const [statusModals, setStatusModals] = useState({
    ongoing: false,
    reject_lease: false,
    expired: false,
    terminate: false,
  });
  const [timerModals, setTimerModals] = useState({
    approved: false,
    rejected: false,
    expired: false,
    terminated: false,
  });
  const [time, setTime] = useState(5);
  const [activateTimer, setActivateTimer] = useState(false);
  const leaseState = useSelector((state) => state?.lease?.leaseForms);
  const { leaseDetails } = leaseState;
  const { currentData: leaseDetail = {}, isLoading, isFetching } = useGetLeaseByIdQuery(leaseId);
  const [updateLease, { isLoading: updateLeaseLoading }] = useUpdateLeaseByIdMutation();

  const breadcrumbData = useMemo(
    () => [
      {
        text: LEASE,
        redirection: `${routeConstants.LEASE_ROUTE}?selectedTab=${
          leaseDetail?.leaseStatus === 'ARCHIVE' ? 3 : leaseDetail?.leaseStatus === 'ACTIVE' ? 2 : 1
        }`,
      },
      { text: LEASE_DETAILS },
    ],
    [leaseDetail?.leaseStatus],
  );
  const buttonDropOptions = [
    { value: 'QUOTE', text: 'Quote' },
    { value: 'MANAGER_REVIEW', text: 'Manager Review' },
    { value: 'FOLLOW_UP', text: 'Follow Up' },
    { value: 'ONGOING', text: 'Ongoing' },
    { value: 'REJECT_LEASE', text: 'Reject Lease' },
    { value: 'EXPIRED', text: 'Expired' },
    { value: 'TERMINATE', text: 'Terminate' },
  ];

  const editLocationDetailHandler = () => {
    navigate(`/${routeConstants.LEASE_ROUTE}/${routeConstants.EDIT_LEASE_ROUTE}/${leaseId}`);
  };

  useEffect(() => {
    const modelTypes = leaseDetail?.contractAssetGameCategoryRates?.map((value) => ({
      id: value?.gameCategory?.id,
      name: value?.gameCategory?.name,
      rate: value?.rentRate,
    }));
    dispatch(
      leaseDetailsFormAction({
        ...leaseDetails,
        location: leaseDetail?.location,
        leaseBegins: leaseDetail?.termStartDate ? dayjs(leaseDetail?.termStartDate).toString() : null,
        leaseEnds: leaseDetail?.termEndDate ? dayjs(leaseDetail?.termEndDate).toString() : null,
      }),
    );
    dispatch(
      rentDetailsFormAction({
        rentType: leaseDetail?.rentType || 'RATE_PLUS_FEE',
        rentFee: leaseDetail?.fee || 0.0,
        rentCalculationTiming: leaseDetail?.rentCalculateInterval || '',
        rentRate: leaseDetail?.rate || 0.0,
        calculationStarts: null, //not available in API
        calculationEnds: null, //not available in API
        breakpointNatural: leaseDetail?.hasNaturalBreakPoint,
        breakpointValue: leaseDetail?.naturalBreakPointValue || 0.0,
        CAMFee: leaseDetail?.CAMFee || 0.0,
        CAMRate: leaseDetail?.CAMRate || 0.0,
        modalTypes: modelTypes,
        thresholdRate: leaseDetail?.thresholdRate || 0.0,
        thresholdRevenue: leaseDetail?.thresholdRevenue || 0.0,
        notes: leaseDetail?.notes || '',
        coinCost: leaseDetail?.coinCost || 0.0,
        cardCost: leaseDetail?.cardCost || 0.0,
      }),
    );
    dispatch(
      generalDetailsFormAction({
        workersComp: leaseDetail?.workersComp || '',
        genLiability: leaseDetail?.genLiability || '',
        umbrella: leaseDetail?.umbrella || '',
        terminationDays: leaseDetail?.termDays || '',
        relocationDays: leaseDetail?.reloDays || '',
        salesReportsBy: leaseDetail?.salesReportBy || '',
        percentRentBy: leaseDetail?.percentRateBy || '',
        fixedRentDue: leaseDetail?.fixedRentDue || '',
        addendum: leaseDetail?.addendum || '',
        securityDeposit: leaseDetail?.securityDeposit || '',
        grossSales: leaseDetail?.grossSales || '',
        OCC: leaseDetail?.OCCPercentage || '',
      }),
    );
  }, [JSON.stringify(leaseDetail)]);

  const rentDetailsObj = (rentDetails) => {
    const gameCategoryRates = rentDetails?.modalTypes?.map((value) => ({
      gameCategoryId: value?.id,
      rate: value?.rate,
    }));
    switch (rentDetails?.rentType) {
      case 'RATE_PLUS_FEE':
      case 'COMBO':
      case 'RATE_FEE_HIGHER':
      case 'SUM':
      case 'NA':
        return {
          fee: rentDetails?.rentFee,
          rate: rentDetails?.rentRate,
          hasNaturalBreakPoint: rentDetails?.breakpointNatural,
          naturalBreakPointValue: rentDetails?.breakpointValue,
          gameCategoryRates: gameCategoryRates,
        };
      case 'RATE_ONLY':
      case 'BENCHMARK_RENT':
        return {
          rate: rentDetails?.rentRate,
          gameCategoryRates: gameCategoryRates,
        };
      case 'FEE_ONLY':
      case 'FEE_ONLY_ANNUAL':
      case 'FEE_ONLY_YEAR':
      case 'FEE_PER_PIC':
      case 'FEE_ONLY_AFTER_BENCHMARK':
      case 'FEE_ONLY_SLIDING_BENCHMARK':
        return {
          fee: rentDetails?.rentFee,
        };

      default:
        return {};
    }
  };
  const rentDetailsUpdateHandler = (values) => {
    const rentDetailsCommon = {
      rentType: values?.rentType,
      rentCalculateInterval: values?.rentCalculationTiming,
      CAMFee: values?.CAMFee,
      CAMRate: values?.CAMRate,
      thresholdRate: values?.thresholdRate,
      thresholdRevenue: values?.thresholdRevenue,
      notes: values?.notes,
      coinCost: values?.coinCost,
      cardCost: values?.cardCost,
    };
    const conditionWiseRentDetailsObj = rentDetailsObj(values);
    const finalRentDetailsObj = {
      id: leaseId,
      ...rentDetailsCommon,
      ...conditionWiseRentDetailsObj,
    };

    updateLease(finalRentDetailsObj).then((res) => {
      if (res.data) {
        setIsUpdate((prev) => ({ ...prev, isUpdateRentDetails: false }));
      }
    });
  };

  const generalDetailsUpdateHandler = (values) => {
    const generalDetailsObj = {
      id: leaseId,
      workersComp: values?.workersComp,
      genLiability: values?.genLiability,
      umbrella: values?.umbrella,
      termDays: values?.terminationDays,
      reloDays: values?.relocationDays,
      salesReportBy: values?.salesReportsBy,
      percentRateBy: values?.percentRentBy,
      fixedRentDue: values?.fixedRentDue,
      addendum: values?.addendum,
      securityDeposit: values?.securityDeposit,
      grossSales: values?.grossSales,
      OCCPercentage: values?.OCC,
    };
    updateLease(generalDetailsObj).then((res) => {
      if (res.data) {
        setIsUpdate((prev) => ({ ...prev, isUpdateGeneralDetails: false }));
      }
    });
  };

  const closeStatusModelsAndOpenTimerModals = (status) => {
    switch (status) {
      case 'ONGOING':
        closeStatusModals('ongoing');
        openTimerModal('approved');
        break;
      case 'REJECT_LEASE':
        closeStatusModals('reject_lease');
        openTimerModal('rejected');
        break;
      case 'EXPIRED':
        closeStatusModals('expired');
        openTimerModal('expired');
        break;
      case 'TERMINATE':
        closeStatusModals('terminate');
        openTimerModal('terminated');
        break;
    }
  };

  const updateLeaseApiCalling = (data) => {
    updateLease(data).then((res) => {
      if (res.data) {
        const { data } = res.data ?? {};
        closeStatusModelsAndOpenTimerModals(data.processStatus);
      }
    });
  };

  const openModalOnSelectedValue = (value) => {
    if (value === 'ONGOING' || value === 'REJECT_LEASE' || value === 'EXPIRED' || value === 'TERMINATE') {
      setStatusModals((prevValue) => ({
        ...prevValue,
        [value.toLowerCase()]: true,
      }));
    } else {
      dispatch(setSearchApiParamsAction(searchApiParams.pending));
      const data = {
        id: leaseId,
        processStatus: value,
        leaseStatus: 'PENDING',
      };
      updateLeaseApiCalling(data);
    }
  };

  const onButtonChangeHandler = (selected) => {
    openModalOnSelectedValue(selected?.value);
  };

  const activateTimerWithTime = () => {
    setActivateTimer(true);
    setTime(5);
  };

  const openTimerModal = (name) => {
    activateTimerWithTime();
    setTimeout(() => {
      setTimerModals((prevValue) => ({ ...prevValue, [name]: true }));
    }, 300);
  };

  const closeTimerModal = (name) => {
    setActivateTimer(false);
    setTimerModals((prevValue) => ({ ...prevValue, [name]: false }));

    if (name === 'approved') {
      dispatch(setSearchApiParamsAction(searchApiParams.active));
      navigate(`/${routeConstants.LEASE_ROUTE}?selectedTab=2`, {
        replace: true,
      });
    } else if (name === 'rejected' || name === 'expired' || name === 'terminated') {
      dispatch(setSearchApiParamsAction(searchApiParams.archive));
      navigate(`/${routeConstants.LEASE_ROUTE}?selectedTab=3`, {
        replace: true,
      });
    }
  };

  const closeStatusModals = (name) => {
    setStatusModals((prevValue) => ({ ...prevValue, [name]: false }));
  };

  const onApproveStatusHandler = (values) => {
    const data = {
      id: leaseId,
      processStatus: 'ONGOING',
      leaseStatus: 'ACTIVE',
      termStartDate: dateFormatForApi(values?.leaseBegins),
      termEndDate: dateFormatForApi(values?.leaseEnds),
    };
    updateLeaseApiCalling(data);
  };

  const onRejectLeaseModalClickHandler = (value) => {
    if (value === 'REJECT_LEASE') {
      const data = {
        id: leaseId,
        processStatus: value,
        leaseStatus: 'ARCHIVE',
      };
      updateLeaseApiCalling(data);
    } else {
      closeStatusModals('reject_lease');
    }
  };

  const onExpiredModalClickHandler = (value) => {
    if (value === 'EXPIRED') {
      const data = {
        id: leaseId,
        processStatus: value,
        leaseStatus: 'ARCHIVE',
      };
      updateLeaseApiCalling(data);
    } else {
      closeStatusModals('expired');
    }
  };

  const terminationSubmitHandler = (values) => {
    console.log('values', values); // not available in api.
    const data = {
      id: leaseId,
      processStatus: 'TERMINATE',
      leaseStatus: 'ARCHIVE',
    };
    updateLeaseApiCalling(data);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>

      <Box mb={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Box>
            <Stack direction={'row'} alignItems="center" spacing={1.5}>
              <Typography variant="h1" data-testid="leaseName">
                {leaseDetail?.name}
              </Typography>
              <Chip
                label={leaseDetail?.leaseStatus}
                color={
                  leaseDetail?.leaseStatus === 'PENDING'
                    ? 'primary'
                    : leaseDetail?.leaseStatus === 'ACTIVE'
                    ? 'success'
                    : 'error'
                }
                variant="outlined"
                data-testid="status"
              />
            </Stack>
          </Box>
          <Box>
            <ButtonDropdown
              options={buttonDropOptions}
              value={leaseDetail?.processStatus}
              onChange={onButtonChangeHandler}
            />
          </Box>
        </Stack>
      </Box>
      <Box mb={3}>
        <Accordion
          defaultExpanded
          marginBottom={3}
          title={'Lease Details'}
          content={
            isLoading || isFetching ? (
              <LoadingSpinner containerHeight={400} />
            ) : (
              <LeaseDetailsAccordion leaseDetails={leaseDetail} isFrom={'LEASE'} />
            )
          }
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: editLocationDetailHandler,
                },
              ]}
            />
          }
        />
      </Box>
      <Box mb={3}>
        {!isUpdate.isUpdateRentDetails && (
          <Accordion
            defaultExpanded
            marginBottom={3}
            title={'Rent Details'}
            content={
              isLoading || isFetching ? (
                <LoadingSpinner containerHeight={400} />
              ) : (
                <LeaseDetailsAccordion leaseDetails={leaseDetail} isFrom={'RENT'} />
              )
            }
            subComponent={
              <PositionedMenu
                menuItemData={[
                  {
                    btnName: 'Edit',
                    btnClickFunc: () =>
                      setIsUpdate((prev) => ({
                        ...prev,
                        isUpdateRentDetails: true,
                      })),
                  },
                ]}
              />
            }
          />
        )}
        {isUpdate.isUpdateRentDetails && (
          <Box component={Paper} pt={3} pl={3} pb={4}>
            <Box>
              <Typography variant="h2" data-testid="editRentDetails">
                Edit Rent Details
              </Typography>
            </Box>
            <Box mb={1}>
              <RentDetailsForm
                isUpdate
                onCancelClick={() =>
                  setIsUpdate((prev) => ({
                    ...prev,
                    isUpdateRentDetails: false,
                  }))
                }
                onUpdateClick={rentDetailsUpdateHandler}
                isLoading={updateLeaseLoading}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box mb={3}>
        {!isUpdate.isUpdateGeneralDetails && (
          <Accordion
            defaultExpanded
            marginBottom={3}
            title={'General Details'}
            content={
              isLoading || isFetching ? (
                <LoadingSpinner containerHeight={400} />
              ) : (
                <LeaseDetailsAccordion leaseDetails={leaseDetail} isFrom={'GENERAL'} />
              )
            }
            subComponent={
              <PositionedMenu
                menuItemData={[
                  {
                    btnName: 'Edit',
                    btnClickFunc: () =>
                      setIsUpdate((prev) => ({
                        ...prev,
                        isUpdateGeneralDetails: true,
                      })),
                  },
                ]}
              />
            }
          />
        )}
        {isUpdate.isUpdateGeneralDetails && (
          <Box component={Paper} pt={3} pl={3} pb={4}>
            <Box>
              <Typography variant="h2" data-testid="editGeneralDetails">
                Edit General Details
              </Typography>
            </Box>
            <Box>
              <GeneralDetailsForm
                isUpdate
                onCancelClick={() =>
                  setIsUpdate((prev) => ({
                    ...prev,
                    isUpdateGeneralDetails: false,
                  }))
                }
                onUpdateClick={generalDetailsUpdateHandler}
                isLoading={updateLeaseLoading}
              />
            </Box>
          </Box>
        )}
      </Box>
      <ApprovalRequestModal
        isOpen={statusModals.ongoing}
        approveClick={onApproveStatusHandler}
        onCancelClick={() => closeStatusModals('ongoing')}
      />
      {timerModals.approved && (
        <TimerModal
          isOpen={timerModals.approved}
          timer={time}
          activate={activateTimer}
          onTimeComplete={() => {
            closeTimerModal('approved');
          }}
          icon={<CheckCircleIcon fontSize="large" className={classes.successIcon} />}
          title="Successful!"
          msg="You have successfully approved the lease, and it is now active You will be redirected in"
        />
      )}
      {timerModals.rejected && (
        <TimerModal
          isOpen={timerModals.rejected}
          timer={time}
          activate={activateTimer}
          onTimeComplete={() => {
            closeTimerModal('rejected');
          }}
          icon={<WarningIcon fontSize="large" className={classes.dangerIcon} />}
          title="Rejected!"
          msg="This lease is now rejected as per your request You will be redirected in"
        />
      )}
      {timerModals.expired && (
        <TimerModal
          isOpen={timerModals.expired}
          timer={time}
          activate={activateTimer}
          onTimeComplete={() => {
            closeTimerModal('expired');
          }}
          icon={<WarningIcon fontSize="large" className={classes.dangerIcon} />}
          title="Expired!"
          msg="This lease is now marked as expired You will be redirected in"
        />
      )}
      {timerModals.terminated && (
        <TimerModal
          isOpen={timerModals.terminated}
          timer={time}
          activate={activateTimer}
          onTimeComplete={() => {
            closeTimerModal('terminated');
          }}
          icon={<WarningIcon fontSize="large" className={classes.dangerIcon} />}
          title="Terminated!"
          msg="This lease is now terminated as per your request You will be redirected in"
        />
      )}

      <ConfirmationModal
        isOpen={statusModals.reject_lease}
        title="Confirmation"
        msg="Are you sure you want to reject this lease? This cannot be recovered"
        buttons={[
          { text: 'Reject', value: 'REJECT_LEASE' },
          { text: 'Cancel', value: 'CANCEL' },
        ]}
        onClick={onRejectLeaseModalClickHandler}
      />
      <ConfirmationModal
        isOpen={statusModals.expired}
        title="Confirmation"
        msg="Are you sure you want to mark this lease as expired? It can be retrieve from archive tab later"
        buttons={[
          { text: 'Expired', value: 'EXPIRED' },
          { text: 'Cancel', value: 'CANCEL' },
        ]}
        onClick={onExpiredModalClickHandler}
      />
      <TerminationRequestModal
        isOpen={statusModals.terminate}
        submitClick={terminationSubmitHandler}
        onCancelClick={() => closeStatusModals('terminate')}
      />
    </Box>
  );
};

export default LeaseDetails;
