import { Box, Paper, Typography } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import AssignNewModemTable from './AssignNewModemTable';
import { Button } from '../../../components/shared';
import useStyles from './style';
import { useState, useEffect } from 'react';
import { useUpdateModemByIdMutation, useDeleteModemByIdMutation } from '../../../services/Modems/ModemsService';
import { isArrayWithLength } from '../../../utils/common-methods';
import { MESSAGE } from '../../../constants/message';
import ConfirmationModal from '../../../components/ConfirmationModel';
import TimerModal from '../../../components/TimerModal';
import { CheckCircle } from '@mui/icons-material';

const AssignModem = ({ machineDetails, showAssignModem, expandModemDetailView, modemInfo = [] }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [preSelectedRows, setPreSelectedRows] = useState({});
  const [searchText, setSearchText] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [updateModemApi] = useUpdateModemByIdMutation();
  const [deleteModemApi] = useDeleteModemByIdMutation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModemDelete, setShowSuccessModemDelete] = useState(false);
  const [deleteModemId, setDeleteModemId] = useState(null);

  useEffect(() => {
    if (isArrayWithLength(modemInfo)) {
      let preSelectedRow = {};
      modemInfo?.forEach((modem) => {
        preSelectedRow = { ...preSelectedRow, [modem?.id]: true };
      });
      setRows({ ...preSelectedRow });
      setPreSelectedRows({ ...preSelectedRow });
    }
  }, []);
  const onSelect = (row, checked) => {
    setRows((prev) => ({ ...prev, [row.id]: checked }));
  };

  const onSaveHandler = () => {
    const assetId = machineDetails?.id;
    const selectedRows = [];
    for (const key in rows) {
      const selectModemId = Boolean(preSelectedRows[key] && rows[key] === false);
      if ((rows[key] && !preSelectedRows[key]) || selectModemId) {
        selectedRows.push(key);
      }
    }
    if (selectedRows && isArrayWithLength(selectedRows)) {
      const apiData = selectedRows?.map((id) => ({
        id: id,
        assetId: preSelectedRows[id] ? null : assetId,
      }));
      apiData?.forEach((modem) => {
        updateModemApi(modem).then((response) => {
          if (response.data) {
            showAssignModem(false);
            expandModemDetailView(true);
          }
        });
      });
    } else {
      showAssignModem(false);
      expandModemDetailView(true);
    }
  };
  const onCancelHandler = () => {
    setRows([]);
    showAssignModem(false);
  };
  const onDeleteModemHandler = (modemData) => {
    setShowDeleteModal(true);
    setDeleteModemId(modemData?.id);
  };
  const searchHandler = (params) => {
    setSearchText(params);
  };

  const onReset = () => {
    setSearchText('');
  };

  const onDeleteModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      deleteHandler();
    } else {
      setShowDeleteModal(false);
      setShowSuccessModemDelete(false);
    }
  };

  const deleteHandler = () => {
    if (deleteModemId) {
      deleteModemApi(deleteModemId).then((response) => {
        setShowDeleteModal(false);
        if (response.data) {
          setShowSuccessModemDelete(true);
        }
      });
    }
  };

  return (
    <Box component={Paper}>
      <Box className={classes.assignNewModemTitle}>
        <Typography variant="h2">Assign New Modems</Typography>
      </Box>
      <Box className={classes.modemSearch}>
        <SearchBar onSearch={searchHandler} onReset={onReset} />
      </Box>
      <AssignNewModemTable
        onSelect={onSelect}
        q={searchText}
        selectedRows={rows}
        setIsSuccess={setIsSuccess}
        onDeleteModem={onDeleteModemHandler}
      />
      {isSuccess && (
        <Box className={classes.btnWrap}>
          <Button variant="contained" onClick={onSaveHandler} disabled={rows.length === 0}>
            <Typography variant="button">SAVE</Typography>
          </Button>
          <Button onClick={onCancelHandler}>
            <Typography variant="button">CANCEL</Typography>
          </Button>
        </Box>
      )}
      <ConfirmationModal
        key={'delete-modal'}
        isOpen={showDeleteModal}
        title={MESSAGE.CONFIRMATION}
        msg={MESSAGE.MODEM_DELETE_DESCRIPTION}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={onDeleteModalClickHandler}
      />
      <TimerModal
        isOpen={showSuccessModemDelete}
        title={MESSAGE.SUCCESSFUL}
        timer={5}
        activate={showSuccessModemDelete}
        onTimeComplete={() => setShowSuccessModemDelete(false)}
        icon={<CheckCircle color={'success'} sx={{ fontSize: '4rem' }} />}
        msg={`Modem ${deleteModemId} Deleted Successfully`}
      />
    </Box>
  );
};

export default AssignModem;
