import { Typography, Box, Stack, Paper } from '@mui/material';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  dateFormatForApi,
  get24HoursTimeFormat,
  set24HoursTimeFormat,
  removeKeysFromObj,
  getPhNo10Digits,
  formatPhNo10Digit,
  checkValueNotNullUndefinedBlank,
  getOptions,
  isArrayWithLength,
} from '../../../utils/common-methods';
import {
  GridContainer,
  Button,
  CheckBox,
  GridItem,
  TextField,
  Select,
  Datepicker,
  Timepicker,
  Autocomplete,
} from '../../../components/shared';
import { subLocAddUpdateSchema } from '../../../schema/validationSchemas';

import useStyles from './style';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import {
  useGetProductCategoriesQuery,
  useGetSubLocationTypesQuery,
} from '../../../services/Sublocation/SublocationService';
import { useState } from 'react';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';

const SubLocationUpdateDetailView = (props) => {
  const classes = useStyles();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [openHrsClockPopup, setOpenHrsClockPopup] = useState(false);
  const [openCloseHrsClockPopup, setOpenCloseHrsClockPopup] = useState(false);
  const {
    subLocationDetails,
    onCancel,
    onSave,
    isAddNewSubLoc = false,
    loadingBtnUse = false,
    isLoading = false,
  } = props;

  const { currentData: locationTypesData, isSuccess: typeSuccess } = useGetSubLocationTypesQuery();
  const { currentData: productTypes } = useGetProductCategoriesQuery();
  const productTypeOptions = getOptions(productTypes);
  const productTypesInitialValues = subLocationDetails?.productCategories?.map((product) => ({
    text: product?.name,
    value: product?.id,
  }));

  const locationTypeOptions = locationTypesData?.map((value) => ({
    value: value?.id,
    text: value?.name,
  }));

  const locationOptions = [
    {
      text: subLocationDetails?.locationName,
      value: subLocationDetails?.locationName,
    },
  ];
  const countryOptions = [
    {
      text: subLocationDetails?.locationCountry,
      value: subLocationDetails?.locationCountry,
    },
  ];

  const stateOptions = [
    {
      text: subLocationDetails?.locationState,
      value: subLocationDetails?.locationState,
    },
  ];
  const cityOptions = [
    {
      text: subLocationDetails?.locationCity,
      value: subLocationDetails?.locationCity,
    },
  ];

  const taxExemptOptions = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const formik = useFormik({
    initialValues: {
      name: subLocationDetails?.name?.trim() || '',
      zipCode: subLocationDetails?.code?.trim() || '',
      phone1: formatPhNo10Digit(subLocationDetails?.phone1) || '',
      phone2: formatPhNo10Digit(subLocationDetails?.phone2) || '',
      openHours: set24HoursTimeFormat(subLocationDetails?.openHours),
      closeHours: set24HoursTimeFormat(subLocationDetails?.closeHours),
      type: subLocationDetails?.sublocationType?.id || '',
      location: subLocationDetails?.locationName || '',
      country: subLocationDetails?.locationCountry || '',
      stateName: subLocationDetails?.locationState || '',
      cityName: subLocationDetails?.locationCity || '',
      startDate:
        checkValueNotNullUndefinedBlank(subLocationDetails?.startDate) &&
        dayjs(subLocationDetails?.startDate).toString() !== 'Invalid Date'
          ? dayjs(subLocationDetails?.startDate)
          : null,
      endDate:
        checkValueNotNullUndefinedBlank(subLocationDetails?.endDate) &&
        dayjs(subLocationDetails?.endDate).toString() !== 'Invalid Date'
          ? dayjs(subLocationDetails?.endDate)
          : null,
      spaceIdentifier: subLocationDetails?.spaceIdentifier?.trim() || '',
      address1: subLocationDetails?.address1?.trim() || '',
      address2: subLocationDetails?.address2?.trim() || '',
      productType: isArrayWithLength(productTypesInitialValues)
        ? productTypesInitialValues[0]
        : { text: '', value: '' },
      stateTaxRate: subLocationDetails?.stateTaxRate || '',
      areaTaxRate: subLocationDetails?.areaTaxRate || '',
      miscTax: subLocationDetails?.miscTax || '',
      locPaysTaxFlag: subLocationDetails?.locPaysTaxFlag || false,
      calculateRent: subLocationDetails?.calculateRent || false,
      taxExemptFlag: subLocationDetails?.taxExemptFlag || false,
    },
    validationSchema: subLocAddUpdateSchema,
    onSubmit: (data) => {
      if (!isAddNewSubLoc) {
        data.id = subLocationDetails.id;
      }
      const productCategories = data?.productType?.id ? [data?.productType?.id] : [];
      data.openHours =
        data?.openHours && dayjs(data?.openHours).toString() !== 'Invalid Date'
          ? get24HoursTimeFormat(data?.openHours)
          : null;
      data.closeHours =
        data?.closeHours && dayjs(data?.closeHours).toString() !== 'Invalid Date'
          ? get24HoursTimeFormat(data?.closeHours)
          : null;
      data.phone1 = data?.phone1 ? getPhNo10Digits(data?.phone1) : '';
      data.phone2 = data?.phone2 ? getPhNo10Digits(data?.phone2) : '';
      data.startDate = data?.startDate ? dateFormatForApi(data?.startDate) : null;
      data.endDate = data?.endDate ? dateFormatForApi(data?.endDate) : null;
      data.code = data.zipCode.toString();
      data.type = Number(data?.type);
      data.productCategories = productCategories || [];
      data.stateTaxRate = data.stateTaxRate || 0;
      data.areaTaxRate = data.areaTaxRate || 0;
      data.miscTax = data.miscTax || 0;
      data.calculateRent = Boolean(data?.calculateRent);
      data.taxExemptFlag = data.taxExemptFlag || false;
      data = removeKeysFromObj(data, ['zipCode', 'location', 'country', 'stateName', 'cityName', 'productType']);
      onSave(data);
    },
  });

  useEffect(() => {
    if (typeSuccess && !isAddNewSubLoc) {
      const existingLocType = locationTypeOptions.find(
        (eachLocType) => eachLocType.value.toString() === subLocationDetails?.sublocationType?.id?.toString(),
      );
      formik.setFieldValue('type', existingLocType ? existingLocType.value : '');
    }
  }, [typeSuccess]);
  const onArchiveClickHandler = () => {
    const status = subLocationDetails?.status;
    const data = {
      id: subLocationDetails?.id,
      status: !status,
    };
    onSave(data);
  };
  const openHrsPopupHandler = () => {
    setOpenHrsClockPopup(true);
  };
  const closeHrsPopupHandler = () => {
    setOpenCloseHrsClockPopup(true);
  };
  const { getFieldProps } = formik;
  return (
    <Box component={Paper} className={classes.locationDetailsUpdateBox}>
      <Typography variant="h2">{isAddNewSubLoc ? 'New' : 'Update'} Sub Location Details</Typography>
      <form onSubmit={formik.handleSubmit}>
        <GridContainer
          rowSpacing={3}
          columnSpacing={{ xs: 0, sm: 0, md: 7 }}
          className={classes.locationDetailsFieldsContainer}
          mb={4}
        >
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Sub Location Name"
              InputProps={{
                'data-testid': 'form-elements',
              }}
              name="name"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('name')}
              value={formik.values.name || ''}
              id="name"
              error={formik.touched.name && formik.errors.name ? true : false}
              helperText={formik.touched.name ? formik.errors.name : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Zip code"
              InputProps={{
                'data-testid': 'form-elements',
              }}
              name="zipCode"
              type="number"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('zipCode')}
              value={formik.values.zipCode || ''}
              id="zipCode"
              error={formik.touched.zipCode && formik.errors.zipCode ? true : false}
              helperText={formik.touched.zipCode ? formik.errors.zipCode : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Phone 1"
              InputProps={{
                'data-testid': 'form-elements',
                inputComponent: TextMaskCustom,
              }}
              name="phone1"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('phone1')}
              value={formik.values.phone1 || ''}
              id="phone1"
              error={formik.touched.phone1 && formik.errors.phone1 ? true : false}
              helperText={formik.touched.phone1 ? formik.errors.phone1 : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Phone 2"
              InputProps={{
                'data-testid': 'form-elements',
                inputComponent: TextMaskCustom,
              }}
              name="phone2"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('phone2')}
              value={formik.values.phone2 || ''}
              id="phone2"
              error={formik.touched.phone2 && formik.errors.phone2 ? true : false}
              helperText={formik.touched.phone2 ? formik.errors.phone2 : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Stack direction="row" spacing={2}>
              <Box
                className={formik.values?.openHours && formik.errors.openHours ? classes.formCustomFields : ''}
                sx={{ width: '50%' }}
              >
                <Timepicker
                  label="Open Hours"
                  name="openHours"
                  open={openHrsClockPopup}
                  onOpen={() => setOpenHrsClockPopup(true)}
                  onClose={() => setOpenHrsClockPopup(false)}
                  value={formik.values?.openHours ? dayjs(formik.values.openHours) : null}
                  onChange={(value) => {
                    formik.setFieldValue('openHours', value);
                  }}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                    onClick: openHrsPopupHandler,
                    disabled: true,
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                  error={Boolean(formik.values?.openHours && formik.errors.openHours)}
                  helperText={formik.values?.openHours && formik.errors.openHours && 'Enter Valid Time'}
                />
              </Box>
              <Box
                className={formik.values?.closeHours && formik.errors.closeHours ? classes.formCustomFields : ''}
                sx={{ width: '50%' }}
              >
                <Timepicker
                  label="Close Hours"
                  name="closeHours"
                  open={openCloseHrsClockPopup}
                  onOpen={() => setOpenCloseHrsClockPopup(true)}
                  onClose={() => setOpenCloseHrsClockPopup(false)}
                  value={formik.values?.closeHours ? dayjs(formik.values.closeHours) : null}
                  onChange={(value) => {
                    formik.setFieldValue('closeHours', value);
                  }}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                    onClick: closeHrsPopupHandler,
                    disabled: true,
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                  error={Boolean(formik.values?.closeHours && formik.errors.closeHours)}
                  helperText={formik.values?.closeHours && formik.errors.closeHours && 'Enter Valid Time'}
                />
              </Box>
            </Stack>
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Box className={classes.formFields}>
              {locationTypesData && typeSuccess && (
                <Select
                  inputLabel="Sub Location Type"
                  data-testid="form-elements"
                  name="type"
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched('type')}
                  value={formik.values.type || ''}
                  id="type"
                  options={locationTypeOptions}
                  error={formik.touched.type && formik.errors.type ? true : false}
                  helperText={formik.touched.type ? formik.errors.type : ''}
                />
              )}
            </Box>
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Box className={classes.formFields}>
              <Select
                inputLabel="Location"
                data-testid="form-elements"
                name="location"
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('location')}
                value={formik.values.location || ''}
                id="location"
                options={locationOptions}
                disabled
                error={formik.touched.location && formik.errors.location ? true : false}
                helperText={formik.touched.location ? formik.errors.location : ''}
              />
            </Box>
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Box className={classes.formFields}>
              <Select
                inputLabel="Country"
                data-testid="form-elements"
                name="country"
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('country')}
                value={formik.values.country || ''}
                id="country"
                options={countryOptions}
                disabled
                error={formik.touched.country && formik.errors.country ? true : false}
                helperText={formik.touched.country ? formik.errors.country : ''}
              />
            </Box>
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Box className={classes.formFields}></Box>
            <Select
              inputLabel="State"
              data-testid="form-elements"
              name="stateName"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('stateName')}
              value={formik.values.stateName || ''}
              id="stateName"
              options={stateOptions}
              disabled
              error={formik.touched.stateName && formik.errors.stateName ? true : false}
              helperText={formik.touched.stateName ? formik.errors.stateName : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Box className={classes.formFields}>
              <Select
                inputLabel="City"
                data-testid="form-elements"
                name="cityName"
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('cityName')}
                value={formik.values.cityName || ''}
                id="cityName"
                options={cityOptions}
                disabled
                error={formik.touched.cityName && formik.errors.cityName ? true : false}
                helperText={formik.touched.cityName ? formik.errors.cityName : ''}
              />
            </Box>
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Datepicker
              className={classes.formFields}
              label="Installed Date"
              name="startDate"
              onChange={(newValue) => formik.setFieldValue('startDate', newValue)}
              onBlur={() => formik.setFieldTouched('startDate')}
              value={formik.values.startDate ? dayjs(formik.values.startDate) : null}
              id="startDate"
              inputFormat="MM/DD/YYYY"
              data-testid="form-elements"
              error={formik.touched.startDate && formik.errors.startDate ? true : false}
              helperText={formik.touched.startDate ? formik.errors.startDate : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Datepicker
              className={classes.formFields}
              label="End Date"
              name="endDate"
              onChange={(newValue) => formik.setFieldValue('endDate', newValue)}
              onBlur={() => formik.setFieldTouched('endDate')}
              value={formik.values.endDate ? dayjs(formik.values.endDate) : null}
              id="endDate"
              inputFormat="MM/DD/YYYY"
              data-testid="form-elements"
              error={formik.touched.endDate && formik.errors.endDate ? true : false}
              helperText={formik.touched.endDate ? formik.errors.endDate : ''}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Space Identifier"
              InputProps={{
                'data-testid': 'form-elements',
              }}
              name="spaceIdentifier"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('spaceIdentifier')}
              value={formik.values.spaceIdentifier || ''}
              id="spaceIdentifier"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Address 1"
              InputProps={{
                'data-testid': 'form-elements',
              }}
              name="address1"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('address1')}
              value={formik.values.address1 || ''}
              id="address1"
              error={formik.touched.address1 && formik.errors.address1 ? true : false}
              helperText={formik.touched.address1 ? formik.errors.address1 : ''}
              multiline
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              className={classes.formFields}
              label="Address 2"
              InputProps={{
                'data-testid': 'form-elements',
              }}
              name="address2"
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched('address2')}
              value={formik.values.address2 || ''}
              id="address2"
              error={formik.touched.address2 && formik.errors.address2 ? true : false}
              helperText={formik.touched.address2 ? formik.errors.address2 : ''}
              multiline
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Autocomplete
              label="Product Type"
              data-testid="form-elements"
              value={formik.values.productType || { text: '', value: '' }}
              options={productTypeOptions || []}
              getOptionLabel={(option) => option.text || ''}
              onChange={(e, value) => {
                formik.setFieldValue('productType', value);
              }}
              onBlur={() => formik.setFieldTouched('productType')}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              clearOnBlur={false}
              error={Boolean(
                formik.touched.productType && (formik.errors.productType?.text || formik.errors.productType),
              )}
              helperText={formik.touched.productType && (formik.errors.productType?.text || formik.errors.productType)}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="State tax %"
              type="number"
              {...getFieldProps('stateTaxRate')}
              InputProps={{
                'data-testid': 'updateField',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Area tax %"
              type="number"
              {...getFieldProps('areaTaxRate')}
              InputProps={{
                'data-testid': 'updateField',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Misc tax %"
              type="number"
              {...getFieldProps('miscTax')}
              InputProps={{
                'data-testid': 'updateField',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              options={taxExemptOptions}
              inputLabel="Tax Exempt"
              {...getFieldProps('taxExemptFlag')}
              data-testid="editSubLocation"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <CheckBox
              label="Location Pays Tax"
              labelPlacement="end"
              checked={formik.values.locPaysTaxFlag || false}
              name="locPaysTaxFlag"
              id="locPaysTaxFlag"
              onChange={formik.handleChange}
              data-testid="form-elements"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <CheckBox
              label="Calculate Rent"
              labelPlacement="end"
              checked={formik.values.calculateRent || false}
              name="calculateRent"
              id="calculateRent"
              onChange={formik.handleChange}
              data-testid="form-elements"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="space-around" py={1}>
          <GridItem xs={6} className={classes.updateBtn} pb={0}>
            {loadingBtnUse ? (
              <LoadingButton type="submit" loading={isLoading} variant="contained">
                {isAddNewSubLoc ? 'ADD' : 'UPDATE'}
              </LoadingButton>
            ) : (
              <Button type="submit">{isAddNewSubLoc ? 'ADD' : 'UPDATE'}</Button>
            )}
            <Button onClick={onCancel}>CANCEL</Button>
          </GridItem>
          <GridItem xs={6} className={classes.archiveBtn} pb={0}>
            {!isAddNewSubLoc && (
              <Button
                onClick={() => setShowArchiveModal(true)}
                color={subLocationDetails?.status ? 'error' : 'success'}
              >
                {subLocationDetails?.status ? 'ARCHIVE' : 'ACTIVATE'} SUB LOCATION
              </Button>
            )}
          </GridItem>
        </GridContainer>
        <ConfirmationModal
          isLoading={isLoading}
          isOpen={showArchiveModal}
          title="Confirmation"
          msg={`Are you sure you want to ${
            subLocationDetails?.status ? 'archive' : 'active'
          } this sub location? This process cannot be undone.`}
          buttons={[
            { text: subLocationDetails?.status ? 'Archive' : 'Active', value: true },
            { text: 'Cancel', value: false },
          ]}
          onClick={(value) => {
            if (value) onArchiveClickHandler();
            else setShowArchiveModal(false);
          }}
        />
      </form>
    </Box>
  );
};

export default SubLocationUpdateDetailView;
