import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    addLease: build.mutation({
      query: (data) => ({
        url: `${apiUrl.lease}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Leases'],
    }),
    getLeases: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.lease}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${page || DEFAULT_PAGE}&${searchParams}`;
        }
        return `${apiUrl.lease}`;
      },
      transformResponse: (responseData) => {
        const contractData = responseData?.data?.contracts;
        const data = contractData?.map((lease) => {
          const productTypes =
            lease?.contractSublocations?.flatMap((subloc) => {
              const productCategoriesNames = subloc?.sublocation?.productCategories?.map(
                (productCategory) => productCategory?.name,
              );
              return productCategoriesNames;
            }) ?? [];
          return { ...lease, productTypes };
        });
        return {
          data,
          pagination: responseData?.pagination,
        };
      },
      providesTags: (result) =>
        result ? ['Leases', ...result?.data?.map(({ id }) => ({ type: 'Leases', id }))] : ['Leases'],
    }),
    getLeaseById: build.query({
      query: (leaseId) => `${apiUrl.lease}/${leaseId}`,
      transformResponse: (responseData) => responseData?.data,
      providesTags: (result, error, arg) => [{ type: 'Leases', id: arg }],
    }),
    updateLeaseById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.lease}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Leases', id: arg.id }, 'Sublocation', 'SubLocations'],
    }),
    validateSubLocations: build.query({
      query: ({ locationId, subLocationIds, termStartDate, termEndDate, contractId }) =>
        `${apiUrl.lease}/${
          apiUrl.validateSubLocationsPeriod
        }?locationId=${locationId}&sublocationIds=${subLocationIds}&termStartDate=${termStartDate}&termEndDate=${termEndDate}${
          contractId ? `&contractId=${contractId}` : ''
        }`,
      transformResponse: (responseData) => responseData?.data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddLeaseMutation,
  useGetLeasesQuery,
  useGetLeaseByIdQuery,
  useUpdateLeaseByIdMutation,
  useLazyValidateSubLocationsQuery,
} = extendedApi;
