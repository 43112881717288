import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  activeLocationOwner: {
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const locationFilterSlice = createSlice({
  name: 'locationOwnerFilterSlice',
  initialState,
  reducers: {
    setActiveLocationOwnerFilters(state, action) {
      state.activeLocationOwner.searchText = action.payload?.searchText || '';
      state.activeLocationOwner.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.activeLocationOwner.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetActiveLocationOwnerFilters(state) {
      state.activeLocationOwner = {
        ...initialState.activeLocationOwner,
        searchText: state.activeLocationOwner.searchText,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default locationFilterSlice;
