import React, { useState } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Button, Tabs } from '../../../components/shared';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import ActiveTab from './ActiveTab';
import ArchiveTab from '../../LocationOwners/LocationContent/ArchiveTab';
import useStyles from './style';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { routeConstants } from '../../../constants/routeConstants';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
const displayName = 'Models';

const Models = () => {
  const classes = useStyles();
  const [total, onTotalLoad] = useState('');
  const theme = useTheme();
  const canAdd = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.EQUIPMENT]);

  const tabs = [
    {
      heading: TAB_TYPES.ACTIVE,
      content: <ActiveTab onTotalLoad={(v) => onTotalLoad(v)} />,
    },
    {
      heading: TAB_TYPES.ARCHIVE,
      content: <ArchiveTab />,
    },
  ];

  return (
    <Box>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">{displayName}</Typography>
          <Button
            component={Link}
            to={`/${routeConstants.ADD_MODEL}`}
            variant="contained"
            data-testid="add-location-btn"
            disabled={!canAdd}
            startIcon={<AddIcon />}
          >
            ADD NEW
          </Button>
        </Stack>
      </Box>
      <Box>
        <Typography variant="h3" style={{ color: theme.palette.secondary.main }}>
          {`${total ? `${total} Models` : ''}`}
        </Typography>
      </Box>

      <Box className={classes.usersTable} mt={2}>
        <Tabs tabsData={tabs} />
      </Box>
    </Box>
  );
};

Models.displayName = displayName;
export default Models;
