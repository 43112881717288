import React from 'react';
import { Link as MUILink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Link = (props) => {
  const { children, ...others } = props;
  return (
    <MUILink component={RouterLink} {...others}>
      {children}
    </MUILink>
  );
};

export default Link;
