import React, { forwardRef, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import useStyles from './style';
import { Box, Stack } from '@mui/material';
import { Autocomplete, Button, Datepicker, GridContainer, GridItem, Select, TextField } from '../shared';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { leaseSchema } from '../../schema/validationSchemas';
import { useGetLocationDetailsByIdQuery, useGetLocationsQuery } from '../../services/Location/LocationService';
import { useDebounce } from '../../hooks';
import {
  useGetAllCountriesQuery,
  useGetCountryStatesByCountryIdQuery,
  useGetStateCitiesByStateIdQuery,
} from '../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import { leaseDetailsFormAction } from '../../redux-slice/lease';
import { dateFormatForApi } from '../../utils/common-methods';
import { useLazyValidateSubLocationsQuery } from '../../services/Lease/LeaseService';
import ConfirmationModal from '../ConfirmationModel/ConfirmationModal';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useParams } from 'react-router-dom';

const displayName = 'LeaseDetailsForm';
const LeaseDetailsForm = forwardRef((props, ref) => {
  const {
    onNextClick = () => {},
    onUpdateHandler = () => {},
    onCancelHandler = () => {},
    isUpdate = false,
    isLoading = false,
  } = props;
  const classes = useStyles();
  const leaseState = useSelector((state) => state?.lease?.leaseForms);
  const { leaseDetails } = leaseState;
  const dispatch = useDispatch();
  const params = useParams();
  const leaseId = Number(params.id);
  const [searchText, setSearchText] = useState('');
  const [subLocations, setSubLocations] = useState([]);
  const [locationId, setLocationId] = useState(leaseDetails?.location?.id);
  const [subLocationsModal, setSubLocationsModal] = useState(false);
  const [subLocationNames, setSubLocationsNames] = useState([]);
  const searchParams = 'includeSublocationAssets=true&includeSublocationManagers=true';
  const { currentData: locationResponse = [] } = useGetLocationsQuery({
    page: 1,
    perPage: 10,
    searchParams: `q=${useDebounce(searchText, 300)}`,
  });
  const { currentData: locationDetailsById = {} } = useGetLocationDetailsByIdQuery(
    { id: locationId, searchParams },
    { skip: locationId ? false : true },
  );

  const [validateSubLocationsApi, { isLoading: sublocationsCheckingLoader }] = useLazyValidateSubLocationsQuery();

  const { currentData: countries = [], isSuccess: countriesSuccess } = useGetAllCountriesQuery();

  const { currentData: states, isSuccess: statesSuccess } = useGetCountryStatesByCountryIdQuery(leaseDetails.country, {
    skip: leaseDetails.country ? false : true,
  });

  const { currentData: cities, isSuccess: citiesSuccess } = useGetStateCitiesByStateIdQuery(leaseDetails.state, {
    skip: leaseDetails.state ? false : true,
  });

  const countryOptions = countries?.map((value) => ({
    value: value?.id,
    text: value?.name,
  }));

  const stateOptions = states?.data?.states?.map((value) => ({
    value: value?.id,
    text: value?.name,
  }));

  const cityOptions = cities?.data?.cities?.map((value) => ({
    value: value?.id,
    text: value?.name,
  }));

  const filteredSubLocationsWithOverlappingSubLocations = (data, values) => {
    const overlapSubLocations = data?.filter((value) => value.overlap);
    const filteredSubLocations = values?.subLocation.filter((el) =>
      overlapSubLocations.some((f) => f.sublocationId == el.id),
    );
    return filteredSubLocations;
  };

  const formik = useFormik({
    initialValues: leaseDetails,
    validationSchema: leaseSchema.leaseDetailSchema,
    onSubmit: (values) => {
      const subLocationIds = values?.subLocation?.map((value) => value.id).join(',') || [];
      if (subLocationIds?.length > 0) {
        let data = {
          locationId: values?.location?.id,
          subLocationIds: subLocationIds,
          termStartDate: dateFormatForApi(values?.leaseBegins),
          termEndDate: dateFormatForApi(values?.leaseEnds),
        };
        if (isUpdate) {
          data = {
            ...data,
            contractId: leaseId,
          };
        }

        validateSubLocationsApi(data).then((res) => {
          if (res.data) {
            const filteredSubLocations = filteredSubLocationsWithOverlappingSubLocations(res.data, values);

            if (filteredSubLocations?.length > 0) {
              const subLocationNames = filteredSubLocations?.map((value) => value.name);
              setSubLocationsNames(subLocationNames);
              setSubLocationsModal(true);
            } else {
              if (!isUpdate) {
                onNextClick('leaseDetails', values);
              }
              if (isUpdate) {
                onUpdateHandler(values);
              }
            }
          }
        });
      } else {
        if (!isUpdate) {
          onNextClick('leaseDetails', values);
        }
        if (isUpdate) {
          onUpdateHandler(values);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, values, getFieldProps, setFieldValue, setValues } = formik;

  useEffect(() => {
    setValues(leaseDetails);
    if (leaseDetails.location) {
      setLocationId(leaseDetails?.location?.id);
    }
  }, [leaseDetails]);

  useEffect(() => {
    if (locationDetailsById?.data) {
      if (locationDetailsById?.data?.sublocations) {
        setSubLocations(locationDetailsById?.data?.sublocations || []);
      }
    }
  }, [locationDetailsById]);

  const autoCompleteChangeHandler = (name, value) => {
    if (name === 'location') {
      setTimeout(() => {
        dispatch(
          leaseDetailsFormAction({
            ...values,
            location: value,
            subLocation: [],
            address1: value?.address1 || '',
            address2: value?.address2 || '',
            zipCode: value?.postcode || '',
            country: value?.countryId || '',
            state: value?.stateId || '',
            city: value?.cityId || '',
          }),
        );
      }, 500);
    } else {
      setFieldValue(name, value);
    }
  };

  const typeArr = [
    { value: 'LEASE', text: 'Lease' },
    { value: 'AMENDMENT', text: 'Amendment' },
    { value: 'MODIFICATION', text: 'Modification' },
    { value: 'OTHER', text: 'Other' },
  ];

  const statusArr = [
    { value: 'NEW', text: 'New' },
    { value: 'RENEW', text: 'Renew' },
    { value: 'OTHER', text: 'Other' },
  ];

  const onInputChangeHandler = (e, value) => {
    setSearchText(value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Box className={classes.leaseDetailsForm} data-testid="leaseDetailsComponent">
          <GridContainer rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 6 }}>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Lease Name"
                {...getFieldProps('leaseName')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                error={Boolean(touched.leaseName && errors.leaseName)}
                helperText={touched.leaseName && errors.leaseName}
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Autocomplete
                label="Location"
                data-testid="leaseDetailsForm"
                value={values.location}
                options={(locationResponse?.locations && locationResponse?.locations) || []}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                onChange={(event, value) => autoCompleteChangeHandler('location', value)}
                error={Boolean(touched.location && errors.location)}
                helperText={touched.location && (errors.location?.name ?? errors.location)}
                disabled={isUpdate}
                onInputChange={onInputChangeHandler}
              />
            </GridItem>
            <GridItem xs={12} pl={0}>
              <Autocomplete
                value={values.subLocation}
                options={subLocations}
                onChange={(event, value) => autoCompleteChangeHandler('subLocation', value)}
                label="Sub Location"
                data-testid="leaseDetailsForm"
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                multiple
                disableCloseOnSelect
              />
            </GridItem>

            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Address 1"
                {...getFieldProps('address1')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Address 2"
                {...getFieldProps('address2')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Zipcode"
                type="number"
                {...getFieldProps('zipCode')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Select
                inputLabel="Country"
                options={countriesSuccess && countryOptions}
                {...getFieldProps('country')}
                data-testid="leaseDetailsForm"
                disabled
                defaultValue=""
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Select
                inputLabel="State"
                options={statesSuccess && stateOptions}
                {...getFieldProps('state')}
                data-testid="leaseDetailsForm"
                disabled
                defaultValue=""
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Select
                inputLabel="City"
                options={citiesSuccess && cityOptions}
                value={values.city}
                {...getFieldProps('city')}
                data-testid="leaseDetailsForm"
                disabled
                defaultValue=""
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Booth Info"
                type="number"
                {...getFieldProps('boothInfo')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Space"
                type="number"
                {...getFieldProps('space')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="DBA"
                {...getFieldProps('dba')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <TextField
                label="Sales Person"
                {...getFieldProps('salesPerson')}
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Select
                inputLabel="Lease Type"
                options={typeArr}
                {...getFieldProps('leaseType')}
                data-testid="leaseDetailsForm"
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Select
                inputLabel="Status"
                options={statusArr}
                {...getFieldProps('status')}
                data-testid="leaseDetailsForm"
              />
            </GridItem>

            <GridItem xs={12} md={4} pl={0}>
              <Datepicker
                label="Lease Begins"
                name="leaseBegins"
                value={values.leaseBegins}
                inputFormat="MM/DD/YYYY"
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                onChange={(date) =>
                  date === null ? setFieldValue('leaseBegins', date) : setFieldValue('leaseBegins', date.toString())
                }
                error={Boolean(touched.leaseBegins && errors.leaseBegins)}
                helperText={touched.leaseBegins && errors.leaseBegins}
              />
            </GridItem>
            <GridItem xs={12} md={4} pl={0}>
              <Datepicker
                label="Lease Ends"
                name="leaseEnds"
                value={values.leaseEnds}
                inputFormat="MM/DD/YYYY"
                InputProps={{
                  'data-testid': 'leaseDetailsForm',
                }}
                onChange={(date) =>
                  date === null ? setFieldValue('leaseEnds', date) : setFieldValue('leaseEnds', date.toString())
                }
                error={Boolean(touched.leaseEnds && errors.leaseEnds)}
                helperText={touched.leaseEnds && errors.leaseEnds}
              />
            </GridItem>
          </GridContainer>
          {isUpdate ? (
            <>
              <GridContainer mt={5} mb={1} className="next-btn-wrapper">
                <GridItem xs={6} data-testid="update-btns-wrapper">
                  <LoadingButton
                    loading={isLoading || sublocationsCheckingLoader}
                    variant="contained"
                    type="submit"
                    data-testid="leaseDetailsFormUpdateBtn"
                    ref={ref}
                  >
                    UPDATE
                  </LoadingButton>
                  <Button onClick={onCancelHandler}>CANCEL</Button>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            <Box mt={5}>
              <LoadingButton
                loading={isLoading || sublocationsCheckingLoader}
                variant="contained"
                type="submit"
                data-testid="leaseDetailSubmitBtn"
              >
                Next
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Form>
      <ConfirmationModal
        isOpen={subLocationsModal}
        title={
          <Stack direction="row" spacing={1}>
            <Box className={classes.errorIcon}>
              <ReportProblemIcon />
            </Box>
            <Box className={classes.errorText}>Error</Box>
          </Stack>
        }
        msg={`The sublocation${subLocationNames.length > 1 ? 's' : ''} ${
          subLocationNames.length > 1 ? subLocationNames.join(', ') : subLocationNames
        } already has an active lease in the selected date range, please change the date range or remove the sublocation.`}
        buttons={[{ text: 'Okay', value: 'OKAY' }]}
        onClick={() => setSubLocationsModal(false)}
      />
    </FormikProvider>
  );
});
LeaseDetailsForm.displayName = displayName;
export default LeaseDetailsForm;
