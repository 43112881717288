import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { GridContainer, GridItem, Select, TextField } from '../../../components/shared';
import { AddNewLocationOwnerSchema } from '../../../schema/validationSchemas';
import React, { useState, useEffect, useMemo } from 'react';
import { getOptions, getPhNo10Digits } from '../../../utils/common-methods';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import {
  useAddLocationOwnerMutation,
  useGetAllLocationOwnerTypesQuery,
} from '../../../services/Location/LocationService';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import {
  useGetAllCountriesQuery,
  useLazyGetStateCitiesByStateIdQuery,
  useLazyGetCountryStatesByCountryIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';

const AddNewLocationOwner = () => {
  const [addLocationOwnerApi, { isSuccess }] = useAddLocationOwnerMutation();
  const navigate = useNavigate();
  const navigateToLocOwnPage = () => navigate(`/${routeConstants.LOCATION_OWNERS_ROUTE}`);

  const { currentData: locationOwnerTypesResponse } = useGetAllLocationOwnerTypesQuery();

  const locationOwnerTypes = useMemo(() => getOptions(locationOwnerTypesResponse), [locationOwnerTypesResponse]);
  const { currentData: countriesResponse, isSuccess: countriesAPISuccess } = useGetAllCountriesQuery();
  const [getCitiesByStateId, citiesResponseByStateId] = useLazyGetStateCitiesByStateIdQuery();
  const [getStatesByCountryId, statesResponseByCountryId] = useLazyGetCountryStatesByCountryIdQuery();

  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);

  useEffect(() => {
    if (countriesAPISuccess) {
      setCountryData(getOptions(countriesResponse));
    }
  }, [countriesResponse]);

  useEffect(() => {
    if (statesResponseByCountryId.isSuccess) {
      setStatesData(getOptions(statesResponseByCountryId.currentData?.data?.states));
    }
  }, [statesResponseByCountryId.currentData]);

  useEffect(() => {
    if (citiesResponseByStateId.isSuccess) {
      setCitiesData(getOptions(citiesResponseByStateId.currentData?.data?.cities));
    }
  }, [citiesResponseByStateId.currentData]);

  if (isSuccess) {
    navigateToLocOwnPage();
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      typeId: '',
      address1: '',
      address2: '',
      countryId: '',
      stateId: '',
      cityId: '',
      postcode: '',
      phone1: '',
      phone2: '',
      email: '',
    },
    validationSchema: AddNewLocationOwnerSchema,
    onSubmit: (values) => {
      const data = {
        name: values?.name?.trim() || '',
        typeId: values?.typeId || null,
        address1: values.address1?.trim() || '',
        address2: values.address2?.trim() || '',
        countryId: Number(values.countryId),
        stateId: Number(values.stateId),
        cityId: Number(values.cityId),
        postcode: values.postcode,
        phone1: getPhNo10Digits(values.phone1) || '',
        phone2: getPhNo10Digits(values.phone2) || '',
        email: values.email?.trim() || '',
      };
      addLocationOwnerApi(data);
    },
  });

  const { touched, errors, getFieldProps, handleSubmit } = formik;
  const countryChangeHandler = (e) => {
    formik.setFieldValue('countryId', e.target.value);
    getStatesByCountryId(e.target.value);
    setCitiesData([]);
    formik.setFieldValue('stateId', '');
    formik.setFieldValue('cityId', '');
  };

  const stateChangeHandler = (e) => {
    formik.setFieldValue('stateId', e.target.value);
    getCitiesByStateId(e.target.value);
    formik.setFieldValue('cityId', '');
  };
  return (
    <Container maxWidth="xl">
      <Box mb={{ xs: 1, sm: 2, md: 3 }}>
        <Typography variant="h1"> Add New Location Owner</Typography>
      </Box>
      <Box component={Paper} p={{ xs: 1, sm: 2, md: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }}>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Business Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Type"
                  options={locationOwnerTypes || []}
                  {...getFieldProps('typeId')}
                  data-testid="updateField"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Address1"
                  multiline
                  maxRows={3}
                  {...getFieldProps('address1')}
                  error={Boolean(touched.address1 && errors.address1)}
                  helperText={touched.address1 && errors.address1}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Address2"
                  multiline
                  maxRows={3}
                  {...getFieldProps('address2')}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                  error={Boolean(touched.address2 && errors.address2)}
                  helperText={touched.address2 && errors.address2}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Country"
                  options={countryData || []}
                  {...getFieldProps('countryId')}
                  data-testid="updateField"
                  error={Boolean(touched.countryId && errors.countryId)}
                  helperText={touched.countryId && errors.countryId}
                  onChange={countryChangeHandler}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="State"
                  options={statesData || []}
                  {...getFieldProps('stateId')}
                  data-testid="updateField"
                  disabled={Boolean(statesData.length === 0)}
                  error={Boolean(touched.stateId && errors.stateId)}
                  helperText={
                    statesData.length === 0 ? 'Please first select country' : touched.stateId && errors.stateId
                  }
                  onChange={stateChangeHandler}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="City"
                  options={citiesData || []}
                  {...getFieldProps('cityId')}
                  data-testid="updateField"
                  error={Boolean(touched.cityId && errors.cityId)}
                  helperText={citiesData.length === 0 ? 'Please first select state' : touched.cityId && errors.cityId}
                  disabled={Boolean(citiesData.length === 0)}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Postal Code"
                  {...getFieldProps('postcode')}
                  error={Boolean(touched.postcode && errors.postcode)}
                  helperText={touched.postcode && errors.postcode}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Phone1"
                  {...getFieldProps('phone1')}
                  error={Boolean(touched.phone1 && errors.phone1)}
                  helperText={touched.phone1 && errors.phone1}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Phone2"
                  {...getFieldProps('phone2')}
                  error={Boolean(touched.phone2 && errors.phone2)}
                  helperText={touched.phone2 && errors.phone2}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={12}>
                <TextField
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <Stack direction="row" justifyContent="center" spacing={2} mt={3}>
                  <Box>
                    <Button variant="contained" type="submit">
                      Add
                    </Button>
                  </Box>
                  <Box>
                    <Button onClick={navigateToLocOwnPage}>Cancel</Button>
                  </Box>
                </Stack>
              </GridItem>
            </GridContainer>
          </Form>
        </FormikProvider>
      </Box>
    </Container>
  );
};

export default AddNewLocationOwner;
