import { Box, DialogContentText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Modal } from '../shared';
import { Stack } from '@mui/system';
import useStyles from './style';
import { useTimer } from '../../hooks';

const TimerModal = (props) => {
  const {
    isOpen = false,
    timer = 0,
    activate = false,
    onTimeComplete = () => {},
    icon = <></>,
    title = '',
    msg = '',
  } = props;
  const classes = useStyles();
  const time = useTimer(activate, timer);

  useEffect(() => {
    if (!time) {
      onTimeComplete();
    }
  }, [time]);

  return (
    <Modal open={isOpen} customWidth="304px">
      <Box>
        <Stack alignItems="center" spacing={2} mb={1}>
          <Box>{icon}</Box>
          <Box>
            <Typography variant="modalHeading" fontWeight={900} color="common.darkblue" fontSize={24}>
              {title}
            </Typography>
          </Box>
          <DialogContentText variant="body2" className={classes.successText}>
            {msg}
            <Typography variant="body2" color="primary" component={'span'}>
              {` ${time}`} seconds
            </Typography>
          </DialogContentText>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TimerModal;
