import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '380px',
    maxHeight: '280px',
    padding: 24,
    boxShadow: '0px 1px 4px rgba(100, 116, 139, 0.24)',
  },
  commentList: { overflow: 'auto', maxHeight: '110px' },
  commentHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  commentBodyHead: { display: 'flex', justifyContent: 'space-between' },
  commentBodyContent: {
    '& .MuiTypography-root': { wordBreak: 'break-word', textTransform: 'none' },
  },
}));

export default useStyles;
