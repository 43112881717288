import React, { useMemo } from 'react';
import { Container, Typography } from '@mui/material';

import DepositDetailCommonComponent from '../CommonComponent/DepositDetailCommonComponent';
import { Breadcrumbs } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { useGetDepositsByIdQuery } from '../../../services/Deposit/DepositService';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

const ReconciliationDetailDeposit = () => {
  const params = useParams();
  const depositId = Number(params.id);
  const {
    DEPOSIT: { DEPOSIT, RECONCILIATION_DETAIL },
  } = BREADCRUMB_NAMES;
  const { currentData: depositDetails, isError, error, isLoading } = useGetDepositsByIdQuery(depositId);

  const breadcrumbData = useMemo(
    () => [
      {
        text: DEPOSIT,
        redirection: `${routeConstants.DEPOSIT_ROUTE}?selectedTab=2`,
      },
      { text: RECONCILIATION_DETAIL },
    ],
    [],
  );
  if (isLoading) {
    return <LoadingSpinner containerHeight={400} />;
  }
  if (isError)
    return (
      <Container maxWidth={'xl'} disableGutters>
        <Typography>{error.message}</Typography>
      </Container>
    );
  return (
    <Container maxWidth={'xl'} disableGutters>
      <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      <DepositDetailCommonComponent depositDetails={depositDetails?.data} />
    </Container>
  );
};

export default ReconciliationDetailDeposit;
