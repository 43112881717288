import dayjs from 'dayjs';
import { InformationPad } from '../../../components/shared';
import { getCurrency } from '../../../utils/common-methods';

const MachineDetailView = ({ machineInfo }) => {
  if (machineInfo) {
    machineInfo = {
      ...machineInfo,
      price: machineInfo?.price || 0,
    };
  }
  const fields = [
    { label: 'Asset ID', value: machineInfo?.id },
    { label: 'Legacy Asset ID', value: machineInfo?.legacyAssetId },
    { label: 'Status', value: machineInfo?.status?.title },
    { label: 'No.of Plays', value: machineInfo?.numberOfPlays || 0 },
    {
      label: 'Installed Date',
      value: machineInfo?.installedDate
        ? dayjs(machineInfo?.installedDate).format('MM/DD/YYYY')
        : machineInfo?.installedDate,
    },
    { label: 'Model Name', value: machineInfo?.model?.title },
    { label: 'Game Reading', value: machineInfo?.gameMeter || 0 },
    {
      label: 'Cost Per Play',
      value: machineInfo?.costPerPlay,
    },
    { label: 'Clicks Per Play', value: machineInfo?.clicksPerPlay },
    {
      label: 'Price',
      value: `${getCurrency(machineInfo?.currency?.abbreviation || 'USD')} ${machineInfo?.price}`,
    },
    { label: 'Sub Location', value: machineInfo?.sublocation?.name },
    {
      label: 'Last sublocation change Date',
      value: machineInfo?.sublocationChangeDate
        ? dayjs(machineInfo?.sublocationChangeDate).format('MM/DD/YYYY')
        : machineInfo?.sublocationChangeDate,
    },
    { label: 'Last sublocation', value: machineInfo?.oldSublocation?.name },
  ];

  return <InformationPad data={fields} />;
};
export default MachineDetailView;
