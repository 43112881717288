export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID, // process.env.REACT_APP_MSAL_CLIENT_ID
    authority: process.env.REACT_APP_MSAL_AUTHORITY, // process.env.REACT_APP_MSAL_AUTHORITY
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI, // process.env.REACT_APP_MSAL_REDIRECT_URI
  },
  cache: {
    cacheLocation: process.env.REACT_APP_MSAL_CACHE_LOCATION, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  telemetry: {
    application: {
      appName: process.env.REACT_APP_NAME,
      appVersion: process.env.REACT_APP_VERSION,
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    `${process.env.REACT_APP_MSAL_CUSTOM_API_SCOPE_URI}/${process.env.REACT_APP_MSAL_CUSTOM_API_SCOPE_NAME_ALL_ACCESS}`,
  ],
};

export const userReadRequest = {
  scopes: ['User.Read.All'],
};
