import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Grid, Box, Stack } from '@mui/material';
import { Autocomplete, Button } from '../../../components/shared';
import {
  useGetAllCountriesQuery,
  useLazyGetCountryStatesByCountryIdQuery,
  useLazyGetStateCitiesByStateIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import { useGetAllLocationTypesQuery, useLazyGetLocationsQuery } from '../../../services/Location/LocationService';
import { debounce, getOptions, isArrayWithLength } from '../../../utils/common-methods';

const ACTION_TYPE_CONSTANTS = {
  UPDATE_COUNTRIES_DATA: 'UPDATE_COUNTRIES_DATA',
  UPDATE_STATES_DATA: 'UPDATE_STATES_DATA',
  UPDATE_CITIES_DATA: 'UPDATE_CITIES_DATA',
  UPDATE_LOCATION_TYPE_DATA: 'UPDATE_LOCATION_TYPE_DATA',
  UPDATE_LOCATION_NAME_DATA: 'UPDATE_LOCATION_NAME_DATA',
  UPDATE_SEARCH_PARAMS: 'UPDATE_SEARCH_PARAMS',
  RESET_STATE: 'RESET_STATE',
};

const initialSearchState = {
  country: null,
  state: null,
  city: null,
  locationType: [],
  locationName: [],
};

const initialState = {
  searchParams: initialSearchState,
  countriesData: [],
  statesData: [],
  citiesData: [],
  locationTypesData: [],
  locationNameData: [],
};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPE_CONSTANTS.UPDATE_COUNTRIES_DATA:
      return { ...state, countriesData: payload };
    case ACTION_TYPE_CONSTANTS.UPDATE_STATES_DATA:
      return { ...state, statesData: payload };
    case ACTION_TYPE_CONSTANTS.UPDATE_CITIES_DATA:
      return { ...state, citiesData: payload };
    case ACTION_TYPE_CONSTANTS.UPDATE_LOCATION_TYPE_DATA:
      return { ...state, locationTypesData: payload };
    case ACTION_TYPE_CONSTANTS.UPDATE_LOCATION_NAME_DATA:
      return { ...state, locationNameData: payload };
    case ACTION_TYPE_CONSTANTS.UPDATE_SEARCH_PARAMS:
      return { ...state, searchParams: { ...state.searchParams, ...payload } };
    case ACTION_TYPE_CONSTANTS.RESET_STATE:
      return { ...state, searchParams: initialSearchState };
    default:
      return state;
  }
}

const UserAddNewLocationFilter = ({ applyFilterSearch = () => {}, onCancelPress = () => {} }) => {
  const [getLocations] = useLazyGetLocationsQuery();
  const { currentData: countriesData, isSuccess: countriesAPISuccess } = useGetAllCountriesQuery();
  const { currentData: locationTypes, isSuccess: locationTypesSuccess } = useGetAllLocationTypesQuery();
  const [getStatesByCountryId, statesResponseByCountryId] = useLazyGetCountryStatesByCountryIdQuery();
  const [getCitiesByStateId, citiesResponseByStateId] = useLazyGetStateCitiesByStateIdQuery();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLocationNameOpen, setIsLocationNameOpen] = useState(false);

  const isButtonDisable = useMemo(() => {
    const { city, country, locationType, state: selectedState, locationName } = state.searchParams;
    return !(country || selectedState || city || isArrayWithLength(locationType) || isArrayWithLength(locationName));
  }, [state.searchParams]);

  useEffect(() => {
    if (locationTypesSuccess) {
      dispatch({
        type: ACTION_TYPE_CONSTANTS.UPDATE_LOCATION_TYPE_DATA,
        payload: getOptions(locationTypes || []),
      });
    }
  }, [locationTypes]);

  useEffect(() => {
    if (countriesAPISuccess) {
      dispatch({
        type: ACTION_TYPE_CONSTANTS.UPDATE_COUNTRIES_DATA,
        payload: getOptions(countriesData || []),
      });
    }
  }, [countriesData]);

  useEffect(() => {
    if (statesResponseByCountryId.isSuccess) {
      const statesData = statesResponseByCountryId.currentData?.data?.states || [];
      dispatch({
        type: ACTION_TYPE_CONSTANTS.UPDATE_STATES_DATA,
        payload: getOptions(statesData),
      });
    }
  }, [statesResponseByCountryId.currentData]);

  useEffect(() => {
    if (citiesResponseByStateId.isSuccess) {
      const citiesData = citiesResponseByStateId.currentData?.data?.cities || [];
      dispatch({
        type: ACTION_TYPE_CONSTANTS.UPDATE_CITIES_DATA,
        payload: getOptions(citiesData),
      });
    }
  }, [citiesResponseByStateId.currentData]);

  const onCountrySelect = (event, selectedValue) => {
    if (selectedValue) {
      getStatesByCountryId(selectedValue.value);
    }
    dispatch({
      type: ACTION_TYPE_CONSTANTS.UPDATE_SEARCH_PARAMS,
      payload: { country: selectedValue, state: null, city: null },
    });
  };

  const onStateSelect = (event, selectedValue) => {
    if (selectedValue) {
      getCitiesByStateId(selectedValue.value);
    }
    dispatch({
      type: ACTION_TYPE_CONSTANTS.UPDATE_SEARCH_PARAMS,
      payload: { state: selectedValue, city: null },
    });
  };

  const onCitySelect = (event, selectedValue) => {
    dispatch({
      type: ACTION_TYPE_CONSTANTS.UPDATE_SEARCH_PARAMS,
      payload: { city: selectedValue },
    });
  };

  const onLocationTypeSelect = (event, selectedValue) => {
    dispatch({
      type: ACTION_TYPE_CONSTANTS.UPDATE_SEARCH_PARAMS,
      payload: { locationType: selectedValue },
    });
  };

  const onLocationNameSelect = (event, selectedValue) => {
    dispatch({
      type: ACTION_TYPE_CONSTANTS.UPDATE_SEARCH_PARAMS,
      payload: { locationName: selectedValue },
    });
  };

  const setSearchLocationName = debounce(async (inputText) => {
    let params = {
      page: 1,
      perPage: 100,
      searchParams: `q=${inputText}&isDropdown=true`,
    };
    getLocations(params).then((resp) => {
      const { isSuccess, data } = resp;
      if (isSuccess) {
        dispatch({
          type: ACTION_TYPE_CONSTANTS.UPDATE_LOCATION_NAME_DATA,
          payload: getOptions(data?.locations || []),
        });
      }
    });
  }, 600);

  const onCancel = () => {
    dispatch({
      type: ACTION_TYPE_CONSTANTS.RESET_STATE,
    });
    onCancelPress();
  };

  const onApplyFilter = () => {
    const { city, state: stateId, country, locationType, locationName } = state.searchParams;
    const locationTypeId = locationType.map((item) => item.value).join(',');
    const locationNameId = locationName.map((item) => item.value).join(',');
    const searchParams = `cityId=${city?.value || ''}&stateId=${stateId?.value || ''}&countryId=${
      country?.value || ''
    }&locationTypeId=${locationTypeId}&includeLocations=${locationNameId}`;
    applyFilterSearch(searchParams);
  };

  return (
    <Box>
      <Grid container spacing={'25px'}>
        <Grid item sm={12} md={4}>
          <Autocomplete
            options={state.countriesData}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={state.searchParams.country || ''}
            label={'Country'}
            onChange={onCountrySelect}
            id={'country'}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Autocomplete
            options={state.statesData}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={state.searchParams.state || ''}
            label={'State'}
            onChange={onStateSelect}
            id={'state'}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Autocomplete
            options={state.citiesData}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={state.searchParams.city || ''}
            label={'City'}
            onChange={onCitySelect}
            id={'city'}
          />
        </Grid>
        <Grid item sm={12}>
          <Autocomplete
            multiple={true}
            disableCloseOnSelect
            options={state.locationTypesData}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={state.searchParams.locationType || []}
            label={'Location Type'}
            onChange={onLocationTypeSelect}
            id={'locationType'}
          />
        </Grid>
        <Grid item sm={12}>
          <Autocomplete
            multiple={true}
            options={state.locationNameData}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={state.searchParams.locationName || []}
            label={'Location Name'}
            onChange={onLocationNameSelect}
            id={'locationName'}
            onOpen={() => setIsLocationNameOpen(true)}
            onClose={() => setIsLocationNameOpen(false)}
            open={isLocationNameOpen}
            onInputChange={(e, newValue) => setSearchLocationName(newValue)}
          />
        </Grid>
      </Grid>
      <Stack direction={'row'} mt={'52px'}>
        <Button disabled={isButtonDisable} variant={'contained'} onClick={onApplyFilter}>
          {'SEARCH'}
        </Button>
        <Button onClick={onCancel}>{'CANCEL'}</Button>
      </Stack>
    </Box>
  );
};

export default UserAddNewLocationFilter;
