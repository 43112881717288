import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PinDropOutlined } from '@mui/icons-material';

import ReactTable from '../../../components/shared/ReactTable';
import { USER_ASSIGN_LOCATION_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { useGetLocationsQuery } from '../../../services/Location/LocationService';
import { isArrayWithLength } from '../../../utils/common-methods';
import { Button, CheckBox, NoContent } from '../../../components/shared';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import useStyles from './style';

const UserAddNewLocationTable = ({
  searchParams = '',
  userAssignedLocation = [],
  onCancelAssignLocationTable = () => {},
  onLocationCheckboxChange = () => {},
  onSubLocationCheckboxChange = () => {},
  setLocationIndeterminateChange = () => {},
  onSaveLocationClicked = () => {},
  locationCheckBox = {},
  locationIndeterminate = {},
  subLocationCheckBox = {},
  setSubLocationCheckBox = () => {},
}) => {
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const {
    isError: locationError,
    isSuccess,
    currentData: latestData,
  } = useGetLocationsQuery({
    page: currentPage,
    perPage: perPageNumber,
    searchParams: `${searchParams}&sort=locationName:ASC`,
  });

  useEffect(() => {
    if (isSuccess && latestData) {
      const locations = latestData?.locations?.map((location) => {
        const transformLocation = {};
        transformLocation.id = location?.id;
        transformLocation.locationName = location?.locationName || 'N?A';
        transformLocation.city = location?.city?.name || 'N/A';
        transformLocation.state = location?.state?.name || 'N/A';
        transformLocation.postcode = location?.postcode || 'N/A';
        transformLocation.sublocations =
          location?.sublocations?.map((item) => ({
            id: item.id,
            locationId: location?.id,
            subLocationName: item?.name || 'N/A',
            city: item?.city?.name || location?.city?.name || 'N/A',
            state: item?.state?.name || location?.state?.name || 'N/A',
            postcode: item?.postcode || location?.postcode || 'N/A',
          })) || [];

        return transformLocation;
      });

      setTableData(locations);
      let total = latestData?.pagination?.total || 0;
      setTotalCount(total);
    }
  }, [isSuccess, latestData, locationError]);

  useEffect(() => {
    tableData?.map((loc) => {
      const userAssignedLocationId = userAssignedLocation.filter((location) => location.locationId === loc.id);
      let newSubLocationObj = {};
      loc.sublocations.map((subLoc) => {
        const isAlreadyAssignedToUser = userAssignedLocationId
          .map((assignedSubLoc) => assignedSubLoc.id)
          .includes(subLoc.id);
        if (subLocationCheckBox?.[loc.id]?.[subLoc.id]) {
          newSubLocationObj[subLoc.id] = subLocationCheckBox[loc.id][subLoc.id];
        } else {
          newSubLocationObj[subLoc.id] = isAlreadyAssignedToUser;
        }
      });
      setSubLocationCheckBox((prev) => ({
        ...prev,
        [loc.id]: newSubLocationObj,
      }));
      setLocationIndeterminateChange({ locationId: loc.id }, newSubLocationObj, tableData);
    });
  }, [tableData]);

  const locationColumns = useMemo(() => {
    const dynamicColumns = USER_ASSIGN_LOCATION_TABLE_DYNAMIC(isBelowMD);
    return [
      {
        id: 'selection Location',
        Header: () => null,
        width: 50,
        Cell: ({ row }) => (
          <CheckBox
            onChange={(e) => onLocationCheckboxChange(row.original, e.target.checked)}
            checked={locationCheckBox[row.original.id]}
            indeterminate={locationIndeterminate[row.original.id]}
          />
        ),
      },
      {
        Header: 'LOCATION NAME',
        id: 'locationName',
        width: 500,
        accessor: 'locationName',
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()} data-testid="expandIcon">
            {row.original?.locationName}
          </span>
        ),
      },
      ...dynamicColumns,
    ];
  }, [locationCheckBox, locationIndeterminate, isBelowMD]);

  const subLocationColumn = useMemo(() => {
    const dynamicColumns = USER_ASSIGN_LOCATION_TABLE_DYNAMIC(isBelowMD);
    return [
      {
        id: 'selection subLocation',
        Header: () => null,
        width: 50,
        Cell: ({ row }) => {
          const isChecked = subLocationCheckBox?.[row?.original?.locationId]?.[row?.original?.id];
          return (
            <CheckBox
              onChange={(e) => onSubLocationCheckboxChange(row.original, e.target.checked, tableData)}
              checked={isChecked}
            />
          );
        },
      },
      {
        Header: 'LOCATION NAME',
        accessor: 'subLocationName',
        width: 500,
      },
      ...dynamicColumns,
    ];
  }, [subLocationCheckBox, isBelowMD]);

  const renderRowSubComponent = ({ row }) => (
    <Box className={classes.renderSubComponentWrapper}>
      <Typography variant="h3" pl={9} pt={2} pb={1} role="presentation">
        Sub Locations
      </Typography>
      <ReactTable key={'Sublocation'} data={row.original.sublocations} columns={subLocationColumn} hideHeader />
    </Box>
  );

  const onCancelPress = () => {
    onCancelAssignLocationTable();
  };

  const onSavePress = () => {
    onSaveLocationClicked(subLocationCheckBox);
  };

  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  return (
    <Box mt={'32.5px'} className={classes.assignLocationTableContainer}>
      {!locationError && isArrayWithLength(tableData) ? (
        <>
          <ReactTable
            key={'Location'}
            data={tableData}
            columns={locationColumns}
            expandable
            renderRowSubComponent={renderRowSubComponent}
            expandCheck={'sublocations'}
            pagination
            manualPagination
            totalCount={totalCount}
            pageNumberForTablePagination={currentPage - 1}
            onPageChange={pageChangeHandler}
            onPerPageChange={perPageChangeHandler}
            maxWidth="xl"
          />
          <Stack mt={'52px'} direction={'row'}>
            <Button onClick={onSavePress} variant={'contained'}>
              {'SAVE'}
            </Button>
            <Button onClick={onCancelPress}>{'CANCEL'}</Button>
          </Stack>
        </>
      ) : (
        <NoContent
          icon={<PinDropOutlined sx={{ width: '2.5em', height: '2.5em', color: '#2CA2DF' }} />}
          title="No Locations Found"
          desc={
            <>
              There are no locations in this search criteria
              <br /> Please try again using new search criteria
            </>
          }
          buttonName="SEARCH AGAIN"
          onBtnClickHandler={onCancelPress}
        />
      )}
    </Box>
  );
};

export default UserAddNewLocationTable;
