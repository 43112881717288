import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalCollected: 0,
  totalCollectedInUSD: 0,
  collectionCollected: 0,
  transformedSublocations: [],
  formikData: [],
  intercardData: {},
  locationCurrency: {},
  sublocationDates: {},
  subLocationDatesFormIsDirty: false,
  summary: {
    locationName: '',
    sublocations: [],
    totalAssets: 0,
    submissionDate: '',
    totalTicketDispensed: 0,
    totalPrizeDispensed: 0,
    totalMedallionDispensed: 0,
    totalTokenCollected: 0,
    totalCollected: 0,
    totalCollectedInUSD: 0,
    collectionCollected: 0,
  },
};

export const newCollectionSlice = createSlice({
  name: 'newCollection',
  initialState,
  reducers: {
    setTotalCollected(state, action) {
      state.totalCollected = action.payload?.total || 0;
      state.totalCollectedInUSD = action.payload?.totalInUSD || 0;
      state.summary.totalCollected = action.payload?.total || 0;
      state.summary.totalCollectedInUSD = action.payload?.totalInUSD || 0;
    },
    setTotalTicketDispensed(state, action) {
      state.summary.totalTicketDispensed = action.payload;
    },
    setTotalPrizeDispensed(state, action) {
      state.summary.totalPrizeDispensed = action.payload;
    },
    setTotalMedallionDispensed(state, action) {
      state.summary.totalMedallionDispensed = action.payload;
    },
    setTotalTokenCollected(state, action) {
      state.summary.totalTokenCollected = action.payload;
    },
    setFormikData(state, action) {
      state.formikData = action.payload;
    },
    setIntercardData(state, action) {
      state.intercardData = action.payload;
    },
    setCollectionCollected(state, action) {
      state.collectionCollected = action.payload;
      state.summary.collectionCollected = action.payload;
    },
    setTransformedSublocations(state, action) {
      state.transformedSublocations = action.payload;
      state.summary.sublocations = action.payload;
    },
    setSummary(state, action) {
      const { locationName, totalAssets, submissionDate } = action.payload;
      state.summary.locationName = locationName;
      state.summary.totalAssets = totalAssets;
      state.summary.submissionDate = submissionDate;
    },
    setLocationCurrency(state, action) {
      state.locationCurrency = action.payload;
    },
    setSublocationDates(state, action) {
      state.sublocationDates = {
        ...state.sublocationDates,
        ...action.payload,
      };
    },
    setSubLocationDatesFormIsDirty(state, action) {
      state.subLocationDatesFormIsDirty = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    resetSubLocationDates(state) {
      return {
        ...state,
        sublocationDates: {},
      };
    },
  },
});

export default newCollectionSlice;
