import {
  GridContainer,
  GridItem,
  InformationField,
  Select,
  TextField,
  Button,
  CheckBox,
} from '../../../components/shared';
import { Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { MESSAGE } from '../../../constants/message';
import {
  useGetAllReportIssuesQuery,
  useUpdateReportByIssueIdMutation,
} from '../../../services/CollectionService/CollectionService';
import { getOptions, isArrayWithLength } from '../../../utils/common-methods';
import { reportModalSchemaForIssue, reportModalSchemaForResolve } from '../../../schema/validationSchemas';
import { useFormik, useFormikContext } from 'formik';
import useStyles from './style';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';

const ReportMachineModelBody = ({
  assetId,
  assetName,
  onConfirm,
  onClose,
  asset = {},
  isApproved = false,
  reportedIssue = () => {},
  isAddNewCollection = false,
  isReconciliation = false,
  isIssueReported = false,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext() || {};
  const [updateReportByIssueIdApi] = useUpdateReportByIssueIdMutation();
  // const isSuperAdminOrAccountingUser = useHasRoles([ROLE.ACCOUNTING, ROLE.SUPER_ADMIN_IT]);
  const isIRUser = useHasRoles([ROLE.INDEPENDENT_REPS]);

  const formik = useFormik({
    initialValues: {
      issue: '',
      reason: '',
      status: false,
    },
    validationSchema: isIssueReported ? reportModalSchemaForResolve : reportModalSchemaForIssue,
    onSubmit: (data) => {
      //if there is no pending issue on add collection then submit new report
      if (!isIssueReported && isAddNewCollection) {
        onConfirm({
          assetId: assetId,
          assetName: assetName,
          issue: data?.issue,
          reason: data?.reason,
          status: false,
        });
      }
      //If issue is pending in data received from api, then resolve it
      if (isIssueReported && data?.status) {
        updateReportByIssueIdApi({
          id: asset?.noCollectionReason?.id,
          status: MESSAGE.REPORT_STATUS_RESOLVED,
        }).then((response) => {
          if (response?.data) {
            reportedIssue(false);
            onConfirm({});
          }
        });
      }
    },
  });
  const { errors, touched, getFieldProps, handleSubmit, setValues } = formik;

  useEffect(() => {
    if (isAddNewCollection) {
      const fieldName = `report_${asset.subLocationId}_${asset.id}`;
      const reportedValueFromFormikContext = values[fieldName];
      setValues({
        issue: reportedValueFromFormikContext?.issue,
        reason: reportedValueFromFormikContext?.reason,
        status: false,
      });
    }
  }, [asset]);

  const { currentData: issuesResponse, isError: issuesError } = useGetAllReportIssuesQuery();
  const issuesOptions = useMemo(() => {
    let reportOptions = issuesResponse?.data?.noCollectionReportIssues;
    if (isArrayWithLength(reportOptions)) {
      return getOptions([...issuesResponse?.data?.noCollectionReportIssues, { id: '', name: 'None' }]);
    }
    return getOptions([{ id: '', name: 'None' }]);
  }, [issuesResponse]);

  if (issuesError) {
    console.error('Error in report issues api');
  }
  //On add collection disable report submit only when issue and reason are blank while reporting new issue
  const disableSubmitOrUpdate = isIssueReported
    ? !formik.values?.status
    : !formik.values?.issue && !formik.values.reason && !values[`report_${asset.subLocationId}_${asset.id}`]?.issue;
  const showSubmitAndUpdateButton =
    (isAddNewCollection && !isIssueReported) ||
    (isAddNewCollection && isIssueReported && !isIRUser) ||
    (isReconciliation && isIssueReported && !isIRUser);

  return (
    <Stack my={2}>
      <GridContainer>
        <GridItem xs={3}>
          <InformationField value={'MACHINE ID'} variant="subtitle2" />
          <InformationField value={assetId} variant="subtitle1" />
        </GridItem>
        <GridItem xs={3}>
          <InformationField value={'MACHINE NAME'} variant="subtitle2" />
          <InformationField value={assetName} variant="subtitle1" />
        </GridItem>
      </GridContainer>
      {isIssueReported && (
        <GridContainer>
          <GridItem xs={3} pt={2}>
            <InformationField value={'ISSUE REPORTED'} variant="subtitle2" />
          </GridItem>
          <GridItem xs={9} pt={2}>
            <InformationField value={asset?.noCollectionReason?.issue?.title || 'N/A'} variant="subtitle1" />
          </GridItem>
          <GridItem xs={3} pt={2}>
            <InformationField value={'REASON'} variant="subtitle2" />
          </GridItem>
          <GridItem xs={9} pt={2} className={classes.reportIssueReason}>
            <InformationField value={asset?.noCollectionReason?.reason || 'N/A'} variant="subtitle1" />
          </GridItem>
        </GridContainer>
      )}
      {!isApproved && (
        <form onSubmit={handleSubmit}>
          <GridContainer my={3}>
            {!isIssueReported && (
              <>
                <GridItem xs={12} mb={3} pr={2}>
                  <Select
                    inputProps={{
                      'data-testid': 'form-elements',
                    }}
                    inputLabel={'Select Issue'}
                    options={issuesOptions || []}
                    {...getFieldProps('issue')}
                    error={Boolean(touched.issue && errors.issue)}
                    helperText={touched.issue && errors.issue}
                  />
                </GridItem>
                <GridItem xs={12} pr={2}>
                  <TextField
                    label="Reason"
                    {...getFieldProps('reason')}
                    error={Boolean(touched.reason && errors.reason)}
                    helperText={touched.reason && errors.reason}
                  />
                </GridItem>
              </>
            )}
            {!isIRUser && isIssueReported && (
              <>
                <GridItem xs={0.7} className={classes.reportModalClass}>
                  <CheckBox {...getFieldProps('status')} />
                </GridItem>
                <GridItem xs={10} p={1.2}>
                  <Typography variant="subtitle1">This issue is resolved</Typography>
                </GridItem>
              </>
            )}
          </GridContainer>
          {showSubmitAndUpdateButton && (
            <Stack direction={'row'} mt={'15px'} alignItems={'center'}>
              <Button type="Submit" variant={'contained'} disabled={disableSubmitOrUpdate}>
                {isIssueReported ? MESSAGE.UPDATE : MESSAGE.SUBMIT}
              </Button>
              <Button onClick={onClose}>{MESSAGE.CANCEL}</Button>
            </Stack>
          )}
          {!showSubmitAndUpdateButton && <Button onClick={onClose}>{MESSAGE.CANCEL}</Button>}
        </form>
      )}
    </Stack>
  );
};
export default ReportMachineModelBody;
