import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playCards: {},
  playcardSalesSummary: 0,
  playcardFormDirty: false, // this is for add-collection page to detect if the form of playcard summary is dirty
};

export const playCardSummarySlice = createSlice({
  name: 'playCardSummary',
  initialState,
  reducers: {
    setPlaycardFormDirty(state, action) {
      state.playcardFormDirty = action.payload;
    },
    setPlayCards(state, action) {
      state.playCards = action.payload;
    },
    setPlayCardSalesSummary(state, action) {
      state.playcardSalesSummary = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    setPlayCardsSalesSummary(state, action) {
      state.playcardSalesSummary = action.payload;
    },
  },
});

export default playCardSummarySlice;
