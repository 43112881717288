import { createSlice } from '@reduxjs/toolkit';
import { isArrayWithLength } from '../../utils/common-methods';

const initialState = {
  collections: {},
  draftSublocationDates: {},
};

export const storedCollectionsSlice = createSlice({
  name: 'storedCollections',
  initialState,
  reducers: {
    setCollection(state, action) {
      const { locationId, formData, exchangeRate, playcards, intercardData, locationName } = action.payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          [locationId]: {
            ...formData,
            exchangeRate: exchangeRate,
            playcards: playcards,
            intercardData: intercardData,
            locationName: locationName,
          },
        },
      };
    },
    setDraftSublocationDates(state, action) {
      state.draftSublocationDates = { ...state.draftSublocationDates, ...action.payload };
    },
    resetCollectionByLocationId(state, action) {
      const locationId = action.payload;
      if (locationId && state?.collections[locationId]) {
        delete state.collections[locationId];
      }
    },
    reduceDraftDatesBySublocationIds(state, action) {
      const sublocationIds = action.payload;
      if (isArrayWithLength(sublocationIds)) {
        sublocationIds.forEach((id) => {
          if (state.draftSublocationDates[id]) delete state.draftSublocationDates[id];
        });
      }
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default storedCollectionsSlice;
