import { Box, Stack } from '@mui/material';
import { Autocomplete, Button, GridContainer, GridItem, Link } from '../../../components/shared';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { ownerClickAction, ownerDetailsAction } from '../../../redux-slice/location';
import { useGetAllLocationOwnersQuery } from '../../../services/Location/LocationService';
import { useMemo, useState } from 'react';

const LocationOwnersDetailsFrom = (props) => {
  const { onNextClick, onBackClick } = props;
  const [searchText, setSearchText] = useState('');
  const { currentData: ownersResponse } = useGetAllLocationOwnersQuery({
    page: 1,
    perPage: 100,
    searchParams: `q=${searchText}`,
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const locationState = useSelector((state) => state?.location);

  const { buttonActions, addNewLocationForms } = locationState;
  const { ownerDetails } = addNewLocationForms;

  const dispatchClickHandler = async (boolValue) => {
    const receivedValue = await { ...buttonActions, ownerClick: boolValue };
    dispatch(ownerClickAction(receivedValue));
  };
  const ownersArr = useMemo(() => {
    let ownerFinalArr = [];
    if (ownersResponse) {
      ownerFinalArr = ownersResponse?.owners?.map((value) => ({
        id: value?.id,
        name: value?.name,
      }));
    }
    return ownerFinalArr;
  }, [ownersResponse]);
  const onNextClickHandler = () => {
    const isNextClick = true;
    dispatchClickHandler(isNextClick).then(() => {
      onNextClick();
    });
  };

  const onSkipClickHandler = () => {
    const isNextClick = false;
    dispatchClickHandler(isNextClick).then(() => {
      onNextClick();
    });
    dispatch(ownerDetailsAction(null));
  };

  const onChangeHandler = (event, value) => {
    const ownerObj = { ...ownerDetails, ownerId: value };
    dispatch(ownerDetailsAction(ownerObj));
  };

  const onInputChangeHandler = (e, value) => {
    setSearchText(value);
  };

  return (
    <Box mt={6} className={classes.locationOwnerDetailsForm} data-testid="locationOwnerComponent">
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Autocomplete
            name="companyName"
            label="Company Name"
            options={ownersArr}
            value={ownerDetails?.ownerId}
            onChange={onChangeHandler}
            onInputChange={onInputChangeHandler}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            data-testid="locationDetailForms"
          />
        </GridItem>
      </GridContainer>
      <Box mt={4}>
        <Link component="button" underline="none" className={classes.skipThisStep} onClick={onSkipClickHandler}>
          Skip This Step For Now!
        </Link>
      </Box>
      <Box className={classes.addSubLocationBtnWrap}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={onNextClickHandler} data-testid="nextBtn">
            Next
          </Button>
          <Button onClick={onBackClick} data-testid="backBtn">
            Back
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default LocationOwnersDetailsFrom;
