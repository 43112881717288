import usersFilterSlice from './usersFilter-slice';

export const {
  setActiveUsersFilters,
  setArchiveUsersFilters,
  resetState,
  resetActiveUsersFiltersState,
  resetArchiveUsersFiltersState,
} = usersFilterSlice.actions;

export default usersFilterSlice;
