import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './style/HeaderMenu.style';
import { Box, ListItemButton, ListItemIcon, ListItemText, Menu, Typography } from '@mui/material';
import { PersonOutlineOutlined } from '@mui/icons-material';
import SignOutButton from '../SignOutButton';
import { useDispatch, useSelector } from 'react-redux';
import { isArrayWithLength } from '../../utils/common-methods';
import ConfirmationModal from '../ConfirmationModel/ConfirmationModal';
import { useMsal } from '@azure/msal-react';
import { logout } from '../../redux-slice/user';
import { LOADING_STATE } from '../../constants/CommonConstants';
import { setSsoSilentStatus } from '../../redux-slice/msalStatus';

const displayName = 'HeaderMenu';
const propTypes = {
  classes: PropTypes.object.isRequired,
};

const HeaderMenu = (props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state);
  const { name, userDetails } = user;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const roles = useMemo(() => {
    if (isArrayWithLength(userDetails.roles)) {
      const userRoles = userDetails.roles.map((item) => item.title);
      return userRoles.length > 1 ? `${userRoles[0]}, (+${userRoles.length - 1})` : userRoles.join(', ');
    }
    return '';
  }, [userDetails]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutPressHandler = () => {
    setConfirmationOpen(true);
    setAnchorEl(null);
  };
  const onConfirmationClickHandler = (value) => {
    if (value) {
      dispatch(logout());
      dispatch(setSsoSilentStatus(LOADING_STATE.SIGNED_OUT));
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    } else {
      setConfirmationOpen(false);
    }
  };

  return (
    <Box pl={3}>
      <Box onClick={handleClick} className={classes.userContainer} aria-hidden="true">
        <Typography variant="h5">{name}</Typography>
        <Typography variant="p3">{roles}</Typography>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ListItemButton onClick={handleClose}>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText primary={'Profile'} />
        </ListItemButton>
        <SignOutButton onLogoutPress={onLogoutPressHandler} />
      </Menu>
      <ConfirmationModal
        isOpen={confirmationOpen}
        title="Confirmation"
        msg="Are you sure you want to sign out?"
        buttons={[
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ]}
        onClick={onConfirmationClickHandler}
      />
    </Box>
  );
};

HeaderMenu.displayName = displayName;
HeaderMenu.propTypes = propTypes;
export default withStyles(styles)(HeaderMenu);
