import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import ReactTable from '../../../components/shared/ReactTable/ReactTable';
import { COLUMNS, MODEL_MACHINE_DYNAMIC_COLUMN } from '../../../constants/tableColumns';
import FIELD_TYPE from '../../../constants/fieldType';
import { GridContainer, GridItem, InformationField, InformationPad, Link } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { MODEL_DETAIL_LABEL } from '../../../constants/accordionFields';
import { getDateFormat } from '../../../utils/common-methods';
import useStyles from './style';

const ModelMachineTable = (props) => {
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const { NOT_APPLICABLE } = FIELD_TYPE;
  const { machines = [] } = props;
  const classes = useStyles();

  const columns = useMemo(() => {
    const dynamicColumns = MODEL_MACHINE_DYNAMIC_COLUMN(isBelowMD);
    const col = [COLUMNS.EXPAND_ICON_COLUMN, ...dynamicColumns];
    return col;
  }, [isBelowMD]);

  const data = useMemo(
    () =>
      machines.map((item) => {
        const { id, numberOfPlays, title, installedDate, status = { title: '' }, sublocation = { name: '' } } = item;
        return {
          ...item,
          machineId: id,
          title: title || NOT_APPLICABLE,
          statusTitle: status?.title || NOT_APPLICABLE,
          numberOfPlays: numberOfPlays || NOT_APPLICABLE,
          installedDate: getDateFormat(installedDate) || NOT_APPLICABLE,
          sublocationName: sublocation?.name || NOT_APPLICABLE,
        };
      }),
    [machines, isBelowMD],
  );

  const detailMachineComponent = useCallback(({ row }) => {
    const { original = {} } = row;

    const fields = () => {
      const data = {
        [MODEL_DETAIL_LABEL.MACHINES.TYPE]: original?.type?.title,
        [MODEL_DETAIL_LABEL.MACHINES.PRICE]:
          `${original?.price || ''} ${original?.currency?.abbreviation || ''}`.trim() || NOT_APPLICABLE,
        [MODEL_DETAIL_LABEL.MACHINES.MODEL]: original?.title,
      };
      return Object.keys(data).map((key) => ({ label: key, value: data[key] }));
    };
    return (
      <Box
        component={Paper}
        py={1}
        sx={{
          border: `1px solid ${theme.palette.grey[900]}`,
          borderRadius: '8px',
          '& .MuiGrid2-container': {
            paddingLeft: '40px',
          },
        }}
      >
        <InformationPad data={fields()} />
        <GridContainer my={3} ml={1} px={3}>
          <GridItem xs={12}>
            <InformationField
              fontSize="14px"
              fontWeight="500"
              lineHeight="16px"
              value={
                <Link
                  variant="link"
                  underline="none"
                  color="primary"
                  to={`/${routeConstants.MACHINES_ROUTE}/${original.id}`}
                >
                  VIEW FULL DETAILS
                </Link>
              }
            />
          </GridItem>
        </GridContainer>
      </Box>
    );
  }, []);

  return (
    <Box className={classes.machinesTableWrapper}>
      <ReactTable
        data={data}
        columns={columns}
        maxWidth="xl"
        expandable
        renderRowSubComponent={detailMachineComponent}
        localFilter
        searchPlaceHolder="Search by asset id & machine name"
      />
    </Box>
  );
};

export default ModelMachineTable;
