import { GridContainer, GridItem, InformationField } from '../../../components/shared';
import { Box, Typography, Paper } from '@mui/material';
import { Link } from '../../../components/shared';
import LocationDetailAccordion from '../LocationDetails/LocationDetailAccordion';
import useStyles from './style';

const LocationAccordion = ({ locationInfo }) => {
  const classes = useStyles();

  return (
    <Box component={Paper} className={classes.locationDetailAccordion}>
      <Box>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Typography variant="h3" mb={1}>
              Location Details
            </Typography>
          </GridItem>
        </GridContainer>
        <LocationDetailAccordion locationInfo={locationInfo} />

        <GridContainer className={classes.locationDetailBtnWrap}>
          <GridItem xs={12} md={12}>
            <InformationField
              fontSize="14px"
              fontWeight="500"
              lineHeight="16px"
              value={
                <Link variant="link" underline="none" color="primary" to={`/location/${locationInfo.id}`}>
                  VIEW FULL DETAILS
                </Link>
              }
            />
          </GridItem>
        </GridContainer>
      </Box>
    </Box>
  );
};
export default LocationAccordion;
