import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { CheckBox, GridContainer, GridItem } from '../../../components/shared';
import useStyles from './style';
import { MESSAGE } from '../../../constants/message';

export const DraftSummaryBody = ({ draftsData, locationsIds, setLocationIds }) => {
  const classes = useStyles();
  const onChangeHandler = (e, id) => {
    setLocationIds({ ...locationsIds, [id]: e?.target?.checked });
  };
  return (
    <Box>
      <Divider />
      <GridContainer py={2}>
        {draftsData?.length &&
          draftsData?.map((data) => (
            <>
              <GridItem xs={2}>
                <CheckBox
                  onChange={(e) => {
                    onChangeHandler(e, data.id);
                  }}
                />
              </GridItem>
              <GridItem xs={10} className={classes.draftedLocationName}>
                <Typography variant="subtitle1">{data?.name || data?.id}</Typography>
              </GridItem>
            </>
          ))}
      </GridContainer>
      <Typography variant="subtitle9" px={2}>
        {MESSAGE.DRAFT_NOTE}
      </Typography>
    </Box>
  );
};
