import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  basicDetailsContainer: {
    marginTop: '32px',
    backgroundColor: '#fff',
    border: '0px 1px 4px #64748b',
    borderRadius: '8px',
    padding: '24px 40px 40px 0px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
  },

  errorButton: {
    paddingRight: `${0}`,
  },
}));

export default useStyles;
