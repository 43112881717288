import { Box, Paper, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { GridContainer, GridItem, InformationField, InformationPad, Link } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from './style';
import { LEASE_DETAIL_LABEL } from '../../../constants/accordionFields';
import { rentTypeString } from '../../../utils/common-methods';

const LeaseAccordion = (props) => {
  const { leaseInfo } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fields = useMemo(
    () => [
      { label: LEASE_DETAIL_LABEL.LOCATION, value: leaseInfo?.location?.locationName },
      {
        label: LEASE_DETAIL_LABEL.SUBLOCATION,
        value: leaseInfo.sublocation?.map((item) => item?.sublocation?.name)?.join(', ') || '',
      },
      { label: LEASE_DETAIL_LABEL.ADDRESS1, value: leaseInfo.locationAddress1 },
      { label: LEASE_DETAIL_LABEL.ADDRESS2, value: leaseInfo.locationAddress2 },
      { label: LEASE_DETAIL_LABEL.ZIPCODE, value: leaseInfo?.zipcode },
      { label: LEASE_DETAIL_LABEL.CITY, value: leaseInfo?.city?.name },
      { label: LEASE_DETAIL_LABEL.STATE, value: leaseInfo?.state?.name },
      { label: LEASE_DETAIL_LABEL.COUNTRY, value: leaseInfo?.country?.name },
      { label: LEASE_DETAIL_LABEL.RENT_TYPE, value: rentTypeString(leaseInfo?.rentType) },
      { label: LEASE_DETAIL_LABEL.RENT_RATE, value: leaseInfo?.rate },
      { label: LEASE_DETAIL_LABEL.RENT_FEE, value: leaseInfo?.fee && `$${leaseInfo?.fee}` },
    ],
    [leaseInfo],
  );

  return (
    <Box
      component={Paper}
      className={classes.locationDetailAccordion}
      sx={{
        border: `1px solid ${theme.palette.grey[900]}`,
        borderRadius: '8px',
      }}
    >
      <Box>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Typography variant="h3" mb={1}>
              Lease & Rent Details
            </Typography>
          </GridItem>
        </GridContainer>

        <InformationPad data={fields} />

        <GridContainer className={classes.locationDetailBtnWrap}>
          <GridItem xs={12} md={12}>
            <InformationField
              fontSize="14px"
              fontWeight="500"
              lineHeight="16px"
              value={
                <Link
                  variant="link"
                  underline="none"
                  color="primary"
                  to={`/${routeConstants.LEASE_ROUTE}/${leaseInfo.id}`}
                >
                  VIEW FULL DETAILS
                </Link>
              }
            />
          </GridItem>
        </GridContainer>
      </Box>
    </Box>
  );
};

export default LeaseAccordion;
