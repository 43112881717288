import React, { memo, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { CachedOutlined } from '@mui/icons-material';
import { SYNC_STATUS } from '../../../constants/CommonConstants';

const SyncingModalBody = ({ syncStatus }) => {
  const { SUCCESS, FAILURE, SYNCING } = SYNC_STATUS;

  const getTitle = useMemo(() => {
    switch (syncStatus) {
      case SYNCING:
        return 'Syncing Data';
      case SUCCESS:
        return 'Sync Successful';
      case FAILURE:
        return 'Sync Failed';
      default:
        return 'Syncing Data';
    }
  }, [syncStatus]);

  const getDescription = useMemo(() => {
    switch (syncStatus) {
      case SYNCING:
        return 'Please wait, this action may take some time.';
      case SUCCESS:
        return 'Sync has been successfully completed.';
      case FAILURE:
        return 'There was error with syncing users. Please try again after some time.';
      default:
        return 'Please wait, this action may take some time.';
    }
  }, [syncStatus]);

  return (
    <Stack spacing={2} p={3} alignItems={'center'}>
      <CachedOutlined color="primary" sx={{ fontSize: '4rem' }} />
      <Typography variant="h1" textAlign="center">
        {getTitle}
      </Typography>
      <Typography variant="h4" textAlign="center">
        {getDescription}
      </Typography>
    </Stack>
  );
};

export default memo(SyncingModalBody);
