import ReactTable from '../../../components/shared/ReactTable';
import { COLUMNS } from '../../../constants/tableColumns';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useGetModemsQuery } from '../../../services/Modems/ModemsService';
import ModemAccordion from './ModemAccordion';
import { Link, NoContent } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import FIELD_TYPE from '../../../constants/fieldType';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getDateFormat } from '../../../utils/common-methods';
import { setModemsFilters } from '../../../redux-slice/modemFilters';
import { useDispatch } from 'react-redux';

const { NOT_APPLICABLE } = FIELD_TYPE;

const modemColumns = [COLUMNS.EXPAND_ICON_COLUMN, ...COLUMNS.MODEMS_TABLE];
export default function ModemsTable({ onTotalModem, searchParams, modems }) {
  const MODEMS_COLUMNS = useMemo(() => modemColumns, []);

  const [currentPage, setCurrentPage] = useState(modems?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(modems?.rowsPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);
  const dispatch = useDispatch();

  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
    isFetching,
  } = useGetModemsQuery(
    {
      searchParams: searchFilterData,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );
  const [tableData, setTableData] = useState([]);
  const serialNumberLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`/${routeConstants.MODEMS_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );
  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData?.modems?.map((modem) => {
        const transformModem = { ...modem };
        transformModem.serialNumber = serialNumberLink(modem?.id, modem?.serialNumber);
        transformModem.assetId = modem?.assetId ?? NOT_APPLICABLE;
        transformModem.installedDate = transformModem?.installedDate
          ? getDateFormat(transformModem?.installedDate)
          : NOT_APPLICABLE;
        return transformModem;
      });
      setTableData(data);
      let total = latestData?.pagination?.total ?? 0;
      setTotalCount(total);
      onTotalModem(total ?? totalCount);
    }
  }, [isSuccess, latestData]);

  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(modems?.pageNumber);
    setPerPageNumber(modems?.rowsPerPage);
    setSkipCall(false);
  }, [searchParams]);

  const pageChangeHandler = (currentPage) => {
    dispatch(setModemsFilters({ ...modems, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(setModemsFilters({ ...modems, rowsPerPage: newPerPage, pageNumber: currentPage }));
    setPerPageNumber(newPerPage);
  };

  const detailModemComponent = ({ row }) => {
    const original = row?.original;
    return original && <ModemAccordion modemInfo={original} />;
  };

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Modem Found"
          desc={(isError && error?.data?.message) || <>There are no modem present with this filter.</>}
        />
      )}
      {!isLoading && !isFetching && !isError && tableData && (
        <ReactTable
          data={tableData}
          columns={MODEMS_COLUMNS}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          expandable
          renderRows
          renderRowSubComponent={detailModemComponent}
        />
      )}
    </>
  );
}
