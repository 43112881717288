import { Button, GridContainer } from '../../components/shared';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Grid } from '@mui/material';
import useStyles from './style';

const FallbackUI = ({ error = null }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleRedirectToHome = () => {
    // go previous route.
    navigate(-1);
    setTimeout(() => {
      navigate(0);
    }, 500);
  };
  return (
    <Box className={classes.root}>
      <GridContainer direction="column" className={classes.container}>
        <Grid>
          <Typography variant="h1">Something Went Wrong</Typography>
        </Grid>
        <Grid pt={3}>
          <Typography variant="h3">{error?.toString()}</Typography>
        </Grid>
        <Grid>
          <Typography variant="h3" pt={1}>
            Please Share This Error With System Administrator
          </Typography>
        </Grid>
        <Grid pt={3}>
          <Button variant="contained" onClick={handleRedirectToHome}>
            Go Back
          </Button>
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default FallbackUI;
