export const styles = (theme) => ({
  drawerBoxInner: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '& .MuiBox-root': {
      transition: 'all 0.2s ease-in-out',
    },
  },
});
