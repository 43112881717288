import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getAllSublocations: build.query({
      query: (args) => {
        if (args) {
          const { page = '', perPage = '', searchParams = '' } = args;
          return `${apiUrl.sublocation}?perPage=${perPage}&page=${page}&${searchParams}`;
        }
        return apiUrl.sublocation;
      },
      transformResponse: (response) => response.data.sublocations,
      providesTags: ['SubLocations'],
    }),
    addSubLocation: build.mutation({
      query: (data) => ({
        url: `${apiUrl.sublocation}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SubLocations', 'Locations', 'Location'],
    }),
    updateSubLocationById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.sublocation}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['SubLocations', 'Locations', 'Location', 'Sublocation'],
    }),
    deleteSubLocationById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.sublocation}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubLocations', 'Locations', 'Location'],
    }),
    getSublocationById: build.query({
      query: ({ id = '', apiSearchParams = '' }) => `${apiUrl.sublocation}/${id}?${apiSearchParams}`,
      providesTags: ['Sublocation', 'SubLocations'],
    }),
    getSublocationMachinesById: build.query({
      query: ({ id = '', apiSearchParams = '' }) => `${apiUrl.sublocation}/${id}/assets?${apiSearchParams}`,
      providesTags: ['Sublocation', 'SubLocations'],
    }),
    getSubLocationTypes: build.query({
      query: () => `${apiUrl.sublocation}/${apiUrl.locationType}`,
      transformResponse: (response) => response.data?.sublocationTypes,
    }),
    updateSubLocationMachineById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.sublocation}/${id}/associate-assets`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Sublocation', 'SubLocations', 'Location'],
    }),
    updateSubLocationLeaseById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.sublocation}/${id}/${apiUrl.lease}`,
        method: 'PUT',
        body: { ...rest },
      }),
      invalidatesTags: ['Sublocation', 'SubLocations', 'Location'],
    }),
    getProductCategories: build.query({
      query: () => `${apiUrl.productCategories}`,
      transformResponse: (response) => response?.data?.productCategories,
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetAllSublocationsQuery,
  useLazyGetAllSublocationsQuery,
  useGetSublocationByIdQuery,
  useGetSublocationMachinesByIdQuery,
  useAddSubLocationMutation,
  useUpdateSubLocationByIdMutation,
  useDeleteSubLocationByIdMutation,
  useGetSubLocationTypesQuery,
  useUpdateSubLocationMachineByIdMutation,
  useUpdateSubLocationLeaseByIdMutation,
  useGetProductCategoriesQuery,
  useLazyGetProductCategoriesQuery,
} = extendedApi;
