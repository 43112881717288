import React, { useState, useMemo, useEffect } from 'react';
import { Comment, Info } from '@mui/icons-material';
import { Box, Typography, Stack, IconButton, useMediaQuery, useTheme, Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import {
  GridContainer,
  GridItem,
  Button,
  ControlledTooltips,
  InformationField,
  Modal,
} from '../../../components/shared';
import ChildField from './ChildField';
import ReportMachineModelBody from './ReportMachineModelBody';
import { METER_TYPE_FILED_MAP } from '../../../constants/MeterTypeFieldMap';
import { MESSAGE } from '../../../constants/message';
import { sortAssetMeters } from '../../../utils/collection-methods';
import useStyles from '../style';
import IntercardFields from './IntercardFields';
import { getMeterLabel } from '../../../utils/collection-methods';
import { CommentBox } from '../../../components/CommentBox';
import { checkObjectNotEmpty } from '../../../utils/common-methods';

const MachineFormAccordionContent = ({ asset }) => {
  const { assetMeters } = asset;
  const { setFieldValue, values } = useFormikContext();
  const sortedAssetMeters = useMemo(() => {
    const rawData = [...assetMeters];
    return sortAssetMeters(rawData);
  }, [asset]);

  const theme = useTheme();
  const classes = useStyles();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const { locationCurrency, intercardData } = useSelector((state) => state.newCollection);

  const [showReportModal, setShowReportModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [intercardTransactions, setIntercardTransactions] = useState(asset.intercardTransactions ?? []);
  const [report, setReport] = useState({});
  const [isIssueReported, setIsIssueReported] = useState(false);
  const openReportHandler = () => {
    setShowReportModal(true);
  };
  useEffect(() => {
    if (values) {
      const fieldName = `report_${asset.subLocationId}_${asset.id}`;
      const reportedValueFromFormikContext = values[fieldName];
      setIsIssueReported(reportedValueFromFormikContext?.status);
      if (checkObjectNotEmpty(reportedValueFromFormikContext) && reportedValueFromFormikContext?.issue) {
        setReport({
          assetId: asset?.id,
          assetName: asset?.name,
          issue: reportedValueFromFormikContext?.issue,
          reason: reportedValueFromFormikContext?.reason,
          status: reportedValueFromFormikContext?.status,
        });
      } else {
        setReport({});
      }
    }
  }, [showReportModal, asset]);

  useEffect(() => {
    if (intercardData[asset?.id]?.intercardData) {
      setIntercardTransactions(intercardData[asset?.id]?.intercardData);
    } else {
      setIntercardTransactions([]);
    }
  }, [intercardData]);

  const submitReportHandler = (v) => {
    setReport(v);
    setFieldValue(`report_${asset.subLocationId}_${asset.id}`, v);
    setShowReportModal(false);
  };
  const openCommentHandler = () => {
    setShowCommentModal(true);
  };
  const submitCommentHandler = (v) => {
    setFieldValue(`comment_${asset.subLocationId}_${asset.id}`, v);
  };
  return (
    <React.Fragment>
      {assetMeters && assetMeters.length == 0 && (
        <InformationField my={1} pl={2} variant="subtitle1" value={MESSAGE.COLLECTION_NO_METERS} />
      )}
      <IntercardFields
        intercardTransactions={intercardTransactions}
        assetId={asset?.id}
        sublocationId={asset?.subLocationId}
      />
      {assetMeters && assetMeters.length > 0 && (
        <React.Fragment>
          <GridContainer pb={1}>
            {sortedAssetMeters?.map((assetMeter, index) => {
              const { meter } = assetMeter;
              const meterLabel = getMeterLabel(assetMeter, locationCurrency);
              return (
                <GridItem
                  sm={12}
                  md={6}
                  px={isBelowMD ? 0 : 1}
                  pt={1}
                  display="block"
                  justifyContent={isBelowMD ? 'flex-start' : 'flex-end'}
                  alignItems="center"
                  key={index}
                >
                  <GridItem px={1}>
                    <Typography variant="subtitle4"> {meterLabel}</Typography>
                  </GridItem>
                  <GridContainer key={meter?.id}>
                    <ChildField
                      assetId={asset?.id}
                      assetMeterId={assetMeter?.id}
                      assetMeter={assetMeter}
                      sublocationId={asset?.subLocationId}
                      meterName={meter?.name}
                      meterId={meter?.id}
                      fields={METER_TYPE_FILED_MAP[meter?.name]}
                      reported={Object.keys(report).length > 0}
                      isAddNewCollection
                    />
                  </GridContainer>
                </GridItem>
              );
            })}
          </GridContainer>
          <Divider />
          <Stack px={1} my={2} direction="row" justifyContent={'flex-start'} alignItems="top">
            <Box>
              {Object.keys(report).length > 0 || isIssueReported ? (
                <Box display="flex" alignItems="center" onClick={() => setShowReportModal(true)}>
                  <Box pt={1}>
                    <ControlledTooltips tooltipValue={MESSAGE.REPORT_MACHINE_INFO}>
                      <Info fontSize="small" color="warning" />
                    </ControlledTooltips>
                  </Box>
                  <Typography variant="p4" pl={1}>
                    {MESSAGE.RECONCILIATION_TOOLTIP_NAME}
                  </Typography>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.reportMachineBtn}
                  onClick={openReportHandler}
                >
                  {MESSAGE.REPORT_MACHINE_BTN_NAME}
                </Button>
              )}
            </Box>
            <Box px={3} pb={0}>
              <IconButton
                className={classes.comment}
                onClick={openCommentHandler}
                sx={{
                  color: showCommentModal ? theme.palette.primary.main : theme.palette.secondary.main,
                  padding: 1,
                }}
              >
                <Comment />
              </IconButton>
            </Box>
            <Box className={classes.commentBox}>
              {showCommentModal && (
                <CommentBox
                  handleClose={() => setShowCommentModal(false)}
                  handleSubmit={submitCommentHandler}
                  initialCommentData={values[`comment_${asset.subLocationId}_${asset.id}`]}
                  data={{
                    titleVariant: 'h3',
                    title: MESSAGE.RECONCILIATION_COMMENT_TITLE,
                    fieldName: `comment_${asset.subLocationId}_${asset.id}`,
                  }}
                />
              )}
            </Box>
          </Stack>

          <Modal open={showReportModal} title={MESSAGE.REPORT_MACHINE_BTN_NAME}>
            <ReportMachineModelBody
              assetId={asset?.id}
              assetName={asset?.title}
              asset={asset}
              reportedIssue={setIsIssueReported}
              onConfirm={(v) => {
                submitReportHandler(v);
              }}
              onClose={() => {
                setShowReportModal(false);
                setReport({});
              }}
              isAddNewCollection={true}
              isIssueReported={isIssueReported}
            />
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MachineFormAccordionContent;
