const FIELD_TYPE = {
  TEXT_FIELD: 'TextField',
  SELECT: 'Select',
  TIME_PICKER: 'Timepicker',
  DATE_PICKER: 'DatePicker',
  NOT_APPLICABLE: 'N/A',
  AUTO_COMPLETE: 'Autocomplete',
};

export default FIELD_TYPE;
