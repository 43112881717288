import { Typography } from '@mui/material';
import React from 'react';

const InformationField = ({ value, ...others }) => (
  <Typography data-testid="info-field" {...others}>
    {value ?? 'N/A'}
  </Typography>
);

export default React.memo(InformationField);
