import React, { useState, useEffect } from 'react';
import {
  Box,
  TablePagination,
  Pagination,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { GridContainer, GridItem, CheckBox, NoContent } from '../../shared';
import { useTable, usePagination, useExpanded, useRowSelect, useGlobalFilter } from 'react-table';
import useStyles from './style';
import SearchBar from '../../SearchBar';
import { DEFAULT_PER_PAGE, DEFAULT_PAGE } from '../../../constants/pagination';

/**
 * Returns a table
 * By passing pagination, React table will use usePagination hook to handle client-side pagination
 * @param {pagination: Bool}
 * It is not used in this component
 * To use server-side pagination, it needs to pass manualPagination
 * @param {pagination: Bool}
 * @param {manualPagination: Bool}
 *
 * There are two type of server-side pagination: Basic pagination and Table pagination, can only use one of them
 * To use basic pagination, need to pass
 * @param {basicPagination: Bool}
 * @param {pageNumberForBasicPagination: Number}
 * @param {totalPages: Number}
 * @param {onPageChange: Function}
 *
 * To use table pagination
 * @param {totalCount: Number}
 * @param {pageNumberForTablePagination: Number}
 * @param {perPage: Number}
 * @param {totalCount: Number}
 * @param {onPageChange: Function}
 * @param {onPerPageChange: Function}
 *
 * By passing expandable, React table will use useExpanded hook to implement row expanding
 * @param {expandable: Bool}
 * @param {renderRowSubComponent: Function}
 * The renderRowSubComponent is a function to render sub components when an element is expanded
 * @param {autoExpandedItems: [Object<rowId: String, expanded: Bool>]}
 * autoExpandedItems is to pass an array of objects, each object: {rowId: String, expanded: Bool}
 * Example: 
 * [
    {
        "rowId": true
    },
    {
        "1": true
    }
]
 * @param {expandCheck: String}
 * expandCheck is used to check if row.original[expandCheck] has any objects
 *
 * By passing rowSelect, React table will use useRowSelect hook to implement basic row selection
 * @param {rowSelect: Bool}
 * @param {onSetSelectedRows: Function}
 * onSetSelectedRows is a function to pass selectedFlatRows data to other components
 * @param {toggleSelectionRef: Object (a ref object)}
 * toggleSelectionRef is to mutate toggleRowSelected,toggleAllRowsSelected
 * toggleRowSelected: Function(rowPath: String, ?set: Bool) => void
 * - can toggle a row's selected state.
 * toggleAllRowsSelected: Function(?set: Bool) => void
 * - can toggle all rows as selected or not
 */

const ReactTable = ({
  columns,
  data,
  pagination,
  manualPagination = false,
  basicPagination = false,
  pageNumberForBasicPagination = DEFAULT_PAGE,
  totalPages = 0,
  totalCount = 0,
  pageNumberForTablePagination = DEFAULT_PAGE - 1,
  perPage = DEFAULT_PER_PAGE,
  onPageChange = () => {},
  onPerPageChange = () => {},
  expandable = false,
  autoExpandedItems = {},
  renderRowSubComponent,
  stickyHeader = false,
  tableHeight = 'auto',
  expandCheck = null,
  rowSelect = false,
  onSetSelectedRows,
  toggleSelectionRef = () => {},
  localFilter = false,
  isSingleSelect = false,
  hideHeader = false,
  checkboxIndeterminate = {},
  extraBtn = null,
  bottomExtraBtn = null,
  preSelectedRows = [],
  noContentIcon = null,
  searchPlaceHolder = 'Search',
  rowClassName,
}) => {
  const handleSelectedRows = () => {
    const preIds = preSelectedRows.map((item) => item.id);
    const obj = {};
    for (let index in data) {
      if (preIds.includes(data[index].id)) {
        obj[index] = true;
      }
    }
    return obj;
  };
  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: manualPagination,
      initialState: {
        expanded: autoExpandedItems,
        selectedRowIds: handleSelectedRows(),
      },
      stateReducer: (newState, action) => {
        if (isSingleSelect) {
          if (action.type === 'toggleRowSelected') {
            newState.selectedRowIds = {
              [action.id]: true,
            };
          }
          return newState;
        }
      },
    },
    localFilter ? useGlobalFilter : '',
    expandable ? useExpanded : '',
    pagination ? usePagination : '',
    rowSelect ? useRowSelect : '',
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (rowSelect) {
          return [
            {
              id: `selection ${rowSelect}`,
              Header: () => null,
              width: 'auto',
              Cell: ({ row }) => {
                if (row.original.isChecked) {
                  return <CheckBox {...row.getToggleRowSelectedProps()} checked />;
                }
                return (
                  <CheckBox
                    {...row.getToggleRowSelectedProps()}
                    indeterminate={checkboxIndeterminate[row.id] || false}
                    onClick={() => {
                      if (isSingleSelect) {
                        const check = row.isSelected;

                        toggleAllRowsSelected(false);
                        toggleRowSelected(row.id, !check);
                      }
                    }}
                  />
                );
              },
            },
            ...columns,
          ];
        }
        return [...columns];
      });
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    rows,
    page,
    state: { selectedRowIds },
    visibleColumns,
    selectedFlatRows,
    toggleRowSelected,
    toggleAllRowsSelected,
  } = tableInstance;
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(
    basicPagination ? pageNumberForBasicPagination : pageNumberForTablePagination,
  );
  const [rowsPerPage, setRowsPerPage] = useState(perPage);

  useEffect(() => {
    setRowsPerPage(perPage);
  }, [perPage]);

  const rowsPerPageChangeHandler = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(DEFAULT_PAGE - 1);
  };

  useEffect(() => {
    if (rowSelect) {
      const flatRows = selectedFlatRows.map((d) => d.original);
      onSetSelectedRows(flatRows);
    }
  }, [selectedRowIds, rows]);
  useEffect(() => {
    if (!basicPagination) {
      setCurrentPage(pageNumberForTablePagination);
    }
  }, [pageNumberForTablePagination]);
  useEffect(() => {
    toggleSelectionRef.current = {
      toggleAllRowsSelected,
      toggleRowSelected,
    };
  }, []);

  const expandElement = (row) => {
    if (expandCheck && row.original[expandCheck]?.length === 0) {
      return;
    }

    if (row.isExpanded) {
      return (
        <TableRow className={`${classes.accordionBody} ${row.isExpanded ? classes.accordionExpanded : ''}`}>
          <TableCell colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</TableCell>
        </TableRow>
      );
    }
  };

  const expandablePaginationElement = (row, i, rowClassName) => {
    const rowProps = row.getRowProps();
    // to enable whole line clickable to expand, attach {...rowExpand} in TableCell
    // const rowExpand = row.getToggleRowExpandedProps();

    return (
      <React.Fragment key={rowProps.key}>
        <TableRow
          key={i}
          {...rowProps}
          className={`${rowClassName?.(row) ? classes.error : ''} ${row.isExpanded ? classes.accordionExpanded : ''}`}
        >
          {row.cells.map((cell, i) =>
            cell.column.hideHeaderAndColumn ? null : (
              <TableCell
                key={i}
                {...cell.getCellProps()}
                sx={{
                  padding: cell.column.isPadding ? cell.column.padding : '20px 24px',
                }}
              >
                {cell.render('Cell')}
              </TableCell>
            ),
          )}
        </TableRow>
        {expandElement(row)}
      </React.Fragment>
    );
  };

  const nonExpandElement = (row, i) => (
    <TableRow {...row.getRowProps()} key={i}>
      {row.cells.map((cell, i) =>
        cell.column.hideHeaderAndColumn ? null : (
          <TableCell
            key={i}
            {...cell.getCellProps()}
            sx={{
              padding: cell.column.isPadding ? cell.column.padding : '20px 24px',
              width: cell.column.width === 'auto' ? 'auto' : `${cell.column.width}px`,
            }}
          >
            {cell.render('Cell')}
          </TableCell>
        ),
      )}
    </TableRow>
  );

  useEffect(() => {
    if (manualPagination && basicPagination) {
      onPageChange(currentPage);
    }

    if (manualPagination) {
      onPageChange(currentPage + 1);
      onPerPageChange(rowsPerPage);
    }
  }, [currentPage, manualPagination, rowsPerPage]);

  return (
    <Box>
      {localFilter && (
        <Box className={classes.searchWrap}>
          <SearchBar
            onSearch={(value) => {
              setGlobalFilter(value);
            }}
            resetFilter={() => setGlobalFilter('')}
            placeholder={searchPlaceHolder}
          />
          {extraBtn ?? extraBtn}
        </Box>
      )}
      <TableContainer
        component={Paper}
        sx={
          stickyHeader
            ? {
                maxHeight: tableHeight,
                overflowY: 'auto',
              }
            : {}
        }
      >
        <Table {...getTableProps()}>
          {hideHeader || (rows.length === 0 && localFilter) ? null : (
            <TableHead className={`${classes.tableHeader}`}>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => {
                    if (!column?.hideHeaderAndColumn) {
                      return (
                        <TableCell
                          key={i}
                          {...column.getHeaderProps()}
                          className={stickyHeader ? classes.stickyHeaderCell : ''}
                          sx={{
                            padding: column.isPadding ? column.padding : '12px 24px',
                            width: column.width === 'auto' ? 'auto' : `${column.width}px`,
                          }}
                        >
                          {column.render('Header')}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableHead>
          )}
          {!pagination && (
            <TableBody {...getTableBodyProps()} className={`${classes.tableBody}`}>
              {!expandable &&
                rows.map((row, i) => {
                  prepareRow(row);
                  return nonExpandElement(row, i);
                })}
              {expandable &&
                rows.map((row, i) => {
                  prepareRow(row);
                  return expandablePaginationElement(row, i, rowClassName);
                })}
            </TableBody>
          )}
          {pagination && (
            <TableBody {...getTableBodyProps()} className={`${classes.tableBody}`}>
              {!expandable &&
                page.map((row, i) => {
                  prepareRow(row);
                  return nonExpandElement(row, i);
                })}
              {expandable &&
                page.map((row, i) => {
                  prepareRow(row);
                  return expandablePaginationElement(row, i, rowClassName);
                })}
            </TableBody>
          )}
        </Table>
        {!pagination && rows.length === 0 && localFilter && (
          <NoContent
            icon={noContentIcon}
            title="No Result Found"
            desc={<>Please try again using new search criteria</>}
          />
        )}
        {pagination && manualPagination && !basicPagination && (
          <Box className={bottomExtraBtn ? classes.searchWrap : ''}>
            {bottomExtraBtn ?? bottomExtraBtn}
            <TablePagination
              component={Paper}
              count={totalCount}
              page={currentPage}
              onPageChange={(e, value) => setCurrentPage(value)}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onRowsPerPageChange={rowsPerPageChangeHandler}
            />
          </Box>
        )}
      </TableContainer>
      {pagination && manualPagination && basicPagination && (
        <GridContainer spacing={3} mt={3} className={classes.manualPagination}>
          <GridItem xs={12} display="flex" justifyContent="center">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                variant="outlined"
                classes={{ ul: classes.ul }}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
              />
            </Stack>
          </GridItem>
        </GridContainer>
      )}
    </Box>
  );
};
export default React.memo(ReactTable);
