import { Button, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { checkValueNotNullUndefinedBlank } from '../../../utils/common-methods';

import useStyles from './style';

const NoContent = ({
  icon = null,
  title = '',
  desc = null,
  buttonName = '',
  onBtnClickHandler = () => {},
  isTwoBtn = false,
  secondBtnName = '',
  onSecondBtnClickHandler = () => {},
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.noContentContainer} data-testid="notFound">
      <Grid className={classes.noContentWrap} container direction="column" alignItems="center">
        {checkValueNotNullUndefinedBlank(icon) && <Grid className={classes.iconContainer}>{icon}</Grid>}
        <Grid container direction="column" alignItems="center" className={classes.titleDescContainer}>
          {checkValueNotNullUndefinedBlank(title) && <Grid className={classes.titleContainer}>{title}</Grid>}
          {checkValueNotNullUndefinedBlank(desc) && (
            <Grid className={classes.descContainer}>
              <Typography variant="p1" color="textSecondary">
                {desc}
              </Typography>
            </Grid>
          )}
        </Grid>
        {checkValueNotNullUndefinedBlank(buttonName) && (
          <Grid className={classes.buttonContainer}>
            <Stack direction={'row'} spacing={3}>
              <Button variant="contained" onClick={onBtnClickHandler} data-testid="assignNewBtn" disabled={disabled}>
                {buttonName}
              </Button>
              {isTwoBtn && (
                <Button
                  variant="outlined"
                  onClick={onSecondBtnClickHandler}
                  data-testid="secondBtn"
                  disabled={disabled}
                >
                  {secondBtnName}
                </Button>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default NoContent;
