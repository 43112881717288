import React from 'react';
import { ControlledTooltips } from '../shared';
import { Warning } from '@mui/icons-material';
import { MESSAGE } from '../../constants/message';

const ErrorEndAdornment = (props) => {
  const { message = MESSAGE.CURRENT_METER_ERROR } = props;

  return (
    <ControlledTooltips tooltipValue={message}>
      <Warning fontSize="small" color="error" />
    </ControlledTooltips>
  );
};

export default ErrorEndAdornment;
