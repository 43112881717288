import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: { padding: 0 },
  machineDetailContent: {
    '& .MuiAccordionSummary-root': { minHeight: '72px' },
    '& .MuiAccordionDetails-root': { padding: '8px 0px' },
    '& .MuiGrid2-root MuiGrid2-container': { padding: '8px 24px 0px 24px' },
  },
  modemDetailContent: {
    backgroundColor: theme?.palette?.common?.white,
    boxShadow: `0px 1px 4px ${theme?.palette?.grey[300]}`,
    borderRadius: 8,
    '& .MuiAccordionDetails-root': { padding: '8px 0px' },
    '& .MuiGrid2-root MuiGrid2-container': { padding: '8px 24px 0px 24px' },
    '& .MuiAccordionSummary-root': { minHeight: '72px' },
  },
  machineIcon: {
    width: '2.5em',
    height: '2.5em',
    color: theme?.palette?.primary?.main,
  },
  assignNewMachineTitle: { padding: '31px 0px 0px 24px' },
  machineSearch: {
    padding: '16px 24px 24px 24px',
    '& .MuiSvgIcon-root': { color: theme?.palette?.primary?.main },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.primary?.main,
      borderRadius: 8,
    },
  },
  btnWrap: { padding: '52px 0px 40px 24px' },
  assignMachine: {
    '& .MuiTableContainer-root': { borderRadius: 0 },
    '& .MuiTableCell-head': { padding: 12, lineHeight: 3 },
    '& .MuiTableCell-body': { padding: '16px 12px 16px 12px' },
  },
  machineDetails: {
    padding: '16px 24px 16px 24px',
    minHeight: '72px',
    backgroundColor: theme?.palette?.common?.white,
    '& .MuiGrid2-root': {
      padding: 0,
    },
  },
  machineDetailTitle: { display: 'flex', alignItems: 'center' },
  assignNewBtn: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  assignMachineTableWrapper: {
    '& td[class*=MuiTableCell-body]': {
      '&:nth-child(1)': {
        width: 30,
        paddingLeft: 0,
      },
    },
  },
}));

export default useStyles;
