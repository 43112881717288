import locationSlice from './location-slice';
export const {
  locationDetailsAction,
  ownerDetailsAction,
  subLocationDetailsAction,
  countryAction,
  stateAction,
  cityAction,
  ownerClickAction,
  subLocationClickAction,
  resetState,
} = locationSlice.actions;

export default locationSlice;
