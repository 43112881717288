import { createSlice } from '@reduxjs/toolkit';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { DATE_ARCHIVE_DESC, LOCATIONNAME_ASC } from '../../constants/SortConstants';

const initialState = {
  activeLocation: {
    ...FILTER_CONSTANTS.FILTER_DATA.LOCATION.INITIAL_PARAMS,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LOCATIONNAME_ASC,
  },
  archiveLocation: {
    ...FILTER_CONSTANTS.FILTER_DATA.LOCATION.INITIAL_PARAMS,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: DATE_ARCHIVE_DESC,
  },
};

export const locationFilterSlice = createSlice({
  name: 'locationFilterSlice',
  initialState,
  reducers: {
    setActiveLocationFilters(state, action) {
      state.activeLocation[FILTER_VALUES.LOCATION_TYPE_ID] = action.payload?.locationTypeId || [];
      state.activeLocation[FILTER_VALUES.OWNER] = action.payload?.ownerId || [];
      state.activeLocation[FILTER_VALUES.COUNTRY] = action.payload?.countryId || {};
      state.activeLocation[FILTER_VALUES.STATE] = action.payload?.stateId || {};
      state.activeLocation[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.activeLocation[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.activeLocation.searchText = action.payload?.searchText || '';
      state.activeLocation.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.activeLocation.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.activeLocation.sort = action.payload?.sort || LOCATIONNAME_ASC;
    },
    setArchiveLocationFilters(state, action) {
      state.archiveLocation[FILTER_VALUES.LOCATION_TYPE_ID] = action.payload?.locationTypeId || [];
      state.archiveLocation[FILTER_VALUES.OWNER] = action.payload?.ownerId || [];
      state.archiveLocation[FILTER_VALUES.COUNTRY] = action.payload?.countryId || {};
      state.archiveLocation[FILTER_VALUES.STATE] = action.payload?.stateId || {};
      state.archiveLocation[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.archiveLocation[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.archiveLocation.searchText = action.payload?.searchText || '';
      state.archiveLocation.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.archiveLocation.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.archiveLocation.sort = action.payload?.sort || DATE_ARCHIVE_DESC;
    },
    resetActiveLocationFilters(state) {
      state.activeLocation = {
        ...initialState.activeLocation,
        searchText: state.activeLocation.searchText,
        sort: state.activeLocation.sort,
      };
    },
    resetArchiveLocationFilters(state) {
      state.archiveLocation = {
        ...initialState.archiveLocation,
        searchText: state.archiveLocation.searchText,
        sort: state.archiveLocation.sort,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default locationFilterSlice;
