import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { Link, NoContent, ReactTable } from '../../components/shared';
import { CURRENCY_EXCHANGE_DYNAMIC } from '../../constants/tableColumns';
import { dateFormatForApi, isArrayWithLength } from '../../utils/common-methods';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useGetCurrencyExchangeQuery } from '../../services/ExchangeRate/ExchangeRateService';
import { routeConstants } from '../../constants/routeConstants';

const ExchangeRateTable = () => {
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const columns = useMemo(() => CURRENCY_EXCHANGE_DYNAMIC(isBelowMD), [isBelowMD]);
  const [exchangeRateData, setExchangeRateData] = useState([]);
  const { currentData: latestData, isSuccess, isLoading, isError, error } = useGetCurrencyExchangeQuery();

  const currencyLink = useCallback(
    (id, name) => (
      <Link
        underline="none"
        to={`/${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}/${id}`}
      >
        {name}
      </Link>
    ),
    [],
  );

  useEffect(() => {
    const exchangeData =
      latestData?.data?.exchangeRates?.map((item) => {
        const { id, currency } = item;
        return {
          ...item,
          currencyName: currencyLink(id, currency?.name),
          currencyAbbreviation: currency?.abbreviation,
          updatedAt: (item?.updatedAt && dateFormatForApi(item?.updatedAt || '')) || 'N/A',
        };
      }) || [];
    setExchangeRateData(exchangeData);
  }, [isSuccess, latestData]);

  if (isLoading) return <LoadingSpinner containerHeight={300} />;
  if (isError && isArrayWithLength(exchangeRateData)) {
    return (
      <Container maxWidth="xl">
        <NoContent
          title="No Currency Exchange Rate Data Found"
          desc={isError ? error?.data?.message : <>There is no currency exchange rate data present.</>}
        />
      </Container>
    );
  }
  return (
    <Box>
      <ReactTable data={exchangeRateData} columns={columns} />
    </Box>
  );
};

export default ExchangeRateTable;
