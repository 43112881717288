import leaseSlice from './lease-slice';
export const {
  leaseDetailsFormAction,
  resetLeaseAction,
  rentDetailsFormAction,
  generalDetailsFormAction,
  setSearchApiParamsAction,
  setLeasesAction,
  setPageAction,
  setPerPageAction,
} = leaseSlice.actions;

export default leaseSlice;
