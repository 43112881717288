import { Box, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../../../components/shared';
import { useUpdateSubLocationLeaseByIdMutation } from '../../../services/Sublocation/SublocationService';
import AssignLeaseTable from './AssignLeaseTable';
import useStyles from './style';

const AssignNewLease = (props) => {
  const { onSaveClick, onCancel } = props;
  const [selectedRow, setSelectedRow] = useState(undefined);
  const { id } = useParams();
  const [assignLeaseApi] = useUpdateSubLocationLeaseByIdMutation();
  const classes = useStyles();

  const onSelect = (row) => {
    setSelectedRow(row[0]);
  };
  const onSaveClickHandler = () => {
    const data = {
      id: id,
      contractIds: [selectedRow.id],
    };

    assignLeaseApi(data).then((res) => {
      if (res.data) {
        onSaveClick();
      }
    });
  };
  return (
    <Box component={Paper} className={classes.assignNewMachineBox}>
      <Box className={classes.assignNewMachineTitle}>
        <Typography variant="h2">Assign New Lease</Typography>
      </Box>
      <Box className={classes.assignLeaseTableWrapper}>
        <AssignLeaseTable onSelect={onSelect} />
      </Box>

      <Box className={classes.btnWrap}>
        <Button variant="contained" onClick={onSaveClickHandler} disabled={selectedRow ? false : true}>
          SAVE
        </Button>
        <Button onClick={onCancel}>CANCEL</Button>
      </Box>
    </Box>
  );
};

export default AssignNewLease;
