import { Box, Button, DialogContentText } from '@mui/material';
import React from 'react';
import { Modal } from '../shared';
import { LoadingButton } from '@mui/lab';

const ConfirmationModal = (props) => {
  const { isOpen = false, title = '', msg = '', buttons = [], onClick = () => {}, isLoading = false } = props;
  const firstBtn = buttons[0];
  const secondBtn = buttons[1];
  const thirdBtn = buttons[2];
  return (
    <Modal
      open={isOpen}
      title={title}
      titleVariant="subtitle1"
      customWidth="395px"
      buttonPosition={'left'}
      actionButtons={
        buttons.length > 0 ? (
          <>
            <Box>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  onClick(firstBtn?.value);
                }}
                size="small"
              >
                {firstBtn?.text}
              </LoadingButton>
              {secondBtn && (
                <Button
                  onClick={() => {
                    onClick(secondBtn?.value);
                  }}
                  size="small"
                >
                  {secondBtn?.text}
                </Button>
              )}
            </Box>
            {thirdBtn && (
              <Box>
                <Button
                  onClick={() => {
                    onClick(thirdBtn?.value);
                  }}
                  size="small"
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  {thirdBtn?.text}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <></>
        )
      }
    >
      <DialogContentText variant="body2">{msg}</DialogContentText>
    </Modal>
  );
};

export default ConfirmationModal;
