import React from 'react';
import { Box, DialogContentText, Stack } from '@mui/material';
import { Modal } from '../../components/shared';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useSelector } from 'react-redux';

const displayName = 'SessionTimeout';

const SessionTimeout = () => {
  const msalStatus = useSelector((state) => state.msalStatus);

  const message = {
    title: 'Checking Session',
    description: 'Your session might be expired. The system is working on refreshing your session.',
  };

  return (
    <Modal open={msalStatus.openRefreshTokenModal} customWidth="395px" title={message.title} titleVariant="h2">
      <DialogContentText component={'span'} variant="subtitle1">
        <Stack spacing={2}>
          <Box>{message.description}</Box>
          <Box>
            <LoadingSpinner containerHeight={300} />
          </Box>
        </Stack>
      </DialogContentText>
    </Modal>
  );
};

SessionTimeout.displayName = displayName;
export default SessionTimeout;
