import { GridContainer, GridItem, InformationField, InformationPad } from '../shared';
import { Box, Paper, Typography } from '@mui/material';
import { Link } from '../shared';
import useStyles from './style';
import { routeConstants } from '../../constants/routeConstants';
import { getCurrency } from '../../utils/common-methods';
import dayjs from 'dayjs';

const MachineAccordion = ({ machineInfo }) => {
  const classes = useStyles();
  if (machineInfo) {
    machineInfo = {
      ...machineInfo,
      price: machineInfo?.price || 0,
      installedDate: machineInfo?.installedDate || null,
    };
  }
  const fields = [
    { label: 'Status', value: machineInfo?.statusTitle },
    { label: 'No.of Plays', value: machineInfo?.numberOfPlays || 0 },
    {
      label: 'Installed Date',
      value: machineInfo?.installedDate
        ? dayjs(machineInfo?.installedDate).format('MM/DD/YYYY')
        : machineInfo?.installedDate,
    },
    { label: 'Model Name', value: machineInfo?.modelTitle },
    {
      label: 'Price',
      value: `${getCurrency(machineInfo?.currency?.abbreviation || 'USD')} ${machineInfo?.price}`,
    },
    {
      label: 'Sub Location',
      value: machineInfo?.sublocationName,
    },
  ];
  const id = machineInfo?.id?.props?.children;
  return (
    <Box component={Paper} className={classes.machineAccordion}>
      <GridContainer>
        <GridItem xs={12} md={12}>
          <Typography variant="h3" mb={1}>
            Machine Details
          </Typography>
        </GridItem>
      </GridContainer>
      <InformationPad data={fields} />
      <GridContainer className={classes.machineAccordionBtnWrap}>
        <GridItem xs={12} md={12}>
          <InformationField
            fontSize="14px"
            fontWeight="500"
            lineHeight="16px"
            value={
              <Link
                variant="link"
                underline="none"
                color="primary"
                to={`/${routeConstants.MACHINES_ROUTE}/${id || machineInfo?.id}`}
              >
                VIEW FULL DETAILS
              </Link>
            }
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
};

export default MachineAccordion;
