/* eslint-disable indent */
import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import useStyles from './style';

/**
 * The PositionedMenu is customized from https://mui.com/joy-ui/react-menu/#positioned-menu
 */

const PositionedMenu = ({ menuItemData = [], disabled }) => {
  const classes = useStyles();

  const menuItemStyle = (btnType = '') => {
    switch (btnType) {
      case 'dangerBtn':
        return classes.removeBtn;
      case 'warningBtn':
        return classes.warningBtn;
      case 'successBtn':
        return classes.addBtn;
      default:
        return;
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const menuButtonHandler = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const closeButtonHandler = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box className={classes.positionMenuWrap}>
      <IconButton
        className={classes.moreHoriz}
        aria-expanded={open ? 'true' : false}
        onClick={menuButtonHandler}
        disabled={disabled}
      >
        <MoreVert />
      </IconButton>
      <Menu
        sx={{ minWidth: 180 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeButtonHandler}
      >
        {menuItemData.map(({ btnName = 'button', btnClickFunc = () => {}, btnTypeStyle = '' }, i) => (
          <MenuItem
            key={btnName + i}
            className={menuItemStyle(btnTypeStyle)}
            onClick={(e) => {
              e.stopPropagation();
              btnClickFunc();
              closeButtonHandler(e);
            }}
          >
            {btnName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default PositionedMenu;
