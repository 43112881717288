import React from 'react';
import { FIELD_NAME, METER_TYPE_FILED_MAP } from '../../../constants/MeterTypeFieldMap';
import { GridContainer, GridItem, TextField } from '../../../components/shared';
import ErrorEndAdornment from '../../../components/ErrorEndAdornment';
import useStyles from '../style';
import { updateMeterNameForReconciliation } from '../../../utils/collection-methods';
import { getCurrency } from '../../../utils/common-methods';
import { InputAdornment } from '@mui/material';
import { CURRENCY_CONSTANT, CURRENCY_TYPE, DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import { getApprovedCalculatedFieldValue, getApprovedErrorAndMessage } from '../../../utils/approved-warning-methods';

const ApprovedCollectionChildField = ({
  assetMeter,
  clicksPerPlay,
  costPerPlay,
  locationCurrency,
  averageTokenValue,
}) => {
  const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
  const exchangeRate = locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE;

  const { meter } = assetMeter;
  let meterTypeName = meter?.name;
  const classes = useStyles();
  meterTypeName = updateMeterNameForReconciliation(meter?.name, true);

  return (
    <GridContainer key={meter?.id}>
      {METER_TYPE_FILED_MAP[meterTypeName].map((element, index) => {
        const fieldValue = getApprovedCalculatedFieldValue({
          meterMapElement: element,
          assetMeter,
          clicksPerPlay,
          costPerPlay,
          meterTypeName,
          averageTokenValue,
          exchangeRate,
        });

        const { message, error } = getApprovedErrorAndMessage({
          meterMapElement: element,
          assetMeter,
          clicksPerPlay,
          costPerPlay,
          meterTypeName,
          exchangeRate,
        });

        if (abbreviation === CURRENCY_CONSTANT.USD && element.name === FIELD_NAME.REVENUE_IN_USD) return;
        return (
          <GridItem
            className={classes.collectionFieldItem}
            sx={{ paddingRight: '2px', paddingLeft: '2px' }}
            key={index}
            xs={12}
            sm={3}
            md={5}
            lg={3}
            p={1}
          >
            <TextField
              key={index}
              type="number"
              label={element.label}
              name={element.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {element.priceIcon
                      ? element?.currencyType === CURRENCY_TYPE.USD
                        ? getCurrency(CURRENCY_CONSTANT.USD)
                        : getCurrency(abbreviation)
                      : ''}
                  </InputAdornment>
                ),
                inputProps: { min: 0, step: 'any' },
                endAdornment: error ? <ErrorEndAdornment message={message} /> : '',
              }}
              value={fieldValue}
              error={error}
              disabled
            />
          </GridItem>
        );
      })}
    </GridContainer>
  );
};
export default ApprovedCollectionChildField;
