import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  noContentContainer: {
    padding: '24px 0',
  },
  noContentWrap: {
    padding: '34px 0',
  },
  iconContainer: {
    paddingBottom: 32,
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: '2.5em',
      height: '2.5em',
    },
  },
  titleDescContainer: {
    paddingBottom: 32,
  },
  titleContainer: {
    fontSize: 32,
    lineHeight: '26px',
    letterSpacing: 0.33,
    color: theme.palette.common.darkblue,
    fontWeight: 900,
    marginBottom: 24,
  },
  descContainer: {
    textAlign: 'center',
    '& .MuiTypography-root': {
      lineHeight: '26px',
      letterSpacing: 0.33,
    },
  },
}));

export default useStyles;
