import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    paddingBottom: '40px',
    marginTop: 24,
  },
}));

export default useStyles;
