export const DENOMINATION = [
  { id: 1, text: '0.25', name: '0.25', value: 1 },
  { id: 2, text: '1', name: '1', value: 2 },
  { id: 3, text: '5', name: '5', value: 3 },
  { id: 4, text: '10', name: '10', value: 4 },
  { id: 5, text: '20', name: '20', value: 5 },
  { id: 6, text: '30', name: '30', value: 6 },
  { id: 7, text: '50', name: '50', value: 7 },
  { id: 8, text: '100', name: '100', value: 8 },
];

export const TOKEN_CHANGER_PAYMENT_TYPE = {
  CASH: 'CASH',
  CREDIT: 'CREDIT',
};

export const TYPE = [
  { id: 1, text: TOKEN_CHANGER_PAYMENT_TYPE.CREDIT, name: 'Credit', value: 1 },
  { id: 2, text: TOKEN_CHANGER_PAYMENT_TYPE.CASH, name: 'Cash', value: 2 },
];

export const METER_TYPES = {
  CREDIT_METER: 'Credit Meter',
  TICKET_METER: 'Ticket Meter',
  TOKEN_METER: 'Token Meter',
  CASH_METER: 'Cash Meter',
  COIN_METER: 'Coin Meter',
  BILL_METER: 'Bill Meter',
  PRIZE_METER: 'Prize Meter',
  MEDALLION_METER: 'Medallion Meter',
  TOKEN_CHANGER_METER: 'Token changer Meter',
  CANDY_METER: 'Candy Meter',
};
