import { userSlice } from './user-slice';

export const {
  setAccessToken,
  setUserData,
  logout,
  setExpirationTime,
  setUserDeactivated,
  setUserDetails,
  setStatus,
  setError,
  setManuallyLogoutStatus,
} = userSlice.actions;
export default userSlice;
