import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './style/Drawer.style';

import Header from './Header';
import SideNav from './SideNav';
import { DrawerConstant } from '../../constants/DrawerConstants';

const propTypes = {
  classes: PropTypes.object.isRequired,
};

const ResponsiveDrawer = (props) => {
  const { window, children, classes } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(true);
  const drawerWidth = showDrawer ? DrawerConstant.OPEN_DRAWER_WIDTH : DrawerConstant.COLLAPSE_DRAWER_WIDTH;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className={classes.drawerBoxInner}>
      {/* Header Component */}
      <Header drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />

      {/* Side Drawer Navigation Component */}
      <SideNav
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        drawerWidth={drawerWidth}
        container={container}
        mobileOpen={mobileOpen}
        onScreenSelect={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

ResponsiveDrawer.propTypes = propTypes;
export default withStyles(styles)(ResponsiveDrawer);
