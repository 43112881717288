import { useNavigate } from 'react-router-dom';

const displayName = 'NotFound';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <h2>404 Not Found!</h2>
      <p>Sorry but that page doesn't seem to exist.</p>
      <p>
        Please head off to our{' '}
        <a
          href={'/'}
          onClick={(e) => {
            e.preventDefault();
            navigate('/', { replace: true });
          }}
        >
          Home
        </a>{' '}
        and follow the menu items at the top of the page.
      </p>
      <p>
        If you believe this to be an error on our part, please don't hesitate to
        <br />
        contact your CTM Representative, and they will be happy to assist you!
      </p>
      <p>Thank you</p>
    </>
  );
};

NotFound.displayName = displayName;
export default NotFound;
