import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { InformationField } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import { getReportMachineSortByAssetId } from '../../../utils/collection-methods';

const ReportMachineSummaryField = ({ sublocationId, reportMachines }) => {
  const report = useMemo(
    () => getReportMachineSortByAssetId(sublocationId, reportMachines),
    [sublocationId, reportMachines],
  );

  if (report.length > 0) {
    return (
      <Stack pr={4} py={1} direction={'row'} display="flex" justifyContent="space-between">
        <InformationField value={MESSAGE.REPORTED_MACHINES} variant="subtitle8" />
        <InformationField value={report.length} variant="h3" />
      </Stack>
    );
  }
};

export default ReportMachineSummaryField;
