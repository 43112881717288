import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import {
  useGetCurrencyExchangeByIdQuery,
  useUpdateCurrencyExchangeByIdMutation,
} from '../../../services/ExchangeRate/ExchangeRateService';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Button, GridContainer, GridItem, TextField } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import useStyles from './style';

const UpdateCurrencyRate = () => {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [currenyData, setCurrencyData] = useState(null);
  const [conversionRate, setConversionRate] = useState(0);
  const { currentData: latestData, isSuccess } = useGetCurrencyExchangeByIdQuery(Number(params.id));
  const [updateCurrencyExchange] = useUpdateCurrencyExchangeByIdMutation();
  const {
    SETTINGS: { SETTINGS, CURRENCY_RATES, UPDATE_CURRENCY_RATES },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      {
        text: SETTINGS,
        redirection: `${routeConstants.SETTING_ROUTE}`,
      },
      {
        text: CURRENCY_RATES,
        redirection: `${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}`,
      },
      { text: UPDATE_CURRENCY_RATES },
    ],
    [],
  );

  useEffect(() => {
    if (isSuccess && latestData) {
      setCurrencyData(latestData?.data?.currency || {});
      setConversionRate(latestData?.data?.exchangeRate);
    }
  }, [isSuccess, latestData]);

  const onUpdatePress = () => {
    const updatedCurrencyData = {
      id: Number(params.id),
      exchangeRate: conversionRate,
    };
    updateCurrencyExchange(updatedCurrencyData).then((response) => {
      if (response.data) {
        navigate(`/${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}`);
      }
    });
  };

  const onCancelPress = () => {
    navigate(`/${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}`);
  };

  return (
    <Container maxWidth={'xl'} disableGutters>
      <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      <Typography mt={'34px'} variant="h1">
        {currenyData?.name}
      </Typography>
      <Box className={classes.basicCurrencyDetailsContainer}>
        <Typography variant="h2" ml={'24px'}>
          Currency Details
        </Typography>
        <Box>
          <GridContainer rowSpacing={4} columnSpacing={6}>
            <GridItem item xs={12} md={6}>
              <TextField label="Currency" variant="outlined" value={currenyData?.name || ''} disabled />
            </GridItem>
            <GridItem item xs={12} md={6}>
              <TextField label="Currency Code" variant="outlined" value={currenyData?.abbreviation || ''} disabled />
            </GridItem>
            <GridItem item xs={12} md={6}>
              <TextField
                label="Conversion Rate"
                variant="outlined"
                type="number"
                value={conversionRate}
                onChange={(e) => setConversionRate(e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </Box>
        <Stack
          direction={'row'}
          margin={'52px 24px 0px 24px'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          alignItems={'center'}
        >
          <Stack direction={'row'} spacing={2}>
            <Button disabled={!conversionRate} onClick={onUpdatePress} variant="contained">
              {'UPDATE'}
            </Button>
            <Button onClick={onCancelPress}>{'CANCEL'}</Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default UpdateCurrencyRate;
