import { Box } from '@mui/material';
import useStyles from './style/SortContent.style';
import { Check } from '@mui/icons-material/';
import RadioGroup from '../RadioGroup';

const SortContent = ({ data = [], selectedSort, handleSort }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <RadioGroup
        id="default-radio"
        formlabel="Sort"
        defaultValue="latest"
        radioGrpClassName={classes.radioGroup}
        value={selectedSort}
        data={data}
        icon={<></>}
        checkedIcon={<Check />}
        onChange={handleSort}
        fullWidth
      />
    </Box>
  );
};

export default SortContent;
