import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { usePropagateRef } from '../../../hooks/usePropagateRef';

const displayName = 'PerformantTextField';
/**
 * Refactored from https://hackernoon.com/improving-formik-performance-when-its-slow-material-ui
 * https://github.com/superjose/increase-formik-performance-react/blob/main/src/components/Fields/Form/PerformantTextField/index.tsx
 * @param {Object} props { name, disablePerformance, loading, min, max, others}
 */
export const PerformantTextField = React.memo((props) => {
  const { disablePerformance, label, loading, type, name, ...otherProps } = props; //customizedError

  const [field, meta] = useField(name);
  const { values } = useFormikContext();

  /**
   * For performance reasons (possible due to CSS in JS issues), heavy views
   * affect re-renders (Formik changes state in every re-render), bringing keyboard
   * input to its knees. To control this, we create a setState that handles the field's inner
   * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
   * onFocus.
   */
  const [currentFieldValue, setCurrentFieldValue] = useState(field.value ?? 0);

  // const error = !isValueValid(customizedError) ? !!meta.error && meta.touched : customizedError;

  usePropagateRef({
    setCurrentFieldValue,
    name: name,
    value: field.value ?? 0,
  });

  /**
   * Using this useEffect guarantees us that pre-filled forms
   */
  useEffect(() => {
    if (meta.touched) {
      return;
    }

    if (field.value !== currentFieldValue) {
      setCurrentFieldValue(field.value ?? 0);
    }
    // eslint-disable-next-line
  }, [field.value]);

  useEffect(() => {
    if (field.value !== currentFieldValue) {
      setCurrentFieldValue(field.value ?? '');
    }
    // eslint-disable-next-line
  }, [values]);

  const onChange = (evt) => {
    setCurrentFieldValue(evt.target.value);
  };

  /**
   *
   * Propagate the change to Formik onBlur
   */
  const onBlur = (evt) => {
    const val = evt.target.value || '';
    window.setTimeout(() => {
      if (String(values[name]) !== String(val)) {
        field.onChange({
          target: {
            name: name,
            value: val,
          },
        });
      }
    }, 0);
  };

  // Will set depending on the performance props
  const performanceProps = disablePerformance
    ? {
        ...field,
        value: loading ? 'Loading...' : currentFieldValue,
      }
    : {
        ...field,
        value: loading ? 'Loading...' : currentFieldValue,
        onChange,
        onBlur,
        onFocus: onBlur,
      };

  return (
    <>
      <TextField
        {...otherProps}
        type={type}
        label={label}
        name={name}
        InputProps={{
          ...((props.type === 'number' && {
            inputProps: { min: props?.min ?? 0, max: props?.max, step: 'any' },
          }) ||
            undefined),
        }}
        error={meta.error}
        helperText={meta.error}
        {...performanceProps}
      />
    </>
  );
});

PerformantTextField.displayName = displayName;
