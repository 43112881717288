import React, { useState } from 'react';
import { Box } from '@mui/material';
import { GridContainer, Button, GridItem, Autocomplete } from '../../../components/shared';
import {
  useGetAllLocationOwnersQuery,
  useUpdateLocationByIdMutation,
} from '../../../services/Location/LocationService';
import { useFormik } from 'formik';
import useStyles from './style';
import { useDebounce } from '../../../hooks';

const OwnerUpdateDetailsView = (props) => {
  const { locationId, ownerInfo = {}, onSave, onCancel } = props;
  const classes = useStyles();
  const [ownerInputValue, setOwnerInputValue] = useState('');
  const { currentData: ownersResponse } = useGetAllLocationOwnersQuery({
    searchParams: `q=${useDebounce(ownerInputValue, 600)}`,
    perPage: 10,
    page: 1,
  });
  const [updateLocationApi] = useUpdateLocationByIdMutation();
  const owners = ownersResponse?.owners?.map((value) => ({
    text: value?.name,
    value: value?.id,
  }));

  const formik = useFormik({
    initialValues: {
      ownerId: { text: ownerInfo?.name || '', value: ownerInfo?.id || '' },
    },
    onSubmit: (data) => {
      const updateData = {
        id: locationId,
        ownerId: data?.ownerId?.value || null,
      };
      updateLocationApi(updateData).then((response) => {
        if (response.data) {
          onSave();
        }
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <GridContainer spacing={2} alignItems="center" className={classes.ownerUpdateDetailsView}>
          <GridItem xs={12}>
            <Autocomplete
              label="Company Name"
              name="ownerId"
              data-testid="autocomplete"
              value={formik.values.ownerId}
              options={owners || []}
              getOptionLabel={(option) => option.text || ''}
              onChange={(e, value) => {
                formik.setFieldValue('ownerId', value);
              }}
              onInputChange={(event, newInputValue) => {
                setOwnerInputValue(newInputValue);
              }}
              onBlur={() => formik.setFieldTouched('ownerId')}
            />
          </GridItem>
        </GridContainer>
        <Box className={classes.ownerUpdateBtnWrap}>
          <Button type={'submit'} variant="contained">
            SAVE
          </Button>
          <Button onClick={onCancel}>CANCEL</Button>
        </Box>
      </form>
    </>
  );
};

export default OwnerUpdateDetailsView;
