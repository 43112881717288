import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentRoutePath: undefined,
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setCurrentRoutePath(state, action) {
      state.currentRoutePath = action.payload;
    },
  },
});

export default routeSlice;
