import React, { useMemo, useState } from 'react';
import { Divider, IconButton, Typography, useMediaQuery, useTheme, Box } from '@mui/material';

import { InformationField, GridContainer, GridItem, ControlledTooltips } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import { getSummaryArray, sortAssetMeters } from '../../../utils/collection-methods';
import ApprovedCollectionChildField from './ApprovedCollectionChildField';
import { getMeterLabel } from '../../../utils/collection-methods';
import useStyles from '../style';
import { Comment, Info } from '@mui/icons-material';
import { CommentBox } from '../../../components/CommentBox';
import { getESTDateFormat } from '../../../utils/common-methods';
import dayjs from 'dayjs';
import SublocationSummaryField from '../ReconciliationDetail/SublocationSummaryField';
import ApprovedIntercardFields from './ApprovedIntercardFields';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';
import Modal from '../../../components/shared/Modal/Modal';
import ReportMachineModelBody from '../AddCollection/ReportMachineModelBody';

const ApprovedMachineFormAccordionContent = ({ asset, assetSubtotalMap, locationCurrency, averageTokenValue }) => {
  const { assetMeters, intercardTransactions } = asset;
  const classes = useStyles();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const sortedAssetMeters = useMemo(() => {
    const rawData = [...assetMeters];
    return sortAssetMeters(rawData);
  }, [asset]);

  const openCommentHandler = () => {
    setShowCommentModal(true);
  };

  const content = useMemo(() => {
    const subtotals = assetSubtotalMap[asset?.id];
    const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
    return getSummaryArray(subtotals, abbreviation);
  }, [asset, locationCurrency]);

  const commentData = useMemo(() => {
    const comments = asset?.comments?.map((comment) => ({
      id: comment?.id,
      createdAt: getESTDateFormat(dayjs(comment?.createdAt)),
      comment: comment?.comment,
      name: `${comment?.createdByUser?.firstName} ${comment?.createdByUser?.lastName}`,
    }));
    return comments;
  }, [asset]);
  const isIssueReported = Boolean(
    asset?.noCollectionReason?.status?.title &&
      String(asset?.noCollectionReason?.status?.title) === MESSAGE.REPORT_STATUS_PENDING,
  );

  return (
    <React.Fragment>
      {assetMeters && assetMeters.length == 0 && (
        <InformationField my={1} pl={2} variant="subtitle1" value={MESSAGE.COLLECTION_NO_METERS} />
      )}
      <ApprovedIntercardFields intercardTransactions={intercardTransactions} />
      {assetMeters && assetMeters.length > 0 && (
        <React.Fragment>
          <GridContainer pb={1}>
            {sortedAssetMeters?.map((assetMeter, index) => {
              const meterLabel = getMeterLabel(assetMeter, locationCurrency);
              return (
                <GridItem
                  sm={12}
                  md={6}
                  px={isBelowMD ? 0 : 1}
                  pt={1}
                  display="block"
                  justifyContent={isBelowMD ? 'flex-start' : 'flex-end'}
                  alignItems="center"
                  key={index}
                >
                  <GridItem px={1}>
                    <Typography variant="subtitle4"> {meterLabel}</Typography>
                  </GridItem>
                  <ApprovedCollectionChildField
                    assetMeter={assetMeter}
                    clicksPerPlay={asset?.clicksPerPlay}
                    costPerPlay={asset?.costPerPlay}
                    locationCurrency={locationCurrency}
                    averageTokenValue={averageTokenValue}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
          <Divider />
          <GridContainer pl={2} my={1.5}>
            {isIssueReported && (
              <GridItem xs={12} sm={2} lg={1.3}>
                <ControlledTooltips tooltipValue={MESSAGE.REPORT_MACHINE_INFO_RECONCILIATION}>
                  <Box display="flex" alignItems="center" onClick={() => setShowReportModal(true)}>
                    <Info fontSize="small" color="warning" />
                    <Typography variant="p4" pl={1}>
                      {MESSAGE.RECONCILIATION_TOOLTIP_NAME}
                    </Typography>
                  </Box>
                </ControlledTooltips>
              </GridItem>
            )}
            <GridItem xs={12} sm={1} md={0.5} lg={0.5} px={1}>
              <IconButton
                px={1}
                className={classes.comment}
                onClick={openCommentHandler}
                sx={{
                  color: showCommentModal ? theme.palette.primary.main : theme.palette.secondary.main,
                }}
              >
                <Comment />
              </IconButton>
            </GridItem>
            <GridItem xs={12} sm={isIssueReported ? 3.5 : 5} lg={isIssueReported ? 3.3 : 5.5}>
              {showCommentModal && (
                <CommentBox
                  handleClose={() => setShowCommentModal(false)}
                  isApproved={true}
                  initialCommentData={commentData}
                  data={{
                    titleVariant: 'h3',
                    title: MESSAGE.RECONCILIATION_COMMENT_TITLE,
                    fieldName: `comment_${asset.subLocationId}_${asset.id}`,
                  }}
                />
              )}
            </GridItem>
            <GridItem mt={isBelowSm ? 1 : 0} xs={12} sm={6.5} md={6} lg={6}>
              {content.length > 0 && <SublocationSummaryField content={content} />}
            </GridItem>
          </GridContainer>
          <Modal
            title={MESSAGE.REPORT_MACHINE_BTN_NAME}
            open={showReportModal}
            onClose={() => setShowReportModal(false)}
            closeIcon={true}
          >
            <ReportMachineModelBody
              assetId={asset?.id}
              assetName={asset?.title}
              asset={asset}
              isApproved={true}
              isIssueReported={isIssueReported}
            />
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ApprovedMachineFormAccordionContent;
