import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { sortIcon } from '../../../assets/images/icons';

import SearchBar from '../../../components/SearchBar';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import SORT_CONSTANT, { LEASE_LATEST_UPDATED } from '../../../constants/SortConstants';
import useStyles from './style';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import { useSearchFilterParam } from '../../../hooks';
import LeaseTable from './LeaseTable';
import { setSearchApiParamsAction } from '../../../redux-slice/lease';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import { useLazyGetLocationsQuery } from '../../../services/Location/LocationService';
import { getOptions } from '../../../utils/common-methods';
import { ARCHIVE_LEASE_FILTER_STATUS_DATA } from './data';
import {
  useLazyGetAllSublocationsQuery,
  useLazyGetProductCategoriesQuery,
} from '../../../services/Sublocation/SublocationService';
import { resetArchiveLeaseFilters, setArchiveLeaseFilters } from '../../../redux-slice/leaseFilters';

const ArchiveLease = () => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.LEASE.ARCHIVE_LEASE.INITIAL_PARAMS);
  const [sort, setSort] = useState(LEASE_LATEST_UPDATED);
  const dispatch = useDispatch();
  const { archiveLease } = useSelector((state) => state.leaseFilter);

  const [getLocations] = useLazyGetLocationsQuery();
  const [getAllSublocations] = useLazyGetAllSublocationsQuery();
  const [getAllProductTypes] = useLazyGetProductCategoriesQuery();

  useEffect(() => {
    const storedFilterParams = { ...FILTER_DATA.LEASE.ARCHIVE_LEASE.INITIAL_PARAMS };
    storedFilterParams.processStatus = archiveLease[FILTER_VALUES.LEASE_STATUS] ?? [];
    storedFilterParams.locationId = archiveLease[FILTER_VALUES.LOCATION] ?? [];
    storedFilterParams.sublocation = archiveLease[FILTER_VALUES.COLLECTION_SUBLOCATION] ?? [];
    storedFilterParams.productType = archiveLease[FILTER_VALUES.PRODUCT_TYPE] ?? [];
    setSearchText(archiveLease.searchText);
    setSort(archiveLease?.sort);
    setFilterParams(storedFilterParams);
  }, [archiveLease]);

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.LOCATION:
        return getLocations;
      case FILTER_VALUES.COLLECTION_SUBLOCATION:
        return getAllSublocations;
      case FILTER_VALUES.PRODUCT_TYPE:
        return getAllProductTypes;
      default:
        return [];
    }
  };

  const leaseProcessStatusOptions = (value) => {
    switch (value) {
      case FILTER_VALUES.LEASE_STATUS:
        return getOptions(ARCHIVE_LEASE_FILTER_STATUS_DATA);

      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.LEASE.ARCHIVE_LEASE.FILTER.map((item) => {
        const api = getAPI(item.value);
        return {
          ...item,
          api,
          options: leaseProcessStatusOptions(item.value),
        };
      }),
    [],
  );

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.LEASE.ARCHIVE_LEASE,
    filterParams,
    sort,
    searchText,
    extraParam: 'leaseStatus=ARCHIVE',
  });

  useEffect(() => {
    dispatch(setSearchApiParamsAction(searchParams));
  }, [searchText, sort, filterParams]);

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setArchiveLeaseFilters({
        ...archiveLease,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetSearchBarHandler = () => {
    setSearchText('');
    dispatch(
      setArchiveLeaseFilters({
        ...archiveLease,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const applyFilterHandler = (filterValues) => {
    setFilterParams(filterValues);
    dispatch(
      setArchiveLeaseFilters({
        ...archiveLease,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const handleSort = (e) => {
    dispatch(
      setArchiveLeaseFilters({
        ...archiveLease,
        sort: e.target.value,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setSort(e.target.value);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.LEASE.ARCHIVE_LEASE.INITIAL_PARAMS);
    dispatch(resetArchiveLeaseFilters());
  };

  return (
    <>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            placeholder="Search by lease name"
            onSearch={searchHandler}
            onReset={resetSearchBarHandler}
            searchText={searchText}
          />
        </Box>
        <Box px={3}>
          <NewFilterDrawer
            name={TITLE.SORT_BY.toUpperCase()}
            title={TITLE.SORT_BY}
            drawerType={DRAWER_TYPES.SORT_DRAWER}
            startIcon={<>{sortIcon}</>}
            onSortSelect={handleSort}
            selectedSort={sort}
            sortData={SORT_CONSTANT.LEASE_SORT}
          />
        </Box>
        <Box>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={(v) => {
              applyFilterHandler(v);
            }}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.LEASE.ARCHIVE_LEASE.INITIAL_PARAMS).length}
          />
        </Box>
      </Box>
      <LeaseTable isArchiveTab={true} tab="archive" archiveLease={archiveLease} />
    </>
  );
};

export default ArchiveLease;
