import { setMainMenuItemsAndRoles } from '../../redux-slice/UI';
import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getUserUiInfo: build.query({
      query: () => ({
        url: apiUrl.uiInfo,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { mainMenuItems, roles } = data.data;
          dispatch(setMainMenuItemsAndRoles({ mainMenuItems, roles }));
        } catch (error) {
          console.error('error', error);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserUiInfoQuery, useLazyGetUserUiInfoQuery } = extendedApi;
