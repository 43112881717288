import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material';
import { Link } from '..';

const Breadcrumbs = (props) => {
  const { variant = 'body2', underline = 'hover', data = [] } = props;
  if (data.length > 0) {
    return (
      <MUIBreadcrumbs>
        {data.map((value, index) => {
          if (index === data.length - 1) {
            return (
              <Typography data-testid="breadcrumb" key={(index + 1).toString()} variant={variant}>
                {value.text}
              </Typography>
            );
          } else {
            return (
              <Link
                data-testid="breadcrumb"
                variant={variant}
                underline={underline}
                key={(index + 1).toString()}
                to={`/${value.redirection}`}
              >
                {value.text}
              </Link>
            );
          }
        })}
      </MUIBreadcrumbs>
    );
  }
  return null;
};

export default Breadcrumbs;
