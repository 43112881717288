import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  TabHeadingsContainer: {
    padding: '0 12px',
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
  },
  TabHeading: {
    padding: '12px 0',
    position: 'relative',
    minWidth: 'auto',
    letterSpacing: 'normal',
    margin: '0 12px',
    '&.Mui-selected::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'unset',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      width: '100%',
    },
  },
  TabBody: {
    padding: '0px',
  },
}));

export default useStyles;
