import { makeStyles } from '@mui/styles';
import downloadImage from '../../assets/images/icons/download.svg';

const useStyles = makeStyles((theme) => ({
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
    margin: '16px 0px 24px',
    height: '100%',
  },
  searchWrap: {
    width: '100%',
  },
  previewChip: {
    minWidth: 130,
    maxWidth: 250,
    width: 200,
    '& .MuiChip-avatar': {
      color: theme.palette.primary.main,
    },
  },
  imageWrapper: {
    position: 'relative',
    display: 'inline-block',
    '&:hover:after': {
      content: '""',
      position: 'absolute',
      left: '0px',
      top: '0px',
      bottom: '0px',
      width: '100%',
      background: `url(${downloadImage}) center no-repeat`,
      backgroundSize: '50px',
    },
    '&:hover img': {
      opacity: 0.4,
    },
  },
}));

export default useStyles;
