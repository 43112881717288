import React from 'react';
import { GridContainer, GridItem, TextField } from '../../../components/shared';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { FIELD_NAME } from '../../../constants/MeterTypeFieldMap';
import { isArrayWithLength, roundOffValue } from '../../../utils/common-methods';
import { MESSAGE } from '../../../constants/message';

/**
 * Directly read values from intercardTransactions, no need to use formik
 *
 * @param {Array} intercardTransactions
 * @returns
 */
const ApprovedIntercardFields = ({ intercardTransactions }) => {
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  if (intercardTransactions && isArrayWithLength(intercardTransactions)) {
    return (
      <React.Fragment>
        <Typography variant="subtitle4">{`${MESSAGE.INTERCARD}:  ${
          intercardTransactions?.description ?? ''
        }`}</Typography>
        {isArrayWithLength(intercardTransactions) &&
          intercardTransactions.map((transaction, i) => (
            <GridContainer my={1} key={i}>
              {transaction?.assetSubTag && (
                <GridItem p={1} spacing={2} pt={1} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="subtitle4">{transaction?.assetSubTag}</Typography>
                </GridItem>
              )}
              {[
                {
                  fieldName: FIELD_NAME.STANDARD_PLAY,
                  label: 'Standard Play',
                  value: transaction[FIELD_NAME.STANDARD_PLAY],
                  disabled: true,
                },
                {
                  fieldName: FIELD_NAME.CASH_DEBITS,
                  label: 'Cash Debits',
                  value: transaction[FIELD_NAME.CASH_DEBITS],
                  disabled: true,
                },
                {
                  fieldName: FIELD_NAME.POINTS,
                  label: 'Points',
                  value: transaction[FIELD_NAME.POINTS],
                  disabled: true,
                },
              ].map((field, index) => (
                <GridItem
                  xs={12}
                  sm={3}
                  md={5}
                  lg={3}
                  p={1}
                  spacing={2}
                  px={isBelowMD ? 0 : 1}
                  pt={1}
                  display="block"
                  justifyContent={isBelowMD ? 'flex-start' : 'flex-end'}
                  alignItems="center"
                  key={index}
                >
                  <TextField
                    type="number"
                    label={
                      field?.fieldName === FIELD_NAME.POINTS && transaction[FIELD_NAME.GROUP]
                        ? `${field.label}(${transaction[FIELD_NAME.GROUP]})`
                        : field.label
                    }
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={roundOffValue(field.value)}
                    disabled={field.disabled}
                  />
                </GridItem>
              ))}
            </GridContainer>
          ))}
      </React.Fragment>
    );
  }
  return;
};

export default ApprovedIntercardFields;
