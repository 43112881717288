import { useEffect, useMemo, useState } from 'react';
import ReactTable from '../../../components/shared/ReactTable';
import { COLUMNS } from '../../../constants/tableColumns';
import { Link, NoContent } from '../../../components/shared';
import { useGetAllLocationOwnersQuery } from '../../../services/Location/LocationService';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { routeConstants } from '../../../constants/routeConstants';
import { useDispatch } from 'react-redux';
import { setActiveLocationOwnerFilters } from '../../../redux-slice/locationOwnerFilters';

const locationOwnersColumns = [
  ...COLUMNS.LOCATION_OWNERS_TABLE,
  {
    Header: () => 'Action',
    id: 'view',
    Cell: ({ row }) => <Link to={`/${routeConstants.LOCATION_OWNERS_ROUTE}/${row.original.id}`}>View</Link>,
  },
];

const LocationOwnersTable = (props) => {
  const { onTotalLocationOwner, searchParams, tab, activeLocationOwner } = props;
  const [currentPage, setCurrentPage] = useState(tab === 'active' && activeLocationOwner?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(tab === 'active' && activeLocationOwner?.rowsPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);
  const dispatch = useDispatch();
  const {
    currentData: ownersResponse,
    isSuccess: ownersSuccess,
    isError,
    isLoading,
    isFetching,
    error,
  } = useGetAllLocationOwnersQuery(
    {
      searchParams: searchFilterData,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );

  useEffect(() => {
    if (ownersResponse && ownersSuccess) {
      let total = ownersResponse?.pagination?.total ?? 0;
      const owners =
        ownersResponse?.owners?.map((item) => ({
          id: item.id,
          name: item.name,
          city: item.city?.name,
          state: item.state?.name,
          postal_code: item.postcode,
        })) || [];
      setTableData(owners);
      setTotalCount(total);
      onTotalLocationOwner(total ?? totalCount);
    }
  }, [ownersResponse, ownersSuccess, isError]);

  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(tab === 'active' && activeLocationOwner?.pageNumber);
    setPerPageNumber(tab === 'active' && activeLocationOwner?.rowsPerPage);
    setSkipCall(false);
  }, [searchParams]);

  const pageChangeHandler = (currentPage) => {
    dispatch(
      setActiveLocationOwnerFilters({ ...activeLocationOwner, pageNumber: currentPage, rowsPerPage: perPageNumber }),
    );
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(
      setActiveLocationOwnerFilters({ ...activeLocationOwner, rowsPerPage: newPerPage, pageNumber: currentPage }),
    );
    setPerPageNumber(newPerPage);
  };

  const LOCATION_OWNERS_COLUMNS = useMemo(() => locationOwnersColumns, []);
  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Location Owner Found"
          desc={(isError && error?.data?.message) || <>There are no location owner present with this filter.</>}
        />
      )}
      {!isLoading && !isFetching && ownersSuccess && (
        <ReactTable
          data={tableData}
          columns={LOCATION_OWNERS_COLUMNS}
          pagination
          manualPagination
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          perPage={perPageNumber}
        />
      )}
    </>
  );
};

export default LocationOwnersTable;
