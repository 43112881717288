import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Datepicker,
  GridContainer,
  GridItem,
  Select,
  TextField,
} from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { AddNewModelSchema } from '../../../schema/validationSchemas';
import {
  useAddModelMutation,
  useGetModelCategoryQuery,
  useGetModelManufacturerQuery,
} from '../../../services/Models/ModelsService';
import { LoadingButton } from '@mui/lab';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { useMemo } from 'react';

const AddModel = () => {
  const {
    MODEL: { MODEL, ADD_MODEL },
  } = BREADCRUMB_NAMES;
  const { currentData: allCategoryData } = useGetModelCategoryQuery();
  const { currentData: manufacturerData } = useGetModelManufacturerQuery();
  const [addModelApi, { isSuccess: addModelSuccess, isLoading: addModelLoading }] = useAddModelMutation();
  const navigate = useNavigate();

  const breadcrumbData = useMemo(
    () => [{ text: MODEL, redirection: `${routeConstants.MODELS_ROUTE}` }, { text: ADD_MODEL }],
    [],
  );

  const categoryOptions = allCategoryData?.data?.modelCategories?.map((value) => ({
    value: value?.id,
    text: value?.name,
  }));
  const manufacturerOptions = manufacturerData?.data?.modelManufacturers?.map((value) => ({
    value: value?.id,
    text: value?.name,
  }));
  if (addModelSuccess) {
    navigate(`/${routeConstants.MODELS_ROUTE}`);
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      categoryId: '',
      manufacturer: '',
      theme: '',
      subTheme: '',
      yearMade: null,
      height: '',
      width: '',
      weight: '',
    },
    validationSchema: AddNewModelSchema,
    onSubmit: (values) => {
      /* meterId and p9ModelLegacyNumber:// these are mandatory fields which is set by backend team from the data sheet dictionary.
      But these fields Not available in UI that's why passes hardcoded value. */
      const data = {
        title: values?.title?.trim(),
        yearMade: dayjs(values.yearMade).year().toString(),
        height: values?.height,
        weight: values?.weight,
        width: values?.width,
        categoryId: values?.categoryId,
        manufactureId: values?.manufacturer,
        meterId: 1,
      };
      addModelApi(data);
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const yearMadeChangeHandler = (value) => {
    setFieldValue('yearMade', value);
  };

  const onCancelClickHandler = () => {
    navigate(`/${routeConstants.MODELS_ROUTE}`);
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>
      <Box mb={{ xs: 1, sm: 2, md: 3 }}>
        <Typography variant="h1"> Add New Model</Typography>
      </Box>
      <Box component={Paper} p={{ xs: 1, sm: 2, md: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }}>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Title"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Category"
                  options={categoryOptions}
                  {...getFieldProps('categoryId')}
                  error={Boolean(touched.categoryId && errors.categoryId)}
                  helperText={touched.categoryId && errors.categoryId}
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Manufacturer"
                  options={manufacturerOptions}
                  {...getFieldProps('manufacturer')}
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Theme"
                  options={categoryOptions}
                  {...getFieldProps('theme')}
                  disabled
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Sub theme"
                  options={categoryOptions}
                  {...getFieldProps('subTheme')}
                  disabled
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Datepicker
                  label="Year Made"
                  name="yearMade"
                  value={values.yearMade || null}
                  onChange={yearMadeChangeHandler}
                  inputFormat="YYYY"
                  views={['year']}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  error={Boolean(touched.yearMade && errors.yearMade)}
                  helperText={touched.yearMade && errors.yearMade}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Height"
                  error={touched.height && errors.height}
                  helperText={touched.height && errors.height}
                  {...getFieldProps('height')}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </GridItem>

              <GridItem xs={12} md={6}>
                <TextField
                  label="Width"
                  error={touched.width && errors.width}
                  helperText={touched.width && errors.width}
                  {...getFieldProps('width')}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Weight"
                  error={touched.weight && errors.weight}
                  helperText={touched.weight && errors.weight}
                  {...getFieldProps('weight')}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <Stack direction="row" justifyContent="center" spacing={2} mt={3}>
                  <Box>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={addModelLoading}
                      data-testid="addModelBtn"
                    >
                      Add
                    </LoadingButton>
                  </Box>
                  <Box>
                    <Button onClick={onCancelClickHandler}>Cancel</Button>
                  </Box>
                </Stack>
              </GridItem>
            </GridContainer>
          </Form>
        </FormikProvider>
      </Box>
    </Container>
  );
};

export default AddModel;
