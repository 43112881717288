import collectionFilterSlice from './collectionFilter-slice';
export const {
  setOpenCollectionFilters,
  setReconciliationFilters,
  setApprovedCollectionFilters,
  resetState,
  resetOpenCollectionFiltersState,
  resetReconciliationFilterState,
  resetApprovedCollectionFiltersState,
} = collectionFilterSlice.actions;

export default collectionFilterSlice;
