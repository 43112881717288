import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getAllCountries: build.query({
      query: () => apiUrl.countries,
      transformResponse: (response) => response.data.countries,
    }),
    getCountryById: build.query({
      query: (id) => `${apiUrl.countries}/${id}`,
    }),
    getCountryStatesByCountryId: build.query({
      query: (id) => `${apiUrl.countries}/${id}/${apiUrl.states}`,
    }),
    getAllStates: build.query({
      query: () => apiUrl.states,
      transformResponse: (response) => response.data.states,
    }),
    getStateById: build.query({
      query: (id) => `${apiUrl.states}/${id}`,
      transformResponse: (response) => response.data,
    }),
    getStateCitiesByStateId: build.query({
      query: (id) => `${apiUrl.states}/${id}/${apiUrl.cities}`,
    }),
    getAllCities: build.query({
      query: () => apiUrl.cities,
      transformResponse: (response) => response.data?.cities,
    }),
    getCityById: build.query({
      query: (id) => `${apiUrl.cities}/${id}`,
      transformResponse: (response) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCountriesQuery,
  useLazyGetAllCountriesQuery,
  useGetCountryByIdQuery,
  useGetCountryStatesByCountryIdQuery,
  useLazyGetCountryStatesByCountryIdQuery,
  useGetAllStatesQuery,
  useGetStateByIdQuery,
  useGetStateCitiesByStateIdQuery,
  useLazyGetStateCitiesByStateIdQuery,
  useGetAllCitiesQuery,
  useGetCityByIdQuery,
} = extendedApi;
