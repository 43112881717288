import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getX3Departments: build.query({
      query: () => apiUrl.x3Departments,
      transformResponse: (response) => response.data.x3_departments,
    }),
    getX3Industries: build.query({
      query: () => apiUrl.x3Industries,
      transformResponse: (response) => response.data.x3_industries,
    }),
    getX3Owners: build.query({
      query: () => apiUrl.x3Owners,
      transformResponse: (response) => response.data.x3_owners,
    }),
  }),
  overrideExisting: false,
});

export const { useGetX3DepartmentsQuery, useGetX3IndustriesQuery, useGetX3OwnersQuery } = extendedApi;
