import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { LATEST_UPDATED } from '../../constants/SortConstants';

const initialState = {
  activeMachines: {
    [FILTER_VALUES.STATUS]: [],
    [FILTER_VALUES.SUBLOCATION]: [],
    [FILTER_VALUES.MODEL]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LATEST_UPDATED,
  },
  archiveMachines: {
    [FILTER_VALUES.STATUS]: [],
    [FILTER_VALUES.SUBLOCATION]: [],
    [FILTER_VALUES.MODEL]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LATEST_UPDATED,
  },
};

export const machinesFilterSlice = createSlice({
  name: 'machinesFilter',
  initialState,
  reducers: {
    setActiveMachinesFilters(state, action) {
      state.activeMachines[FILTER_VALUES.STATUS] = action.payload?.statusId || [];
      state.activeMachines[FILTER_VALUES.SUBLOCATION] = action.payload?.subLocationId || [];
      state.activeMachines[FILTER_VALUES.MODEL] = action.payload?.modelId || [];
      state.activeMachines.searchText = action.payload?.searchText || '';
      state.activeMachines.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.activeMachines.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.activeMachines.sort = action.payload?.sort || LATEST_UPDATED;
    },
    setArchiveMachinesFilters(state, action) {
      state.archiveMachines[FILTER_VALUES.STATUS] = action.payload?.statusId || [];
      state.archiveMachines[FILTER_VALUES.SUBLOCATION] = action.payload?.subLocationId || [];
      state.archiveMachines[FILTER_VALUES.MODEL] = action.payload?.modelId || [];
      state.archiveMachines.searchText = action.payload?.searchText || '';
      state.archiveMachines.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.archiveMachines.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.archiveMachines.sort = action.payload?.sort || LATEST_UPDATED;
    },
    resetActiveMachinesFiltersState(state) {
      state.activeMachines = {
        ...initialState.activeMachines,
        searchText: state.activeMachines.searchText,
        sort: state.activeMachines.sort,
      };
    },
    resetArchiveMachinesFiltersState(state) {
      state.archiveMachines = {
        ...initialState.archiveMachines,
        searchText: state.archiveMachines.searchText,
        sort: state.archiveMachines.sort,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default machinesFilterSlice;
