import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { PinDropOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { Breadcrumbs, NoContent } from '../../../components/shared';
import { UserInfoUpdateForm } from './UserInfoUpdateForm';
import UserAddNewLocation from './UserAddNewLocation';
import { UserAssignedLocation } from './UserAssignedLocation';
import useStyles from './style';
import {
  useAddSubLocationToUserMutation,
  useGetUserByIdQuery,
  useLazyLoginUserQuery,
} from '../../../services/Users/UsersService';
import { isArrayWithLength } from '../../../utils/common-methods';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';

const UserDetails = () => {
  const classes = useStyles();
  const params = useParams();
  const canEdit = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.ACCOUNTING]);
  const {
    USER: { USERS, USER_DETAILS },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [{ text: USERS, redirection: routeConstants.USERS_ROUTE }, { text: USER_DETAILS }],
    [],
  );

  const { currentData: userDetailResponse, isSuccess, isFetching, isLoading } = useGetUserByIdQuery(Number(params.id));
  const { userDetails } = useSelector((state) => state.user);
  const [getCurrentUser] = useLazyLoginUserQuery();
  const [addSubLocAPI] = useAddSubLocationToUserMutation();
  const [addNewLocationSelected, setAddNewLocationSelected] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({});
  // let isLoading = true;
  // let isFetching = true;
  useEffect(() => {
    const userDetails = {};
    if (isSuccess) {
      const data = userDetailResponse?.data;
      for (let x in data) {
        if (typeof data[x] === 'boolean') {
          userDetails[x] = data[x];
        } else {
          userDetails[x] = data[x] ? data[x] : '-';
        }
      }
    }
    setEmployeeDetails(userDetails);
  }, [userDetailResponse, isSuccess]);

  const userAssignedLocation = useMemo(() => {
    const locations = userDetailResponse?.data?.locations || [];
    return locations.filter((item) => isArrayWithLength(item.sublocations));
  }, [userDetailResponse]);

  const userAssignedSubLocation = useMemo(() => {
    const locations = userDetailResponse?.data?.locations || [];
    return locations
      .map((item) => item.sublocations)
      .reduce((prevValue, currentValue) => prevValue.concat(currentValue), []);
  }, [userDetailResponse]);

  const addSubLocationToUser = (subLocationIds) => {
    const data = {
      id: Number(params.id),
      subLocationIds,
    };

    addSubLocAPI(data).then(async () => {
      if (params.id === userDetails.id) {
        await getCurrentUser();
      }
      setAddNewLocationSelected(false);
    });
  };

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      {isLoading || isFetching ? (
        <LoadingSpinner isPaper containerHeight={300} />
      ) : (
        <UserInfoUpdateForm employeeDetails={employeeDetails} disabled={!canEdit} />
      )}
      {addNewLocationSelected ? (
        <UserAddNewLocation
          addSubLocationToUser={addSubLocationToUser}
          userAssignedLocation={userAssignedSubLocation}
          onCancelPress={() => setAddNewLocationSelected(false)}
        />
      ) : isArrayWithLength(userAssignedLocation) ? (
        isLoading || isFetching ? (
          <LoadingSpinner isPaper containerHeight={300} />
        ) : (
          <UserAssignedLocation
            onAddNewLocation={() => setAddNewLocationSelected(true)}
            assignedLocation={userAssignedLocation}
            removeSubLocationFromUser={addSubLocationToUser}
            disabled={!canEdit}
          />
        )
      ) : (
        <NoContent
          icon={<PinDropOutlined sx={{ width: '2.5em', height: '2.5em', color: '#2CA2DF' }} />}
          title="No Locations"
          desc={
            <>
              There are no locations assigned to this user
              <br /> Please assign new location
            </>
          }
          buttonName="Assign Location"
          disabled={!canEdit}
          onBtnClickHandler={() => setAddNewLocationSelected(true)}
        />
      )}
    </Container>
  );
};

export default UserDetails;
