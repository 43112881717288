import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px',
  },
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
    margin: '16px 0px 24px',
    height: '100%',
  },
  searchWrap: {
    width: '100%',
  },
  locationDetailAccordion: {
    padding: '24px 60px 18px',
    '& .MuiTypography-h3': {
      lineHeight: '24px',
    },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': {
        padding: '0',
      },
    },
  },
  locationDetailBtnWrap: {
    marginTop: '38px',
  },
  viewDetailsBtn: {
    letterSpacing: '1.25px',
  },
}));

export default useStyles;
