import React, { memo } from 'react';
import Tooltip from '@mui/material/Tooltip';

const ControlledTooltips = (props) => {
  const { children, tooltipValue } = props;

  return (
    <Tooltip disableHoverListener={tooltipValue === null} title={tooltipValue || ''}>
      <span>
        {
          children
          // As children cannot hold ref so we wrap it with span to hold its ref
        }
      </span>
    </Tooltip>
  );
};

export default memo(ControlledTooltips);
