import palette from '../../../theme/palette';

export const styles = () => ({
  listItem: {
    margin: '6px 16px',
    borderRadius: '8px',
    '& .MuiListItemIcon-root': {
      '& .svgIcon': {
        fill: palette.grey[500_1],
      },
    },
  },
  listItemActive: {
    '& .MuiListItemIcon-root': {
      '& .svgIcon': {
        fill: palette.common.white,
      },
    },
  },
  toolbarTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 55,
    marginBottom: 41,
  },
  CTMLogo: {
    height: 56,
  },
  drawerCollapseBtn: {
    position: 'absolute',
    minWidth: 'auto',
    padding: '4px',
    borderRadius: '50px',
    right: '-16px',
    top: '82px',
  },
  openDrawer: {
    transform: 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out',
  },
  closeDrawer: {
    transform: 'rotate(180deg)',
    transition: 'all 0.2s ease-in-out',
  },
  drawerBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.common.darkblue,
    height: '100%',
  },
  drawerBoxListWrap: {
    position: 'relative',
    height: '100%',
  },
  drawerBoxList: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: palette.common.darkblue,
      borderRight: 'none',
      overflowY: 'unset',
      transition: 'all 0.2s ease-in-out',
    },
  },
});
