import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  root: {
    padding: '0px',
  },
  skipThisStepWrap: {
    marginTop: '19px',
    paddingBottom: '4px',
  },
  skipThisStep: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '1.25px',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  locationDetailsForm: {
    marginTop: '35px',
  },
  locationOwnerDetailsForm: {
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiAutocomplete-endAdornment': {
        right: 18,
        top: 'calc(50% - 13px)',
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
    },
  },
  addSubLocationBtnWrap: {
    marginTop: '52px',
  },
}));

export default useStyles;
