import { useEffect, useState } from 'react';
import { Box, Tab as MUITab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useStyles from './style';

const Tabs = (props) => {
  const { tabsData = [], defaultTab = '1', onTabChange = () => {} } = props;
  const [tabIndex, setTabIndex] = useState('1');

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    onTabChange(newValue);
  };

  useEffect(() => {
    setTabIndex(defaultTab);
  }, [defaultTab]);

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <TabContext value={tabIndex}>
        <Box className={classes.TabHeadingsContainer}>
          <TabList onChange={handleChange}>
            {tabsData.length > 0 &&
              tabsData?.map((data, index) => {
                const value = index + 1;
                return (
                  <MUITab
                    label={data.heading}
                    value={value.toString()}
                    className={classes.TabHeading}
                    key={`TabHeading-${value.toString()}`}
                  />
                );
              })}
          </TabList>
        </Box>
        {tabsData.length > 0 &&
          tabsData?.map((data, index) => {
            const value = index + 1;
            return (
              <TabPanel value={value.toString()} className={classes.TabBody} key={`TabBody-${value.toString()}`}>
                {data.content}
              </TabPanel>
            );
          })}
      </TabContext>
    </Box>
  );
};

export default Tabs;
