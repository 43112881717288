import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './style/Protected.style';
import ResponsiveDrawer from './Drawer';
import { routeConstants } from '../../constants/routeConstants';
import { Container } from '@mui/material';

const displayName = 'LayoutProtected';
const propTypes = {
  classes: PropTypes.object.isRequired,
};

const Protected = (props) => {
  const { children, isAdmin = false, adminAccessibleRoutes } = props;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //if not admin: admin only routes are not accessible
    if (!isAdmin && adminAccessibleRoutes.indexOf(location.pathname) >= 0) {
      navigate(`/${routeConstants.DASHBOARD_ROUTE}`, { redirect: true });
      return;
    }
  }, [location.pathname, isAdmin]);

  return (
    <ResponsiveDrawer>
      <Container maxWidth="100%" className={'app-container'}>
        {children}
      </Container>
    </ResponsiveDrawer>
  );
};

Protected.displayName = displayName;
Protected.propTypes = propTypes;
export default withStyles(styles)(Protected);
