import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getModems: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.modem}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${page || DEFAULT_PAGE}&${searchParams}`;
        }
        return `${apiUrl.modem}`;
      },
      transformResponse: (response) => {
        const pagination = response?.pagination;
        const loadModems = response?.data?.modems;
        return { modems: loadModems, pagination };
      },
      providesTags: ['Modems'],
    }),
    getModemDetailsById: build.query({
      query: (id) => `${apiUrl.modem}/${id}`,
      providesTags: ['Modem'],
    }),
    updateModemById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.modem}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Modem', 'Modems', 'ModemMachine', 'Machine'],
    }),
    addModem: build.mutation({
      query: (data) => ({
        url: `${apiUrl.modem}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Modems'],
    }),
    deleteModemById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.modem}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Modem', 'Modems', 'ModemMachine'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetModemsQuery,
  useGetModemDetailsByIdQuery,
  useUpdateModemByIdMutation,
  useAddModemMutation,
  useDeleteModemByIdMutation,
} = extendedApi;
