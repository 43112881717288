const UPDATE_FIELDS = {
  LOCATION_DETAIL_TEXT_FIELD_NAME: {
    LOCATION_NAME: 'locationName',
    POSTCODE: 'postcode',
    PHONE1: 'phone1',
    PHONE2: 'phone2',
    CURRENCY: 'currencyId',
    LOCATION_TYPE: 'locationTypeId',
    COUNTRY: 'countryId',
    STATE: 'stateId',
    CITY: 'cityId',
    ADDRESS1: 'address1',
    ADDRESS2: 'address2',
    STATUS: 'status',
    X3_LOCATIONS_ID: 'X3LocationsID',
    X3_VENDORS_ID: 'X3VendorsID',
    X3_LOCATIONS_NAME: 'X3LocationName',
    X3_DEPARTMENT_ID: 'X3DepartmentID',
    X3_INDUSTRY_ID: 'X3IndustryID',
    X3_OWNER_ID: 'X3OwnerID',
  },
  LOCATION_OWNER_DETAIL_TEXTFIELD_NAME: {
    BUSINESS_NAME: 'name',
    BUSINESS_ADDRESS: 'address',
    COUNTRY: 'country',
    STATE: 'state',
    CITY: 'city',
    POSTAL_CODE: 'postalCode',
    CONTACT_NUMBER: 'phone',
    EMAIL: 'email',
    CONTACT_PERSON_NAME: 'contactPersonName',
    CONTACT_PERSON_EMAIL: 'contactPersonEmail',
    CONTACT_PERSON_NUMBER: 'contactPersonNumber',
  },
};

export default UPDATE_FIELDS;
