import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import CollectionsTable from './CollectionsTable';
import SearchBar from '../../../components/SearchBar';
import useStyles from '../style';
import { DEFAULT_PER_PAGE, DEFAULT_PAGE } from '../../../constants/pagination';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_ACCOUNTING_ROLE, FILTER_VALUES } from '../../../constants/FilterConstants';
import { useLazyGetLocationsQuery } from '../../../services/Location/LocationService';
import {
  useLazyGetAllSublocationsQuery,
  useLazyGetProductCategoriesQuery,
} from '../../../services/Sublocation/SublocationService';
import { useHasRoles, useSearchFilterParam } from '../../../hooks';
import { useLazyGetUsersForSelectQuery } from '../../../services/Users/UsersService';
import { ROLE } from '../../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { resetOpenCollectionFiltersState, setOpenCollectionFilters } from '../../../redux-slice/collectionFilters';
import { getUserName } from '../../../utils/common-methods';

const displayName = 'Open Collections';

const OpenCollections = () => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const [searchText, setSearchText] = useState('');
  const [noDefaultFilter, setNoDefaultFilter] = useState(false);
  const dispatch = useDispatch();
  const { openCollection } = useSelector((state) => state.collectionFilter);

  const [getLocations] = useLazyGetLocationsQuery();
  const [getAllSublocations] = useLazyGetAllSublocationsQuery();
  const [getAccountingUsers] = useLazyGetUsersForSelectQuery();
  const [getAllProductTypes] = useLazyGetProductCategoriesQuery();

  const isAccounting = useHasRoles([ROLE.ACCOUNTING]);
  const { userDetails } = useSelector((state) => state.user);

  const OPEN_COLLECTION = {
    INITIAL_PARAMS: isAccounting
      ? {
          ...FILTER_DATA.OPEN_COLLECTION.INITIAL_PARAMS,
          ...FILTER_DATA.OPEN_COLLECTION.ACCOUNTING_INITIAL_PARAMS,
        }
      : FILTER_DATA.OPEN_COLLECTION.INITIAL_PARAMS,
    FILTER: isAccounting
      ? [...FILTER_DATA.OPEN_COLLECTION.FILTER, ...FILTER_DATA.OPEN_COLLECTION.ACCOUNTING_FILTER]
      : FILTER_DATA.OPEN_COLLECTION.FILTER,
  };

  const [filterParams, setFilterParams] = useState(OPEN_COLLECTION.INITIAL_PARAMS);
  const getCustomParams = (value) => {
    switch (value) {
      case FILTER_VALUES.LOCATION:
        return {
          api: getLocations,
        };
      case FILTER_VALUES.COLLECTION_SUBLOCATION:
        return { api: getAllSublocations, customParam: 'status=true' };
      case FILTER_VALUES.ACCOUNTING_USER:
        return { api: getAccountingUsers, customParam: `role=${FILTER_ACCOUNTING_ROLE}` };
      case FILTER_VALUES.PRODUCT_TYPE:
        return { api: getAllProductTypes };
      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      OPEN_COLLECTION.FILTER.map((item) => {
        const params = getCustomParams(item.value);
        return {
          ...item,
          ...params,
        };
      }),
    [],
  );

  useEffect(() => {
    const storedFilterParams = isAccounting
      ? {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.ACCOUNTING_USER]: [],
        }
      : {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
        };

    if (openCollection[FILTER_VALUES.LOCATION] && openCollection[FILTER_VALUES.LOCATION]?.length > 0) {
      storedFilterParams.locationId = openCollection[FILTER_VALUES.LOCATION];
    }
    if (
      openCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] &&
      openCollection[FILTER_VALUES.COLLECTION_SUBLOCATION]?.length > 0
    ) {
      storedFilterParams.sublocation = openCollection[FILTER_VALUES.COLLECTION_SUBLOCATION];
    }
    if (openCollection[FILTER_VALUES.ACCOUNTING_USER] && openCollection[FILTER_VALUES.ACCOUNTING_USER]?.length > 0) {
      storedFilterParams.accountingUser = openCollection[FILTER_VALUES.ACCOUNTING_USER];
    }

    if (openCollection[FILTER_VALUES.ACCOUNTING_USER]?.length === 0 && isAccounting && !noDefaultFilter) {
      const username = getUserName(userDetails);
      const user = { name: username, text: username, id: userDetails?.id, value: userDetails?.id };
      if (!storedFilterParams.accountingUser.find((au) => au.id === user.id)) {
        storedFilterParams.accountingUser.push(user);
      }
    }
    storedFilterParams.productType = openCollection[FILTER_VALUES.PRODUCT_TYPE] ?? [];
    setSearchText(openCollection.searchText);
    setFilterParams(storedFilterParams);
  }, [openCollection, isAccounting, noDefaultFilter]);

  const searchParams = useSearchFilterParam({
    filterKey: OPEN_COLLECTION,
    filterParams,
    searchText,
    extraParam: 'includeNewCollectionData=true',
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setOpenCollectionFilters({
        ...openCollection,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setOpenCollectionFilters({
        ...openCollection,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetFilterParams = () => {
    setNoDefaultFilter(true);
    setFilterParams(OPEN_COLLECTION.INITIAL_PARAMS);
    dispatch(resetOpenCollectionFiltersState());
  };

  const onApplyFilterHandler = (v) => {
    dispatch(
      setOpenCollectionFilters({ ...openCollection, ...v, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }),
    );
    setFilterParams(v);
  };

  return (
    <React.Fragment>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            onSearch={searchHandler}
            placeholder={'Search by location'}
            onReset={onReset}
            searchText={searchText}
          />
        </Box>
        <Box pl={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={onApplyFilterHandler}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(OPEN_COLLECTION.INITIAL_PARAMS)?.length}
          />
        </Box>
      </Box>
      <CollectionsTable searchParams={searchParams} openCollection={openCollection} />
    </React.Fragment>
  );
};

OpenCollections.displayName = displayName;
export default OpenCollections;
