import React, { useEffect, useMemo } from 'react';
import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import dayjs from 'dayjs';

import {
  Breadcrumbs,
  Button,
  Datepicker,
  GridContainer,
  GridItem,
  Select,
  TextField,
} from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import {
  useGetModelCategoryQuery,
  useGetModelDetailsByIdQuery,
  useGetModelManufacturerQuery,
  useGetModelThemeQuery,
  useGetModelTypesQuery,
  useUpdateModelByIdMutation,
} from '../../../services/Models/ModelsService';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { LoadingButton } from '@mui/lab';
import { AddNewModelSchema } from '../../../schema/validationSchemas';
import { getOptions } from '../../../utils/common-methods';

const EditModel = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    MODEL: { MODEL, MODEL_DETAIL, EDIT_MODEL },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [
      { text: MODEL, redirection: `${routeConstants.MODELS_ROUTE}` },
      {
        text: MODEL_DETAIL,
        redirection: `${routeConstants.MODELS_ROUTE}/${params.id}`,
      },
      { text: EDIT_MODEL },
    ],
    [],
  );

  const { isSuccess: modelDetailSuccess, currentData } = useGetModelDetailsByIdQuery(Number(params.id));
  const { currentData: modelCategoryData } = useGetModelCategoryQuery();
  const { currentData: modelThemeData } = useGetModelThemeQuery();
  const { currentData: modelManufacturerData } = useGetModelManufacturerQuery();
  const { currentData: modelTypeData } = useGetModelTypesQuery();
  const [updateModelById, { isLoading: updateModelLoading }] = useUpdateModelByIdMutation();

  const categoryOptions = useMemo(
    () => getOptions(modelCategoryData?.data?.modelCategories || []),
    [modelCategoryData],
  );
  const manufacturerOptions = useMemo(
    () => getOptions(modelManufacturerData?.data?.modelManufacturers || []),
    [modelManufacturerData],
  );
  const themeOptions = useMemo(() => getOptions(modelThemeData?.data?.modelThemes || []), [modelThemeData]);
  const typesOptions = useMemo(() => getOptions(modelTypeData?.data?.modelTypes || []), [modelTypeData]);

  const formik = useFormik({
    initialValues: {
      title: '',
      categoryId: '',
      manufacturer: '',
      theme: '',
      subTheme: '',
      yearMade: '',
      height: '',
      width: '',
      weight: '',
      typeId: '',
    },
    validationSchema: AddNewModelSchema,
    onSubmit: (value) => {
      const updatedModelData = {
        id: Number(params.id),
        title: value.title.trim(),
        categoryId: Number(value.categoryId),
        typeId: value.typeId || null,
        // themeId: value.theme,
        // subThemeId: value.subTheme
        // TODO:theme and sub theme is not yet there in API so need to add those once it will be available.
        // TODO: We have "meterId","prizeId" as a key so in future if we want to add we can add it.
      };
      if (value.yearMade) {
        updatedModelData.yearMade = dayjs(value.yearMade).year().toString();
      }
      if (value.manufacturer) {
        updatedModelData.manufactureId = value.manufacturer;
      }
      if (value.height) {
        updatedModelData.height = Number(value.height);
      }
      if (value.weight) {
        updatedModelData.weight = Number(value.weight);
      }
      if (value.width) {
        updatedModelData.width = Number(value.width);
      }

      updateModelById(updatedModelData).then((response) => {
        if (response.data) {
          navigate(`/${routeConstants.MODELS_ROUTE}/${params.id}`);
        }
      });
    },
  });

  const { values, touched, errors, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (modelDetailSuccess && currentData) {
      const modelData = currentData.data;
      setFieldValue('title', modelData?.title.trim() || 'N/A');
      setFieldValue('manufacturer', modelData?.manufacturer?.id || '');
      setFieldValue('theme', modelData?.category?.theme?.id || '');
      setFieldValue('subTheme', modelData?.category?.theme?.subTheme || '');
      setFieldValue('categoryId', modelData?.category?.id || '');
      setFieldValue('yearMade', modelData?.yearMade || '');
      setFieldValue('height', modelData?.height || '');
      setFieldValue('width', modelData?.width || '');
      setFieldValue('weight', modelData?.weight || '');
      setFieldValue('typeId', modelData.typeId || '');
    }
  }, [modelDetailSuccess, currentData]);

  const yearMadeChangeHandler = (value) => {
    setFieldValue('yearMade', value);
  };

  const onCancelClickHandler = () => {
    navigate(`/${routeConstants.MODELS_ROUTE}/${Number(params.id)}`);
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>
      <Box mb={3}>
        <Typography variant="h1">{currentData?.data?.title}</Typography>
      </Box>
      <Box component={Paper} p={{ xs: 1, sm: 2, md: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box mb={3}>
              <Typography variant="h2">Model Details</Typography>
            </Box>
            <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }}>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Title"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Category"
                  options={categoryOptions}
                  {...getFieldProps('categoryId')}
                  error={Boolean(touched.categoryId && errors.categoryId)}
                  helperText={touched.categoryId && errors.categoryId}
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Manufacturer"
                  options={manufacturerOptions}
                  {...getFieldProps('manufacturer')}
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Theme"
                  options={themeOptions}
                  {...getFieldProps('theme')}
                  disabled
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Sub theme"
                  options={[]}
                  {...getFieldProps('subTheme')}
                  disabled
                  data-testid="addModelForms"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Datepicker
                  label="Year Made"
                  name="yearMade"
                  value={values.yearMade}
                  onChange={yearMadeChangeHandler}
                  inputFormat="YYYY"
                  views={['year']}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  error={Boolean(touched.yearMade && errors.yearMade)}
                  helperText={touched.yearMade && errors.yearMade}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Height"
                  error={touched.height && errors.height}
                  helperText={touched.height && errors.height}
                  {...getFieldProps('height')}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </GridItem>

              <GridItem xs={12} md={6}>
                <TextField
                  label="Width"
                  error={touched.width && errors.width}
                  helperText={touched.width && errors.width}
                  {...getFieldProps('width')}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Weight"
                  error={touched.weight && errors.weight}
                  helperText={touched.weight && errors.weight}
                  {...getFieldProps('weight')}
                  InputProps={{
                    'data-testid': 'addModelForms',
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Type"
                  options={typesOptions}
                  {...getFieldProps('typeId')}
                  data-testid="addModelForms"
                />
              </GridItem>

              <GridItem xs={12}>
                <Stack direction="row" spacing={2} mt={3}>
                  <Box>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={updateModelLoading}
                      data-testid="addModelBtn"
                    >
                      UPDATE
                    </LoadingButton>
                  </Box>
                  <Box>
                    <Button onClick={onCancelClickHandler}>CANCEL</Button>
                  </Box>
                </Stack>
              </GridItem>
            </GridContainer>
          </Form>
        </FormikProvider>
      </Box>
    </Container>
  );
};

export default EditModel;
