import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Stack } from '@mui/material';
import { GridContainer, Button, GridItem, TextField, Select } from '../../../components/shared';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';

import {
  useGetAllCountriesQuery,
  useLazyGetStateCitiesByStateIdQuery,
  useLazyGetCountryStatesByCountryIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import { useGetAllLocationTypesQuery, useUpdateLocationByIdMutation } from '../../../services/Location/LocationService';
import UPDATE_FIELDS from '../../../constants/updateFields';
import useStyles from './style';
import { formatPhNo10Digit, getOptions, getPhNo10Digits } from '../../../utils/common-methods';
import { locationUpdateSchema } from '../../../schema/validationSchemas';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import { useGetCurrenciesQuery } from '../../../services/Currencies/CurrenciesService';
import {
  useGetX3DepartmentsQuery,
  useGetX3IndustriesQuery,
  useGetX3OwnersQuery,
} from '../../../services/X3Locations/X3LocationsService';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';

const LocationUpdateDetailView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { locationDetails } = props;
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentData: countriesResponse, isSuccess: countriesAPISuccess } = useGetAllCountriesQuery();
  const [getCitiesByStateId, citiesResponseByStateId] = useLazyGetStateCitiesByStateIdQuery();
  const [getStatesByCountryId, statesResponseByCountryId] = useLazyGetCountryStatesByCountryIdQuery();
  const { currentData: typesResponse } = useGetAllLocationTypesQuery();
  const [updateLocationApi] = useUpdateLocationByIdMutation();
  const { currentData: currenciesData = [] } = useGetCurrenciesQuery();
  const { currentData: x3DepartmentsData = [] } = useGetX3DepartmentsQuery();
  const { currentData: x3IndustriesData = [] } = useGetX3IndustriesQuery();
  const { currentData: x3OwnersData = [] } = useGetX3OwnersQuery();

  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const currencyOptions = getOptions(currenciesData);
  const x3DepartmentsOptions = getOptions(x3DepartmentsData, 'X3_DeptCode');
  const xIndustriesOptions = getOptions(x3IndustriesData, 'X3_vertical');
  const x3OwnersOptions = getOptions(x3OwnersData, 'X3_OwnerCode');
  const locationTypeOptions = getOptions(typesResponse);

  useEffect(() => {
    if (countriesAPISuccess) {
      setCountryData(getOptions(countriesResponse));
    }
  }, [countriesResponse]);

  useEffect(() => {
    if (statesResponseByCountryId.isSuccess) {
      setStatesData(getOptions(statesResponseByCountryId.currentData?.data?.states));
    }
  }, [statesResponseByCountryId.currentData]);

  useEffect(() => {
    if (citiesResponseByStateId.isSuccess) {
      setCitiesData(getOptions(citiesResponseByStateId.currentData?.data?.cities));
    }
  }, [citiesResponseByStateId.currentData]);

  const cancelHandler = () => {
    if (locationDetails?.id) {
      navigate(`/${routeConstants.LOCATION_ROUTE}/${Number(locationDetails.id)}`);
    } else {
      navigate(`/${routeConstants.LOCATION_ROUTE}`);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: locationDetails?.id,
      locationName: locationDetails?.locationName || '',
      postcode: locationDetails?.postcode || '',
      phone1: formatPhNo10Digit(locationDetails?.phone1) || '',
      phone2: formatPhNo10Digit(locationDetails?.phone2) || '',
      currencyId: locationDetails?.currencyId || '',
      locationTypeId: locationDetails?.locationTypeId || '',
      countryId: locationDetails?.countryId || '',
      stateId: locationDetails?.stateId || '',
      cityId: locationDetails?.cityId || '',
      address1: locationDetails?.address1 || '',
      address2: locationDetails?.address2 || '',
      X3_LocationsID: locationDetails?.X3_LocationsID || '',
      X3_VendorsID: locationDetails?.X3_VendorsID || '',
      X3_LocationName: locationDetails?.X3_LocationName || '',
      X3_DepartmentID: locationDetails?.X3_DepartmentID || '',
      X3_IndustryID: locationDetails?.X3_IndustryID || '',
      X3_OwnerID: locationDetails?.X3_OwnerID || '',
    },
    validationSchema: locationUpdateSchema,
    onSubmit: (values) => {
      const data = {
        id: values.id,
        locationName: values.locationName,
        postcode: values.postcode,
        phone1: getPhNo10Digits(values.phone1) || '',
        phone2: getPhNo10Digits(values.phone2) || '',
        countryId: values?.countryId ? Number(values?.countryId) : null,
        stateId: values?.stateId ? Number(values?.stateId) : null,
        cityId: values?.cityId ? Number(values?.cityId) : null,
        currencyId: values?.currencyId ? Number(values?.currencyId) : null,
        address1: values?.address1,
        address2: values?.address2,
        X3_LocationsID: values?.X3_LocationsID,
        X3_VendorsID: values?.X3_VendorsID,
        X3_LocationName: values?.X3_LocationName,
        X3_DepartmentID: values?.X3_DepartmentID,
        X3_IndustryID: values?.X3_IndustryID,
        X3_OwnerID: values?.X3_OwnerID,
      };
      updateLocationApi(data).then((response) => {
        if (response.data) {
          navigate(`/${routeConstants.LOCATION_ROUTE}/${Number(locationDetails?.id)}`);
        }
      });
    },
  });

  useEffect(() => {
    if (locationDetails.countryId) {
      getStatesByCountryId(locationDetails?.countryId);
    }
    if (locationDetails?.stateId) {
      getCitiesByStateId(locationDetails?.stateId);
    }
  }, [locationDetails]);

  const archiveHandler = () => {
    setLoading(true);
    const status = locationDetails?.status;
    const archiveData = {
      id: formik.values.id,
      status: !status,
    };
    updateLocationApi(archiveData).then((response) => {
      setLoading(false);
      if (response.data) {
        navigate(`/${routeConstants.LOCATION_ROUTE}?archive=${status}`);
      }
    });
    setShowArchiveModal(false);
  };
  const { errors, touched, getFieldProps, setFieldValue } = formik;
  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setFieldValue(name, value);
    if (name === UPDATE_FIELDS.LOCATION_DETAIL_TEXT_FIELD_NAME.COUNTRY) {
      getStatesByCountryId(value);
      setFieldValue(UPDATE_FIELDS.LOCATION_DETAIL_TEXT_FIELD_NAME.STATE, '');
      setFieldValue(UPDATE_FIELDS.LOCATION_DETAIL_TEXT_FIELD_NAME.CITY, '');
      setCitiesData([]);
    }
    if (name === UPDATE_FIELDS.LOCATION_DETAIL_TEXT_FIELD_NAME.STATE) {
      getCitiesByStateId(value);
      setFieldValue(UPDATE_FIELDS.LOCATION_DETAIL_TEXT_FIELD_NAME.CITY, '');
    }
  };
  return (
    <Box component={Paper} className={classes.locationDetailsUpdateBox}>
      <Typography variant="h2">Location Details</Typography>
      <FormikProvider value={formik}>
        <GridContainer
          rowSpacing={3}
          columnSpacing={{ xs: 0, sm: 0, md: 7 }}
          className={classes.locationDetailsFieldsContainer}
          mb={4}
        >
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Location Name"
              {...getFieldProps('locationName')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.locationName && errors.locationName)}
              helperText={touched.locationName && errors.locationName}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Zip Code"
              {...getFieldProps('postcode')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.postcode && errors.postcode)}
              helperText={touched.postcode && errors.postcode}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Phone 1"
              {...getFieldProps('phone1')}
              InputProps={{
                'data-testid': 'updateField',
                inputComponent: TextMaskCustom,
              }}
              error={Boolean(touched.phone1 && errors.phone1)}
              helperText={touched.phone1 && errors.phone1}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Phone 2"
              {...getFieldProps('phone2')}
              InputProps={{
                'data-testid': 'updateField',
                inputComponent: TextMaskCustom,
              }}
              error={Boolean(touched.phone2 && errors.phone2)}
              helperText={touched.phone2 && errors.phone2}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="Currency"
              options={currencyOptions}
              {...getFieldProps('currencyId')}
              data-testid="updateField"
              error={Boolean(touched.currencyId && errors.currencyId)}
              helperText={touched.currencyId && errors.currencyId}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="Location Type"
              options={locationTypeOptions}
              {...getFieldProps('locationTypeId')}
              data-testid="updateField"
              disabled
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="Country"
              options={countryData}
              {...getFieldProps('countryId')}
              data-testid="updateField"
              error={Boolean(touched.countryId && errors.countryId)}
              helperText={touched.countryId && errors.countryId}
              onChange={onChangeHandler}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="State"
              options={statesData}
              {...getFieldProps('stateId')}
              data-testid="updateField"
              error={Boolean(touched.stateId && errors.stateId)}
              helperText={touched.stateId && errors.stateId}
              disabled={statesData.length === 0}
              onChange={onChangeHandler}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="City"
              options={citiesData}
              {...getFieldProps('cityId')}
              data-testid="updateField"
              error={Boolean(touched.cityId && errors.cityId)}
              helperText={touched.cityId && errors.cityId}
              disabled={citiesData.length === 0}
              onChange={onChangeHandler}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Address 1"
              {...getFieldProps('address1')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.address1 && errors.address1)}
              helperText={touched.address1 && errors.address1}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Address 2"
              {...getFieldProps('address2')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.address2 && errors.address2)}
              helperText={touched.address2 && errors.address2}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              type="number"
              label="X3 LocationsID"
              {...getFieldProps('X3_LocationsID')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.X3_LocationsID && errors.X3_LocationsID)}
              helperText={touched.X3_LocationsID && errors.X3_LocationsID}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              type="string"
              label="X3 VendorsID"
              {...getFieldProps('X3_VendorsID')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.X3_VendorsID && errors.X3_VendorsID)}
              helperText={touched.X3_VendorsID && errors.X3_VendorsID}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="X3 LocationName"
              {...getFieldProps('X3_LocationName')}
              InputProps={{
                'data-testid': 'updateField',
              }}
              error={Boolean(touched.X3_LocationName && errors.X3_LocationName)}
              helperText={touched.X3_LocationName && errors.X3_LocationName}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="X3 DepartmentID"
              options={x3DepartmentsOptions}
              {...getFieldProps('X3_DepartmentID')}
              data-testid="updateField"
              error={Boolean(touched.X3_DepartmentID && errors.X3_DepartmentID)}
              helperText={touched.X3_DepartmentID && errors.X3_DepartmentID}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="X3 IndustryID"
              options={xIndustriesOptions}
              {...getFieldProps('X3_IndustryID')}
              data-testid="updateField"
              error={Boolean(touched.X3_IndustryID && errors.X3_IndustryID)}
              helperText={touched.X3_IndustryID && errors.X3_IndustryID}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              inputLabel="X3 OwnerID"
              options={x3OwnersOptions}
              {...getFieldProps('X3_OwnerID')}
              data-testid="updateField"
              error={Boolean(touched.X3_OwnerID && errors.X3_OwnerID)}
              helperText={touched.X3_OwnerID && errors.X3_OwnerID}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="space-around" py={1}>
          <GridItem xs={6}>
            <Stack direction="row" spacing={2}>
              <Button onClick={formik.handleSubmit} variant="contained">
                UPDATE
              </Button>
              <Button onClick={cancelHandler}>CANCEL</Button>
            </Stack>
          </GridItem>
          <GridItem xs={6} display="flex" justifyContent="flex-end">
            <Button onClick={() => setShowArchiveModal(true)} color={locationDetails?.status ? 'error' : 'success'}>
              {locationDetails?.status ? 'ARCHIVE LOCATION' : 'ACTIVATE LOCATION'}
            </Button>
          </GridItem>
        </GridContainer>
      </FormikProvider>
      <ConfirmationModal
        isOpen={showArchiveModal}
        isLoading={loading}
        title="Confirmation"
        msg={`Are you sure you want to ${
          locationDetails?.status ? 'archive' : 'active'
        }  this location? This process cannot be undone.`}
        buttons={[
          { text: locationDetails?.status ? 'ARCHIVE' : 'ACTIVATE', value: true },
          { text: 'Cancel', value: false },
        ]}
        onClick={(value) => {
          if (value) archiveHandler();
          else setShowArchiveModal(false);
        }}
      />
    </Box>
  );
};

export default LocationUpdateDetailView;
