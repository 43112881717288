import { Box, CircularProgress, Paper } from '@mui/material';
import useStyles from './style/style';

const displayName = 'LoadingSpinner';

const LoadingSpinner = (props) => {
  const { containerHeight = 200, isPaper = false } = props;
  const classes = useStyles();
  return (
    <Box
      data-testid="loadingSpinner"
      className={classes.root}
      component={isPaper ? Paper : 'div'}
      sx={{ minHeight: containerHeight }}
    >
      <CircularProgress />
    </Box>
  );
};

LoadingSpinner.displayName = displayName;
export default LoadingSpinner;
