import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getUserRoles: build.query({
      query: () => apiUrl.userRole,
    }),
    getAllRoles: build.query({
      query: () => apiUrl.userRole,
      transformResponse: (response) => {
        const managementRoles = response?.data?.managementRoles || [];
        const roles = response?.data?.roles || [];

        return [...managementRoles, ...roles].map((item) => ({
          ...item,
          name: item.title,
        }));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserRolesQuery, useLazyGetAllRolesQuery } = extendedApi;
