import React, { useEffect, useMemo, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';

import {
  Accordion,
  Breadcrumbs,
  GridContainer,
  GridItem,
  InformationPad,
  PositionedMenu,
} from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetModelDetailsByIdQuery } from '../../../services/Models/ModelsService';
import { MODEL_DETAIL_LABEL } from '../../../constants/accordionFields';
import ModelMachineTable from './ModelMachineTable';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import FIELD_TYPE from '../../../constants/fieldType';
import { isArrayWithLength } from '../../../utils/common-methods';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';

const ModelDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { NOT_APPLICABLE } = FIELD_TYPE;
  const {
    MODEL: { MODEL, MODEL_DETAIL },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [{ text: MODEL, redirection: `${routeConstants.MODELS_ROUTE}` }, { text: MODEL_DETAIL }],
    [],
  );
  const canEdit = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.EQUIPMENT]);
  const { isSuccess: modelDetailSuccess, currentData } = useGetModelDetailsByIdQuery(Number(params.id));

  const [modelDetails, setModelDetails] = useState(null);

  useEffect(() => {
    if (modelDetailSuccess) {
      setModelDetails(currentData.data);
    }
  }, [modelDetailSuccess, currentData]);

  const modelAccordionDetails = useMemo(() => {
    if (!modelDetails) return [];
    const data = {
      [MODEL_DETAIL_LABEL.DETAILS.MANUFACTURER]: modelDetails?.manufacturer?.name || NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.THEME]: modelDetails?.category?.theme?.name || NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.SUB_THEME]: modelDetails?.category?.theme?.subTheme || NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.CATEGORY]: modelDetails?.category?.name || NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.TYPE]: modelDetails?.type?.title || NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.YEAR_MADE]: modelDetails?.yearMade || NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.HEIGHT]: modelDetails?.height ? `${modelDetails?.height} inch` : NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.WIDTH]: modelDetails?.width ? `${modelDetails?.width} inch` : NOT_APPLICABLE,
      [MODEL_DETAIL_LABEL.DETAILS.WEIGHT]: modelDetails?.weight ? `${modelDetails?.weight} lbs` : NOT_APPLICABLE,
    };

    return Object.keys(data).map((key) => ({ label: key, value: data[key] }));
  }, [modelDetails]);

  return (
    <React.Fragment>
      <Box>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>
      <Typography my={4} variant={'h1'}>
        {modelDetails?.title}
      </Typography>
      <Box
        mb={3}
        sx={{
          '& .MuiAccordionDetails-root': {
            '& .MuiGrid2-container': {
              paddingLeft: 0,
            },
          },
        }}
      >
        <Accordion
          defaultExpanded
          title={'Model Details'}
          content={<InformationPad data={modelAccordionDetails} />}
          showMore={true}
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: () =>
                    navigate(`/${routeConstants.MODELS_ROUTE}/${routeConstants.EDIT_MODELS_ROUTE}/${params.id}`),
                },
              ]}
              disabled={!canEdit}
            />
          }
        />
      </Box>
      {isArrayWithLength(modelDetails?.assets) ? (
        <Box component={Paper} mb={3}>
          <GridContainer pt={4} px={3} pb={2} justifyContent="space-between" alignItems="center">
            <GridItem xs={7} md={8}>
              <Typography variant="h2">Machines</Typography>
            </GridItem>
          </GridContainer>
          <Box mt={1}>
            <ModelMachineTable machines={modelDetails?.assets} />
          </Box>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default ModelDetails;
