import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  showErrorModal: false,
  errorMsg: '',
  errorTitle: '',
  isInternalServerError: false,
  isForbiddenError: false,
  isParseError: false,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setShowErrorModal(state, action) {
      state.showErrorModal = action.payload;
    },
    setErrorMsg(state, action) {
      state.errorMsg = action.payload;
    },
    setErrorTitle(state, action) {
      state.errorTitle = action.payload;
    },
    setIsInternalServerError(state, action) {
      state.isInternalServerError = action.payload;
    },
    setIsForbiddenError(state, action) {
      state.isForbiddenError = action.payload;
    },
    setIsParseError(state, action) {
      state.isParseError = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: () => {},
});

export default errorSlice;
