import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  filter: {
    paddingRight: '4px',
    marginTop: '9px',
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
    '& .MuiGrid2-root': {
      padding: 0,
    },
  },
  accordion: {
    '& .Mui-expanded': {
      '& .MuiCollapse-root': {
        marginTop: '4px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      width: '32px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        color: `${theme.palette.secondary.main}`,
      },
    },
    '& .MuiAccordionSummary-root': {
      '&.MuiButtonBase-root': {
        padding: 0,
      },
      '&.Mui-expanded': {
        minHeight: '48px',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      marginRight: 0,
    },
    '& .MuiAccordionSummary-content': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '700',
      color: theme.palette.grey[800_1],
      margin: 0,
    },
    '& .MuiFormControl-root': {
      paddingLeft: 0,
    },
  },
}));

export default useStyles;
