import { Typography, Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Button, Tabs } from '../../../components/shared';
import useStyles from './style';
import ActiveTab from './ActiveTab';
import ArchiveTab from './ArchiveTab';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import { routeConstants } from '../../../constants/routeConstants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { ROLE } from '../../../constants/roles';
import { useHasRoles } from '../../../hooks';

const displayName = 'Machines';

const Machines = () => {
  const classes = useStyles();
  const canAdd = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.EQUIPMENT]);
  const [totalMachines, setTotalMachines] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('selectedTab');
  const tabs = [
    {
      heading: TAB_TYPES.ACTIVE,
      content: <ActiveTab onTotalMachine={(v) => setTotalMachines(v)} />,
    },
    {
      heading: TAB_TYPES.ARCHIVE,
      content: <ArchiveTab onTotalMachine={(v) => setTotalMachines(v)} />,
    },
  ];

  const onTabChange = (newValue) => {
    navigate(`/${routeConstants.MACHINES_ROUTE}?selectedTab=${newValue}`, {
      replace: true,
    });
  };

  return (
    <React.Fragment>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">Machines</Typography>
          <Button
            component={Link}
            to={`/${routeConstants.ADD_MACHINE_ROUTE}`}
            variant="contained"
            data-testid="add-machine-btn"
            disabled={!canAdd}
            startIcon={<Add />}
          >
            ADD NEW
          </Button>
        </Stack>
      </Box>
      <Box>
        <Typography pl={2} variant="h3" className={classes.totalMachines}>
          {totalMachines ? `${totalMachines} Machines` : ''}
        </Typography>
      </Box>
      <Box className={classes.machinesTable} mt={2}>
        <Tabs defaultTab={selectedTab ?? '1'} tabsData={tabs} onTabChange={onTabChange} />
      </Box>
    </React.Fragment>
  );
};

Machines.displayName = displayName;
export default Machines;
