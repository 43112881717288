import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, setIsForbiddenError, setIsParseError, setShowErrorModal } from '../../redux-slice/error';
import { MESSAGE } from '../../constants/message';
import ConfirmationModal from '../ConfirmationModel/ConfirmationModal';
import { logout, setUserDeactivated } from '../../redux-slice/user';
import { LOADING_STATE } from '../../constants/CommonConstants';
import { ERROR_MESSAGE, ERROR_TITLE } from '../../constants/errorConstants';

const ErrorModal = () => {
  const dispatch = useDispatch();
  const errorSlice = useSelector((state) => state.error);
  const userSlice = useSelector((state) => state.user);
  const msalStatus = useSelector((state) => state.msalStatus);

  const onClickErrorModalHandler = () => {
    dispatch(setShowErrorModal(false));
    if (errorSlice?.isInternalServerError) {
      dispatch(logout());
    }
    if (userSlice.isDeactivated) {
      dispatch(setUserDeactivated(false));
      dispatch(setIsForbiddenError(false));
    }
    if (errorSlice?.isParseError) {
      dispatch(setIsParseError(false));
    }
    if (errorSlice.isForbiddenError || errorSlice?.isInternalServerError) {
      dispatch(reset());
    }
  };

  return (
    <ConfirmationModal
      isOpen={errorSlice.showErrorModal}
      title={
        errorSlice?.isForbiddenError && userSlice.isDeactivated
          ? ERROR_TITLE.ACCOUNT_DEACTIVATED
          : errorSlice?.isForbiddenError
          ? MESSAGE.FORBIDDEN_ERROR_TITLE
          : errorSlice?.errorTitle === ''
          ? MESSAGE.SOMETHING_WENT_WRONG
          : errorSlice?.errorTitle
      }
      msg={
        errorSlice?.isForbiddenError
          ? userSlice.isDeactivated
            ? ERROR_MESSAGE.ACCOUNT_DEACTIVATED
            : MESSAGE.FORBIDDEN_ERROR_MESSAGE
          : msalStatus.ssoSilentStatus === LOADING_STATE.BACKEND_ISSUE || userSlice.manuallyLogoutStatus
          ? MESSAGE.BACKEND_UNAUTHORIZED
          : errorSlice?.errorMsg
      }
      buttons={[{ text: MESSAGE.OK, value: MESSAGE.OK }]}
      onClick={onClickErrorModalHandler}
    />
  );
};

export default ErrorModal;
