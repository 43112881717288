import { useState } from 'react';
import { Box, Button, Paper, Stack, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './style';
import { Tabs } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { Link } from 'react-router-dom';
import ActiveTab from './ActiveTab';
import ArchiveTab from './ArchiveTab';
import { TAB_TYPES } from '../../../constants/CommonConstants';

const LocationOwners = () => {
  const classes = useStyles();
  const [totalLocationOwnersCount, setTotalLocationOwnersCount] = useState('');
  const theme = useTheme();

  const tabs = [
    {
      heading: TAB_TYPES.ACTIVE,
      content: <ActiveTab onTotalLocationOwnersCount={(v) => setTotalLocationOwnersCount(v)} />,
    },
    {
      heading: TAB_TYPES.ARCHIVE,
      content: <ArchiveTab />,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">Location Owners</Typography>
          <Button
            component={Link}
            to={`/${routeConstants.ADD_NEW_LOCATION_OWNER}`}
            variant="contained"
            data-testid="add-location-btn"
            startIcon={<AddIcon />}
          >
            ADD NEW
          </Button>
        </Stack>
      </Box>
      <Box>
        <Typography variant="h3" style={{ color: theme.palette.secondary.main }}>
          {`${totalLocationOwnersCount ? `${totalLocationOwnersCount} Location Owners` : ''}`}
        </Typography>
      </Box>
      <Box mt={2} component={Paper}>
        <Tabs tabsData={tabs} />
      </Box>
    </Box>
  );
};

export default LocationOwners;
