import { Box, Container, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrumbs, TimeLine } from '../../../components/shared';
import useStyles from './style';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import GeneralDetailsForm from '../../../components/GeneralDetailsFrom';
import { leaseDetailsFormAction, resetLeaseAction, rentDetailsFormAction } from '../../../redux-slice/lease';
import { useDispatch, useSelector } from 'react-redux';
import LeaseDetailsForm from '../../../components/LeaseDetailsForm';
import RentDetailsForm from '../../../components/RentDetailsForm';
import { dateFormatForApi } from '../../../utils/common-methods';
import { useAddLeaseMutation } from '../../../services/Lease/LeaseService';

const AddNewLease = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location');
  const subLocationId = searchParams.get('subLocation');
  const {
    LEASE: { LEASE, ADD_LEASE },
    LOCATION: { LOCATION, LOCATION_DETAIL, SUB_LOCATION_DETAIL },
  } = BREADCRUMB_NAMES;
  const leaseBreadcrumbData = [{ text: LEASE, redirection: `${routeConstants.LEASE_ROUTE}` }, { text: ADD_LEASE }];
  const locationBreadcrumbData = [
    { text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` },
    { text: LOCATION_DETAIL, redirection: `${routeConstants.LOCATION_ROUTE}/${locationId}` },
    {
      text: SUB_LOCATION_DETAIL,
      redirection: `${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}?locationId=${locationId}`,
    },
    { text: ADD_LEASE },
  ];
  const [currentIndex, setCurrentIndex] = useState(1);
  const dispatch = useDispatch();
  const leaseFormsValue = useSelector((state) => state?.lease?.leaseForms);
  const { leaseDetails, rentDetails } = leaseFormsValue;
  const [AddNewLeaseApi, { isLoading }] = useAddLeaseMutation();

  //this code is for page reloading prompt message.
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  // this is for when page(component) loads all states should be empty.
  useEffect(() => {
    if (!locationId && !subLocationId) dispatch(resetLeaseAction());
  }, []);

  const onNextClickHandler = (type, values) => {
    if (type === 'leaseDetails') {
      const formValues = {
        ...values,
        leaseBegins: values.leaseBegins.toString(),
        leaseEnds: values.leaseEnds.toString(),
      };
      dispatch(leaseDetailsFormAction(formValues));
    }
    if (type === 'rentDetails') {
      dispatch(rentDetailsFormAction(values));
    }
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  const onBackClickHandler = () => {
    const nextIndex = currentIndex - 1;
    setCurrentIndex(nextIndex);
  };

  const rentDetailsObj = (rentDetails) => {
    const gameCategoryRates = rentDetails?.modalTypes?.map((value) => ({
      gameCategoryId: value?.id,
      rate: value?.rate,
    }));
    switch (rentDetails?.rentType) {
      case 'RATE_PLUS_FEE':
      case 'COMBO':
      case 'RATE_FEE_HIGHER':
      case 'SUM':
      case 'NA':
        return {
          fee: rentDetails?.rentFee,
          rate: rentDetails?.rentRate,
          hasNaturalBreakPoint: rentDetails?.breakpointNatural,
          naturalBreakPointValue: rentDetails?.breakpointValue,
          gameCategoryRates: gameCategoryRates,
        };
      case 'RATE_ONLY':
      case 'BENCHMARK_RENT':
        return {
          rate: rentDetails?.rentRate,
          gameCategoryRates: gameCategoryRates,
        };
      case 'FEE_ONLY':
      case 'FEE_ONLY_ANNUAL':
      case 'FEE_ONLY_YEAR':
      case 'FEE_PER_PIC':
      case 'FEE_ONLY_AFTER_BENCHMARK':
      case 'FEE_ONLY_SLIDING_BENCHMARK':
        return {
          fee: rentDetails?.rentFee,
        };

      default:
        return {};
    }
  };

  const onAddClickHandler = (values) => {
    const subLocationIds = leaseDetails?.subLocation?.map((value) => value?.id);
    const leaseObj = {
      name: leaseDetails.leaseName,
      locationId: leaseDetails?.location?.id,
      sublocationIds: subLocationIds,
      leaseType: leaseDetails?.leaseType,
      status: leaseDetails?.status,
      termStartDate: dateFormatForApi(leaseDetails?.leaseBegins),
      termEndDate: dateFormatForApi(leaseDetails?.leaseEnds),
    };

    const rentDetailsCommon = {
      rentType: rentDetails?.rentType,
      rentCalculateInterval: rentDetails?.rentCalculationTiming,
      CAMFee: rentDetails?.CAMFee,
      CAMRate: rentDetails?.CAMRate,
      thresholdRate: rentDetails?.thresholdRate,
      thresholdRevenue: rentDetails?.thresholdRevenue,
      notes: rentDetails?.notes,
      coinCost: rentDetails?.coinCost,
      cardCost: rentDetails?.cardCost,
    };
    const conditionWiseRentDetailsObj = rentDetailsObj(rentDetails);
    const finalRentDetailsObj = {
      ...rentDetailsCommon,
      ...conditionWiseRentDetailsObj,
    };
    const generalDetailsObj = {
      workersComp: values?.workersComp,
      genLiability: values?.genLiability,
      umbrella: values?.umbrella,
      termDays: values?.terminationDays,
      reloDays: values?.relocationDays,
      salesReportBy: values?.salesReportsBy,
      percentRateBy: values?.percentRentBy,
      fixedRentDue: values?.fixedRentDue,
      addendum: values?.addendum,
      securityDeposit: values?.securityDeposit,
      grossSales: values?.grossSales,
      OCCPercentage: values?.OCC,
    };

    const finalObj = {
      ...leaseObj,
      ...finalRentDetailsObj,
      ...generalDetailsObj,
    };
    AddNewLeaseApi(finalObj).then((res) => {
      if (res.data) {
        if (locationId && subLocationId) {
          navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}?locationId=${locationId}`, {
            replace: true,
          });
        } else {
          navigate(`/${routeConstants.LEASE_ROUTE}?selectedTab=1`, {
            replace: true,
          });
        }
      }
    });
  };

  const timeLineData = [
    {
      id: 1,
      heading: 'Lease Details',
      content: (
        <Box mt={4}>
          <LeaseDetailsForm onNextClick={onNextClickHandler} isUpdate={false} isLoading={false} />
        </Box>
      ),
    },
    {
      id: 2,
      heading: 'Rent Details',
      content: (
        <Box mt={4}>
          <RentDetailsForm onNextClick={onNextClickHandler} onBackClick={onBackClickHandler} />
        </Box>
      ),
    },
    {
      id: 3,
      heading: 'General Details',
      content: (
        <Box mt={4}>
          <GeneralDetailsForm onAddClick={onAddClickHandler} onBackClick={onBackClickHandler} isLoading={isLoading} />
        </Box>
      ),
    },
  ];
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box mb={3}>
        <Box>
          <Breadcrumbs
            variant="body2"
            underline="hover"
            data={locationId && subLocationId ? locationBreadcrumbData : leaseBreadcrumbData}
          />
        </Box>
        <Box>
          <Typography variant="h1">Add New Lease</Typography>
        </Box>
      </Box>
      <Box component={Paper}>
        <TimeLine data={timeLineData} currentIndex={currentIndex} />
      </Box>
    </Container>
  );
};

export default AddNewLease;
