import leaseFilterSlice from './leaseFilter-slice';
export const {
  setPendingLeaseFilters,
  setActiveLeaseFilters,
  setArchiveLeaseFilters,
  resetState,
  resetPendingLeaseFilters,
  resetActiveLeaseFilters,
  resetArchiveLeaseFilters,
} = leaseFilterSlice.actions;

export default leaseFilterSlice;
