import React from 'react';
import { REMIT_DETAILS_LABEL } from '../../../constants/accordionFields';
import { InformationPad } from '../../../components/shared';
import { mergedTwoPhoneNumber } from '../../../utils/common-methods';

const RemitDetailsAccordion = (props) => {
  const { subLocationInfo } = props;
  const fields = [
    {
      label: REMIT_DETAILS_LABEL.FIRST_NAME,
      value: subLocationInfo?.remitFirstName,
    },
    {
      label: REMIT_DETAILS_LABEL.LAST_NAME,
      value: subLocationInfo?.remitLastName,
    },

    {
      label: REMIT_DETAILS_LABEL.ADDRESS_1,
      value: subLocationInfo?.remitAddress1,
    },
    {
      label: REMIT_DETAILS_LABEL.ADDRESS_2,
      value: subLocationInfo?.remitAddress2,
    },
    {
      label: REMIT_DETAILS_LABEL.ZIPCODE,
      value: subLocationInfo?.remitZip,
    },
    {
      label: REMIT_DETAILS_LABEL.COUNTRY,
      value: subLocationInfo?.remitCountry?.name,
    },
    {
      label: REMIT_DETAILS_LABEL.STATE,
      value: subLocationInfo?.remitState?.name,
    },
    {
      label: REMIT_DETAILS_LABEL.CITY,
      value: subLocationInfo?.remitCity?.name,
    },
    {
      label: REMIT_DETAILS_LABEL.EMAIL_ADDRESS,
      value: subLocationInfo?.remitEmail,
    },
    {
      label: REMIT_DETAILS_LABEL.CONTACT_NUMBER,
      value: mergedTwoPhoneNumber(subLocationInfo?.remitPhone1, subLocationInfo?.remitPhone2),
    },
  ];
  return <InformationPad data={fields} />;
};

export default RemitDetailsAccordion;
