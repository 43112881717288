import { Box, Stack, Divider } from '@mui/material';
import { GridContainer, GridItem, InformationField } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import { getTransformedDataForSummary } from '../../../utils/collection-methods';
import SublocationSummaryField from './SublocationSummaryField';
import { useSelector } from 'react-redux';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';
import { getCurrency, roundOffValue } from '../../../utils/common-methods';

const SummaryModelBody = ({ show, data, sublocationNameMap, reportMachines, sublocationIds }) => {
  if (show) {
    const {
      collectionCollected,
      locationName,
      totalAssets,
      submissionDate,
      totalCollected,
      totalCollectedInUSD,
      sublocations,
      totalTicketDispensed,
      totalPrizeDispensed,
      totalMedallionDispensed,
      totalTokenCollected,
    } = data;
    const { playCards, playcardSalesSummary } = useSelector((state) => state.playCard);
    const { locationCurrency } = useSelector((state) => state.newCollection);
    const { abbreviation } = locationCurrency;
    const transformedSublocations = getTransformedDataForSummary(sublocationIds, sublocations);
    let numberOfAssetAdded = 0;
    transformedSublocations?.map((sub) => {
      numberOfAssetAdded = numberOfAssetAdded + (sub?.assets?.length || 0);
    });

    return (
      <Box>
        <InformationField value={locationName} variant="h3" />
        <GridContainer data-testid="info-pad" py={1} display="flex" justifyContent="flex-start">
          <GridItem xs={12} md={4}>
            <InformationField value={MESSAGE.SUBMISSION_DATE.toUpperCase()} variant="subtitle2" />
            <InformationField value={submissionDate} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} md={3}>
            <InformationField value={MESSAGE.ASSETS} variant="subtitle2" />
            <InformationField value={`${numberOfAssetAdded} out of ${totalAssets}`} variant="subtitle1" />
          </GridItem>
        </GridContainer>
        <SublocationSummaryField
          sublocations={transformedSublocations}
          sublocationNameMap={sublocationNameMap}
          reportMachines={reportMachines}
        />
        <Divider />
        <Stack pr={2} py={3} direction={'row'} display="flex" justifyContent="space-between">
          <InformationField value={MESSAGE.SUBLOCATIONS_COLLECTED} variant="subtitle2" />
          <InformationField value={collectionCollected} variant="subtitle2" />
        </Stack>
        {totalTicketDispensed > 0 && (
          <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
            <InformationField value={MESSAGE.COLLECTION_TOTAL_TICKET_DISPENSED} variant="subtitle2" />
            <InformationField value={totalTicketDispensed} variant="subtitle2" />
          </Stack>
        )}
        {totalPrizeDispensed > 0 && (
          <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
            <InformationField value={MESSAGE.COLLECTION_TOTAL_PRIZE_DISPENSED} variant="subtitle2" />
            <InformationField value={totalPrizeDispensed} variant="subtitle2" />
          </Stack>
        )}
        {totalMedallionDispensed > 0 && (
          <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
            <InformationField value={MESSAGE.COLLECTION_TOTAL_MEDALLION_DISPENSED} variant="subtitle2" />
            <InformationField value={totalMedallionDispensed} variant="subtitle2" />
          </Stack>
        )}
        {totalTokenCollected > 0 && (
          <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
            <InformationField value={MESSAGE.COLLECTION_TOTAL_TOKEN_COLLECTED} variant="subtitle2" />
            <InformationField value={totalTokenCollected} variant="subtitle2" />
          </Stack>
        )}
        {JSON.stringify(playCards).length > 2 && (
          <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
            <InformationField
              value={`${MESSAGE.PLAYCARD_SALES_SUMMARY} (${CURRENCY_CONSTANT.USD})`}
              variant="subtitle2"
            />
            <InformationField value={`$ ${roundOffValue(playcardSalesSummary)}`} variant="subtitle2" />
          </Stack>
        )}
        <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
          <InformationField
            value={`${MESSAGE.COLLECTION_TOTAL_COLLECTED_UPPERCASE} (${abbreviation || CURRENCY_CONSTANT.USD})`}
            variant="subtitle2"
          />
          <InformationField
            value={`${getCurrency(abbreviation)} ${roundOffValue(totalCollected)}`}
            variant="subtitle2"
          />
        </Stack>
        {abbreviation !== CURRENCY_CONSTANT.USD && (
          <Stack pr={2} pb={3} direction={'row'} display="flex" justifyContent="space-between">
            <InformationField
              value={`${MESSAGE.COLLECTION_TOTAL_COLLECTED_UPPERCASE} (${CURRENCY_CONSTANT.USD})`}
              variant="subtitle2"
            />
            <InformationField value={`$ ${roundOffValue(totalCollectedInUSD)}`} variant="subtitle2" />
          </Stack>
        )}
      </Box>
    );
  }
};

export default SummaryModelBody;
