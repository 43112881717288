import React, { useEffect, useState } from 'react';
import { GridItem } from '../../../components/shared';
import { FIELD_NAME, METER_TYPE } from '../../../constants/MeterTypeFieldMap';
import { getFieldName } from '../../../utils/collection-methods';
import {
  getCashMeterDifference,
  getCoinMeterDifference,
  getCreditMeterDifference,
  getReadingFieldValues,
  getTokenChangerDifference,
} from '../../../utils/reconciliation-childField-methods';
import { isValueValid } from '../../../utils/common-methods';
import { MESSAGE } from '../../../constants/message';
import { useFormikContext } from 'formik';
import { PerformantCollectionTextField } from './PerformantCollectionTextField';
import { RECONCILIATION_WARNING_COMPARING_VALUE } from '../../../constants/CommonConstants';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { setReconciliationWarningForModal } from '../../../redux-slice/reconciliationCollection';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';
import useStyles from './style';

const ChildField = ({
  assetId,
  sublocationId,
  meterName,
  meterId,
  assetMeterId,
  assetMeter,
  fields,
  reported,
  costPerPlay,
  clicksPerPlay,
  isReconciliation,
  isAddNewCollection = false,
  skip = true,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showWarning, setShowWarning] = useState({});
  const [warningMessage, setWarningMessage] = useState({});
  const { values, errors, touched } = useFormikContext();
  const isIndependentUser = useHasRoles([ROLE.INDEPENDENT_REPS]);
  const { locationCurrency: reconciliationLocationCurrency } = useSelector((state) => state.reconciliationCollection);
  const { locationCurrency } = useSelector((state) => state.newCollection);
  const { abbreviation, exchangeRate } = isReconciliation ? reconciliationLocationCurrency : locationCurrency;
  const isSuperAdminOrAccountingOrManagerUser = useHasRoles([
    ROLE.SUPER_ADMIN_IT,
    ROLE.MANAGER,
    ROLE.ACCOUNTING,
    ROLE.DM,
    ROLE.REGIONAL_MANAGER,
    ROLE.EXECS,
    ROLE.VP,
  ]);
  const updateWarningMessage = (changes) => {
    setWarningMessage((prev) => ({
      ...prev,
      ...changes,
    }));
  };

  const updateShowWarning = (changes) => {
    setShowWarning((prev) => ({
      ...prev,
      ...changes,
    }));
  };

  const updateCashMeterWarning = () => {
    const { difference, billsCollectedFieldName, coinsCollectedFieldName } = getCashMeterDifference(
      sublocationId,
      assetId,
      meterId,
      assetMeterId,
      values,
      exchangeRate,
    );
    const hasWarning = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
    updateShowWarning({ [billsCollectedFieldName]: hasWarning, [coinsCollectedFieldName]: hasWarning });
    if (hasWarning) {
      updateWarningMessage({
        [billsCollectedFieldName]: MESSAGE.CASH_METER_EXPECTED_REVENUE_ERROR,
        [coinsCollectedFieldName]: MESSAGE.CASH_METER_EXPECTED_REVENUE_ERROR,
      });
    }
  };

  const updateCurrentReadingWarning = () => {
    const {
      priorReading: currentMeterPriorReading,
      currentReading: currentMeterCurrentReading,
      currentReadingFieldName: currentMeterCurrentReadingFieldName,
    } = getReadingFieldValues(meterName, sublocationId, assetId, meterId, assetMeterId, values);
    const hasWarning =
      currentMeterCurrentReading !== '' && Number(currentMeterCurrentReading) < currentMeterPriorReading;
    updateShowWarning({ [currentMeterCurrentReadingFieldName]: hasWarning });
    if (hasWarning) {
      updateWarningMessage({ [currentMeterCurrentReadingFieldName]: MESSAGE.CURRENT_METER_ERROR });
    }
  };

  useEffect(() => {
    if (values) {
      fields.map((el) => {
        switch (el.name) {
          case FIELD_NAME.CURRENT_READING: {
            updateCurrentReadingWarning();
            break;
          }
          case FIELD_NAME.PRIOR_READING: {
            updateCurrentReadingWarning();
            break;
          }
          case FIELD_NAME.ESTIMATED_COIN_REVENUE: {
            // COIN meter warning
            if (isReconciliation) {
              const { difference, coinCollectedFieldName } = getCoinMeterDifference(
                sublocationId,
                assetId,
                meterId,
                assetMeterId,
                values,
                exchangeRate,
              );
              const hasWarning = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
              updateShowWarning({ [coinCollectedFieldName]: hasWarning });
              if (hasWarning) {
                updateWarningMessage({ [coinCollectedFieldName]: MESSAGE.COIN_ESTIMATED_COIN_REVENUE_ERROR });
              }
            }
            break;
          }
          case FIELD_NAME.EXPECTED_REVENUE: {
            if (isReconciliation && el.label.includes('CC')) {
              // CREDIT meter warning
              const { difference, estimatedCreditCardRevenueFieldName } = getCreditMeterDifference(
                sublocationId,
                assetId,
                meterId,
                assetMeterId,
                values,
              );
              const hasWarning = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
              updateShowWarning({ [estimatedCreditCardRevenueFieldName]: hasWarning });
              if (hasWarning) {
                updateWarningMessage({
                  [estimatedCreditCardRevenueFieldName]: MESSAGE.CREDIT_METER_ESTIMATED_REVENUE_ERROR,
                });
              }
            }
            break;
          }
          case FIELD_NAME.BILLS_COLLECTED: {
            if (isReconciliation) {
              //Cash Meter warning
              updateCashMeterWarning();
            }
            break;
          }
          case FIELD_NAME.COINS_COLLECTED: {
            if (isReconciliation) {
              updateCashMeterWarning();
            }
            break;
          }
          case FIELD_NAME.SEEDLIVE_REVENUE: {
            if (isReconciliation) {
              const creditCardRevenueFieldName = getFieldName(
                METER_TYPE.CREDIT,
                FIELD_NAME.SEEDLIVE_REVENUE,
                sublocationId,
                assetId,
                meterId,
                assetMeterId,
              );
              const creditCardRevenue = values[creditCardRevenueFieldName];
              const hasWarning = !isValueValid(creditCardRevenue) || creditCardRevenue === 0;
              updateShowWarning({ [creditCardRevenueFieldName]: hasWarning });
              if (hasWarning) {
                updateWarningMessage({
                  [creditCardRevenueFieldName]: MESSAGE.SEEDLIVE_VALUE_ERROR,
                });
              }
            }
            break;
          }
          case FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER: {
            if (isReconciliation) {
              // Token changer meter warning
              const { difference, tokenChangerFieldName } = getTokenChangerDifference(
                sublocationId,
                assetId,
                meterId,
                assetMeterId,
                values,
                exchangeRate,
              );
              const hasWarning = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
              updateShowWarning({ [tokenChangerFieldName]: hasWarning });
              if (hasWarning) {
                updateWarningMessage({
                  [tokenChangerFieldName]: MESSAGE.TOKEN_CHANGER_METER_EXPECTED_REVENUE_ERROR,
                });
              }
            }
            break;
          }
          // no default really required here
        }
      });
    }
  }, [values]);

  useEffect(() => {
    if (isReconciliation && !skip && meterName === METER_TYPE.CREDIT) {
      const { difference, estimatedCreditCardRevenueFieldName } = getCreditMeterDifference(
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
        values,
      );
      const hasWarning = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
      updateShowWarning({ [estimatedCreditCardRevenueFieldName]: hasWarning });
      if (hasWarning) {
        updateWarningMessage({
          [estimatedCreditCardRevenueFieldName]: MESSAGE.CREDIT_METER_ESTIMATED_REVENUE_ERROR,
        });
      }
    }
  }, [skip]);

  useEffect(() => {
    if (isReconciliation) {
      const tempObj = {};
      for (const key in showWarning) {
        if (key.includes(FIELD_NAME.CURRENT_READING)) {
          tempObj[key] = showWarning[key];
        } else if (
          key.includes(METER_TYPE.CASH) &&
          (key.includes(FIELD_NAME.BILLS_COLLECTED) || key.includes(FIELD_NAME.COINS_COLLECTED))
        ) {
          tempObj[key] = showWarning[key];
        } else if (key.includes(METER_TYPE.COIN) && key.includes(FIELD_NAME.COINS_COLLECTED)) {
          tempObj[key] = showWarning[key];
        } else if (key.includes(METER_TYPE.CREDIT) && key.includes(FIELD_NAME.EXPECTED_REVENUE)) {
          tempObj[key] = showWarning[key];
        } else if (
          key.includes(METER_TYPE.TOKEN_CHANGER) &&
          key.includes(FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER)
        ) {
          tempObj[key] = showWarning[key];
        }
      }
      dispatch(setReconciliationWarningForModal(tempObj));
    }
  }, [showWarning]);

  const error = (fieldName) => {
    if (showWarning[fieldName]) {
      return true;
    }

    return !reported && values && touched && errors && touched[fieldName] && errors[fieldName] ? true : false;
  };

  if (fields && fields.length > 0) {
    return fields.map((el, index) => {
      const fieldName = getFieldName(meterName, el.name, sublocationId, assetId, meterId, assetMeterId);
      const isDisabled = isReconciliation && fieldName.includes(FIELD_NAME.PRIOR_READING) ? false : el.disabled;
      if (
        isAddNewCollection &&
        isIndependentUser &&
        !isSuperAdminOrAccountingOrManagerUser &&
        el.name === FIELD_NAME.PRIOR_READING
      ) {
        return;
      }
      if (abbreviation === CURRENCY_CONSTANT.USD && el.name === FIELD_NAME.REVENUE_IN_USD) {
        return;
      }
      const labelAdjustment = [
        'Coins/ Quarter Collected',
        'Bills taken from changer',
        'Expected Cash Revenue',
      ].includes(String(el?.label));
      return (
        <GridItem
          xs={12}
          sm={3}
          md={5}
          lg={3}
          p={1}
          key={index}
          sx={{ paddingRight: '2px', paddingLeft: '2px' }}
          className={labelAdjustment ? classes.labelClass : ''}
        >
          <PerformantCollectionTextField
            key={index}
            type="number"
            label={el.label}
            currencyType={el.currencyType}
            name={fieldName}
            priceIcon={el.priceIcon}
            showWarning={showWarning[fieldName]}
            warningMessage={warningMessage[fieldName]}
            assetMeter={assetMeter}
            isReconciliation={isReconciliation}
            costPerPlay={costPerPlay}
            clicksPerPlay={clicksPerPlay}
            // Return boolean for error to avoid react report error
            customizedError={error(fieldName)}
            disabled={isDisabled}
          />
        </GridItem>
      );
    });
  }
};

export default ChildField;
