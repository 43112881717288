import { createSlice } from '@reduxjs/toolkit';
import { isArrayWithLength } from '../../utils/common-methods';

const initialState = {
  reconciliationCollections: {},
  draftSublocationDatesOfReconciliation: {},
};

export const storedReconciliationCollectionsSlice = createSlice({
  name: 'storedReconciliationCollections',
  initialState,
  reducers: {
    setReconciliationCollection(state, action) {
      const {
        locationId,
        formData,
        exchangeRate,
        playcards,
        intercardData,
        locationName,
        accountSummary,
        collectionType,
        transactionId,
        status,
      } = action.payload;
      return {
        ...state,
        reconciliationCollections: {
          ...state.reconciliationCollections,
          [locationId]: {
            ...formData,
            collectionType: collectionType,
            exchangeRate: exchangeRate,
            playcards: playcards,
            intercardData: intercardData,
            locationName: locationName,
            accountSummary: accountSummary,
            transactionId: transactionId,
            status: status,
          },
        },
      };
    },
    setDraftSublocationDatesInReconciliation(state, action) {
      state.draftSublocationDatesOfReconciliation = {
        ...state.draftSublocationDatesOfReconciliation,
        ...action.payload,
      };
    },
    resetCollectionByLocationIdInReconciliation(state, action) {
      const locationId = action.payload;
      if (locationId && state?.reconciliationCollections[locationId]) {
        delete state.reconciliationCollections[locationId];
      }
    },
    reduceDraftDatesBySublocationIdsInReconciliation(state, action) {
      const sublocationIds = action.payload;
      if (isArrayWithLength(sublocationIds)) {
        sublocationIds.forEach((id) => {
          if (state.draftSublocationDatesOfReconciliation[id]) delete state.draftSublocationDatesOfReconciliation[id];
        });
      }
    },
    resetStateOfReconciliation(state) {
      Object.assign(state, initialState);
    },
  },
});

export default storedReconciliationCollectionsSlice;
