import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  assignLocationTableContainer: {
    '& td[class*=MuiTableCell-body]': {
      '&:first-of-type': {
        padding: '10px 0!important',
      },
      '&:nth-child(2)': {
        color: theme.palette.common.black,
      },
    },
    '& tr[class*=makeStyles-accordionBody]': {
      '& >td': {
        border: `1px solid ${palette.grey[900]}`,
      },
      '& td': {
        background: `${alpha(palette.grey[100_1], 0.2)}`,
      },
    },
  },
  renderSubComponentWrapper: {
    '& td[class*=MuiTableCell-body]': {
      '&:first-of-type': {
        borderBottom: 'none !important',
      },
    },
    '& div[class*=MuiTableContainer-root]': {
      boxShadow: 'none !important',
    },
  },
  userNameContentWrap: {
    display: 'flex',
    marginTop: 34,
    alignItems: 'center',
  },
  accordionHeaderWrap: {
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.125)}`,
    width: '100%',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  editMenuListWrap: {
    position: 'absolute',
    top: 25,
    left: -185,
    zIndex: 1,
  },
  editMenuList: {
    minWidth: 206,
  },
  addBtn: {
    padding: '8px 24px 8px 0px',
    '& .MuiButtonBase-root': {
      whiteSpace: 'nowrap',
    },
    '& button': {
      maxWidth: '100%',
    },
  },
  accordionHeaderInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  noLinkUnderline: {
    li: {
      a: {
        textDecoration: 'none',
      },
    },
  },
  headingWrapper: {
    marginBottom: '20px',
  },
  checkBoxWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
    '&.css-faawzk-MuiFormControlLabel-root.Mui-disabled': {
      margin: 0,
    },
  },
  radioGrpWrapper: {
    justifyContent: 'space-between',
  },
  emailText: {
    wordBreak: 'break-all',
  },
  infoBtnWrapper: {
    paddingTop: '30px',
  },
  userAssignedLocWrap: {
    marginTop: 24,
  },
  userAssignedLocHead: {
    padding: '24px 24px 0 24px',
  },
  userAssignedLocTable: {
    '& .MuiTableContainer-root': {
      marginTop: '24px',
      '& td[class*=MuiTableCell-body]': {
        '&:nth-child(2)': {
          color: theme.palette.common.black,
        },
      },
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      margin: '0 14px',
    },
    marginTop: '20px',
  },
  userSubLocationWrap: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.125)}`,
    borderRadius: 8,
    '& tr': {
      '& td[class*=MuiTableCell-body]': {
        '&:nth-child(1)': {
          color: theme.palette.common.black,
        },
        '&:nth-child(2)': {
          color: `${theme.palette.secondary.main} !important`,
        },
        '&:first-of-type': {
          padding: '20px 24px!important',
          borderBottom: 'none !important',
        },
      },
    },
    '& .MuiPaper-root': {
      marginTop: 0,
    },
  },
  userSubLocationInfo: {
    padding: '24px 0px',
  },
  userSubLocationTableWrap: {
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.125)}`,
  },
  assignSubLocText: {
    padding: '24px 24px 0px 24px',
  },
}));

export default useStyles;
