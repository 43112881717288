import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    '& .MuiPaper-root': {
      '&.MuiAccordion-root': {
        boxShadow: 'none',
        borderBottom: '1px solid #E6E8EF',
        '&.Mui-expanded': {
          boxShadow: `0px 1px 4px ${theme.palette.grey[400_12]}`,
          borderBottom: 'none',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          width: '32px',
          height: '32px',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'rotate(0deg)',
          transition: 'unset',
          color: theme.palette.secondary.main,
          '&.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        },
      },
      '& .MuiAccordionSummary-root': {
        padding: '20px 24px 20px 20px',
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '16px 24px 28px',
      '& .MuiGrid2-root': {
        padding: 0,
      },
      '& .MuiGrid2-container': {
        marginBottom: '16px',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  breadcrumbContainer: {
    margin: '15px 0px',
  },
  subLocationOwnerWrapper: {
    '& .MuiAccordionSummary-content': {
      margin: '20px 0px',
    },
  },
  subLocAssignedMachineWrap: {
    marginTop: 24,
  },
  subLocAssignedMachineHead: {
    padding: '24px 24px 0 24px',
  },
  subLocAssignedMachineTable: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      margin: '0 24px',
    },
    '& .MuiTableCell-body': {
      padding: '12px 24px',
      '&:first-of-type': {
        width: '56px',
        paddingRight: 0,
        '& > span': {
          height: '32px',
          width: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      '&:last-child': {
        paddingRight: '32px',
        paddingLeft: 0,
        width: '64px',
      },
    },
  },
  assignNewMachineBox: {
    '& .MuiTableContainer-root': {
      marginTop: '26px',
    },
    '& .MuiTableCell-body': {
      padding: '12px 24px',
      '&:first-of-type': {
        width: '56px',
        paddingRight: 0,
        paddingLeft: '28px',
        '& > span': {
          height: '32px',
          width: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
      '& .MuiCheckbox-root': {
        padding: '0',
      },
    },
  },
  machineIcon: {
    width: '2.5em',
    height: '2.5em',
    color: theme?.palette?.primary?.main,
  },
  assignNewMachineTitle: {
    padding: '31px 24px 16px',
    '& .MuiTypography-h2': {
      lineHeight: '32px',
    },
  },
  machineSearch: {
    padding: '0 24px',
    '& .MuiSvgIcon-root': { color: theme?.palette?.primary?.main },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.primary?.main,
      borderRadius: 8,
    },
  },
  btnWrap: { padding: '52px 24px 40px 24px' },
  addMachineTable: {
    '& .MuiTableContainer-root': {
      marginTop: '26px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.grey[100],
      borderRadius: '8px',
    },
    '& .MuiTableCell-root MuiTableCell-head': { width: '50%' },
    '& .MuiTableCell-head': { whiteSpace: 'noWrap' },
  },
  machineTableHeading: {
    padding: '31px 24px 16px 24px',
    '& .MuiTypography-h2': {
      lineHeight: '32px',
    },
  },
  addBtn: {
    paddingRight: '24px',
    flex: '0 0 auto',
  },
  machineSearchIcon: { color: `${theme.palette.primary.main} !important` },
  subLocDetailNoContent: {
    '& .svgIcon': {
      width: '72px',
      height: '72px',
      fill: theme?.palette?.primary?.main,
    },
  },
  assignLeaseTableWrapper: {
    '& div[class*=makeStyles-searchWrapper]': {
      padding: '0 24px',
    },
    '& td[class*=MuiTableCell-body]': {
      '&:nth-child(3)': {
        color: theme.palette.common.black,
      },
    },
  },

  leaseAccordion: {
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
    padding: '24px 70px 18px',
    '& .MuiTypography-h3': {
      lineHeight: '24px',
    },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': {
        padding: '0',
      },
    },
  },
  leaseAccordionBtnWrap: {
    marginTop: '38px',
  },
}));

export default useStyles;
