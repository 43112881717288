import React, { useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { GridContainer, GridItem, Tabs } from '../../../components/shared';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import { ApprovedDeposit, OpenDeposit, Reconciliation } from '../index';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';

const Deposit = () => {
  const isIndependentRep = useHasRoles([ROLE.INDEPENDENT_REPS]);
  const canViewReconciliationAndApproveRoles = useHasRoles([
    ROLE.SUPER_ADMIN_IT,
    ROLE.MANAGER,
    ROLE.ACCOUNTING,
    ROLE.DM,
    ROLE.REGIONAL_MANAGER,
    ROLE.EXECS,
    ROLE.VP,
  ]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('selectedTab');

  const TABS = useMemo(() => {
    if (canViewReconciliationAndApproveRoles) {
      return [
        {
          index: '1',
          heading: TAB_TYPES.OPEN_DEPOSIT,
          content: <OpenDeposit />,
        },
        {
          index: '2',
          heading: TAB_TYPES.RECONCILIATION,
          content: <Reconciliation />,
        },
        {
          index: '3',
          heading: TAB_TYPES.APPROVED,
          content: <ApprovedDeposit />,
        },
      ];
    }
    if (isIndependentRep) {
      return [
        {
          index: '1',
          heading: TAB_TYPES.OPEN_DEPOSIT,
          content: <OpenDeposit />,
        },
      ];
    }
  }, [isIndependentRep]);

  const onTabChange = (newValue) => {
    navigate(`/${routeConstants.DEPOSIT_ROUTE}?selectedTab=${newValue}`, {
      replace: true,
    });
  };

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} display="flex" alignItems="center">
          <Typography variant="h1">Deposit</Typography>
        </GridItem>
      </GridContainer>
      <Box mt={2} component={Paper}>
        <Tabs defaultTab={selectedTab === null ? '1' : selectedTab} tabsData={TABS} onTabChange={onTabChange} />
      </Box>
    </React.Fragment>
  );
};

export default Deposit;
