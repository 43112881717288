import { createSlice } from '@reduxjs/toolkit';
import { checkNumberIsNaNAndInfinity, roundOffValue } from '../../utils/common-methods';
import { getRevenueInUSD } from '../../utils/collection-methods';

const initialState = {
  totalRevenue: 0,
  totalRevenueInUSD: 0,
  totalTicketDispensed: 0,
  totalTokenDispensed: 0,
  totalTokenCollected: 0,
  totalPrizeDispensed: 0,
  averageTokenValueInUSD: 0,
  totalTokenChangerRevenueCollected: 0,
  locationCurrency: {},
  transformedSublocations: [],
  revenueMeterData: [],
  formikData: [],
  subTotalRevenues: [],
  reconciliationWarningForModal: {},
  taxExemptFlag: false,
  locationPaysTax: false,
  readFromDraftAccountSummary: false,
  accountSummary: {
    searchState: false,
    data: [],
    errorState: {},
    draftFlagState: {},
    defaultAccountSummaryState: false,
    storedSummary: {},
  },
  intercardDataForReconciliation: {},
  sublocationDatesForReconciliation: {},
  updateStatesForReconciliationSublocationDatesChange: {},
  accountSummaryFormDirty: false,
};

export const reconciliationCollectionSlice = createSlice({
  name: 'reconciliationCollection',
  initialState,
  reducers: {
    setSubTotalRevenues(state, action) {
      const subTotals = action.payload;
      state.subTotalRevenues = subTotals;
      let total = subTotals?.total || 0;
      let totalInUSD = subTotals?.totalInUSD || 0;
      subTotals?.map((data) => {
        total += checkNumberIsNaNAndInfinity(data?.subtotalRevenue);
        totalInUSD += checkNumberIsNaNAndInfinity(data?.subtotalRevenueInUSD);
      });
      state.totalRevenue = roundOffValue(total);
      state.totalRevenueInUSD = roundOffValue(totalInUSD);
    },
    setTotalTicketDispensed(state, action) {
      state.totalTicketDispensed = action.payload;
    },
    setTotalTokenCollected(state, action) {
      state.totalTokenCollected = action.payload;
    },
    setTotalTokenChangerRevenueValue(state, action) {
      state.totalTokenChangerRevenueCollected = action.payload;
      const totalTokenChangerRevenueCollected = getRevenueInUSD(action.payload, state.locationCurrency?.exchangeRate);

      state.averageTokenValueInUSD = roundOffValue(totalTokenChangerRevenueCollected / state.totalTokenCollected, 4);
    },
    setTotalPrizeDispensed(state, action) {
      state.totalPrizeDispensed = action.payload;
    },
    setFormikData(state, action) {
      state.formikData = action.payload;
    },
    setTransformedSublocations(state, action) {
      state.transformedSublocations = action.payload;
    },
    setRevenueMeterData(state, action) {
      state.revenueMeterData = action.payload;
    },
    setReconciliationWarningForModal(state, action) {
      state.reconciliationWarningForModal = { ...state.reconciliationWarningForModal, ...action.payload };
    },
    setLocationCurrency(state, action) {
      state.locationCurrency = action.payload;
    },
    setAccountSummaryData(state, action) {
      state.accountSummary.data = {
        ...state.accountSummary.data,
        ...action.payload,
      };
    },
    setStoredAccountSummaryData(state, action) {
      state.accountSummary.storedSummary = {
        ...state.accountSummary.storedSummary,
        ...action.payload,
      };
    },
    setReadFromDraftAccountSummary(state, action) {
      state.accountSummary.draftFlagState = { ...state.accountSummary.draftFlagState, ...action.payload };
    },
    setAccountSummaryError(state, action) {
      state.accountSummary.errorState = { ...state.accountSummary.errorState, ...action.payload };
    },
    setTaxExempt(state, action) {
      state.taxExemptFlag = action.payload;
    },
    setLocationPaysTax(state, action) {
      state.locationPaysTax = { ...state.locationPaysTax, ...action.payload };
    },
    setSublocationDatesForReconciliation(state, action) {
      state.sublocationDatesForReconciliation = { ...state.sublocationDatesForReconciliation, ...action.payload };
    },
    setUpdateStatesForReconciliationSublocationDatesChange(state, action) {
      state.updateStatesForReconciliationSublocationDatesChange = action.payload;
    },
    setIntercardDataForReconciliation(state, action) {
      state.intercardDataForReconciliation = action.payload;
    },
    setAccountSummarySearchState(state, action) {
      state.accountSummary.searchState = action.payload;
    },
    setAccountSummaryFormDirty(state, action) {
      state.accountSummaryFormDirty = action.payload;
    },
    setTotalTokenDispensed(state, action) {
      state.totalTokenDispensed = action.payload;
    },
    setDefaultAccountSummaryState(state, action) {
      state.accountSummary.defaultAccountSummaryState = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default reconciliationCollectionSlice;
