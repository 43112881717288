import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  filterName: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  iconBtn: {
    '& .MuiButton-startIcon': {
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
  },
  filterContainer: {
    '& .MuiIconButton-root': {
      padding: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.375rem',
      },
    },
  },
  filterContainerList: {
    padding: '0 28px 0 24px',
  },
  filterBtnWrap: {
    paddingTop: '36px',
  },
}));

export default useStyles;
