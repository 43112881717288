import { useMemo, useCallback, useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useGetUsersQuery } from '../../../services/Users/UsersService';
import ReactTable from '../../../components/shared/ReactTable/ReactTable';
import { USER_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { Link, NoContent, ControlledTooltips } from '../../../components/shared';
import { getControlledTooltipValueAndCellValue, isArrayWithLength } from '../../../utils/common-methods';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { setActiveUsersFilters, setArchiveUsersFilters } from '../../../redux-slice/usersFilters';

const UsersTable = ({ searchParams, onTotalUser, activeUsers, archiveUsers, tab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const [currentPage, setCurrentPage] = useState(tab === 'active' ? activeUsers?.pageNumber : archiveUsers?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(
    tab === 'active' ? activeUsers?.rowsPerPage : archiveUsers?.rowsPerPage,
  );
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);

  const columns = useMemo(() => {
    const dynamicColumns = USER_TABLE_DYNAMIC(isBelowMD);
    const col = dynamicColumns;
    return col;
  }, [isBelowMD]);

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
    isFetching,
  } = useGetUsersQuery(
    { searchParams: searchFilterData, page: currentPage, perPage: perPageNumber },
    { skip: skipCall },
  );

  const viewLink = useCallback(
    (id, name, email) => (
      <Link underline={'none'} to={`/user/${id}`}>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.common.black}>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ display: { md: 'none' } }}>
            {email}
          </Typography>
        </Box>
      </Link>
    ),
    [],
  );

  const viewRole = useCallback((role) => {
    const roles = role ? role.split(', ') : [];
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(roles);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  const viewLocation = useCallback((location) => {
    const locations = isArrayWithLength(location)
      ? location
          .map((item) => item.sublocations)
          .reduce((prevValue, currentValue) => prevValue.concat(currentValue), [])
          .map((item) => item.name)
      : [];
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(locations);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  useEffect(() => {
    if (isSuccess && latestData) {
      const latestUsers = [];
      Object.entries(latestData.users).forEach((el) => latestUsers.push(el[1]));
      const users = latestUsers.map((user) => {
        const transformUser = { ...user };
        transformUser.roles = viewRole(user.roles);
        transformUser.location = viewLocation(user.locations);
        transformUser.name = viewLink(user.id, `${user.firstName} ${user.lastName}`, user.email);
        return transformUser;
      });
      setTableData(users);
      let total = latestData?.pagination?.total || 0;
      setTotalCount(total);
      onTotalUser(total ?? totalCount);
    } else if (isError) {
      onTotalUser(0);
    }
  }, [isError, isSuccess, latestData]);

  const pageChangeHandler = (currentPage) => {
    dispatch(
      tab === 'active'
        ? setActiveUsersFilters({ ...activeUsers, pageNumber: currentPage, rowsPerPage: perPageNumber })
        : setArchiveUsersFilters({ ...archiveUsers, pageNumber: currentPage, rowsPerPage: perPageNumber }),
    );
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(
      tab === 'active'
        ? setActiveUsersFilters({ ...activeUsers, rowsPerPage: newPerPage, pageNumber: currentPage })
        : setArchiveUsersFilters({ ...archiveUsers, rowsPerPage: newPerPage, pageNumber: currentPage }),
    );
    setPerPageNumber(newPerPage);
  };

  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(tab === 'active' ? activeUsers?.pageNumber : archiveUsers?.pageNumber);
    setPerPageNumber(tab === 'active' ? activeUsers?.rowsPerPage : archiveUsers?.rowsPerPage);
    setSkipCall(false);
  }, [searchParams]);

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Users Found"
          desc={(isError && error?.data?.message) || <>There are no user present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          maxWidth="xl"
        />
      )}
    </>
  );
};

export default UsersTable;
