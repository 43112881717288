import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { ReactTable, Link, NoContent, ControlledTooltips } from '../../../components/shared';

import { COLUMNS, LEASE_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { routeConstants } from '../../../constants/routeConstants';
import { getControlledTooltipValueAndCellValue, getDateFormat } from '../../../utils/common-methods';
import LeaseAccordion from './LeaseAccordion';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { setLeasesAction } from '../../../redux-slice/lease';
import { useGetLeasesQuery } from '../../../services/Lease/LeaseService';
import {
  setActiveLeaseFilters,
  setArchiveLeaseFilters,
  setPendingLeaseFilters,
} from '../../../redux-slice/leaseFilters';

const LeaseTable = (props) => {
  const { isArchiveTab = false, activeLease, archiveLease, pendingLease, tab } = props;
  const { searchApiParams, leases } = useSelector((state) => state?.lease);
  const [searchFilterData, setSearchFilterData] = useState(searchApiParams);

  const [currentPage, setCurrentPage] = useState(
    tab === 'active'
      ? activeLease?.pageNumber
      : tab === 'archive'
      ? archiveLease?.pageNumber
      : pendingLease?.pageNumber,
  );
  const [perPageNumber, setPerPageNumber] = useState(
    tab === 'active'
      ? activeLease?.rowsPerPage
      : tab === 'archive'
      ? archiveLease?.rowsPerPage
      : pendingLease?.rowsPerPage,
  );

  const { data, pagination, isError } = leases;
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));

  const {
    currentData: leaseData = {},
    isLoading: leaseLoading,
    isError: leaseError,
    isFetching: leaseFetching,
  } = useGetLeasesQuery({
    searchParams: searchFilterData,
    page: currentPage,
    perPage: perPageNumber,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setLeasesAction({
        data: leaseData?.data || [],
        pagination: leaseData?.pagination || {},
        isLoading: leaseLoading,
        isError: leaseError,
        isFetching: leaseFetching,
      }),
    );
  }, [searchApiParams, JSON.stringify(leaseData), leaseLoading, leaseError]);

  const columns = useMemo(() => {
    const dynamicColumns = LEASE_TABLE_DYNAMIC(isBelowMD, isArchiveTab);
    const serializedActiveColumn = [COLUMNS.EXPAND_ICON_COLUMN, ...dynamicColumns];
    const serializedArchiveColumn = [COLUMNS.EXPAND_ICON_COLUMN, ...dynamicColumns, COLUMNS.HISTORY_ICON_COLUMN];
    const col = isArchiveTab ? serializedArchiveColumn : serializedActiveColumn;
    return col;
  }, [isBelowMD]);

  const locationLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`/${routeConstants.LEASE_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );

  const autoCapitalizedStatus = (status) => {
    switch (status) {
      case 'QUOTE':
        return 'Quote';
      case 'MANAGER_REVIEW':
        return 'Manager Review';
      case 'FOLLOW_UP':
        return 'Follow Up';
      case 'ONGOING':
        return 'Ongoing';
      case 'REJECT_LEASE':
        return 'Rejected';
      case 'EXPIRED':
        return 'Expired';
      case 'TERMINATE':
        return 'Terminated';
      default:
        return 'N/A';
    }
  };
  const viewProductTypes = useCallback((productTypeNames) => {
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(productTypeNames);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  const transformedData = useMemo(() => {
    const tableBodyTransformed = data?.map((value) => ({
      id: value?.id,
      name: locationLink(value?.id, value?.name),
      location: value.location,
      status: autoCapitalizedStatus(value?.processStatus),
      startDate: value?.termStartDate ? getDateFormat(value?.termStartDate) : 'N/A',
      endDate: value?.termEndDate ? getDateFormat(value?.termEndDate) : 'N/A',
      archivedDate: value?.deletedAt ? getDateFormat(value?.deletedAt) : 'N/A',
      sublocation: value?.contractSublocations,
      locationAddress1: value?.location?.address1,
      locationAddress2: value?.location?.address2,
      zipcode: value?.location?.postcode,
      city: value?.location?.city,
      state: value?.location?.state,
      country: value?.location?.country,
      rentType: value?.rentType || 'N/A',
      fee: value?.fee || 'N/A',
      rate: value?.rate || 'N/A',
      productTypes: value?.productTypes ? viewProductTypes(value?.productTypes) : 'N/A',
    }));
    return tableBodyTransformed;
  }, [leases, isError]);

  const pageChangeHandler = (currentPage) => {
    if (tab === 'active') {
      dispatch(setActiveLeaseFilters({ ...activeLease, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    } else if (tab === 'archive') {
      dispatch(setArchiveLeaseFilters({ ...archiveLease, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    } else if (tab === 'pending') {
      dispatch(setPendingLeaseFilters({ ...pendingLease, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    }
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    if (tab === 'active') {
      dispatch(setActiveLeaseFilters({ ...activeLease, rowsPerPage: newPerPage, pageNumber: currentPage }));
    } else if (tab === 'archive') {
      dispatch(setArchiveLeaseFilters({ ...archiveLease, rowsPerPage: newPerPage, pageNumber: currentPage }));
    } else if (tab === 'pending') {
      dispatch(setPendingLeaseFilters({ ...pendingLease, rowsPerPage: newPerPage, pageNumber: currentPage }));
    }
    setPerPageNumber(newPerPage);
  };

  useEffect(() => {
    setCurrentPage(
      tab === 'active'
        ? activeLease?.pageNumber
        : tab === 'archive'
        ? archiveLease?.pageNumber
        : pendingLease?.pageNumber,
    );
    setPerPageNumber(
      tab === 'active'
        ? activeLease?.rowsPerPage
        : tab === 'archive'
        ? archiveLease?.rowsPerPage
        : pendingLease?.rowsPerPage,
    );
    setSearchFilterData(searchApiParams);
  }, [searchApiParams]);

  const detailLocationComponent = ({ row }) => {
    const original = row?.original;
    return original && <LeaseAccordion leaseInfo={original} />;
  };

  return (
    <>
      {(leaseLoading || leaseFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent title="No Lease Found" desc={<>No results found, please try changing the search criteria.</>} />
      )}

      {!leaseLoading && !leaseError && !leaseFetching && transformedData.length > 0 && (
        <Box>
          <ReactTable
            data={transformedData}
            columns={columns}
            pagination
            manualPagination
            totalCount={pagination?.total}
            pageNumberForTablePagination={currentPage - 1}
            onPageChange={pageChangeHandler}
            onPerPageChange={perPageChangeHandler}
            perPage={perPageNumber}
            expandable
            renderRowSubComponent={detailLocationComponent}
          />
        </Box>
      )}
    </>
  );
};

export default LeaseTable;
