import { Box, Stack, Typography } from '@mui/material';
import {
  Breadcrumbs,
  Accordion,
  PositionedMenu,
  NoContent,
  GridItem,
  Button,
  GridContainer,
} from '../../../components/shared';
import { NavigateNext, FactoryOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useGetModemDetailsByIdQuery } from '../../../services/Modems/ModemsService';
import React, { useState } from 'react';
import ModemDetailAccordion from './ModemDetailAccordion';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import AssignNewAsset from './AssignNewAsset';
import AssetDetails from './AssetDetails';
import { MESSAGE } from '../../../constants/message';
import ConfirmationModal from '../../../components/ConfirmationModel';
import { useDeleteModemByIdMutation } from '../../../services/Modems/ModemsService';

const ModemDetails = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [deleteModemApi] = useDeleteModemByIdMutation();
  const [isAssignNewMachine, setIsAssignNewMachine] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    MODEM: { MODEM, MODEM_DETAIL },
  } = BREADCRUMB_NAMES;

  const { isLoading, isFetching, currentData: currentModemDetails } = useGetModemDetailsByIdQuery(Number(params.id));

  const breadcrumbData = [{ text: MODEM, redirection: 'modems' }, { text: MODEM_DETAIL }];

  const { data: modemDetailsResponse } = currentModemDetails ?? {};

  const onDeleteModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      deleteHandler();
    } else {
      setShowDeleteModal(false);
    }
  };

  const deleteHandler = () => {
    deleteModemApi(params.id).then((response) => {
      if (response.data) {
        setShowDeleteModal(false);
        setTimeout(() => {
          navigate(`/${routeConstants.MODEMS_ROUTE}`, {
            redirect: true,
          });
        }, 1000);
      }
    });
  };

  return (
    <React.Fragment>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>
      <Box mb={4} mt={4}>
        <Stack direction={'row'} spacing={2} alignItems="center">
          <Typography variant="h1" data-testid="modemTitle">
            {`${modemDetailsResponse?.type || ''} Modem` || 'N/A'}
          </Typography>
        </Stack>
      </Box>
      <Box mb={3} className={classes.machineDetailContent}>
        <Accordion
          defaultExpanded
          marginBottom={3}
          title={'Modem Details'}
          content={
            isLoading || isFetching ? (
              <LoadingSpinner containerHeight={400} />
            ) : (
              <ModemDetailAccordion modemInfo={modemDetailsResponse} />
            )
          }
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: () =>
                    navigate(
                      `/${routeConstants.MODEMS_ROUTE}/${routeConstants.EDIT_MODEM_ROUTE}/${modemDetailsResponse.id}`,
                      {
                        redirect: true,
                      },
                    ),
                },
              ]}
            />
          }
        />
      </Box>
      <Box mb={3} className={classes.modemDetailContent}>
        {modemDetailsResponse?.asset && !isAssignNewMachine && (
          <AssetDetails
            assetId={modemDetailsResponse?.assetId}
            setAssignNewMachine={setIsAssignNewMachine}
            modemId={modemDetailsResponse?.id}
          />
        )}
        {!modemDetailsResponse?.asset && !isAssignNewMachine && (
          <NoContent
            icon={<FactoryOutlined className={classes.machineIcon} />}
            title="No Machine Associated"
            desc={
              <>
                There is no machine associated to this modem.
                <br />
                Please add new machine
              </>
            }
            buttonName="Assign New"
            onBtnClickHandler={() => {
              setIsAssignNewMachine(true);
            }}
          />
        )}
        {isAssignNewMachine && (
          <AssignNewAsset setAssignNewMachine={setIsAssignNewMachine} modemId={modemDetailsResponse?.id} />
        )}
      </Box>
      <GridContainer
        data-testid="info-pad"
        display="flex"
        justifyContent="flex-start"
        alignItems="baseline"
        columnSpacing={1.5}
        rowSpacing={1}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          display="flex"
          sx={{
            minWidth: '133px',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-end',
            },
          }}
        >
          <Button
            color={'error'}
            onClick={() => {
              setShowDeleteModal(true);
            }}
            data-testid="delete-modem"
          >
            {MESSAGE.DELETE_MODEM}
          </Button>
        </GridItem>
      </GridContainer>
      <ConfirmationModal
        key={'delete-modal'}
        isOpen={showDeleteModal}
        title={MESSAGE.CONFIRMATION}
        msg={MESSAGE.MODEM_DELETE_DESCRIPTION}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={onDeleteModalClickHandler}
      />
    </React.Fragment>
  );
};

export default ModemDetails;
