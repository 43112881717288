import { isRejectedWithValue } from '@reduxjs/toolkit';
import {
  setErrorMsg,
  setShowErrorModal,
  setIsInternalServerError,
  setIsForbiddenError,
  setErrorTitle,
  setIsParseError,
} from '../redux-slice/error';
import { setManuallyLogoutStatus, setUserDeactivated } from '../redux-slice/user'; //setUserDeactivated
import { ERROR_MESSAGE } from '../constants/errorConstants';
import { timeCalculation } from '../utils/common-methods';
import { setOpenRefreshTokenModal, setSsoSilentStatus } from '../redux-slice/msalStatus';
import { LOADING_STATE } from '../constants/CommonConstants';
import { MESSAGE } from '../constants/message';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    api.dispatch(setErrorTitle(MESSAGE.SOMETHING_WENT_WRONG));
    if (action.payload.status === 400) {
      const msg = action?.payload?.data?.message;
      api.dispatch(setErrorMsg(msg));
      api.dispatch(setShowErrorModal(true));
      api.dispatch(setIsInternalServerError(false));
      api.dispatch(setIsForbiddenError(false));
      api.dispatch(setIsParseError(false));
    }
    if (action.payload.status === 401) {
      api.dispatch(setIsInternalServerError(false));
      api.dispatch(setIsForbiddenError(false));
      api.dispatch(setIsParseError(false));
      api.dispatch(setManuallyLogoutStatus(true));
      if (
        api.getState().user?.accessToken !== '' &&
        api.getState().user?.tokenExpirationTime &&
        timeCalculation(api.getState().user?.tokenExpirationTime) > 0 &&
        api.getState().msalStatus?.ssoSilentStatus === LOADING_STATE.FULFILLED
      ) {
        api.dispatch(setSsoSilentStatus(LOADING_STATE.BACKEND_ISSUE));
        api.dispatch(setShowErrorModal(true));
      } else if (
        api.getState().user?.accessToken !== '' &&
        api.getState().user?.tokenExpirationTime &&
        timeCalculation(api.getState().user?.tokenExpirationTime) <= 0
      ) {
        // session timeout
        api.dispatch(setErrorTitle(MESSAGE.SESSION_TIMEOUT));
        api.dispatch(setErrorMsg(MESSAGE.SESSION_TIMEOUT_MESSAGE));
        api.dispatch(setShowErrorModal(true));
        api.dispatch(setOpenRefreshTokenModal(true));
      }
    }
    if (action.payload.status === 403) {
      if (action.payload?.data?.error === ERROR_MESSAGE.ACCOUNT_DEACTIVATED) {
        api.dispatch(setUserDeactivated(true));
      }
      api.dispatch(setIsForbiddenError(true));
      api.dispatch(setShowErrorModal(true));
      api.dispatch(setIsInternalServerError(false));
      api.dispatch(setIsParseError(false));
    }
    if (action.payload.status === 500) {
      const msg = action?.payload?.data?.message;
      api.dispatch(setErrorMsg(msg));
      api.dispatch(setShowErrorModal(true));
      api.dispatch(setIsInternalServerError(false));
      api.dispatch(setIsForbiddenError(false));
      api.dispatch(setIsParseError(false));
    }
    if (action.payload.originalStatus === 500) {
      const msg = action?.payload?.data;
      api.dispatch(setIsInternalServerError(action.payload.originalStatus === 500));
      api.dispatch(setErrorMsg(msg));
      api.dispatch(setIsForbiddenError(false));
      api.dispatch(setShowErrorModal(true));
      api.dispatch(setIsParseError(false));
    }
    if (action.payload.status === 502) {
      const msg = action?.payload?.data?.message;
      api.dispatch(setErrorMsg(msg));
      api.dispatch(setShowErrorModal(true));
      api.dispatch(setIsInternalServerError(false));
      api.dispatch(setIsForbiddenError(false));
      api.dispatch(setIsParseError(false));
    }
    if (
      action?.payload?.status === 'PARSING_ERROR' ||
      action?.payload?.originalStatus === 429 ||
      (action?.payload?.status === 404 && action?.payload?.data?.api === 'menuRoles')
    ) {
      api.dispatch(setIsParseError(true));
      api.dispatch(setShowErrorModal(true));
      api.dispatch(setIsInternalServerError(false));
      api.dispatch(setIsForbiddenError(false));
    }
  }

  return next(action);
};
