import { Box, Typography, useTheme, Paper } from '@mui/material';
import { GridContainer, GridItem } from '../../../components/shared';
import useStyles from './style';
import SubLocationDetailAccordion from './SubLocationDetailAccordion';
const SublocationsAccordion = (props) => {
  const { sublocationInfo } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box
      component={Paper}
      sx={{
        border: `1px solid ${theme.palette.grey[900]}`,
        borderRadius: '8px',
      }}
    >
      <Box className={classes.subLocationsDetailsBox}>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Typography variant="h3" color="black" mb={1}>
              Sub Location Details
            </Typography>
          </GridItem>
        </GridContainer>
        <SubLocationDetailAccordion sublocationInfo={sublocationInfo} />
      </Box>
    </Box>
  );
};

export default SublocationsAccordion;
