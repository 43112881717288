import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  machinesTableWrapper: {
    '& div[class*=makeStyles-searchWrapper]': {
      padding: '0 24px',
    },
    '& .MuiTableContainer-root': {
      marginTop: '26px',
    },
    '& .MuiTableCell-body': {
      padding: '12px 24px',
      '&:first-of-type': {
        width: '56px',
        paddingRight: 0,
        paddingLeft: '28px',
      },
    },
    '& td[class*=MuiTableCell-body]': {
      '&:nth-child(2)': {
        color: theme.palette.common.black,
      },
    },
  },
}));

export default useStyles;
