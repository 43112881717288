import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '../../constants/CommonConstants';

export const initialState = {
  status: LOADING_STATE.IDLE,
  userDetails: {},
  username: '',
  name: '',
  idTokenClaims: {},
  accessToken: '', // accessToken
  tokenExpirationTime: '',
  isDeactivated: false,
  error: false,
  manuallyLogoutStatus: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setUserData(state, action) {
      const { username, name, idTokenClaims } = action.payload;
      state.username = username;
      state.name = name;
      state.idTokenClaims = idTokenClaims;
    },
    setExpirationTime(state, action) {
      state.tokenExpirationTime = action.payload;
    },
    setUserDeactivated(state, action) {
      state.isDeactivated = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setManuallyLogoutStatus(state, action) {
      state.manuallyLogoutStatus = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    logout(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: () => {},
});

export default userSlice;
