import { GridContainer, GridItem, InformationPad } from '../../components/shared';
import { Box, Paper, Typography } from '@mui/material';
import useStyles from './style';

const IntercardAccordion = ({ intercardInfo }) => {
  const classes = useStyles();
  const fields = [
    { label: 'Total Intercard POS Transaction', value: intercardInfo?.totalIntercardPosTransactionsSuccess || 0 },
    { label: 'Total Intercard Teller Transaction', value: intercardInfo?.totalIntercardTellerTransactionsSuccess || 0 },
    {
      label: 'Total Intercard Transaction',
      value: intercardInfo?.totalIntercardTransactionsSuccess || 0,
    },
  ];

  return (
    <Box component={Paper} className={classes.intercardAccordion}>
      <GridContainer>
        <GridItem xs={12} md={12}>
          <Typography variant="h3" mb={1}>
            Intercard Details
          </Typography>
        </GridItem>
      </GridContainer>
      <InformationPad data={fields} />
    </Box>
  );
};

export default IntercardAccordion;
