import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 10px',
  },
  searchWrapper: {
    minHeight: 'auto',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    width: '100%',
    '& .MuiInputBase-root': {
      paddingLeft: '16px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
      },
    },
    '& .MuiInputBase-input': {
      padding: '16.5px 12px',
    },
    '& .MuiFormControl-root': {
      position: 'relative',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: '-25px',
      left: '25px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[100],
      borderRadius: 8,
    },
  },
  searchIconButton: {
    padding: '10px',
  },
  searchInput: {
    flex: 1,
  },
}));

export default useStyles;
