import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, GridContainer, GridItem, Select, TextField } from '../../../components/shared';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import { locationDetailsAction, subLocationDetailsAction } from '../../../redux-slice/location';
import { AddNewLocationSchema } from '../../../schema/validationSchemas';
import { useGetCurrenciesQuery } from '../../../services/Currencies/CurrenciesService';
import { useGetAllLocationTypesQuery } from '../../../services/Location/LocationService';
import { getOptions, isArrayWithLength } from '../../../utils/common-methods';
import useStyles from './style';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';
import {
  useGetX3DepartmentsQuery,
  useGetX3IndustriesQuery,
  useGetX3OwnersQuery,
} from '../../../services/X3Locations/X3LocationsService';

const LocationDetailsForm = (props) => {
  const { onNextClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const locationState = useSelector((state) => state?.location);
  const { apiData, addNewLocationForms } = locationState;
  const { locationDetails, subLocationDetails } = addNewLocationForms;
  const { countryData, statesData, citiesData } = apiData;
  const { currentData: locationTypesData, isSuccess: typeSuccess } = useGetAllLocationTypesQuery();
  const { currentData: currenciesData, isSuccess: currenciesSuccess } = useGetCurrenciesQuery();
  const { currentData: x3DepartmentsData, isSuccess: x3DepartmentsSuccess } = useGetX3DepartmentsQuery();
  const { currentData: x3IndustriesData, isSuccess: x3IndustriesSuccess } = useGetX3IndustriesQuery();
  const { currentData: x3OwnersData, isSuccess: x3OwnersSuccess } = useGetX3OwnersQuery();

  const stateSalesTaxOptions = [{ value: '35%', text: '35%' }];

  const locationTypeOptions = getOptions(locationTypesData);
  const currencyOptions = getOptions(currenciesData);
  const x3DepartmentsOptions = getOptions(x3DepartmentsData, 'X3_DeptCode');
  const xIndustriesOptions = getOptions(x3IndustriesData, 'X3_vertical');
  const x3OwnersOptions = getOptions(x3OwnersData, 'X3_OwnerCode');
  const formik = useFormik({
    initialValues: {
      locationName: locationDetails?.locationName,
      locationType: locationDetails?.locationType,
      zipCode: locationDetails?.zipCode,
      phone1: locationDetails?.phone1,
      phone2: locationDetails?.phone2,
      currency: locationDetails?.currency,
      country: locationDetails?.country,
      state: locationDetails?.state,
      city: locationDetails?.city,
      address1: locationDetails?.address1,
      address2: locationDetails?.address2,
      taxExemptFlag: locationDetails?.taxExemptFlag,
      X3_LocationsID: locationDetails?.X3_LocationsID,
      X3_VendorsID: locationDetails?.X3_VendorsID,
      X3_LocationName: locationDetails?.X3_LocationName,
      X3_DepartmentID: locationDetails?.X3_DepartmentID,
      X3_IndustryID: locationDetails?.X3_IndustryID,
      X3_OwnerID: locationDetails?.X3_OwnerID,
    },
    validationSchema: AddNewLocationSchema.locationDetailsSchema,
    onSubmit: () => {
      onNextClick();
    },
  });

  const { errors, touched, handleSubmit, setValues } = formik;

  // update value in formik when component mount.
  useEffect(() => {
    setValues({
      locationName: locationDetails?.locationName,
      locationType: locationDetails?.locationType,
      zipCode: locationDetails?.zipCode,
      phone1: locationDetails?.phone1,
      phone2: locationDetails?.phone2,
      currency: locationDetails?.currency,
      country: locationDetails?.country,
      state: locationDetails?.state,
      city: locationDetails?.city,
      address1: locationDetails?.address1,
      address2: locationDetails?.address2,
      taxExemptFlag: locationDetails?.taxExemptFlag,
      X3_LocationsID: locationDetails?.X3_LocationsID,
      X3_VendorsID: locationDetails?.X3_VendorsID,
      X3_LocationName: locationDetails?.X3_LocationName,
      X3_DepartmentID: locationDetails?.X3_DepartmentID,
      X3_IndustryID: locationDetails?.X3_IndustryID,
      X3_OwnerID: locationDetails?.X3_OwnerID,
    });
  }, [locationDetails]);

  useEffect(() => {
    let USDId = '';
    if (isArrayWithLength(currenciesData)) {
      USDId = currenciesData.find((item) => item.abbreviation === CURRENCY_CONSTANT.USD)?.id || '';
    }
    const newFormValue = {
      ...locationDetails,
      currency: USDId,
    };
    dispatch(locationDetailsAction(newFormValue));
  }, [currenciesData]);

  const onChangeHandler = (e) => {
    const newFormValue = {
      ...locationDetails,
      [e.target.name]: e.target.value,
    };
    dispatch(locationDetailsAction(newFormValue));
    if (e.target.name === 'zipCode') {
      const subLocationObj = {
        ...subLocationDetails,
        [e.target.name]: e.target.value,
      };
      dispatch(subLocationDetailsAction(subLocationObj));
    }
    if (e.target.name === 'address1') {
      const subLocationObj = {
        ...subLocationDetails,
        [e.target.name]: e.target.value,
      };
      dispatch(subLocationDetailsAction(subLocationObj));
    }
    if (e.target.name === 'address2') {
      const subLocationObj = {
        ...subLocationDetails,
        [e.target.name]: e.target.value,
      };
      dispatch(subLocationDetailsAction(subLocationObj));
    }
    if (e.target.name === 'currency') {
      const subLocationObj = {
        ...subLocationDetails,
        [e.target.name]: e.target.value,
      };
      dispatch(subLocationDetailsAction(subLocationObj));
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Box className={classes.locationDetailsForm} data-testid="locationDetailsComponent">
          <GridContainer rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 7 }}>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Location Name"
                value={locationDetails?.locationName}
                name="locationName"
                onChange={onChangeHandler}
                error={Boolean(touched.locationName && errors.locationName)}
                helperText={touched.locationName && errors.locationName}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Zip Code"
                name="zipCode"
                value={locationDetails?.zipCode}
                onChange={onChangeHandler}
                error={Boolean(touched.zipCode && errors.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Phone 1"
                name="phone1"
                value={locationDetails?.phone1}
                onChange={onChangeHandler}
                error={Boolean(touched.phone1 && errors.phone1)}
                helperText={touched.phone1 && errors.phone1}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Phone 2"
                name="phone2"
                value={locationDetails?.phone2}
                onChange={onChangeHandler}
                error={Boolean(touched.phone2 && errors.phone2)}
                helperText={touched.phone2 && errors.phone2}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              {currenciesData && currenciesSuccess && (
                <Select
                  options={currencyOptions}
                  inputLabel="Currency"
                  name="currency"
                  value={locationDetails?.currency}
                  onChange={onChangeHandler}
                  data-testid="locationDetailForms"
                  error={Boolean(touched.currency && errors.currency)}
                  helperText={touched.currency && errors.currency}
                />
              )}
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              {locationTypesData && typeSuccess && (
                <Select
                  options={locationTypeOptions}
                  inputLabel="Location Type"
                  name="locationType"
                  value={locationDetails?.locationType}
                  onChange={onChangeHandler}
                  data-testid="locationDetailForms"
                  error={Boolean(touched.locationType && errors.locationType)}
                  helperText={touched.locationType && errors.locationType}
                />
              )}
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <Select
                options={countryData}
                inputLabel="Country"
                name="country"
                value={locationDetails?.country}
                onChange={onChangeHandler}
                error={Boolean(touched.country && errors.country)}
                helperText={touched.country && errors.country}
                data-testid="locationDetailForms"
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Country Sales Tax"
                name="countrySalesTax"
                value={locationDetails?.countrySalesTax}
                onChange={onChangeHandler}
                disabled
                data-testid="locationDetailForms"
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <Select
                options={statesData}
                inputLabel="States"
                name="state"
                value={locationDetails?.state}
                onChange={onChangeHandler}
                disabled={statesData.length > 0 ? false : true}
                error={Boolean(touched.state && errors.state)}
                helperText={statesData.length === 0 ? 'Please first select country' : touched.state && errors.state}
                data-testid="locationDetailForms"
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <Select
                options={stateSalesTaxOptions}
                inputLabel="State Sales Tax"
                name="stateSalesTax"
                value=""
                onChange={() => {}}
                disabled
                data-testid="locationDetailForms"
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <Select
                options={citiesData}
                inputLabel="City"
                name="city"
                value={locationDetails?.city}
                onChange={onChangeHandler}
                disabled={citiesData.length > 0 ? false : true}
                error={Boolean(touched.city && errors.city)}
                helperText={citiesData.length === 0 ? 'Please first select state' : touched.city && errors.city}
                data-testid="locationDetailForms"
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Address 1"
                name="address1"
                multiline
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
                value={locationDetails?.address1}
                onChange={onChangeHandler}
                error={Boolean(touched.address1 && errors.address1)}
                helperText={touched.address1 && errors.address1}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="Address 2"
                name="address2"
                multiline
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
                value={locationDetails?.address2}
                onChange={onChangeHandler}
                error={Boolean(touched.address2 && errors.address2)}
                helperText={touched.address2 && errors.address2}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                type="number"
                label="X3 LocationsID"
                name="X3_LocationsID"
                value={locationDetails?.X3_LocationsID}
                onChange={onChangeHandler}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                type="string"
                label="X3 VendorsID"
                name="X3_VendorsID"
                value={locationDetails?.X3_VendorsID}
                onChange={onChangeHandler}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              <TextField
                label="X3 LocationName"
                name="X3_LocationName"
                value={locationDetails?.X3_LocationName}
                onChange={onChangeHandler}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              {x3DepartmentsData && x3DepartmentsSuccess && (
                <Select
                  options={x3DepartmentsOptions}
                  inputLabel="X3 DepartmentID"
                  name="X3_DepartmentID"
                  value={locationDetails?.X3_DepartmentID}
                  onChange={onChangeHandler}
                  data-testid="locationDetailForms"
                  error={Boolean(touched.X3_DepartmentID && errors.X3_DepartmentID)}
                  helperText={touched.X3_DepartmentID && errors.X3_DepartmentID}
                />
              )}
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              {x3IndustriesData && x3IndustriesSuccess && (
                <Select
                  options={xIndustriesOptions}
                  inputLabel="X3 IndustryID"
                  name="X3_IndustryID"
                  value={locationDetails?.X3_IndustryID}
                  onChange={onChangeHandler}
                  data-testid="locationDetailForms"
                  error={Boolean(touched.X3_IndustryID && errors.X3_IndustryID)}
                  helperText={touched.X3_IndustryID && errors.X3_IndustryID}
                />
              )}
            </GridItem>
            <GridItem xs={12} md={6} pl={0}>
              {x3OwnersData && x3OwnersSuccess && (
                <Select
                  options={x3OwnersOptions}
                  inputLabel="X3 OwnerID"
                  name="X3_OwnerID"
                  value={locationDetails?.X3_OwnerID}
                  onChange={onChangeHandler}
                  data-testid="locationDetailForms"
                  error={Boolean(touched.X3_OwnerID && errors.X3_OwnerID)}
                  helperText={touched.X3_OwnerID && errors.X3_OwnerID}
                />
              )}
            </GridItem>
          </GridContainer>

          <Box mt={5}>
            <Button variant="contained" type="submit" data-testid="locationDetailSubmitBtn">
              Next
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default LocationDetailsForm;
