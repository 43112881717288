import React, { useState, useEffect, useMemo } from 'react';
import { Button, GridContainer, GridItem, Select, TextField } from '../../../components/shared';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, Paper } from '@mui/material';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import { formatPhNo10Digit, getOptions, getPhNo10Digits } from '../../../utils/common-methods';
import { AddNewLocationOwnerSchema } from '../../../schema/validationSchemas';
import {
  useDeleteLocationOwnerByIdMutation,
  useGetAllLocationOwnerTypesQuery,
  useUpdateLocationOwnerByIdMutation,
} from '../../../services/Location/LocationService';
import {
  useGetAllCountriesQuery,
  useLazyGetStateCitiesByStateIdQuery,
  useLazyGetCountryStatesByCountryIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import useStyles from './style';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';

const LocationOwnerUpdateView = (props) => {
  const { locationOwnerDetails } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [updateLocationOwner, { isSuccess, isLoading }] = useUpdateLocationOwnerByIdMutation();
  const [deleteLocationOwner, { isSuccess: deleteSuccess, isLoading: deleteLoading }] =
    useDeleteLocationOwnerByIdMutation();

  const { currentData: locationOwnerTypesResponse } = useGetAllLocationOwnerTypesQuery();

  const locationOwnerTypes = useMemo(() => getOptions(locationOwnerTypesResponse), [locationOwnerTypesResponse]);
  const { currentData: countriesResponse, isSuccess: countriesAPISuccess } = useGetAllCountriesQuery();
  const [getCitiesByStateId, citiesResponseByStateId] = useLazyGetStateCitiesByStateIdQuery();
  const [getStatesByCountryId, statesResponseByCountryId] = useLazyGetCountryStatesByCountryIdQuery();

  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);

  useEffect(() => {
    if (countriesAPISuccess) {
      setCountryData(getOptions(countriesResponse));
    }
  }, [countriesResponse]);

  useEffect(() => {
    if (statesResponseByCountryId.isSuccess) {
      setStatesData(getOptions(statesResponseByCountryId.currentData?.data?.states));
    }
  }, [statesResponseByCountryId.currentData]);

  useEffect(() => {
    if (citiesResponseByStateId.isSuccess) {
      setCitiesData(getOptions(citiesResponseByStateId.currentData?.data?.cities));
    }
  }, [citiesResponseByStateId.currentData]);
  const navToLocOwnerDetailPage = () =>
    navigate(`/${routeConstants.LOCATION_OWNERS_ROUTE}/${Number(locationOwnerDetails?.id)}`);

  if (isSuccess) {
    if (locationOwnerDetails?.id) {
      navToLocOwnerDetailPage();
    } else {
      navigate(`/${routeConstants.LOCATION_OWNERS_ROUTE}`);
    }
  }
  if (deleteSuccess) {
    navigate(`/${routeConstants.LOCATION_OWNERS_ROUTE}`);
  }

  const formik = useFormik({
    initialValues: {
      id: locationOwnerDetails?.id || '',
      name: locationOwnerDetails?.name || '',
      typeId: locationOwnerDetails?.typeId || '',
      address1: locationOwnerDetails?.address1 || '',
      address2: locationOwnerDetails?.address2 || '',
      countryId: locationOwnerDetails?.countryId || '',
      stateId: locationOwnerDetails?.stateId || '',
      cityId: locationOwnerDetails?.cityId || '',
      postcode: locationOwnerDetails?.postcode || '',
      phone1: formatPhNo10Digit(locationOwnerDetails?.phone1) || '',
      phone2: formatPhNo10Digit(locationOwnerDetails?.phone2) || '',
      email: locationOwnerDetails?.email || '',
    },
    validationSchema: AddNewLocationOwnerSchema,
    onSubmit: (values) => {
      const data = {
        id: values?.id,
        name: values?.name?.trim() || '',
        typeId: values?.typeId || null,
        address1: values.address1?.trim() || '',
        address2: values.address2?.trim() || '',
        countryId: Number(values.countryId),
        stateId: Number(values.stateId),
        cityId: Number(values.cityId),
        postcode: values.postcode,
        phone1: getPhNo10Digits(values.phone1) || '',
        phone2: getPhNo10Digits(values.phone2) || '',
        email: values.email?.trim() || '',
      };

      updateLocationOwner(data);
    },
  });

  useEffect(() => {
    if (locationOwnerDetails?.countryId) {
      getStatesByCountryId(locationOwnerDetails?.countryId);
    }
    if (locationOwnerDetails?.stateId) {
      getCitiesByStateId(locationOwnerDetails?.stateId);
    }
  }, [locationOwnerDetails]);

  const archiveHandler = () => {
    deleteLocationOwner(locationOwnerDetails?.id).then(() => {
      setShowArchiveModal(false);
    });
  };
  const { touched, errors, getFieldProps, handleSubmit, setFieldValue } = formik;

  const onCountryChangeHandler = (e) => {
    setFieldValue('countryId', e.target.value);
    getStatesByCountryId(e.target.value);
    setCitiesData([]);
    setFieldValue('stateId', '');
    setFieldValue('cityId', '');
  };

  const onStateChangeHandler = (e) => {
    setFieldValue('stateId', e.target.value);
    getCitiesByStateId(e.target.value);
    setFieldValue('cityId', '');
  };

  return (
    <Box component={Paper} p={3}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <GridContainer m={1} spacing={2} alignItems="center" className={classes.formContent}>
            <GridItem xs={12} md={6}>
              <TextField
                label="Business Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="Type"
                options={locationOwnerTypes || []}
                {...getFieldProps('typeId')}
                data-testid="updateField"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Address1"
                multiline
                maxRows={3}
                {...getFieldProps('address1')}
                error={Boolean(touched.address1 && errors.address1)}
                helperText={touched.address1 && errors.address1}
                InputProps={{
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Address2"
                multiline
                maxRows={3}
                {...getFieldProps('address2')}
                error={Boolean(touched.address2 && errors.address2)}
                helperText={touched.address2 && errors.address2}
                InputProps={{
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="Country"
                options={countryData || []}
                {...getFieldProps('countryId')}
                data-testid="updateField"
                error={Boolean(touched.countryId && errors.countryId)}
                helperText={touched.countryId && errors.countryId}
                onChange={onCountryChangeHandler}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="State"
                options={statesData || []}
                {...getFieldProps('stateId')}
                data-testid="updateField"
                disabled={Boolean(statesData.length === 0)}
                error={Boolean(touched.stateId && errors.stateId)}
                helperText={statesData.length === 0 ? 'Please first select country' : touched.stateId && errors.stateId}
                onChange={onStateChangeHandler}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="City"
                options={citiesData || []}
                {...getFieldProps('cityId')}
                data-testid="updateField"
                error={Boolean(touched.cityId && errors.cityId)}
                helperText={citiesData.length === 0 ? 'Please first select state' : touched.cityId && errors.cityId}
                disabled={Boolean(citiesData.length === 0)}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Postal Code"
                {...getFieldProps('postcode')}
                error={Boolean(touched.postcode && errors.postcode)}
                helperText={touched.postcode && errors.postcode}
                InputProps={{
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Phone1"
                {...getFieldProps('phone1')}
                error={Boolean(touched.phone1 && errors.phone1)}
                helperText={touched.phone1 && errors.phone1}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Phone2"
                {...getFieldProps('phone2')}
                error={Boolean(touched.phone2 && errors.phone2)}
                helperText={touched.phone2 && errors.phone2}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={12}>
              <TextField
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                  'data-testid': 'updateField',
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="space-around" m={1} spacing={2}>
            <GridItem xs={6}>
              <LoadingButton loading={isLoading} variant="contained" type="submit">
                UPDATE
              </LoadingButton>
              <Button onClick={navToLocOwnerDetailPage}>CANCEL</Button>
            </GridItem>
            <GridItem xs={6} display="flex" justifyContent="flex-end">
              <Button onClick={() => setShowArchiveModal(true)} color="error">
                ARCHIVE LOCATION OWNER
              </Button>
            </GridItem>
          </GridContainer>
          <ConfirmationModal
            isLoading={deleteLoading}
            isOpen={showArchiveModal}
            title="Confirmation"
            msg="Are you sure you want to archive this location owner? This process cannot be undone."
            buttons={[
              { text: 'Archive', value: true },
              { text: 'Cancel', value: false },
            ]}
            onClick={(value) => {
              if (value) archiveHandler();
              else setShowArchiveModal(false);
            }}
          />
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default LocationOwnerUpdateView;
