import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getDeposits: build.query({
      query: (args) => {
        if (args) {
          const { searchParams, page, perPage } = args;
          return `${apiUrl.deposits}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
        return `${apiUrl.deposits}`;
      },
      providesTags: ['Deposits'],
    }),
    getDepositsById: build.query({
      query: (id) => `${apiUrl.deposits}/${id}`,
      providesTags: ['Deposit'],
    }),
    createDeposit: build.mutation({
      query: (data) => ({
        url: `${apiUrl.deposits}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Deposits'],
    }),
    updateDepositById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.deposits}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Deposits'],
    }),
    getDepositsSubLocations: build.query({
      query: (args) => {
        if (args) {
          const { page = '', perPage = '', searchParams = '' } = args;
          return `${apiUrl.deposits}/${apiUrl.sublocations}?perPage=${perPage}&page=${page}&${searchParams}`;
        }
        return `${apiUrl.deposits}/${apiUrl.sublocations}`;
      },
      transformResponse: (responseData) => {
        const pagination = responseData?.pagination;
        const loadSubLocations = responseData?.data?.sublocations;

        return {
          ...responseData.data,
          sublocations: loadSubLocations,
          pagination,
        };
      },
      providesTags: ['SubLocations'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDepositsQuery,
  useLazyGetDepositsQuery,
  useGetDepositsByIdQuery,
  useLazyGetDepositsByIdQuery,
  useCreateDepositMutation,
  useUpdateDepositByIdMutation,
  useLazyGetDepositsSubLocationsQuery,
} = extendedApi;
