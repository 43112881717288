import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  leaseDetailsForm: {
    marginTop: '17px',
  },
  errorIcon: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
