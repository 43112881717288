import { Box, Container, Paper, Typography } from '@mui/material';
import { Breadcrumbs, TimeLine } from '../../../components/shared';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from './style';
import LocationDetailsForm from './LocationDetailsForm';
import LocationOwnersDetailsFrom from './LocationOwnersDetailsFrom';
import AddSubLocationsFrom from './AddSubLocationsFrom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetAllCountriesQuery,
  useGetCountryStatesByCountryIdQuery,
  useGetStateCitiesByStateIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import { cityAction, countryAction, resetState, stateAction } from '../../../redux-slice/location';

const AddNewLocation = () => {
  const {
    LOCATION: { LOCATION, ADD_LOCATION },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = [{ text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` }, { text: ADD_LOCATION }];
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(1);
  const dispatch = useDispatch();
  const locationState = useSelector((state) => state?.location);
  const { addNewLocationForms } = locationState;
  const { locationDetails } = addNewLocationForms;
  const { currentData: countries, isSuccess: countriesSuccess } = useGetAllCountriesQuery();
  const { currentData: states, isSuccess: statesSuccess } = useGetCountryStatesByCountryIdQuery(
    locationDetails.country,
    {
      skip: locationDetails.country === '',
    },
  );
  const { currentData: cities, isSuccess: citiesSuccess } = useGetStateCitiesByStateIdQuery(locationDetails.state, {
    skip: locationDetails.state === '',
  });

  useEffect(() => () => dispatch(resetState()), []);

  useEffect(() => {
    if (countriesSuccess && countries) {
      const countryData = countries.map((value) => ({
        value: value?.id,
        text: value?.name,
      }));
      dispatch(countryAction(countryData));
    }
  }, [countriesSuccess]);

  useEffect(() => {
    if (statesSuccess && states) {
      const stateOptions = states?.data?.states?.map((value) => ({
        value: value?.id,
        text: value?.name,
      }));
      dispatch(stateAction(stateOptions));
    }
  }, [statesSuccess, locationDetails.country, states]);

  useEffect(() => {
    if (citiesSuccess && cities) {
      const cityOptions = cities?.data?.cities?.map((value) => ({
        value: value?.id,
        text: value?.name,
      }));
      dispatch(cityAction(cityOptions));
    }
  }, [locationDetails.state, citiesSuccess, cities]);

  //this code is for page reloading prompt message.
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const onNextClickHandler = () => {
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);
  };
  const onBackClickHandler = () => {
    const nextIndex = currentIndex - 1;
    setCurrentIndex(nextIndex);
  };
  const timeLineData = [
    {
      id: 1,
      heading: 'Location Details',
      content: <LocationDetailsForm onNextClick={onNextClickHandler} />,
    },
    {
      id: 2,
      heading: 'Location Owner Details',
      content: <LocationOwnersDetailsFrom onNextClick={onNextClickHandler} onBackClick={onBackClickHandler} />,
    },
    {
      id: 3,
      heading: 'Add Sub Locations',
      content: <AddSubLocationsFrom onBackClick={onBackClickHandler} />,
    },
  ];
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box mb={3}>
        <Box>
          <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
        </Box>
        <Box>
          <Typography variant="h1">Add New Location</Typography>
        </Box>
      </Box>
      <Box component={Paper}>
        <TimeLine data={timeLineData} currentIndex={currentIndex} />
      </Box>
    </Container>
  );
};

export default AddNewLocation;
