import React, { useMemo } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { GridContainer, GridItem, Tabs } from '../../../components/shared';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import OpenCollections from '../OpenCollection';
import Reconciliation from '../Reconciliation';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import { routeConstants } from '../../../constants/routeConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApprovedCollection from '../ApprovedCollection';

const displayName = 'Collections';

const Collections = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('selectedTab');
  const isIndependentRep = useHasRoles([ROLE.INDEPENDENT_REPS]);
  const canViewReconciliationAndApproveRoles = useHasRoles([
    ROLE.SUPER_ADMIN_IT,
    ROLE.MANAGER,
    ROLE.ACCOUNTING,
    ROLE.DM,
    ROLE.REGIONAL_MANAGER,
    ROLE.EXECS,
    ROLE.VP,
  ]);
  const TABS = useMemo(() => {
    if (canViewReconciliationAndApproveRoles) {
      return [
        {
          heading: TAB_TYPES.OPEN_COLLECTION,
          content: <OpenCollections />,
        },
        {
          heading: TAB_TYPES.RECONCILIATION,
          content: <Reconciliation />,
        },
        {
          heading: TAB_TYPES.APPROVED,
          content: <ApprovedCollection />,
        },
      ];
    }
    if (isIndependentRep) {
      return [
        {
          heading: TAB_TYPES.OPEN_COLLECTION,
          content: <OpenCollections />,
        },
      ];
    }

    return [];
  }, [isIndependentRep, canViewReconciliationAndApproveRoles]);

  const onTabChange = (newValue) => {
    if (TABS.length > 1) {
      navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=${newValue}`, {
        replace: true,
      });
    } else {
      navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=1`, {
        replace: true,
      });
    }
  };
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} display="flex" alignItems="center">
          <Typography variant="h1">Collections</Typography>
        </GridItem>
      </GridContainer>
      <Box mt={2} component={Paper}>
        <Tabs defaultTab={selectedTab === null ? '1' : selectedTab} tabsData={TABS} onTabChange={onTabChange} />
      </Box>
    </React.Fragment>
  );
};

Collections.displayName = displayName;
export default Collections;
