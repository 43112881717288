import React, { useMemo } from 'react';
import { GridItem, TextField, GridContainer, Button, Autocomplete } from '../../../components/shared';
import { Typography, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { meterUpdateSchema } from '../../../schema/validationSchemas';
import {
  useGetModemsByMachineIdQuery,
  useUpdateMachineMeterByIdMutation,
} from '../../../services/Machines/MachinesService';
import { useFormik } from 'formik';
import useStyles from './style';
import { DENOMINATION, METER_TYPES, TYPE } from '../../../constants/MeterConstants';
import { useParams } from 'react-router-dom';

const EditMeterFields = ({ setIsUpdateMeterField, currentMeter }) => {
  const classes = useStyles();
  const params = useParams();
  const [updateMachineMeterApi] = useUpdateMachineMeterByIdMutation();
  let status = currentMeter?.status;
  const { currentData: currentModemsData, isSuccess: modemSuccess } = useGetModemsByMachineIdQuery(Number(params.id));

  const modemOptions = useMemo(() => {
    const modems = currentModemsData?.data?.modems?.map((value) => ({
      text: value?.serialNumber?.trim(),
      value: value?.id,
    }));
    return modems;
  }, [currentModemsData, modemSuccess]);

  const formik = useFormik({
    initialValues: {
      meterName: currentMeter?.meterName,
      meterPosition: currentMeter?.identifier.trim() || '',
      maxCount: currentMeter?.maxCount,
      description:
        currentMeter?.meterName === METER_TYPES.CANDY_METER
          ? currentMeter?.candyMeterDescription
          : currentMeter?.description,
      displayName: currentMeter?.displayName || '',
      denomination: DENOMINATION.filter((item) => item.text === currentMeter?.denomination)?.[0] || null,
      tokenPayout: currentMeter?.tokenPayout || '',
      machineAcceptedPaymentType:
        TYPE.filter((item) => item.text === currentMeter?.machineAcceptedPaymentType)?.[0] || null,
      modemId: {
        text: currentMeter?.modem?.serialNumber || '',
        value: currentMeter?.modem?.id || '',
      },
      currentAssetMeterReading: currentMeter?.currentAssetMeterReading || 0,
    },
    validationSchema: meterUpdateSchema,
    onSubmit: (data) => {
      const apiData = {
        id: currentMeter?.id,
        identifier: data.meterPosition.trim() || null,
        maxCount: data.maxCount || null,
        description: data.description?.trim() || '',
        displayName: data.displayName?.trim() || '',
        denomination: data.denomination?.text,
        tokenPayout: data.tokenPayout,
        machineAcceptedPaymentType: data.machineAcceptedPaymentType?.text,
        modemId: data?.modemId?.value || null,
        currentAssetMeterReading: data.currentAssetMeterReading ?? 0,
      };
      const candyMeterData = {
        id: currentMeter?.id,
        candyMeterDescription: data.description?.trim() || '',
      };
      updateMachineMeterApi(currentMeter?.meterName === METER_TYPES.CANDY_METER ? candyMeterData : apiData).then(
        (response) => {
          if (response.data) {
            setIsUpdateMeterField(false);
          }
        },
      );
    },
  });

  const onCancelHandler = () => {
    setIsUpdateMeterField(false);
  };

  const archiveHandler = () => {
    updateMachineMeterApi({
      id: currentMeter?.id,
      status: !currentMeter?.status,
    }).then((response) => {
      if (response.data) {
        setIsUpdateMeterField(false);
      }
    });
  };

  const { values, errors, touched, getFieldProps, handleSubmit, setFieldValue, setFieldTouched } = formik;
  const { meterName } = currentMeter;
  const isCandyMeter = meterName === METER_TYPES.CANDY_METER;

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title={<Typography variant="h2">Edit Meter</Typography>} className={classes.addMeterHead} />
        <CardContent className={classes.meterCardContent}>
          <GridContainer spacing={2} alignItems="center">
            <GridItem xs={12} md={12}>
              <Typography variant="h5">{meterName}</Typography>
            </GridItem>
            {isCandyMeter ? (
              <>
                <GridItem xs={12}>
                  <TextField
                    label="Description"
                    {...getFieldProps('description')}
                    InputProps={{
                      'data-testid': 'editMeterFields',
                    }}
                  />
                </GridItem>
              </>
            ) : (
              <>
                <GridItem xs={12} md={4}>
                  <TextField
                    label="Meter Position"
                    {...getFieldProps('meterPosition')}
                    inputProps={{
                      'data-testid': 'editMeterFields',
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <TextField
                    label="Current Meter"
                    {...getFieldProps('currentAssetMeterReading')}
                    inputProps={{
                      'data-testid': 'editMeterFields',
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <TextField
                    label="Max Count"
                    {...getFieldProps('maxCount')}
                    error={Boolean(touched.maxCount && errors.maxCount)}
                    helperText={touched.maxCount && errors.maxCount}
                    inputProps={{
                      maxLength: 6,
                      'data-testid': 'editMeterFields',
                    }}
                  />
                </GridItem>
                {meterName === METER_TYPES.PRIZE_METER && (
                  <GridItem xs={12}>
                    <TextField
                      label="Description"
                      {...getFieldProps('description')}
                      InputProps={{
                        'data-testid': 'editMeterFields',
                      }}
                    />
                  </GridItem>
                )}
                {meterName === METER_TYPES.MEDALLION_METER && (
                  <GridItem xs={12}>
                    <TextField
                      label="Display Name"
                      {...getFieldProps('displayName')}
                      InputProps={{
                        'data-testid': 'editMeterFields',
                      }}
                    />
                  </GridItem>
                )}
                {[METER_TYPES.CASH_METER, METER_TYPES.CREDIT_METER, METER_TYPES.TOKEN_CHANGER_METER].includes(
                  meterName,
                ) && (
                  <GridItem xs={12}>
                    <Autocomplete
                      label="Modem"
                      data-testid="editMeterFields"
                      value={values.modemId}
                      options={modemOptions || []}
                      getOptionLabel={(option) => option.text || ''}
                      onChange={(e, value) => {
                        setFieldValue('modemId', value);
                      }}
                      onBlur={() => setFieldTouched('modemId')}
                    />
                  </GridItem>
                )}
                {meterName === METER_TYPES.TOKEN_CHANGER_METER && (
                  <>
                    <GridItem xs={12} md={4}>
                      <Autocomplete
                        label="Denomination"
                        data-testid="form-elements"
                        value={values.denomination}
                        options={DENOMINATION}
                        getOptionLabel={(option) => option.text || ''}
                        onChange={(e, value) => {
                          setFieldValue('denomination', value);
                        }}
                        onBlur={() => setFieldTouched('denomination')}
                        error={Boolean(touched.denomination && errors.denomination)}
                        helperText={touched.denomination && errors.denomination}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Autocomplete
                        label="Type"
                        data-testid="form-elements"
                        value={values.machineAcceptedPaymentType}
                        options={TYPE}
                        getOptionLabel={(option) => option.text || ''}
                        onChange={(e, value) => {
                          setFieldValue('machineAcceptedPaymentType', value);
                        }}
                        onBlur={() => setFieldTouched('machineAcceptedPaymentType')}
                        error={Boolean(touched.machineAcceptedPaymentType && errors.machineAcceptedPaymentType)}
                        helperText={touched.machineAcceptedPaymentType && errors.machineAcceptedPaymentType}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <TextField
                        label="Token Payout"
                        {...getFieldProps('tokenPayout')}
                        error={Boolean(touched.tokenPayout && errors.tokenPayout)}
                        helperText={touched.tokenPayout && errors.tokenPayout}
                        inputProps={{ maxLength: 6 }}
                        InputProps={{
                          'data-testid': 'editMeterFields',
                        }}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}
          </GridContainer>
          <GridContainer justifyContent="space-around">
            <GridItem xs={12} md={12}>
              <Stack direction={'row'} className={classes.btn}>
                <Stack direction={'row'}>
                  <Button type="Submit" variant="contained">
                    <Typography variant="button">UPDATE</Typography>
                  </Button>
                  <Button onClick={onCancelHandler}>
                    <Typography variant="button">CANCEL</Typography>
                  </Button>
                </Stack>
                <Button color={status ? 'error' : 'success'} className={classes.errorButton} onClick={archiveHandler}>
                  {status ? 'ARCHIVE METER' : 'ACTIVATE METER'}
                </Button>
              </Stack>
            </GridItem>
          </GridContainer>
        </CardContent>
      </Card>
    </form>
  );
};

export default EditMeterFields;
