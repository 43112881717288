import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  reconciliationAccordion: {
    '& tr[class*=makeStyles-accordionBody]': {
      '& td:first-of-type': {
        padding: '12px 22px 0px 22px !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      whiteSpace: 'normal',
      lineHeight: 0.9,
    },
  },
  openCollectionAccordion: {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      whiteSpace: 'normal',
      lineHeight: 0.9,
    },
  },
  accountSummaryFields: {
    height: '40px',
    borderRadius: 0,
    fontSize: '12px',
    '& .MuiAutocomplete-root': { height: '40px' },
    '& .MuiOutlinedInput-notchedOutline': { borderRadius: 0 },
    '& .MuiGrid2-root': { height: '100%', padding: 0 },
    '& .MuiFormControl-root': { height: '100%' },
    '& .MuiInputBase-root': {
      height: '100%',
      fontSize: 'small',
    },
    '& .MuiTextField-root': {
      '& .MuiOutlinedInput-input': { height: '100%', padding: '0px 0px 0px 14px', borderRadius: 0 },
    },
    '& .MuiButtonBase-root MuiMenuItem-root': { fontSize: 'small' },
  },
  accountSummaryTitleFields: { '& .MuiTypography-root': { fontSize: '15px' }, padding: '14px 24px 0px 24px' },
  addNewRowBtn: { fontSize: 'small', padding: '8px 0px 0px 24px', '&:hover': { background: 'white' } },
  accountSummaryTitleContainer: {
    backgroundColor: theme.palette.grey[900_2],
    padding: 10,
  },
  accountSummaryInfoCurrencyText: {
    fontSize: 'small',
    fontWeight: 'bold',
    '& .MuiTypography-root': { fontSize: 'small' },
  },
  collectionFieldItem: {
    '& .Mui-disabled': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
    },
  },
  datePointerEvent: {
    pointerEvents: 'auto',
    backgroundColor: theme.palette?.common?.white,
    borderRadius: 8,
    minWidth: 145,
  },
  dateContainer: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    lineHeight: '20px',
    '& .MuiInputBase-root': { height: '45px', fontSize: '14px' },
  },
  debitCreditField: {
    '& .MuiTextField-root': {
      '& .MuiOutlinedInput-input': { height: '100%', padding: 0, borderRadius: 0 },
    },
  },
  labelClass: {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      marginTop: '-6px',
    },
  },
  reportModalClass: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
  reportIssueReason: {
    textTransform: 'capitalize',
  },
  draftedLocationName: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
