import React from 'react';
import { Box } from '@mui/material';
import { RangePicker } from 'react-trip-date';
import palette from '../../../theme/palette';

const DateRangePicker = (props) => {
  const { selectedDays = { from: '', to: '' }, onChange = () => {}, ...rest } = props;

  const theme = {
    primary: {
      light: palette.primary.main,
      main: palette.primary.main,
      dark: palette.primary.main,
    },
    background: palette.background,
    text: {
      disabled: palette.grey[400],
    },
  };

  return (
    <Box>
      <RangePicker theme={theme} selectedDays={selectedDays} onChange={onChange} {...rest} />
    </Box>
  );
};

export default DateRangePicker;
