import { useMemo, useState, useEffect, useCallback } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { DEPOSIT_DYNAMIC } from '../../../constants/tableColumns';
import ReactTable from '../../../components/shared/ReactTable';
import { Link, NoContent, ControlledTooltips } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetDepositsQuery } from '../../../services/Deposit/DepositService';
import { getControlledTooltipValueAndCellValue, isArrayWithLength } from '../../../utils/common-methods';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { setApprovedFilters, setReconciliationFilters } from '../../../redux-slice/depositFilters';
import { useDispatch } from 'react-redux';

export default function DepositTable({ isApproved = false, searchParams, tab, reconciliation, approved }) {
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));

  const [currentPage, setCurrentPage] = useState(
    tab === 'reconciliation' ? reconciliation?.pageNumber : approved?.pageNumber,
  );
  const [perPageNumber, setPerPageNumber] = useState(
    tab === 'reconciliation' ? reconciliation?.rowsPerPage : approved?.rowsPerPage,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const dispatch = useDispatch();

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useGetDepositsQuery({ searchParams: searchFilterData, page: currentPage, perPage: perPageNumber });

  const columns = useMemo(() => DEPOSIT_DYNAMIC(isBelowMD), [isBelowMD]);

  const depositLink = useCallback(
    (id) => (
      <Link
        underline="none"
        to={`/${routeConstants.DEPOSIT_ROUTE}/${
          isApproved ? routeConstants.APPROVED_DETAIL_DEPOSIT : routeConstants.RECONCILIATION_DETAIL_DEPOSIT
        }/${id}`}
      >
        {id}
      </Link>
    ),
    [],
  );

  const viewLocation = useCallback((sublocations) => {
    const { cellValue, tooltipValue } = getControlledTooltipValueAndCellValue(sublocations);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  const tableData = useMemo(() => {
    if (isSuccess && latestData) {
      const depositList = latestData?.data?.deposits || [];
      return depositList.map((item) => {
        const depositDetails = item?.depositDetails || [];
        const sublocationsName = depositDetails.map((value) => value?.sublocation?.name?.trim());
        const serviceRep = item.user ? `${item.user?.firstName || ''} ${item.user?.lastName || ''}` : 'N/A';
        return {
          id: depositLink(item.id),
          serviceRep,
          sublocationName: viewLocation(sublocationsName),
          depositDate: item.depositDate,
          totalDepositAmount: item?.totalDepositAmount || 0,
        };
      });
    }
    return [];
  }, [isError, isSuccess, latestData]);

  useEffect(() => {
    let total = latestData?.pagination?.total;
    setTotalCount(total);
  }, [tableData]);

  const pageChangeHandler = (currentPage) => {
    dispatch(
      tab === 'reconciliation'
        ? setReconciliationFilters({ ...reconciliation, pageNumber: currentPage, rowsPerPage: perPageNumber })
        : setApprovedFilters({ ...approved, pageNumber: currentPage, rowsPerPage: perPageNumber }),
    );
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(
      tab === 'reconciliation'
        ? setReconciliationFilters({ ...reconciliation, rowsPerPage: newPerPage, pageNumber: currentPage })
        : setApprovedFilters({ ...approved, rowsPerPage: newPerPage, pageNumber: currentPage }),
    );
    setPerPageNumber(newPerPage);
  };

  useEffect(() => {
    setSearchFilterData(searchParams);
    setCurrentPage(tab === 'reconciliation' ? reconciliation?.pageNumber : approved?.pageNumber);
    setPerPageNumber(tab === 'reconciliation' ? reconciliation?.rowsPerPage : approved?.rowsPerPage);
  }, [searchParams]);

  {
    (isLoading || isFetching) && <LoadingSpinner containerHeight={300} />;
  }
  if (isError && !isArrayWithLength(tableData)) {
    return (
      <Container maxWidth="xl">
        <NoContent
          title="No Deposits Found"
          desc={(isError && error.data?.message) || <>There is no deposit present with this filter.</>}
        />
      </Container>
    );
  }
  return (
    <>
      {!isLoading && !isError && !isFetching && isArrayWithLength(tableData) > 0 && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
        />
      )}
    </>
  );
}
