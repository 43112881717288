import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, InputAdornment, IconButton, Typography } from '@mui/material';
import { InformationField, TextField, GridContainer, GridItem } from '../shared';
import { Send, Close } from '@mui/icons-material';
import { getESTDateFormat } from '../../utils/common-methods';
import dayjs from 'dayjs';
import useStyles from './style';

const CommentBox = ({
  data = {},
  handleClose = () => {},
  handleSubmit = () => {},
  initialCommentData = [],
  isApproved = false,
}) => {
  const { title, titleVariant, fieldName } = data;
  const classes = useStyles();
  const commentScrollRef = useRef();
  const { name, userDetails } = useSelector((state) => state.user);
  const [commentData, setCommentData] = useState(initialCommentData);
  const [newComment, setNewComment] = useState('');
  useEffect(() => {
    if (commentScrollRef.current && commentScrollRef.current?.scrollHeight) {
      commentScrollRef.current.scrollTop = commentScrollRef.current?.scrollHeight;
    }
  }, [commentData]);
  const handleCommentUpdate = () => {
    if (newComment !== '') {
      let date = dayjs();
      const commentDate = getESTDateFormat(date);
      const newCommentData = {
        name: name,
        comment: newComment,
        createdAt: commentDate,
        userId: userDetails.id,
      };
      setCommentData((prevComments) => [...prevComments, newCommentData]);
      handleSubmit([...commentData, newCommentData]);
    }
    setNewComment('');
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.commentHead}>
        <Typography variant={titleVariant}>{title}</Typography>
        <IconButton onClick={() => handleClose(commentData)}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.commentList} ref={commentScrollRef}>
        {commentData?.map((item, key) => (
          <GridContainer key={key}>
            <GridItem xs={12} className={classes.commentBodyHead}>
              <InformationField variant="subtitle1" value={item?.name} />
              <Typography variant="subtitle1" pr={1} textAlign="right">
                {item?.createdAt}
              </Typography>
            </GridItem>
            <GridItem xs={12} className={classes.commentBodyContent}>
              <Typography variant="h4">{item.comment}</Typography>
            </GridItem>
          </GridContainer>
        ))}
      </Box>
      <Box pr={1} pt={3}>
        {!isApproved && (
          <TextField
            label=""
            variant="outlined"
            placeholder="Add Comment"
            name={fieldName}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            InputProps={{
              'aria-label': 'Comment',
              'data-testid': 'CommentInput',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="add-comment" edge="end" onClick={handleCommentUpdate}>
                    <Send color="secondary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CommentBox;
