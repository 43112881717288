import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { CachedOutlined } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';

import useStyles from './style';
import ActiveTab from './ActiveTab';
import ArchiveTab from './ArchiveTab';

import { GridItem, GridContainer, Tabs, Button, Modal } from '../../../components/shared';
import { SYNC_STATUS, TAB_TYPES } from '../../../constants/CommonConstants';
import SyncingModalBody from './SyncingModalBody';
import { userReadRequest } from '../../../authConfig';
import { useLazySyncUsersQuery } from '../../../services/Users/UsersService';
import { ROLE } from '../../../constants/roles';
import { useHasRoles } from '../../../hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';

const displayName = 'Users';

const Users = () => {
  const { instance, accounts } = useMsal();
  const classes = useStyles();
  const navigate = useNavigate();
  const isSuperAdmin = useHasRoles([ROLE.SUPER_ADMIN_IT]);
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('selectedTab');

  const [totalUsers, setTotalUsers] = useState('');
  const [syncStatus, setSyncStatus] = useState(SYNC_STATUS.IDLE);

  const [syncGraphUser] = useLazySyncUsersQuery();

  const tabs = [
    {
      heading: TAB_TYPES.ACTIVE,
      content: <ActiveTab onTotalUsers={(v) => setTotalUsers(v)} />,
    },
    {
      heading: TAB_TYPES.ARCHIVE,
      content: <ArchiveTab onTotalUsers={(v) => setTotalUsers(v)} />,
    },
  ];

  const onSyncClick = async () => {
    setSyncStatus(SYNC_STATUS.SYNCING);
    const request = {
      ...userReadRequest,
      account: accounts[0],
    };

    await instance
      .acquireTokenSilent(request)
      .then(async (response) => {
        if (response.accessToken) {
          await syncGraphUser({
            headers: { graphaccesstoken: response.accessToken },
          })
            .then(async (resp) => {
              if (resp.isSuccess) {
                setSyncStatus(SYNC_STATUS.SUCCESS);
              } else if (resp.isError) {
                setSyncStatus(SYNC_STATUS.FAILURE);
              }
            })
            .catch((error) => {
              console.error('error syncing users ', error);
              setSyncStatus(SYNC_STATUS.FAILURE);
            });
        } else {
          setSyncStatus(SYNC_STATUS.FAILURE);
        }
      })
      .catch((error) => {
        console.error('error getting graph token', error);
        setSyncStatus(SYNC_STATUS.FAILURE);
      });
  };

  const onTabChange = (newValue) => {
    navigate(`/${routeConstants.USERS_ROUTE}?selectedTab=${newValue}`, {
      replace: true,
    });
  };

  return (
    <React.Fragment>
      <GridContainer className={classes.pageName} justifyContent={'space-between'}>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h1">{displayName}</Typography>
          </GridItem>
          <Typography pl={2} variant="subtitle2" className={classes.totalUser}>
            {`${totalUsers ? `${totalUsers} Users` : ''}`}
          </Typography>
        </GridContainer>
        {isSuperAdmin && (
          <GridItem pr={3}>
            <Button onClick={onSyncClick} variant={'contained'} className={classes.syncButton}>
              <CachedOutlined className={classes.syncButtonText} />
            </Button>
          </GridItem>
        )}
      </GridContainer>

      <Box component={Paper} className={classes.usersTable}>
        <Tabs defaultTab={selectedTab === null ? '1' : selectedTab} tabsData={tabs} onTabChange={onTabChange} />
      </Box>
      <Modal
        open={[SYNC_STATUS.SYNCING, SYNC_STATUS.FAILURE, SYNC_STATUS.SUCCESS].includes(syncStatus)}
        closeIcon={[SYNC_STATUS.FAILURE, SYNC_STATUS.SUCCESS].includes(syncStatus)}
        onClose={() => setSyncStatus(SYNC_STATUS.IDLE)}
      >
        <SyncingModalBody syncStatus={syncStatus} />
      </Modal>
    </React.Fragment>
  );
};

Users.displayName = displayName;
export default Users;
