import { GridContainer, GridItem, InformationField, InformationPad } from '../../../components/shared';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { Link } from '../../../components/shared';
import { useMemo } from 'react';
import { MODEL_DETAIL_LABEL } from '../../../constants/accordionFields';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from './style';

const ModelAccordion = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles();

  const fields = useMemo(
    () =>
      Object.values(MODEL_DETAIL_LABEL.LIST).map((key) => {
        let value = data[key];
        if (key === MODEL_DETAIL_LABEL.LIST.HEIGHT || key === MODEL_DETAIL_LABEL.LIST.WIDTH) {
          value = `${data[key]} inch`;
        } else if (key === MODEL_DETAIL_LABEL.LIST.WEIGHT) {
          value = `${data[key]} lbs`;
        }
        return { label: key, value: value };
      }),
    [data],
  );

  return (
    <Box
      component={Paper}
      py={1}
      sx={{
        border: `1px solid ${theme.palette.grey[900]}`,
        borderRadius: '8px',
      }}
      className={classes.modelDetailAccordion}
    >
      <GridContainer>
        <GridItem xs={12} md={12}>
          <Typography variant="h3" mb={1}>
            Model Details
          </Typography>
        </GridItem>
      </GridContainer>
      <InformationPad data={fields} />
      <GridContainer my={3} ml={1} px={3}>
        <GridItem xs={12}>
          <InformationField
            fontSize="14px"
            fontWeight="500"
            lineHeight="16px"
            value={
              <Link variant="link" underline="none" color="primary" to={`/${routeConstants.MODELS_ROUTE}/${data.id}`}>
                VIEW FULL DETAILS
              </Link>
            }
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
};
export default ModelAccordion;
