import newCollectionSlice from './newCollection-slice';
export const {
  setTotalCollected,
  setTotalTicketDispensed,
  setTotalTokenCollected,
  setCollectionCollected,
  setTransformedSublocations,
  setSummary,
  setTotalPrizeDispensed,
  setTotalMedallionDispensed,
  setFormikData,
  setIntercardData,
  setLocationCurrency,
  setSublocationDates,
  setSubLocationDatesFormIsDirty,
  resetState,
  resetSubLocationDates,
} = newCollectionSlice.actions;

export default newCollectionSlice;
