import { Box, Paper, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridContainer, GridItem, Breadcrumbs, Datepicker, Button, Modal } from '../../components/shared';
import BREADCRUMB_NAMES from '../../constants/breadcrumbNames';
import { routeConstants } from '../../constants/routeConstants';
import { intercardValidationSchema } from '../../schema/validationSchemas';
import { useImportIntercardDataMutation } from '../../services/Intercard/IntercardSerice';
import { dateFormatForApi } from '../../utils/common-methods';
import IntercardTrackingsTable from './IntercardTrackingsTable';
import useStyles from './style';

const IntercardFeed = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openPopup, setOpenPopUp] = useState(false);
  const {
    SETTINGS: { IMPORT_INTERCARD_FEED, SETTINGS },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [{ text: SETTINGS, redirection: routeConstants.SETTING_ROUTE }, { text: IMPORT_INTERCARD_FEED }],
    [],
  );
  const [importIntercardApi, { isSuccess }] = useImportIntercardDataMutation();
  const formik = useFormik({
    initialValues: {
      fromDate: '',
      toDate: '',
    },
    validationSchema: intercardValidationSchema,
    onSubmit: (data) => {
      const dataForApi = {
        startDate: dateFormatForApi(data?.fromDate),
        endDate: dateFormatForApi(data?.toDate),
      };
      importIntercardApi(dataForApi);
    },
  });

  const { values, errors, touched, setFieldValue } = formik;

  const onCancelHandler = () => {
    navigate(`/${routeConstants.SETTING_ROUTE}`);
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenPopUp(true);
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      <Typography mt={'34px'} variant="h1">
        Import Intercard Feed
      </Typography>
      <Box component={Paper} className={classes.formContent}>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer rowSpacing={4} columnSpacing={4}>
            <GridItem xs={12} md={2}>
              <Datepicker
                label="From Date"
                value={values.fromDate ? new Date(values.fromDate) : null}
                inputFormat="MM/DD/YYYY"
                InputProps={{
                  'data-testid': 'form-elements',
                }}
                onChange={(newValue) => setFieldValue('fromDate', newValue)}
                onBlur={() => formik.setFieldTouched('fromDate')}
                error={Boolean(touched.fromDate && errors.fromDate)}
                helperText={touched.fromDate && errors.fromDate}
                disableFuture
              />
            </GridItem>
            <GridItem xs={12} md={2}>
              <Datepicker
                label="To Date"
                value={values.toDate ? new Date(values.toDate) : null}
                InputProps={{
                  'data-testid': 'form-elements',
                }}
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => setFieldValue('toDate', newValue)}
                onBlur={() => formik.setFieldTouched('toDate')}
                error={Boolean(touched.toDate && errors.toDate)}
                helperText={touched.toDate && errors.toDate}
                disableFuture
              />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="space-around" pl={2} pt={2}>
            <GridItem xs={12} md={12}>
              <Stack direction={'row'} className={classes.btn}>
                <Button type="Submit" variant="contained" data-testid="importBtn">
                  {'IMPORT'}
                </Button>
                <Button onClick={onCancelHandler}>{'CANCEL'}</Button>
              </Stack>
            </GridItem>
          </GridContainer>
        </form>
        <IntercardTrackingsTable />
      </Box>
      <Modal
        open={openPopup}
        customWidth="400px"
        onClose={() => setOpenPopUp(false)}
        closeIcon={true}
        className={classes.popupData}
      >
        <Typography variant="subtitle5" textAlign="center" pt={10}>
          The import process has been started. <br /> You can close this popup.
        </Typography>
      </Modal>
    </React.Fragment>
  );
};

export default IntercardFeed;
