import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { formatPhNo10Digit, getOptions, getPhNo10Digits } from '../../../utils/common-methods';
import { EditRemitSchema } from '../../../schema/validationSchemas';
import { Box, Paper, Typography } from '@mui/material';
import {
  useGetAllCountriesQuery,
  useLazyGetCountryStatesByCountryIdQuery,
  useLazyGetStateCitiesByStateIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import { Button, GridContainer, GridItem, Select, TextField } from '../../../components/shared';
import { LoadingButton } from '@mui/lab';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import { useUpdateSubLocationByIdMutation } from '../../../services/Sublocation/SublocationService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';

const EditRemitUpdateView = (props) => {
  const { subLocationDetails } = props;
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('locationId');
  const navigate = useNavigate();
  const [updateSubLocationApi, { isLoading: updating }] = useUpdateSubLocationByIdMutation();
  const [getCitiesByStateId, citiesResponseByStateId] = useLazyGetStateCitiesByStateIdQuery();
  const [getStatesByCountryId, statesResponseByCountryId] = useLazyGetCountryStatesByCountryIdQuery();
  const { currentData: countriesResponse, isSuccess: countriesAPISuccess } = useGetAllCountriesQuery();

  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);

  useEffect(() => {
    if (countriesAPISuccess) {
      setCountryData(getOptions(countriesResponse));
    }
  }, [countriesResponse]);

  useEffect(() => {
    if (statesResponseByCountryId.isSuccess) {
      setStatesData(getOptions(statesResponseByCountryId.currentData?.data?.states));
    }
  }, [statesResponseByCountryId.currentData]);

  useEffect(() => {
    if (citiesResponseByStateId.isSuccess) {
      setCitiesData(getOptions(citiesResponseByStateId.currentData?.data?.cities));
    }
  }, [citiesResponseByStateId.currentData]);

  const formik = useFormik({
    initialValues: {
      firstName: subLocationDetails?.remitFirstName || '',
      lastName: subLocationDetails?.remitLastName || '',
      zipCode: subLocationDetails?.remitZip || '',
      phone1: formatPhNo10Digit(subLocationDetails?.remitPhone1) || '',
      phone2: formatPhNo10Digit(subLocationDetails?.remitPhone2) || '',
      email: subLocationDetails?.remitEmail || '',
      country: subLocationDetails?.remitCountryId || '',
      state: subLocationDetails?.remitStateId || '',
      city: subLocationDetails?.remitCityId || '',
      address1: subLocationDetails?.remitAddress1 || '',
      address2: subLocationDetails?.remitAddress2 || '',
    },
    validationSchema: EditRemitSchema,
    onSubmit: (values) => {
      const data = {
        id: Number(id),
        remitFirstName: values.firstName,
        remitLastName: values.lastName,
        remitZip: values.zipCode,
        remitPhone1: getPhNo10Digits(values.phone1),
        remitPhone2: getPhNo10Digits(values.phone2),
        remitEmail: values.email,
        remitCountryId: values.country,
        remitStateId: values.state,
        remitCityId: values.city,
        remitAddress1: values.address1,
        remitAddress2: values.address2,
      };

      updateSubLocationApi(data).then((res) => {
        if (res.data) {
          navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${Number(id)}?locationId=${locationId}`);
        }
      });
    },
  });
  const { getFieldProps, handleSubmit, errors, touched, setFieldValue } = formik;

  useEffect(() => {
    if (subLocationDetails?.remitCountryId) {
      getStatesByCountryId(subLocationDetails?.remitCountryId);
    }
    if (subLocationDetails?.remitStateId) {
      getCitiesByStateId(subLocationDetails?.remitStateId);
    }
  }, [subLocationDetails]);

  const onCancelClickHandler = () => {
    navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${Number(id)}?locationId=${locationId}`);
  };

  const onCityStateAndCountryChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setFieldValue(name, value);
    if (name === 'country') {
      getStatesByCountryId(value);
      setFieldValue('state', '');
      setFieldValue('city', '');
    }
    if (name === 'state') {
      getCitiesByStateId(value);
      setFieldValue('city', '');
    }
  };
  return (
    <Box component={Paper} p={3}>
      <Box mb={3} px={2}>
        <Typography variant="h2">Remit Details</Typography>
      </Box>
      <Box>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <GridContainer m={1} rowSpacing={3} columnSpacing={3} alignItems="center">
              <GridItem xs={12} md={6}>
                <TextField
                  label="First Name"
                  {...getFieldProps('firstName')}
                  InputProps={{
                    'data-testid': 'remitDetailsForm',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Last Name"
                  {...getFieldProps('lastName')}
                  InputProps={{
                    'data-testid': 'remitDetailsForm',
                  }}
                />
              </GridItem>

              <GridItem xs={12} md={6}>
                <TextField
                  label="Address 1"
                  {...getFieldProps('address1')}
                  InputProps={{
                    'data-testid': 'remitDetailsForm',
                  }}
                  multiline
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Address 2"
                  {...getFieldProps('address2')}
                  InputProps={{
                    'data-testid': 'remitDetailsForm',
                  }}
                  multiline
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Zip code"
                  multiline
                  maxRows={3}
                  {...getFieldProps('zipCode')}
                  InputProps={{
                    'data-testid': 'remitDetailsForm',
                  }}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  helperText={touched.zipCode && errors.zipCode}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Country"
                  options={countryData}
                  {...getFieldProps('country')}
                  data-testid="remitDetailsForm"
                  onChange={onCityStateAndCountryChangeHandler}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="State"
                  options={statesData}
                  {...getFieldProps('state')}
                  data-testid="remitDetailsForm"
                  onChange={onCityStateAndCountryChangeHandler}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="City"
                  options={citiesData}
                  {...getFieldProps('city')}
                  data-testid="remitDetailsForm"
                  onChange={onCityStateAndCountryChangeHandler}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Phone 1"
                  {...getFieldProps('phone1')}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    'data-testid': 'remitDetailsForm',
                  }}
                  error={Boolean(touched.phone1 && errors.phone1)}
                  helperText={touched.phone1 && errors.phone1}
                />
              </GridItem>

              <GridItem xs={12} md={6}>
                <TextField
                  label="Phone 2"
                  {...getFieldProps('phone2')}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    'data-testid': 'remitDetailsForm',
                  }}
                  error={Boolean(touched.phone2 && errors.phone2)}
                  helperText={touched.phone2 && errors.phone2}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Email"
                  {...getFieldProps('email')}
                  InputProps={{
                    'data-testid': 'remitDetailsForm',
                  }}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-around" m={1} spacing={2}>
              <GridItem xs={12}>
                <LoadingButton loading={updating} variant="contained" type="submit">
                  UPDATE
                </LoadingButton>
                <Button onClick={onCancelClickHandler}>CANCEL</Button>
              </GridItem>
            </GridContainer>
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  );
};

export default EditRemitUpdateView;
