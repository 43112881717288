import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  activeModels: {
    [FILTER_VALUES.CATEGORY]: [],
    [FILTER_VALUES.THEME]: [],
    [FILTER_VALUES.MANUFACTURER]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const modelsFilterSlice = createSlice({
  name: 'modelsFilter',
  initialState,
  reducers: {
    setActiveModelsFilters(state, action) {
      state.activeModels[FILTER_VALUES.CATEGORY] = action.payload?.categoryId || [];
      state.activeModels[FILTER_VALUES.THEME] = action.payload?.themeId || [];
      state.activeModels[FILTER_VALUES.MANUFACTURER] = action.payload?.manufactureId || [];
      state.activeModels.searchText = action.payload?.searchText || '';
      state.activeModels.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.activeModels.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetActiveModelsFiltersState(state) {
      state.activeModels = { ...initialState.activeModels, searchText: state.activeModels.searchText };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default modelsFilterSlice;
