import { Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useStyles from './style';

const ButtonDropdown = (props) => {
  const {
    btnProps = { variant: 'contained', endIcon: <KeyboardArrowDownIcon /> },
    options = [],
    value = '',
    onChange = () => {},
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const index = options?.findIndex((x) => x?.value === value);

    setSelectedOption(options[index]);
  }, [options, value]);

  const onButtonClickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseHandler = () => {
    setAnchorEl(null);
  };
  const onItemClickHandler = (currentIndex) => {
    const selectedObj = options[currentIndex];
    setSelectedOption(selectedObj);
    onChange(selectedObj);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="dropdown-button"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onButtonClickHandler}
        {...btnProps}
        data-testid="dropdown_Btn"
      >
        {selectedOption?.text ?? ''}
      </Button>
      <Menu
        id="dropdown-menu"
        aria-labelledby="dropdown-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseHandler}
        sx={{ minWidth: 180 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.mainMenu}
      >
        {options?.length > 0 &&
          options?.map(({ value, text }, index) => (
            <MenuItem
              onClick={() => {
                onItemClickHandler(index);
              }}
              value={value}
              key={`${value}_${index}`}
              className={classes.menuItem}
              data-testid="menuItem"
            >
              {text}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default ButtonDropdown;
