import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ReactTable from '../../../components/shared/ReactTable';
import { COLUMNS } from '../../../constants/tableColumns';
import { useGetModemsQuery } from '../../../services/Modems/ModemsService';
import FIELD_TYPE from '../../../constants/fieldType';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { CheckBox, NoContent, PositionedMenu } from '../../../components/shared';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import useStyles from './style';
import { getDateFormat } from '../../../utils/common-methods';

const { NOT_APPLICABLE } = FIELD_TYPE;

const AssignNewModemTable = ({ onSelect, q, selectedRows, setIsSuccess, onDeleteModem }) => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilterData, setSearchFilterData] = useState(q);
  const [skipCall, setSkipCall] = useState(false);
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();
  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useGetModemsQuery(
    {
      searchParams: `q=${searchFilterData}`,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );

  const COLUMN_POSITIONED_MENU = [
    {
      Header: () => null,
      id: 'more',
      Cell: ({ row }) => (
        <Box component="span" className={!row.original.status ? classes.archive : ''}>
          <PositionedMenu
            menuItemData={[
              {
                btnName: 'Delete Modem',
                btnClickFunc: () => {
                  onDeleteModem(row.original);
                },
              },
            ]}
            disabled={selectedRows[row.original.id]}
          />
        </Box>
      ),
    },
  ];

  const MODEMS_COLUMNS = [
    {
      id: 'selection Location',
      Header: () => null,
      width: 50,
      Cell: ({ row }) => (
        <>
          <CheckBox
            onChange={(e) => onSelect(row.original, e.target.checked)}
            checked={selectedRows[row.original.id]}
          />
        </>
      ),
    },
    ...COLUMNS.ASSIGN_MODEM_TABLE,
    ...COLUMN_POSITIONED_MENU,
  ];

  useEffect(() => {
    setIsSuccess(isSuccess);
    if (isSuccess && latestData) {
      const data = latestData?.modems?.map((modem) => {
        const machineName = modem?.assetId ? modem?.asset?.title || '' : NOT_APPLICABLE;
        const installedDate = modem.installedDate ? getDateFormat(modem.installedDate) : NOT_APPLICABLE;
        return { ...modem, assetName: machineName, installedDate: installedDate };
      });
      setTableData(data);
      let total = latestData?.pagination?.total ?? 0;
      setTotalCount(total);
    }
  }, [isSuccess, latestData]);

  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(q);
    setCurrentPage(DEFAULT_PAGE);
    setSkipCall(false);
  }, [q]);
  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };
  return (
    <Box className={classes.assignModemTableWrapper}>
      {isLoading && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Modem Found"
          desc={(isError && error?.data?.message) || <>There are no modem present with this filter.</>}
        />
      )}
      {!isLoading && !isError && tableData && (
        <ReactTable
          data={tableData}
          columns={MODEMS_COLUMNS}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          renderRows
        />
      )}
    </Box>
  );
};

export default AssignNewModemTable;
