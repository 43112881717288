import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './style';

const Modal = (props) => {
  const {
    title = '',
    titleVariant = 'h2',
    children = <></>,
    buttonPosition = 'left',
    actionButtons = <></>,
    open = false,
    onClose = () => {},
    closeIcon = false,
    dividers = false,
    fullScreen = false,
    isBreakPoint = false,
    fullScreenBreakPoint = 'sm',
    maxWidth = 'sm',
    customWidth = '',
    ...rest
  } = props;
  const theme = useTheme();
  const fullScreenSize = useMediaQuery(theme.breakpoints.down(fullScreenBreakPoint));
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason == 'backdropClick') return;
        onClose();
      }}
      aria-labelledby="modal-title"
      fullScreen={fullScreen ? fullScreen : isBreakPoint ? fullScreenSize : false}
      fullWidth
      maxWidth={maxWidth}
      disableEscapeKeyDown
      className={classes.modalWrapper}
      {...rest}
      sx={{
        ' .MuiPaper-root': Boolean(customWidth) && { maxWidth: customWidth },
      }}
      data-testid="modal"
    >
      <DialogTitle id="modal-title" variant={titleVariant} className={classes.modalTitle}>
        {title}
        {closeIcon && (
          <IconButton aria-label="close" data-testid="close-icon" onClick={onClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers={dividers} className={classes.modalContent} data-testid="modal-content">
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent:
            buttonPosition === 'left' ? 'space-between' : buttonPosition === 'center' ? 'center' : 'flex-end',
        }}
        className={classes.modalAction}
        data-testid="modal-actions"
      >
        {actionButtons}
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.any,
  titleVariant: PropTypes.string,
  children: PropTypes.any,
  buttonPosition: PropTypes.string,
  actionButtons: PropTypes.element,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  closeIcon: PropTypes.bool,
  dividers: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isBreakPoint: PropTypes.bool,
  fullScreenBreakPoint: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default Modal;
