// Test Commit
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HighlightOff } from '@mui/icons-material';
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  ControlledTooltips,
  GridContainer,
  GridItem,
  TextField,
} from '../../../components/shared';
import {
  checkObjectNotEmpty,
  checkValueNotNullUndefinedBlank,
  getCurrency,
  isArrayWithLength,
  roundOffValue,
} from '../../../utils/common-methods';
import {
  setAccountSummaryData,
  setAccountSummaryError,
  setAccountSummaryFormDirty,
  setDefaultAccountSummaryState,
  setReadFromDraftAccountSummary,
} from '../../../redux-slice/reconciliationCollection';
import useStyles from './style';
import {
  getAccountSummaryData,
  getConditionToRecalculateRentVariable,
  getDebitAndCreditValueForAccountSummary,
  getRunningTotalValue,
  getSortedAccountSummaryForApprovedTab,
  getUpdatedAccountSummaryWithChangeInSalesTaxGrossSale,
  getUpdatedARAccountDataWithSummary,
  getUpdatedVariableFieldsData,
  getVariableAccountSummaryFields,
  getVariableFieldArray,
  getVisibleRows,
} from '../../../utils/collection-methods';
import { ACCOUNT_FIELDS, INITIAL_ACCOUNT_SUMMARY_STATE, NOT_AR_TYPES } from '../../../constants/accountSummary';
import { addNewAccountSummaryRow } from '../../../schema/validationSchemas';
import { MESSAGE } from '../../../constants/message';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';

/**
 * Represents the Account Summary component.
 *
 * @typedef {Object} AccountSummary
 * @property {string} sublocation - The sublocation of the account summary.
 * @property {boolean} [isApproved=false] - Indicates whether the account summary is approved.
 * @property {boolean} [isVerified=false] - Indicates whether the account summary is verified.
 * @property {boolean} [isReconciliation=false] - Indicates whether the account summary is for reconciliation.
 * @property {Array} [accountSummaryDefault=[]] - The default account summary.
 * @property {Object} searchState - The search state of the account summary.
 * @property {boolean} [formIsDirty=false] - Indicates whether the form has been updated w.r.t. original value.
 * @property {boolean} [readFromDraft=false] - Indicates whether to read from draft.
 * @property {function} setReadFromDraft - Setter function for readFromDraft.
 * @property {Object} sortState - The sort state of the account summary.
 */

const AccountSummary = ({
  sublocation,
  isApproved = false,
  isVerified = false,
  isReconciliation = false,
  accountSummaryDefault = [],
  searchState,
  formIsDirty = false,
  readFromDraft = false,
  setReadFromDraft = () => {},
  sortState,
}) => {
  // Hooks and useState calls for dispatch and state management
  const dispatch = useDispatch();
  const classes = useStyles();

  // These selectors are pulling data from the Redux state
  const { subTotalRevenues, accountSummary, locationPaysTax } = useSelector((state) => state.reconciliationCollection);
  const { playCards } = useSelector((state) => state.playCard);

  // Here we initialize variableSummaryFields state and use useFormik hook to manage form state
  const [variableSummaryFields, setVariableSummaryFields] = useState({});
  const formik = useFormik({
    initialValues: {
      // Setting initial form state from props and based on conditions
      accountSummaryState:
        isArrayWithLength(accountSummaryDefault) && !searchState
          ? getSortedAccountSummaryForApprovedTab([...accountSummaryDefault])
          : searchState && accountSummary && isArrayWithLength(accountSummary?.data[sublocation?.id])
          ? Object.values(accountSummary?.data[sublocation?.id])
          : INITIAL_ACCOUNT_SUMMARY_STATE,
    },
    validationSchema: addNewAccountSummaryRow,
    enableReinitialize: true,
  });

  // Following hooks consume data from the formik state
  const {
    values: { accountSummaryState },
    touched,
    errors,
    setValues,
    setFieldValue,
  } = formik;
  // Define isTaxable flag based on if sub-location is tax-exempt or if location pays tax
  const isTaxPayable = useMemo(
    () => Boolean(!sublocation.taxExemptFlag && locationPaysTax?.[sublocation?.id]),
    [sublocation.taxExemptFlag, locationPaysTax?.[sublocation?.id]],
  );

  // This React effect initiates behavior when account summary defaults are provided for reconciliation
  // Deps: accountSummaryDefault
  useEffect(() => {
    //First step: set initial account summary
    if (isReconciliation && isArrayWithLength(accountSummaryDefault)) {
      const initialSummary = getSortedAccountSummaryForApprovedTab(accountSummaryDefault);
      dispatch(setDefaultAccountSummaryState(true));
      setValues({ accountSummaryState: [...initialSummary] });
    }
  }, [accountSummaryDefault]);

  // This React effect initiates behavior when in reconciliation mode but not verified and not in search state
  // On Render, No Deps.
  useEffect(() => {
    if (isReconciliation) {
      if (isReconciliation && !isVerified && !searchState) {
        // Second step: setVariableSummaryFields and formik values according to variable fields
        if (accountSummary && !accountSummary?.searchState) {
          const variableFields = getVariableAccountSummaryFields({
            readFromDraft,
            formIsDirty,
            taxExemptFlag: isTaxPayable,
            sublocation,
          });
          const variableFieldArray = getVariableFieldArray(variableFields);
          const initialSummary = [...INITIAL_ACCOUNT_SUMMARY_STATE];
          initialSummary?.splice(2, 0, ...variableFieldArray);
          setVariableSummaryFields({ ...variableFields });
          setValues({ accountSummaryState: [...initialSummary] });
        }
      }
      if (searchState) {
        // In search mode, update variable fields using redux stored accountSummary data
        const variableFields = getVariableAccountSummaryFields({
          readFromDraft,
          formIsDirty,
          taxExemptFlag: isTaxPayable,
          sublocation,
        });
        const variableFieldsData = getUpdatedVariableFieldsData(variableFields, [
          ...accountSummary?.data[sublocation?.id],
        ]);
        setVariableSummaryFields({ ...variableFieldsData });
        setValues({ accountSummaryState: [...accountSummary?.data[sublocation?.id]] });
      }
    }
  }, []);

  // This React effect applies the drafted summary if the user clicks on read from draft
  // Deps: readFromDraft, playCards, subTotalRevenues
  useEffect(() => {
    if (readFromDraft) {
      //Apply drafted summary if user clicks on read from draft
      const reduxSummary = getSortedAccountSummaryForApprovedTab([...accountSummary?.storedSummary[sublocation?.id]]);
      const temp = [...reduxSummary];
      setReadFromDraft(false);
      dispatch(setAccountSummaryData({ [sublocation.id]: [...temp] }));
      setValues({ accountSummaryState: [...temp] });
    }
  }, [readFromDraft, playCards, subTotalRevenues]);

  // This React effect initiates behavior when the account summary defaults are modified for reconciliation
  // Deps: accountSummaryDefault
  useEffect(() => {
    //First step: set initial account summary
    if (isReconciliation) {
      const initialSummary = getSortedAccountSummaryForApprovedTab(accountSummaryDefault);
      setValues({ accountSummaryState: [...initialSummary] });
    }
  }, [accountSummaryDefault]);

  // This React effect initiates behavior when the sub-location is updated by approval or verification or in search state
  // Deps: sublocation
  useEffect(() => {
    //set account summary to api data, when we receive account summary from api
    if ((isApproved || isVerified) && !searchState) {
      if (
        (isArrayWithLength(sublocation?.accountSumDetails) && isApproved) ||
        (isArrayWithLength(sublocation?.accountSumDetails) && !sortState)
      ) {
        const accountSummary = getSortedAccountSummaryForApprovedTab(sublocation?.accountSumDetails);
        setValues({ accountSummaryState: accountSummary });
      }
    }
    if (searchState) {
      setValues({ accountSummaryState: [...accountSummary?.data[sublocation?.id]] });
      const variableFields = getVariableAccountSummaryFields({
        readFromDraft,
        formIsDirty,
        taxExemptFlag: isTaxPayable,
        sublocation,
      });
      const variableFieldsData = getUpdatedVariableFieldsData(variableFields, [
        ...accountSummary?.data[sublocation?.id],
      ]);
      setVariableSummaryFields({ ...variableFieldsData });
    }
  }, [sublocation]);

  // Several logical operations.
  // Deps: subTotalRevenues, playCards, readFromDraft, formIsDirty
  useEffect(() => {
    if (
      isReconciliation &&
      accountSummary &&
      isArrayWithLength(accountSummaryState) &&
      !accountSummary?.defaultAccountSummaryState
    ) {
      // Third step: update account summary data when subTotalRevenues and playCards change
      let temp = [...accountSummaryState];
      let variableFields = { ...variableSummaryFields };
      //Recalculate variableFieldsData only when variable fields are blank or account summary is set to api data
      if (
        (!checkObjectNotEmpty(variableFields) && isArrayWithLength(accountSummaryDefault)) ||
        JSON.stringify(accountSummaryState) ===
          JSON.stringify(getSortedAccountSummaryForApprovedTab(accountSummaryDefault))
      ) {
        temp = searchState
          ? [...accountSummaryState]
          : getSortedAccountSummaryForApprovedTab([...accountSummaryDefault]);
        let initialVariableFields = getVariableAccountSummaryFields({
          readFromDraft,
          formIsDirty,
          taxExemptFlag: isTaxPayable,
          sublocation,
        });
        variableFields = getUpdatedVariableFieldsData(initialVariableFields, temp);
      }
      //Recalculate variableFieldsData when variable fields are blank and search state is true
      if (!checkObjectNotEmpty(variableFields) && accountSummary && searchState) {
        if (
          sublocation &&
          checkObjectNotEmpty(accountSummary?.data) &&
          isArrayWithLength(accountSummary?.data[sublocation?.id])
        ) {
          const reduxSummary = getSortedAccountSummaryForApprovedTab([...accountSummaryState]);
          let initialVariableFields = getVariableAccountSummaryFields({
            readFromDraft,
            formIsDirty,
            taxExemptFlag: isTaxPayable,
            sublocation,
          });
          variableFields = getUpdatedVariableFieldsData(initialVariableFields, reduxSummary);
        }
      }
      if (accountSummary && accountSummary?.data[sublocation?.id]) {
        const reduxSummary = getSortedAccountSummaryForApprovedTab([...accountSummary?.data[sublocation?.id]]);
        let initialVariableFields = getVariableAccountSummaryFields({
          readFromDraft,
          formIsDirty,
          taxExemptFlag: isTaxPayable,
          sublocation,
        });
        variableFields = getUpdatedVariableFieldsData(initialVariableFields, reduxSummary);
        temp = [...reduxSummary];
      }
      const debitAndCreditValuesForSublocation = getDebitAndCreditValueForAccountSummary(subTotalRevenues);
      const summaryState = accountSummary && accountSummary?.data[sublocation?.id] ? [...temp] : accountSummaryState;

      const { newAccountSummaryData, updatedVariableFieldData } = getAccountSummaryData(
        isTaxPayable,
        debitAndCreditValuesForSublocation,
        sublocation,
        playCards[sublocation?.id],
        summaryState,
        variableFields,
        readFromDraft,
        formIsDirty,
      );
      dispatch(setReadFromDraftAccountSummary({ [sublocation?.id]: false }));
      const variableFieldLength = Object.keys(updatedVariableFieldData)?.length;
      const spliceLength = Number(variableFieldLength + 3);
      temp?.splice(0, spliceLength, ...newAccountSummaryData);
      setVariableSummaryFields({ ...updatedVariableFieldData });
      //Adjust running total and map it to A/R account number
      const newAccountSummary = getUpdatedARAccountDataWithSummary(temp);
      setValues({ accountSummaryState: [...newAccountSummary] });
      dispatch(setAccountSummaryData({ [sublocation.id]: [...newAccountSummary] }));
    } else if (accountSummary?.defaultAccountSummaryState && !readFromDraft) {
      if (readFromDraft && accountSummary && accountSummary?.storedSummary) {
        //If readfromDraft is true give preference to drafted summary over default summary
        const reduxSummary = getSortedAccountSummaryForApprovedTab([...accountSummary?.storedSummary[sublocation?.id]]);
        const temp = [...reduxSummary];
        setValues({ accountSummaryState: [...temp] });
        dispatch(setAccountSummaryData({ [sublocation?.id]: [...temp] }));
      } else if ((!checkObjectNotEmpty(accountSummary?.storedSummary) && !sortState) || isApproved) {
        const initialSummary = getSortedAccountSummaryForApprovedTab(accountSummaryDefault);
        setValues({ accountSummaryState: [...initialSummary] });
        dispatch(setAccountSummaryData({ [sublocation?.id]: [...initialSummary] }));
      }
    }
  }, [subTotalRevenues, playCards, readFromDraft, formIsDirty]);

  // Handles logic for updating errors.
  // Deps: errors
  useEffect(() => {
    const errorPresent = !!errors.accountSummaryState?.some((item) => item !== undefined);
    dispatch(setAccountSummaryError({ [sublocation.id]: errorPresent }));
  }, [errors]);

  // Handles state updates and debounce updates.
  useEffect(() => {
    const dispatchSummary = setTimeout(() => {
      dispatch(setAccountSummaryData({ [sublocation.id]: [...accountSummaryState] }));
      if (accountSummary && !searchState) {
        if (checkObjectNotEmpty(variableSummaryFields)) {
          const newData = getUpdatedVariableFieldsData(variableSummaryFields, accountSummaryState);
          setVariableSummaryFields({ ...newData });
        }
      }
      if (checkObjectNotEmpty(variableSummaryFields) && searchState) {
        const newData = getUpdatedVariableFieldsData(variableSummaryFields, accountSummaryState);
        setVariableSummaryFields({ ...newData });
      }
      if (!checkObjectNotEmpty(variableSummaryFields)) {
        let initialVariableFields = getVariableAccountSummaryFields({
          readFromDraft,
          formIsDirty,
          taxExemptFlag: isTaxPayable,
          sublocation,
        });
        let variableFields = getUpdatedVariableFieldsData(initialVariableFields, accountSummaryState);
        setVariableSummaryFields({ ...variableFields });
      }
    }, 650);
    return () => clearTimeout(dispatchSummary);
  }, [accountSummaryState]);

  // Sum total of account summary
  const total = useMemo(() => {
    let total = 0;
    if (accountSummaryState) {
      total = getRunningTotalValue(accountSummaryState);
    }
    return roundOffValue(total || 0);
  }, [accountSummaryState]);

  // `useMemo` is used here to optimize performance by memorizing the result of an operation and returning
  // the memorized result when the same input(s) occur(s). In this case, this hook will remember `accountNumbers`
  // array and will recompute it only when `sublocation` changes.
  const accountNumbers = useMemo(() => {
    // Using optional chaining to safely access `sublocation.accountNumbers` array
    // each `value` represents an account in `accountNumbers` array
    // we are mapping over the array to return a new array where each item is an object that
    // contains keys(id, name, text, value) and their respective values
    const sublocAccountNumbers = sublocation?.accountNumbers?.map((value) => ({
      id: value?.id,
      name: value?.accountNumber?.trim(),
      text: value?.accountNumber?.trim(),
      value: value?.id,
    }));

    // This part of the function checks if `sublocation` is not an empty object and `sublocAccountNumbers`
    // is an array with at least one item. If the condition is met, it appends a 'None' option to the array.
    // If not, it only returns 'None' option
    return checkObjectNotEmpty(sublocation) && isArrayWithLength(sublocAccountNumbers)
      ? [
          ...sublocAccountNumbers,
          {
            name: 'None',
            id: '',
            text: 'None',
            value: '',
          },
        ]
      : [
          {
            name: 'None',
            id: '',
            text: 'None',
            value: '',
          },
        ];
    // When `sublocation` changes the `useMemo` hook will recompute
  }, [sublocation]);

  // Using a memoized value for getting the account names from sub-location
  // It generates an object where keys are the account id, and values are the account names
  const names = useMemo(() => {
    let obj = {};
    sublocation?.accountNumbers?.map((value) => {
      obj[value.id] = value?.accountName;
    });
    return obj;
  }, [sublocation]);

  // Button handlers and render logic
  const onChange = (index, changedValue) => {
    const tempAccountSummaryState = [...accountSummaryState];
    tempAccountSummaryState[index] = { ...tempAccountSummaryState[index] };
    tempAccountSummaryState[index]['accountNumber'] =
      checkObjectNotEmpty(changedValue) && checkValueNotNullUndefinedBlank(changedValue?.value) ? changedValue : null;
    tempAccountSummaryState[index]['name'] = ![undefined, ''].includes(changedValue?.value)
      ? names[changedValue?.value]
      : '';
    setValues({ accountSummaryState: tempAccountSummaryState });
    dispatch(setAccountSummaryData({ [sublocation.id]: tempAccountSummaryState }));
    dispatch(setDefaultAccountSummaryState(false));
  };

  const onChangeHandler = (name, value) => {
    dispatch(setDefaultAccountSummaryState(false));
    dispatch(setAccountSummaryFormDirty(true));
    if ([ACCOUNT_FIELDS.CREDIT, ACCOUNT_FIELDS.DEBIT].includes(name?.split('.')[2])) {
      // restrict user to put only two decimal values.
      const twoDecimalValues = value
        ?.toString()
        ?.split('.')
        ?.map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
        .join('.');
      setFieldValue(name, twoDecimalValues);
      //Adjust running total and map it to A/R account number and update rent
      let index = name?.split('.')[1];
      let fieldName = name?.split('.')[2];
      if (
        index &&
        NOT_AR_TYPES.find((el) => el?.toLowerCase() === String(accountSummaryState[index]?.name)?.toLowerCase())
      ) {
        let temp = [...accountSummaryState];
        temp[index] = { ...temp[index], [fieldName]: twoDecimalValues };
        let conditionForRent = getConditionToRecalculateRentVariable(accountSummaryState[index]?.name, fieldName);
        if (conditionForRent) {
          temp = getUpdatedAccountSummaryWithChangeInSalesTaxGrossSale(temp, sublocation);
        }
        const newSummaryData = getUpdatedARAccountDataWithSummary(temp);
        setValues({ accountSummaryState: newSummaryData });
      }
    } else {
      setFieldValue(name, value);
    }
  };

  const onBlurHandler = (name) => {
    let index = name?.split('.')[1];
    let fieldName = name?.split('.')[2];
    setFieldValue(name, roundOffValue(Number(accountSummaryState[index][fieldName])));
  };

  const deleteHandler = (index) => {
    let temp = [...accountSummaryState];
    temp = temp?.filter((item, itemIndex) => itemIndex !== index);
    const newSummaryData = getUpdatedARAccountDataWithSummary(temp);
    setValues({ accountSummaryState: newSummaryData });
  };
  const deleteButtonIndex = Number(Object.keys(variableSummaryFields)?.length + 1);

  return (
    <Box>
      <GridContainer className={classes.accountSummaryTitleContainer}>
        <Typography variant="subtitle9">{'Summary'}</Typography>
      </GridContainer>
      <GridContainer className={classes.accountSummaryTitleFields}>
        <GridItem xs={3} lg={2.5}>
          <Typography variant="subtitle4">Account number</Typography>
        </GridItem>
        <GridItem xs={2} lg={1.7}>
          <Typography variant="subtitle4">Name</Typography>
        </GridItem>
        <GridItem xs={3} lg={2.5}>
          <Typography variant="subtitle4">Notes</Typography>
        </GridItem>
        <GridItem xs={1.5} lg={1.7}>
          <Typography variant="subtitle4">Debit</Typography>
        </GridItem>
        <GridItem xs={1.5} lg={1.7}>
          <Typography variant="subtitle4">Credit</Typography>
        </GridItem>
        <GridItem xs={1} lg={1} />
      </GridContainer>
      <FormikProvider value={formik}>
        <FieldArray name="accountSummaryState" validateOnChange="true">
          {({ push, remove }) => (
            <>
              {isArrayWithLength(accountSummaryState) &&
                accountSummaryState.map((fieldSet1, index) => {
                  const accountNumber = `accountSummaryState.${index}.${ACCOUNT_FIELDS.ACCOUNT_NUMBER}`;
                  const name = `accountSummaryState.${index}.${ACCOUNT_FIELDS.NAME}`;
                  const description = `accountSummaryState.${index}.${ACCOUNT_FIELDS.DESCRIPTION}`;
                  let debit = `accountSummaryState.${index}.${ACCOUNT_FIELDS.DEBIT}`;
                  let credit = `accountSummaryState.${index}.${ACCOUNT_FIELDS.CREDIT}`;
                  const touchedAccountNumber = getIn(touched, accountNumber);
                  const errorAccountNumber = getIn(errors, accountNumber);
                  const touchedDebit = getIn(touched, debit);
                  const errorDebit = getIn(errors, debit);
                  const touchedCredit = getIn(touched, credit);
                  const errorCredit = getIn(errors, credit);
                  const visibleRows = getVisibleRows(fieldSet1.name, isTaxPayable);
                  return visibleRows ? (
                    <GridContainer key={index} px={3} className={classes.accountSummaryFields}>
                      <GridItem xs={3} lg={2.5}>
                        {isReconciliation ? (
                          <ControlledTooltips
                            tooltipValue={
                              (touchedAccountNumber && errorAccountNumber) ||
                              (fieldSet1?.description && !fieldSet1?.accountNumber)
                                ? MESSAGE.REQUIRED
                                : ''
                            }
                          >
                            <Autocomplete
                              name={accountNumber}
                              options={accountNumbers}
                              value={fieldSet1?.accountNumber}
                              onChange={(e, value) => {
                                onChange(index, value);
                                setFieldValue(accountNumber, value);
                                dispatch(setAccountSummaryFormDirty(true));
                              }}
                              onBlur={formik.handleBlur}
                              error={Boolean(
                                (touchedAccountNumber && errorAccountNumber) ||
                                  ((Number(fieldSet1?.credit) || Number(fieldSet1?.debit) || fieldSet1?.description) &&
                                    !fieldSet1?.accountNumber),
                              )}
                            />
                          </ControlledTooltips>
                        ) : (
                          <TextField name={accountNumber} value={fieldSet1?.accountNumber} disabled />
                        )}
                      </GridItem>
                      <GridItem xs={2} lg={1.7}>
                        <TextField name={name} value={fieldSet1?.name} placeholder="Name" disabled />
                      </GridItem>
                      <GridItem xs={3} lg={2.5}>
                        <TextField
                          name={description}
                          value={fieldSet1?.description}
                          onChange={(e) => onChangeHandler(description, e.target.value)}
                          placeholder={isApproved ? '' : 'Notes'}
                          disabled={isApproved}
                        />
                      </GridItem>
                      <GridItem xs={1.5} lg={1.7} className={classes.debitCreditField}>
                        <ControlledTooltips tooltipValue={touchedDebit && errorDebit ? MESSAGE.REQUIRED : ''}>
                          <TextField
                            name={debit}
                            type="number"
                            value={fieldSet1?.debit}
                            onChange={(e) => onChangeHandler(debit, e.target.value)}
                            onBlur={(e) => {
                              onBlurHandler(debit);
                              formik.handleBlur(e);
                            }}
                            error={Boolean(touchedDebit && errorDebit)}
                            placeholder={isApproved ? '0' : 'Debit'}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>
                              ),
                            }}
                            disabled={isApproved}
                          />
                        </ControlledTooltips>
                      </GridItem>
                      <GridItem xs={1.5} lg={1.7} className={classes.debitCreditField}>
                        <ControlledTooltips tooltipValue={touchedCredit && errorCredit ? MESSAGE.REQUIRED : ''}>
                          <TextField
                            name={credit}
                            type="number"
                            value={fieldSet1?.credit}
                            placeholder={isApproved ? '0' : 'Credit'}
                            onChange={(e) => onChangeHandler(credit, e.target.value)}
                            onBlur={(e) => {
                              onBlurHandler(credit);
                              formik.handleBlur(e);
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>
                              ),
                            }}
                            error={Boolean(touchedCredit && errorCredit)}
                            disabled={isApproved}
                          />
                        </ControlledTooltips>
                      </GridItem>
                      {isReconciliation && index > deleteButtonIndex && (
                        <GridItem xs={1} lg={1} sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => {
                              remove(index);
                              deleteHandler(index);
                            }}
                          >
                            <HighlightOff />
                          </IconButton>
                        </GridItem>
                      )}
                    </GridContainer>
                  ) : null;
                })}
              <GridContainer px={3} className={classes.accountSummaryFields}>
                <GridItem xs={3} lg={2.5} />
                <GridItem xs={5} lg={4.2}>
                  <TextField name="name" value={'Total'} placeholder="Name" disabled />
                </GridItem>
                <GridItem xs={3} lg={3.4} className={`${classes.collectionFieldItem} ${classes.debitCreditField}`}>
                  <ControlledTooltips tooltipValue={Number(total) !== 0 ? MESSAGE.GROSS_ZERO_REQUIRED : ''}>
                    <TextField
                      value={total}
                      placeholder="Total"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>
                        ),
                      }}
                      error={Number(total) !== 0}
                      disabled
                    />
                  </ControlledTooltips>
                </GridItem>
                <GridItem xs={1} />
              </GridContainer>
              <GridContainer pb={3}>
                {isReconciliation ? (
                  <GridItem xs={8} lg={6.7}>
                    <Box>
                      <Button className={classes.addNewRowBtn} onClick={() => push(INITIAL_ACCOUNT_SUMMARY_STATE[0])}>
                        Add New Row
                      </Button>
                    </Box>
                  </GridItem>
                ) : (
                  <GridItem xs={8} lg={6.7} />
                )}
                <GridItem xs={4} lg={4}>
                  <Box pt={1} className={classes.accountSummaryInfoCurrencyText}>
                    All amounts are in USD
                  </Box>
                </GridItem>
              </GridContainer>
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </Box>
  );
};

export default AccountSummary;
