import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
const displayName = 'NoRolesAssign';

const NoRolesAssign = () => {
  const { isDeactivated } = useSelector((state) => state.user);

  let message = {
    title: 'No Roles Assigned',
    // eslint-disable-next-line quotes
    description1: "Sorry, You don't have any roles assigned.",
    description2: 'Please contact System Admin to assign you a role.',
  };
  if (isDeactivated) {
    message.title = 'Account Deactivated';
    message.description1 = 'Your account is deactivated, please contact the administrator';
  }

  return (
    <Box px={3}>
      <Box component="h2">{message.title}</Box>
      <Box component="p">{message.description1}</Box>
      <Box component="p">{message.description2}</Box>
      <Box component="p">{'Thank you.'}</Box>
    </Box>
  );
};

NoRolesAssign.displayName = displayName;
export default NoRolesAssign;
