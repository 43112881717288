import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  tabsWrapper: {
    padding: '10px 20px 20px 20px',
  },
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
    padding: '0px 20px',
    height: '56px',
  },
  searchWrap: {
    width: '100%',
    '& .MuiBox-root': {
      padding: '0px',
    },
  },
  filterWrapper: {
    paddingLeft: '15px',
  },
});

export default useStyles;
