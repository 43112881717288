import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import ImgMSPictogram from '../../assets/images/icons/ms-pictogram.svg';
import { Typography } from '@mui/material';
import { Button } from '../shared';
import useStyles from './style';
import { useDispatch } from 'react-redux';
import { setManuallyLogoutStatus, setUserDeactivated } from '../../redux-slice/user';
import { setSsoSilentStatus, setResponseLoading } from '../../redux-slice/msalStatus';
import { LOADING_STATE } from '../../constants/CommonConstants';
import { reset } from '../../redux-slice/error';

const displayName = 'SignInButton';

const SignInButton = () => {
  const { instance } = useMsal();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogin = async (instance) => {
    dispatch(setManuallyLogoutStatus(false));
    dispatch(setSsoSilentStatus(LOADING_STATE.IDLE));
    dispatch(setResponseLoading(true));
    dispatch(setUserDeactivated(false));
    dispatch(reset());
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Button variant="outlined" className={classes.signInButton} onClick={() => handleLogin(instance)}>
      <img src={ImgMSPictogram} alt={'Sign In Button Logo'} />
      <Typography variant="p7" ml={2}>
        Sign In with Microsoft
      </Typography>
    </Button>
  );
};

SignInButton.displayName = displayName;
export default SignInButton;
