import React, { useCallback, useMemo, useState } from 'react';
import Autocomplete from '../Autocomplete';
import {
  checkValueNotNullUndefinedBlank,
  debounce,
  getOptions,
  isArrayWithLength,
} from '../../../utils/common-methods';
import { FILTER_VALUES } from '../../../constants/FilterConstants';

const FilterAutoComplete = ({ customData, setCustomData, onChipChange, item, index, filterParams }) => {
  const [loading, setLoading] = useState({});
  const [open, setOpen] = useState({});
  const multiple = useMemo(
    () => (item?.multiple !== undefined ? item.multiple : true),
    [item.value, filterParams[FILTER_VALUES.COUNTRY], filterParams[FILTER_VALUES.STATE]],
  );
  let disabled = useMemo(() => {
    if (item.value === FILTER_VALUES.STATE && filterParams[FILTER_VALUES.COUNTRY] === null) return true;
    else if (item.value === FILTER_VALUES.CITY && filterParams[FILTER_VALUES.STATE] === null) return true;
    return false;
  }, [item.value, filterParams[FILTER_VALUES.COUNTRY], filterParams[FILTER_VALUES.STATE]]);

  const paginationAPIs = useMemo(
    () => [
      FILTER_VALUES.LOCATION,
      FILTER_VALUES.ACCOUNTING_USER,
      FILTER_VALUES.SUBLOCATION,
      FILTER_VALUES.MODEL,
      FILTER_VALUES.COLLECTION_SUBLOCATION,
      FILTER_VALUES.SUBLOCATION_ID,
    ],
    [],
  );

  const extraProps = useCallback((value) => {
    const props = {};
    if (paginationAPIs.includes(value)) {
      props.onInputChange = (e, newValue) => onInputChange(value, newValue);
    }
    return props;
  }, []);

  const callAPI = ({ params, data, selectedIndex, columnName }) =>
    customData[selectedIndex]
      .api(params)
      .then((resp) => {
        if (resp.isSuccess) {
          const { apikey } = customData[selectedIndex];
          let options = Array.isArray(resp.data)
            ? resp.data
            : Array.isArray(resp?.data?.[apikey])
            ? resp?.data?.[apikey]
            : resp.data?.data?.[apikey] || [];
          if (columnName === FILTER_VALUES.ACCOUNTING_USER) {
            options = options.map((item) => ({
              ...item,
              name: `${item?.firstName || ''} ${item?.lastName || ''}`,
            }));
          }
          const optionsForAutocomplete = getOptions(options);
          data.splice(selectedIndex, 1, {
            ...customData[selectedIndex],
            options: optionsForAutocomplete,
          });
          setCustomData([...data]);
        }
      })
      .finally(() => setLoading((prevValue) => ({ ...prevValue, [columnName]: false })));

  const onInputChange = debounce(async (columnName, inputText) => {
    setLoading((prevValue) => ({ ...prevValue, [columnName]: true }));
    const data = customData;
    const selectedIndex = customData.findIndex((item) => item.value === columnName);
    if (selectedIndex !== -1) {
      const customParam = customData[selectedIndex]?.customParam || '';
      let params = {
        page: 1,
        perPage: 100,
        searchParams: `q=${inputText}${customParam ? `&${customParam}` : ''}`,
      };
      await callAPI({ params, data, selectedIndex, columnName });
    }
    setLoading((prevValue) => ({ ...prevValue, [columnName]: false }));
  }, 600);

  const onOpen = async (columnName) => {
    setLoading((prevValue) => ({ ...prevValue, [columnName]: true }));
    setOpen((prevValue) => ({ ...prevValue, [columnName]: true }));
    const data = customData;
    const selectedIndex = customData.findIndex((item) => item.value === columnName);
    const shouldCallAPI =
      [FILTER_VALUES.CITY, FILTER_VALUES.STATE, FILTER_VALUES.ACCOUNTING_USER].includes(columnName) ||
      (!isArrayWithLength(customData[selectedIndex].options) &&
        (filterParams[columnName] === null || !isArrayWithLength(filterParams[columnName])));
    if (selectedIndex !== -1 && shouldCallAPI) {
      let params = '';
      if (columnName === FILTER_VALUES.STATE && filterParams[FILTER_VALUES.COUNTRY]) {
        params = filterParams[FILTER_VALUES.COUNTRY].value;
      } else if (columnName === FILTER_VALUES.CITY && filterParams[FILTER_VALUES.STATE]) {
        params = filterParams[FILTER_VALUES.STATE].value;
      } else if (paginationAPIs.includes(columnName)) {
        const customParam = customData[selectedIndex]?.customParam || '';
        params = {
          page: 1,
          perPage: 100,
          searchParams: `q=${''}${customParam ? `&${customParam}` : ''}`,
        };
      }
      await callAPI({ params, data, selectedIndex, columnName });
    }
    setLoading((prevValue) => ({ ...prevValue, [columnName]: false }));
  };

  const onClose = (columnName) => {
    setOpen((prevValue) => ({ ...prevValue, [columnName]: false }));
  };

  return (
    <Autocomplete
      key={index}
      label={item.name}
      value={
        !checkValueNotNullUndefinedBlank(filterParams[item.value]) ? (multiple ? [] : null) : filterParams[item.value]
      }
      name={item.name}
      options={item.options}
      multiple={multiple}
      onChange={(_, value) => onChipChange(item.value, value)}
      getOptionLabel={(option) => option.name || ''}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading[item.value] || false}
      onOpen={() => onOpen(item.value)}
      onClose={() => onClose(item.value)}
      open={open[item.value] || false}
      freeSolo={false}
      clearOnBlur={false}
      disableCloseOnSelect
      {...extraProps(item.value)}
    />
  );
};

export default FilterAutoComplete;
