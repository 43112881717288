import { Box } from '@mui/material';
import { InformationPad } from '../../../components/shared';
import { LEASE_DETAILS } from '../../../constants/accordionFields';
import { getDateFormat, rentTypeString, roundOffValue } from '../../../utils/common-methods';

const LeaseDetailAccordion = (props) => {
  const { leaseInfo } = props;

  const lease = leaseInfo?.contract;
  const { LEASE, RENT } = LEASE_DETAILS;

  const fields = [
    {
      label: LEASE.DATE,
      value: getDateFormat(lease?.createdAt),
    },
    {
      label: LEASE.BOOTH_INFO,
      value: 'N/A',
    },
    { label: LEASE.SPACE, value: 'N/A' },
    { label: LEASE.DBA, value: 'N/A' },
    { label: LEASE.SALES_PERSON, value: 'N/A' },
    { label: LEASE.TYPE, value: lease?.leaseType },
    { label: LEASE.STATUS, value: lease?.status },
    { label: LEASE.LEASE_BEGINS, value: getDateFormat(lease?.termStartDate) },
    { label: LEASE.LEASE_ENDS, value: getDateFormat(lease?.termEndDate) },
    { label: RENT.RENT_TYPE, value: rentTypeString(lease?.rentType) },
    {
      label: RENT.RENT_CALCULATION_TIMING,
      value:
        lease?.rentCalculateInterval?.charAt(0).toUpperCase() +
        lease?.rentCalculateInterval?.slice(1)?.toLowerCase()?.split('_')?.join(' '),
    },
    {
      label: RENT.CALCULATION_STARTS,
      value: 'N/A',
    },
    {
      label: RENT.CALCULATION_ENDS,
      value: 'N/A',
    },
    {
      label: RENT.CAM_FEE,
      value: `$${roundOffValue(lease?.CAMFee)}`,
    },
    {
      label: RENT.CAM_RATE,
      value: `${roundOffValue(lease?.CAMRate)}`,
    },
    {
      label: RENT.THRESHOLD_RATE,
      value: `${roundOffValue(lease?.thresholdRate)}`,
    },
    {
      label: RENT.THRESHOLD_REVENUE,
      value: `${roundOffValue(lease?.thresholdRevenue)}`,
    },
    {
      label: RENT.NOTES,
      value: `${lease?.notes ?? ''}`,
    },
    {
      label: RENT.COIN_COST,
      value: `${roundOffValue(lease?.coinCost)}`,
    },
    {
      label: RENT.CARD_COST,
      value: `${roundOffValue(lease?.cardCost)}`,
    },
  ];

  const generateDynamicLabels = (modelTypes) =>
    modelTypes?.map((value) => ({
      label: value?.gameCategory?.name,
      value: `${roundOffValue(value?.rentRate)}`,
    }));

  switch (lease?.rentType) {
    case 'RATE_PLUS_FEE':
    case 'COMBO':
    case 'RATE_FEE_HIGHER':
    case 'SUM':
    case 'NA':
      fields.push(
        {
          label: RENT.RENT_FEE,
          value: lease?.fee && `$${roundOffValue(lease?.fee)}`,
        },

        {
          label: RENT.RENT_RATE,
          value: lease?.rate && `${lease?.rate}`,
        },

        {
          label: RENT.BREAKPOINT_NATURAL,
          value: lease?.hasNaturalBreakPoint ? 'Yes' : 'No',
        },
        {
          label: RENT.BREAKPOINT_VALUE,
          value: lease?.naturalBreakPointValue && `$${lease?.naturalBreakPointValue}`,
        },
      );
      break;
    case 'RATE_ONLY':
    case 'BENCHMARK_RENT':
      fields.push(
        {
          label: RENT.RENT_RATE,
          value: lease?.rate && `${lease?.rate}`,
        },
        ...generateDynamicLabels(lease?.contractAssetGameCategoryRates),
      );
      break;

    case 'FEE_ONLY':
    case 'FEE_ONLY_ANNUAL':
    case 'FEE_ONLY_YEAR':
    case 'FEE_PER_PIC':
    case 'FEE_ONLY_AFTER_BENCHMARK':
    case 'FEE_ONLY_SLIDING_BENCHMARK':
      fields.push({
        label: RENT.RENT_FEE,
        value: lease?.fee && `$${lease?.fee}`,
      });
      break;

    default:
      break;
  }

  return (
    <Box>
      <InformationPad data={fields} headingColumn={{ xs: 12, sm: 4, lg: 2 }} titleColumn={{ xs: 12, sm: 8, lg: 10 }} />
    </Box>
  );
};

export default LeaseDetailAccordion;
