import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formContent: {
    '& > .MuiGrid2-root': {
      padding: '0px 12px 24px 12px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      margin: '55px 0px 0px 0px',
    },
  },
}));

export default useStyles;
