import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  root: {
    padding: '0px',
  },
  breadcrumbContainer: {
    margin: '15px 0px',
  },
}));

export default useStyles;
