import { InputLabel, FormHelperText, FormControl, Select as MUISelect, MenuItem } from '@mui/material';

const Select = (props) => {
  const {
    inputLabel = '',
    id,
    helperText,
    options,
    width = '100%',
    variant = 'outlined',
    size = 'medium',
    disabled = false,
    error = false,
    multiple = false,
    required = false,
    children,
    optionStyle = null,
    ...other
  } = props;
  return (
    <FormControl
      variant={variant}
      size={size}
      disabled={disabled}
      error={error}
      required={required}
      sx={{ width: width }}
    >
      {inputLabel && <InputLabel id={`${id}-label`}>{inputLabel}</InputLabel>}
      <MUISelect
        labelId={`${id}-label`}
        id={id}
        label={inputLabel && inputLabel}
        {...other}
        multiple={multiple}
        inputProps={{ 'data-testid': 'select' }}
      >
        {options &&
          options.map(({ value, text }, index) => (
            <MenuItem
              className={`${optionStyle ?? ''}`}
              key={`${text}-${index}`}
              value={value}
              data-testid="select-option"
            >
              {text}
            </MenuItem>
          ))}
        {children}
      </MUISelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
