import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Button, GridContainer, GridItem, Tabs } from '../../../components/shared';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import { routeConstants } from '../../../constants/routeConstants';
import ActiveLease from './ActiveLease';
import ArchiveLease from './ArchiveLease';
import PendingLease from './PendingLease';
import { useSelector } from 'react-redux';

const Lease = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('selectedTab');

  const { leases } = useSelector((state) => state?.lease);

  const { pagination, isLoading } = leases;

  const tabs = [
    {
      heading: TAB_TYPES.PENDING,
      content: <PendingLease />,
    },
    {
      heading: TAB_TYPES.ACTIVE,
      content: <ActiveLease />,
    },
    {
      heading: TAB_TYPES.ARCHIVE,
      content: <ArchiveLease />,
    },
  ];

  const onTabChange = (newValue) => {
    navigate(`/${routeConstants.LEASE_ROUTE}?selectedTab=${newValue}`, {
      replace: true,
    });
  };

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} display="flex" alignItems="center">
          <Typography variant="h1">Leases</Typography>
        </GridItem>
        {selectedTab !== '3' && (
          <GridItem xs={6} display="flex" justifyContent="end" alignItems="center">
            <Button
              component={Link}
              to={`/${routeConstants.ADD_LEASE_ROUTE}`}
              variant="contained"
              data-testid="add-location-btn"
              startIcon={<Add />}
            >
              ADD NEW
            </Button>
          </GridItem>
        )}
      </GridContainer>
      <Typography variant="h3" style={{ color: theme.palette.secondary.main }} data-testid="lease-count">{`${
        isLoading ? '' : `${pagination?.total || 0} Leases`
      }`}</Typography>

      <Box component={Paper} mt={2}>
        <Tabs defaultTab={selectedTab === null ? '1' : selectedTab} tabsData={tabs} onTabChange={onTabChange} />
      </Box>
    </React.Fragment>
  );
};

export default Lease;
