import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-root': { textAlign: 'left' },
    '& .MuiTableCell-head': { whiteSpace: 'nowrap', textAlign: 'left' },
  },
  pageName: {
    paddingBottom: '16px',
    '& .MuiGrid2-root': { padding: 0, display: 'flex', alignItems: 'center' },
  },
  totalMachines: { color: theme?.palette?.grey[450], padding: '0px' },
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
    margin: '16px 0px 24px',
    height: '100%',
  },
  searchWrap: {
    width: '100%',
    '& .MuiSvgIcon-root': { color: theme?.palette?.grey[450] },
    '& .MuiInputBase-root': { borderRadius: '5px' },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.grey[100],
    },
  },
  filterWrap: { padding: '0px' },
  filterContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  machinesTable: { backgroundColor: `${theme?.palette?.common?.white}` },
  sortIcon: { transform: 'scale(1,-1)' },
  heightIcon: { height: 20, width: 18, marginLeft: -7 },
}));

export default useStyles;
