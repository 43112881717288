export const MESSAGE = {
  CONFIRMATION: 'Confirmation',
  // Validation Schema
  REQUIRED: 'This is a required field',
  VALID_PHONE_NUMBER: 'Enter valid phone number',
  VALID_PHONE_NUMBER_WITH_FORMAT: 'Enter valid phone number in format [country code(optional) 909-989-7878]',
  VALID_EMAIL: 'Enter a valid email',
  REQUIRED_MIN_NUMBER: (number) => `Value must be greater than or equal to ${number}`,
  RANGE_NOT_REQUIRED: (number1, number2) => `Value must be between ${number1} and ${number2}`,
  REQUIRED_MIN_0: 'Value must be greater than or equal to 0',
  REQUIRED_MORE_THAN_NUMBER: (number) => `Value should be greater than ${number}`,
  REQUIRED_NUMBER: 'Value must be a number',
  INVALID_TIME: 'Invalid Time',
  INVALID_DATE: 'Invalid Date',
  INVALID_YEAR: 'Invalid Year',
  DATE_COMPARISON: 'The end date cannot be less than or equal to the start date.',
  CURRENT_METER_ERROR: 'The current meter is less than the prior meter.',
  SEEDLIVE_VALUE_ERROR: 'No Modem data',
  CASH_METER_EXPECTED_REVENUE_ERROR: 'The difference between revenue collected and expected is off by more than $25.',
  TOKEN_CHANGER_METER_EXPECTED_REVENUE_ERROR:
    'The difference between seedlive and token changer revenue Is off by more than $25.',
  COIN_ESTIMATED_COIN_REVENUE_ERROR: 'The difference between Coin Revenue and estimated is off by more than $25.',
  CREDIT_METER_ESTIMATED_REVENUE_ERROR: 'The difference between CC Revenue and the estimated is off by more than $25.',
  TOKEN_COLLECTED_ERROR: 'The Tokens collected is not equal to Current Meter - Prior Meter',
  MAC_VALIDATION_MESSAGE: 'Must contain 12 characters(0-9 or a-f/A-F) separated by ":" or "-" ex:"00:04:af:d0:90:9d"',

  INVALID_SEARCH_MESSAGE: 'Please enter at least three characters',
  REPORT_MACHINE_INFO: 'Issue has been reported against this machine',
  REPORT_MACHINE_INFO_RECONCILIATION: 'Issue has been reported against this machine. Click here to view the details',

  // Collection
  SUBMIT: 'SUBMIT',
  CANCEL: 'CANCEL',
  OK: 'OK',
  CONFIRM: 'CONFIRM',
  DELETE: 'DELETE',
  REMOVE: 'REMOVE',
  SUCCESSFUL: 'Successful',
  FAILED: 'Failed',
  UPDATE: 'UPDATE',
  REOPEN: 'Re-Open',
  CLEAR: 'CLEAR',
  REPORT_MACHINE_BTN_NAME: 'REPORT',
  RECONCILIATION_TOOLTIP_WARNING: 'Error detected in few machines for this location',
  RECONCILIATION_TOOLTIP_NAME: 'ISSUE REPORTED',
  COLLECTION_SUMMARY_TITLE: 'Collection Summary',
  DRAFT_SUMMARY: 'Drafts Summary',
  SUBLOCATIONS_COLLECTED: 'SUB-LOCATIONS COLLECTED',
  COLLECTION_TOTAL_COLLECTED_UPPERCASE: 'TOTAL AMOUNT COLLECTED',
  COLLECTION_TOTAL_COLLECTED: 'Total Amount Collected',
  COLLECTION_TOTAL_TICKET_DISPENSED: 'TOTAL TICKET DISPENSED',
  COLLECTION_TOTAL_PRIZE_DISPENSED: 'TOTAL PRIZE DISPENSED',
  COLLECTION_TOTAL_MEDALLION_DISPENSED: 'TOTAL MEDALLION DISPENSED',

  COLLECTION_TOTAL_TOKEN_COLLECTED: 'TOTAL TOKEN COLLECTED',
  COLLECTION_TOTAL_TOKEN_DISPENSED: 'TOTAL TOKEN DISPENSED',
  AVERAGE_TOKEN_VALUE: 'AVERAGE TOKEN VALUE',
  COLLECTION_LOCATION_CITY: 'CITY',
  COLLECTION_LOCATION_ADDRESS: 'ADDRESS',
  COLLECTION_EXCHANGE_RATE: 'Exchange Rate',
  COLLECTION_NO_METERS: 'No associated/active meter',
  INCOMPLETE_METER_DATA_ERROR_MESSAGE: 'Please update the missing information in the meters before proceeding.',
  DEVIATED_METER_DATA_ERROR_MESSAGE: 'Please resolve the deviation on meters before approving this collection.',
  ACCOUNT_SUMMARY_ERROR_MESSAGE: 'Please resolve the account summary errors before approving this collection.',
  SUCCESSFUL_COLLECTION: (locationName, isSuperAdminOrAccountingOrManagerUser) =>
    `Your submission for ${locationName} collection has submitted successfully. ${
      isSuperAdminOrAccountingOrManagerUser
        ? 'Please verify the collection on the next step so that it is ready for reconciliation.'
        : ''
    } You will be redirected in`,
  REOPEN_APPROVED_COLLECTION: 'Are you sure you want to re-open this approved collection?',
  ADD_MISSING_ASSETS_MESSAGE:
    'Revenue of this collection on reconciliation list and reconciliation detailed page will get match when this collection gets verified. ' +
    'Are you sure to add these missing Assets in this collection’s sublocation ?',

  //Reconciliation
  COLLECTION_TOTAL_REVENUE: 'TOTAL REVENUE',
  COLLECTION_TOTAL_REVENUE_IN_USD: 'TOTAL REVENUE IN USD',
  COLLECTION_TICKET_DISPENSED: 'TICKET DISPENSED',
  APPROVE: 'APPROVE',
  VERIFY: 'VERIFY',
  REMOVE_LOCATION: 'REMOVE LOCATION',
  REMOVE_SUBLOCATION: 'REMOVE SUB-LOCATION',
  DELETE_COLLECTION: 'DELETE COLLECTION',
  DELETE_MODEM: 'DELETE MODEM',
  DELETE_LOCATION: 'DELETE LOCATION',
  DELETE_SUBLOCATION: 'DELETE SUB-LOCATION',
  RECONCILIATION_COMMENT_TITLE: 'Comments',
  RECONCILIATION_DELETE_DESCRIPTION: 'Are you sure you want to delete this collection? This process cannot be undone.',
  RECONCILIATION_APPROVE_DESCRIPTION:
    'Are you sure you want to approve this collection? This process cannot be undone.',
  RECONCILIATION_VERIFY_DESCRIPTION: 'Are you sure you want to verify this collection? This process cannot be undone.',
  REMOVE_SUBLOCATION_DESCRIPTION:
    'Are you sure you want to remove this sub-location? The user will no longer be assigned to this sub-location. ' +
    'The sub-location will still be associated with the location and be available to other users.',
  MODEM_DELETE_DESCRIPTION: 'Are you sure you want to delete this modem? This process cannot be undone.',
  DELETE_LOCATION_DESCRIPTION:
    'Are you sure you want to delete this location from the system? The location no longer be available to any user ' +
    'for collection. This will also disable any sub-locations associated with this location. You can revert this action by ' +
    'opening the location in Archived Locations and click the Activate button.',
  DELETE_SUBLOCATION_DESCRIPTION:
    'Are you sure you want to delete this sub-location from the system? The sub-location no longer be available to ' +
    'any new or existing users for collection! This process cannot be undone.',
  ASSETS: 'Assets',
  REPORTED_MACHINES: 'Report Machines',
  DATE_OF_READING: 'Date of Reading',
  SUBMISSION_DATE: 'Submission Date',
  TOTAL_ASSET: 'TOTAL ASSET',
  DATE_ENTERED: 'Date Entered',
  ADD_COLLECTION_DATA_NOT_ENTERED: 'Data not entered',
  NO_ASSOCIATED_ASSET: 'No associated asset',
  INTERCARD: 'Intercard',
  METERS: 'Meters',

  SAVE_AS_DRAFT: 'Save as draft',
  DRAFT_DATA_EXCEED_LOCAL_STORAGE_WARNING:
    'Data cannot be saved due to limited local storage. Please clear drafts of other locations or sign out and then try saving again after signing in',
  OPEN_DRAFT: 'Open Draft',
  CREATE_NEW_COLLECTION: 'Create New Collection',
  USE_EXISTING_SAVED_DATA: 'Use Existing Saved Data',
  DRAFT_FORM_EXIST_MESSAGE: 'Looks like there is a draft collection for this location.',
  DRAFT_COLLECTION_ASK_SAVE: 'Do you want to save this collection as a draft before leaving?',
  CLEAR_DRAFT_CONFIRMATION: 'Are you sure you want to clear saved draft data for this location?',
  APPROVE_BULK_COLLECTIONS: 'You are about to bulk approve the collection(s), this action cannot be undone.',
  DRAFT_DATA_CLEAR_SUCCESSFUL:
    'Drafted data for selected locations has been removed successfully. Now you can save this collection.',
  DRAFT_NOTE: 'Note : Selected Drafts will be permanently cleared from storage.',

  // PLAYCARD
  PLAYCARD_SALES_SUMMARY: 'PLAYCARD SALES SUMMARY',
  REFUND: 'Refunds cannot exceed the playcard sales',
  REFUND_CREDIT_CARD: 'Refunds cannot exceed the sales from credit card',
  NEGATIVE_ADJUST_PLAYCARD: 'Adjusted playcard sales cannot be in negative.',
  ADJUSTED_PLAYCARD_INTERCARD_TOTAL_MISMATCH:
    'The sum of intercard plays and cash debit does not match the playcard sales, please update before proceeding',
  PLAYCARD_SALES_SUMMARY_ERROR:
    'The intercard plays and cash debits do not match playcard sales for some sub-locations.',
  PLAYCARD_SALES_SUMMARY_SUBMIT_MODEL_ERROR:
    'The intercard plays and cash debits do not match the playcard sales for at least one sub-location, please fix the error before submitting the collection.',

  // ACCOUNT SUMMARY
  GROSS_ZERO_REQUIRED: 'Gross total must be zero',

  // 403: ROLES CHANGED
  FORBIDDEN_ERROR_TITLE: 'Access Denied',
  FORBIDDEN_ERROR_MESSAGE:
    'You do not have permission to access this resource. Try refreshing the page or sign in again. If this issue persists then please contact the system administrator.',
  LEAVE_PAGE_MESSAGE: 'Are you sure you want to leave this page.',

  //SOMETHING WENT WRONG API ERROR.
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  BACKEND_UNAUTHORIZED:
    'Access Denied: UNAUTHORIZED(401). You will require to sign in again in the application. If this issue persist then please contact admin.',
  SESSION_TIMEOUT: 'Session Timeout',
  SESSION_TIMEOUT_MESSAGE: 'Failed due to session expired, please try again',
  //REPORTS
  REPORT_STATUS_PENDING: 'Pending',
  REPORT_STATUS_RESOLVED: 'Resolved',
  DUPLICATE_DATA: (value) => `${value} cannot be selected twice`,
};
