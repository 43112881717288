import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

const GridContainer = (props) => {
  const { children, ...others } = props;
  return (
    <Grid container {...others}>
      {children}
    </Grid>
  );
};

export default GridContainer;
