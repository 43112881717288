import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    '& .MuiPaper-root': {
      '&.MuiAccordion-root': {
        boxShadow: 'none',
        borderBottom: '1px solid #E6E8EF',
        '&.Mui-expanded': {
          boxShadow: `0px 1px 4px ${theme.palette.grey[400_12]}`,
          borderBottom: 'none',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          width: '32px',
          height: '32px',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'rotate(0deg)',
          transition: 'unset',
          color: theme.palette.secondary.main,
          '&.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        },
      },
      '& .MuiAccordionSummary-root': {
        padding: '20px 24px 20px 20px',
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '16px 24px 28px',
      '& .MuiGrid2-root': {
        padding: 0,
      },
      '& .MuiGrid2-container': {
        marginBottom: '16px',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  archievLocationBtn: {
    padding: '12px 8px',
  },
  formFields: {
    position: 'relative',
    '& .MuiInputBase-multiline': {
      padding: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
    },
  },
  formCustomFields: {
    position: 'relative',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme?.palette?.error.main}`,
    },
    '& .MuiInputLabel-formControl': {
      color: theme?.palette?.error.main,
    },
    '& .MuiInputBase-root:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme?.palette?.error.main}`,
      },
    },
  },
  subLocationTable: {
    '& .MuiTableContainer-root': {
      marginTop: '18px',
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      margin: '0 24px',
    },
    '& .MuiTableCell-body': {
      padding: '12px 24px',
      '&:first-of-type': {
        paddingRight: 0,
      },
      '&:last-of-type': {
        paddingRight: 32,
      },
    },
  },
  ownerUpdateDetailsView: {
    marginTop: '8px',
    '& .MuiAutocomplete-inputRoot': {
      padding: '8px',
      marginBottom: '36px',
      color: theme.palette.common.black,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.black, 0.11),
        borderRadius: '4px',
      },
    },
  },
  ownerUpdateBtnWrap: {
    marginBottom: '12px',
  },
  locationDetailsUpdateBox: {
    padding: '24px 66px 40px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '40px 24px',
    },
    '& .MuiTypography-h2': {
      marginBottom: '12px',
      lineHeight: '32px',
    },
  },
  locationDetailsFieldsContainer: {
    '& > .MuiGrid2-root': {
      paddingRight: '24px',
    },
    '& .MuiInputBase-input': {
      padding: '15.5px 14px',
      color: theme.palette.common.black,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.black, 0.12),
      borderRadius: '4px',
    },
  },
  customModal: {
    backgroundColor: 'red',
  },
  subLocationsDetailsBox: {
    padding: '24px 60px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 48px 8px',
    },
    '& .MuiTypography-h3': {
      lineHeight: '24px',
    },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': {
        padding: '0',
      },
    },
  },
  assignedMachinesBox: {
    '& .MuiDivider-root': {
      borderColor: theme.palette.grey[900],
    },
  },
  assignedMachinesContainer: {
    padding: '24px 39px 20px 56px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 46px 20px 48px',
    },
    '& .MuiTypography-h3': {
      lineHeight: '24px',
      marginBottom: '20px',
    },
  },
  addBtn: {
    padding: '8px 24px 8px 0px',
    '& .MuiButtonBase-root': {
      whiteSpace: 'nowrap',
    },
  },
  archiveBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
  updateBtn: { paddingLeft: 0 },
  archive: { color: theme?.palette?.grey[500] },
}));

export default useStyles;
