import { Typography, Box, Paper } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { GridContainer, GridItem, Button } from '../../../components/shared';
import SearchBar from '../../../components/SearchBar';
import useStyles from './style';
import ModemsTable from './ModemTable';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { routeConstants } from '../../../constants/routeConstants';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import { useSearchFilterParam } from '../../../hooks';
import { ModemType, UnassignedAsset } from '../ModemDetails/data';
import { getOptions } from '../../../utils/common-methods';
import { useDispatch, useSelector } from 'react-redux';
import { resetModemsFiltersState, setModemsFilters } from '../../../redux-slice/modemFilters';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';

const displayName = 'Modems';

const Modems = () => {
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const dispatch = useDispatch();
  const { modems } = useSelector((state) => state.modemsFilter);
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.MODEM.INITIAL_PARAMS);
  const [totalModems, setTotalModems] = useState('');

  const modemFilterOptions = (value) => {
    switch (value) {
      case FILTER_VALUES.TYPE:
        return getOptions(ModemType);
      case FILTER_VALUES.UNASSIGNED_ASSET:
        return getOptions(UnassignedAsset);
      default:
        return [];
    }
  };
  const filterData = useMemo(
    () =>
      FILTER_DATA.MODEM.FILTER.map((item) => ({
        ...item,
        options: modemFilterOptions(item.value),
      })),
    [],
  );

  useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.TYPE]: [],
      [FILTER_VALUES.UNASSIGNED_ASSET]: {},
    };

    if (modems[FILTER_VALUES.TYPE] && modems[FILTER_VALUES.TYPE]?.length > 0) {
      storedFilterParams.type = modems[FILTER_VALUES.TYPE];
    }
    if (modems[FILTER_VALUES.UNASSIGNED_ASSET]) {
      storedFilterParams.unassignedModems = modems[FILTER_VALUES.UNASSIGNED_ASSET];
    }
    setSearchText(modems.searchText);
    setFilterParams(storedFilterParams);
  }, [modems]);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.MODEM,
    filterParams,
    searchText,
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setModemsFilters({ ...modems, searchText: params, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(setModemsFilters({ ...modems, searchText: '', pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }));
  };

  const applyFilterHandler = (filterValues) => {
    dispatch(setModemsFilters({ ...modems, ...filterValues, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }));
    setFilterParams(filterValues);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.MODEM.INITIAL_PARAMS);
    dispatch(resetModemsFiltersState());
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <GridContainer spacing={3} className={classes.pageName}>
          <GridItem xs={6} display="flex" alignItems="center">
            <Typography variant="h1">Modems</Typography>
          </GridItem>
          <GridItem xs={6} display="flex" justifyContent="end" alignItems="center">
            <Button
              component={Link}
              to={`/${routeConstants.ADD_MODEM}`}
              variant="contained"
              data-testid="add-modem-btn"
              startIcon={<Add />}
            >
              ADD NEW
            </Button>
          </GridItem>
        </GridContainer>
        <Typography pl={2} variant="subtitle2" className={classes.totalModems}>
          {`${totalModems ? `${totalModems} Modems` : ''}`}
        </Typography>
        <Box mt={2} component={Paper} className={classes.modemsTable}>
          <>
            <Box className={classes.searchFilterWrap}>
              <Box className={classes.searchWrap}>
                <SearchBar onSearch={searchHandler} onReset={onReset} searchText={searchText} />
              </Box>
              <Box className={classes.filterWrap}>
                <NewFilterDrawer
                  filteredValues={filterParams}
                  name={TITLE.FILTERS.toUpperCase()}
                  title={TITLE.FILTERS}
                  drawerType={DRAWER_TYPES.FILTER_DRAWER}
                  onReset={resetFilterParams}
                  onApplyFilter={(v) => {
                    applyFilterHandler(v);
                  }}
                  filterData={filterData}
                  emptyFilterLength={JSON.stringify(FILTER_DATA.MODEM.INITIAL_PARAMS).length}
                />
              </Box>
            </Box>
            <ModemsTable searchParams={searchParams} onTotalModem={setTotalModems} modems={modems} />
          </>
        </Box>
      </Box>
    </React.Fragment>
  );
};

Modems.displayName = displayName;
export default Modems;
