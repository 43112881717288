import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  summary: {
    flexDirection: 'row-reverse',
    pointerEvents: 'none',
  },
  expandIcon: { pointerEvents: 'auto' },
  title: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    lineHeight: '28px',
  },
  moreHoriz: { alignItems: 'end', pointerEvents: 'auto' },
  details: {
    borderTop: `1px solid ${theme.palette.grey[900]}`,
  },
  collectionAccordionSummary: {
    backgroundColor: `${theme?.palette?.grey[900_2]}`,
  },
  noGutterAccordion: {
    '& .MuiPaper-root.MuiAccordion-root.Mui-expanded': {
      margin: 0,
      borderLeft: `solid 4px ${theme?.palette?.primary?.main}`,
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      marginRight: 0,
    },
  },
}));

export default useStyles;
