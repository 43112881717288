import { InformationPad } from '../../../components/shared';
import { mergedTwoPhoneNumber } from '../../../utils/common-methods';
import { LOCATION_DETAIL_LABEL } from '../../../constants/accordionFields';
import { useEffect, useState } from 'react';

const LocationDetailAccordion = ({ locationInfo, locationDetailsView }) => {
  const locationDetailsCommonFields = [
    { label: LOCATION_DETAIL_LABEL.ZIPCODE, value: locationInfo?.postcode },
    {
      label: LOCATION_DETAIL_LABEL.PHONE,
      value: mergedTwoPhoneNumber(locationInfo?.phone1, locationInfo?.phone2),
    },
    {
      label: LOCATION_DETAIL_LABEL.CURRENCY,
      value: locationInfo?.currency?.name,
    },
    {
      label: LOCATION_DETAIL_LABEL.LOCATION_TYPE,
      value:
        typeof locationInfo?.locationType === 'string' ? locationInfo?.locationType : locationInfo?.locationType?.name,
    },
    {
      label: LOCATION_DETAIL_LABEL.COUNTRY,
      value: locationInfo?.country?.name,
    },
    { label: LOCATION_DETAIL_LABEL.STATE, value: locationInfo?.state?.name },
    { label: LOCATION_DETAIL_LABEL.CITY, value: locationInfo?.city?.name },
    { label: LOCATION_DETAIL_LABEL.ADDRESS1, value: locationInfo?.address1 },
    { label: LOCATION_DETAIL_LABEL.ADDRESS2, value: locationInfo?.address2 },
  ];

  // location details page additional X3 fields
  const locationDetailsPageFields = [
    { label: LOCATION_DETAIL_LABEL.X3_LOCATIONS_ID, value: locationInfo?.X3_LocationsID },
    { label: LOCATION_DETAIL_LABEL.X3_VENDORS_ID, value: locationInfo?.X3_VendorsID },
    { label: LOCATION_DETAIL_LABEL.X3_LOCATIONS_NAME, value: locationInfo?.X3_LocationName },
    { label: LOCATION_DETAIL_LABEL.X3_DEPARTMENT_ID, value: locationInfo?.x3_department?.X3_DeptCode },
    {
      label: LOCATION_DETAIL_LABEL.X3_INDUSTRY_ID,
      value:
        locationInfo?.x3_industry?.X3_vertical &&
        `${locationInfo?.x3_industry?.X3_vertical} (${locationInfo?.x3_industry?.X3_segment})`,
    },
    { label: LOCATION_DETAIL_LABEL.X3_OWNER_ID, value: locationInfo?.x3_owner?.X3_OwnerCode },
  ];

  const [fields, setFields] = useState([]);
  useEffect(() => {
    if (locationDetailsView === true) {
      setFields([...locationDetailsCommonFields, ...locationDetailsPageFields]);
    } else {
      setFields([...locationDetailsCommonFields]);
    }
  }, [locationInfo, locationDetailsView]);

  return <InformationPad data={fields} />;
};
export default LocationDetailAccordion;
