import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '../../constants/CommonConstants';

export const initialState = {
  responseLoading: false,
  ssoSilentStatus: LOADING_STATE.IDLE,
  openRefreshTokenModal: false,
};

export const msalStatusSlice = createSlice({
  name: 'msalStatus',
  initialState,
  reducers: {
    setResponseLoading(state, action) {
      state.responseLoading = action.payload;
    },
    setSsoSilentStatus(state, action) {
      state.ssoSilentStatus = action.payload;
    },
    setOpenRefreshTokenModal(state, action) {
      state.openRefreshTokenModal = action.payload;
    },
  },
});

export default msalStatusSlice;
