import { configureStore } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import rootReducer from './redux-slice';
import thunk from 'redux-thunk';
import { apiBasePath } from './services/baseUrl';
import { rtkQueryErrorLogger } from './services/UnautthorizationMiddleWare';

export const setUpStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(thunk, apiBasePath.middleware, rtkQueryErrorLogger),
  });

export const store = setUpStore({});

export const persistor = persistStore(store);
