export const CURRENCY_CONSTANT = {
  USD: 'USD',
  INR: 'INR',
  EUR: 'EUR',
  BMD: 'BMD',
  HKD: 'HKD',
  THB: 'THB',
  GBP: 'GBP',
  AUD: 'AUD',
  CAD: 'CAD',
  CNY: 'CNY',
  GIP: 'GIP',
};

export const CURRENCY_TYPE = {
  USD: 'USD',
};

export const DEFAULT_EXCHANGE_RATE = 1; //For USD currency
