import React from 'react';
import FallbackUI from './FallbackUI';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }
  componentDidCatch(errorInfo) {
    console.error('Error Information:', errorInfo);
  }
  render() {
    const { error } = this.state;
    if (this.state.hasError) {
      return <FallbackUI error={error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
