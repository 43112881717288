import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../components/shared';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetLocationOwnerByIdQuery } from '../../../services/Location/LocationService';
import LocationOwnerUpdateView from './LocationOwnerUpdateView';

const EditLocationOwner = () => {
  const { id } = useParams();
  const { currentData: currentLocationOwner, isSuccess, isError, isLoading } = useGetLocationOwnerByIdQuery(Number(id));
  const [locationOwnerDetails, setLocationOwnerDetails] = useState(null);
  const {
    LOCATION_OWNER: { LOCATION_OWNER, LOCATION_OWNER_DETAIL, EDIT_LOCATION_OWNER },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      {
        text: LOCATION_OWNER,
        redirection: routeConstants.LOCATION_OWNERS_ROUTE,
      },
      {
        text: LOCATION_OWNER_DETAIL,
        redirection: `${routeConstants.LOCATION_OWNERS_ROUTE}/${id}`,
      },
      { text: EDIT_LOCATION_OWNER },
    ],
    [],
  );

  useEffect(() => {
    if (currentLocationOwner) {
      setLocationOwnerDetails(currentLocationOwner);
    }
  }, [currentLocationOwner, isSuccess]);

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>
      {isLoading && !isError && !isSuccess && <Typography>Loading</Typography>}
      {isError && !isLoading & !isSuccess && <Typography>Unable to retrieve this location</Typography>}
      {!isLoading && !isError && isSuccess && locationOwnerDetails && (
        <>
          <Box mb={3}>
            <Stack direction={'row'} gap={2} alignItems="center">
              <Typography variant="h1" data-testid="locationOwnerName">
                {locationOwnerDetails.name}
              </Typography>
              <Chip label="Active" color="success" variant="outlined" />
            </Stack>
          </Box>
          <Box>
            <LocationOwnerUpdateView locationOwnerDetails={locationOwnerDetails} />
          </Box>
        </>
      )}
    </Container>
  );
};

export default EditLocationOwner;
