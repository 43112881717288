import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    '& .MuiDialog-paper': {
      padding: '24px 24px 16px 24px',
    },
  },
  modalTitle: {
    padding: 0,
  },
  modalContent: {
    '&.MuiDialogContent-root': {
      padding: '16px 0px',
    },
  },
  modalAction: {
    padding: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
}));

export default useStyles;
