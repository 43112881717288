import { useEffect, useState } from 'react';

const useTimer = (activate = false, timer = 0) => {
  const [counter, setCounter] = useState(timer);
  useEffect(() => {
    if (counter) {
      setCounter(timer);
    }
  }, [timer]);

  useEffect(() => {
    if (!counter && activate) {
      setCounter(timer);
    }
  }, [activate]);

  useEffect(() => {
    let interval;

    if (activate && counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => {
      if (counter >= 0) {
        clearInterval(interval);
      }
    };
  }, [counter, activate]);
  return counter;
};

export default useTimer;
