export const ModemType = [
  { name: 'Playcard', id: 'Playcard', text: 'Playcard', value: 'Playcard' },
  { name: 'Nayax', id: 'Nayax', text: 'Nayax', value: 'Nayax' },
  { name: 'Seedlive', id: 'Seedlive', text: 'Seedlive', value: 'Seedlive' },
  { name: 'Intercard', id: 'Intercard', text: 'Intercard', value: 'Intercard' },
];

export const UnassignedAsset = [
  { name: 'True', id: 'true', text: 'True', value: 'true' },
  { name: 'False', id: 'false', text: 'False', value: 'false' },
];
