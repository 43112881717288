import ReactTable from '../../../components/shared/ReactTable';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import LocationAccordion from './LocationAccordion';
import { COLUMNS, LOCATION_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useGetLocationsQuery } from '../../../services/Location/LocationService';
import { ControlledTooltips, Link, NoContent } from '../../../components/shared';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useStyles from './style';
import { getControlledTooltipValueAndCellValue, getDateFormat } from '../../../utils/common-methods';
import { useDispatch } from 'react-redux';
import { setActiveLocationFilters, setArchiveLocationFilters } from '../../../redux-slice/locationFilters';

export default function LocationsTable({
  searchParams,
  onTotalLocation,
  isArchiveTab = false,
  archiveLocation,
  activeLocation,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(
    !isArchiveTab ? activeLocation?.pageNumber : archiveLocation?.pageNumber,
  );
  const [perPageNumber, setPerPageNumber] = useState(
    !isArchiveTab ? activeLocation?.rowsPerPage : archiveLocation?.rowsPerPage,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);
  const columns = useMemo(() => {
    const dynamicColumns = LOCATION_TABLE_DYNAMIC(isBelowMD, isArchiveTab);
    const serializedActiveColumn = [COLUMNS.EXPAND_ICON_COLUMN, ...COLUMNS.LOCATION_TABLE.ACTIVE, ...dynamicColumns];
    const serializedArchiveColumn = [
      COLUMNS.EXPAND_ICON_COLUMN,
      COLUMNS.LOCATION_TABLE.ARCHIVE[0],
      COLUMNS.LOCATION_TABLE.ARCHIVE[1],
      COLUMNS.LOCATION_TABLE.ARCHIVE[2],
      ...dynamicColumns,
      COLUMNS.LOCATION_TABLE.ARCHIVE[3],
    ];
    const col = isArchiveTab ? serializedArchiveColumn : serializedActiveColumn;
    return col;
  }, [isBelowMD]);

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
    isFetching,
  } = useGetLocationsQuery(
    {
      searchParams: searchFilterData,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );

  const locationLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`/location/${id}`}>
        {name}
      </Link>
    ),
    [],
  );

  const viewProductTypes = useCallback((productTypeNames) => {
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(productTypeNames);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData.locations.map((location) => {
        const productTypes = viewProductTypes(location?.productTypes);
        const transformLocation = { ...location, productTypes };
        transformLocation.locationName = locationLink(location.id, location.locationName);
        transformLocation.locationType = location?.locationType?.name;
        transformLocation.dateArchived = location?.dateArchived ? getDateFormat(location?.dateArchived) : 'N/A';
        return transformLocation;
      });
      setTableData(data);
      let total = latestData?.pagination?.total ?? 0;
      setTotalCount(total);
      onTotalLocation(total ?? totalCount);
    } else if (isError) {
      onTotalLocation(0);
    }
  }, [isError, isSuccess, latestData]);

  const pageChangeHandler = (currentPage) => {
    dispatch(
      !isArchiveTab
        ? setActiveLocationFilters({ ...activeLocation, pageNumber: currentPage, rowsPerPage: perPageNumber })
        : setArchiveLocationFilters({ ...archiveLocation, pageNumber: currentPage, rowsPerPage: perPageNumber }),
    );
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(
      !isArchiveTab
        ? setActiveLocationFilters({ ...activeLocation, rowsPerPage: newPerPage, pageNumber: currentPage })
        : setArchiveLocationFilters({ ...archiveLocation, rowsPerPage: newPerPage, pageNumber: currentPage }),
    );
    setPerPageNumber(newPerPage);
  };

  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(!isArchiveTab ? activeLocation?.pageNumber : archiveLocation?.pageNumber);
    setPerPageNumber(!isArchiveTab ? activeLocation?.rowsPerPage : archiveLocation?.rowsPerPage);
    setSkipCall(false);
  }, [searchParams]);

  const detailLocationComponent = ({ row }) => {
    const original = row?.original;
    return original && <LocationAccordion locationInfo={original} />;
  };

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Location Found"
          desc={(isError && error?.data?.message) || <>There are no location present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <Box className={classes.locationTable}>
          <ReactTable
            data={tableData}
            columns={columns}
            pagination
            manualPagination
            totalCount={totalCount}
            pageNumberForTablePagination={currentPage - 1}
            onPageChange={pageChangeHandler}
            onPerPageChange={perPageChangeHandler}
            perPage={perPageNumber}
            expandable
            renderRowSubComponent={detailLocationComponent}
          />
        </Box>
      )}
    </>
  );
}
