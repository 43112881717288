import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  addMachineTitle: { paddingBottom: 24 },
  formContent: {
    padding: '32px 16px 32px 16px',
    position: 'relative',
    '& .MuiInputBase-root': { borderRadius: '4px' },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
  },
  formFieldContainer: {
    '& > .MuiGrid2-root': {
      padding: '0px 12px 24px 12px',
    },
  },
  formFields: {
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      margin: '55px 0px 0px 0px',
    },
  },
  btnWrap: {
    padding: '12px 0px 0px 12px',
  },
}));

export default useStyles;
