import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  pageName: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    '& .MuiTypography-root': { padding: '0px' },
  },
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
    margin: '16px 0px 24px',
    height: '100%',
  },
  searchWrap: {
    width: '100%',
  },
  totalUser: {
    fontWeight: `${theme?.typography?.fontWeightMedium}`,
  },
  usersTable: { backgroundColor: `${theme?.palette?.common?.white}` },
  box: { width: '100%', display: 'flex' },
  radioGroup: {
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'col',
    display: 'flex',
    gap: 24,
    '& .MuiSvgIcon-root': {
      color: `${theme?.palette?.primary?.light} `,
      marginRight: 24,
    },
    '& .MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      margin: 0,
      flexWrap: 'wrap',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      '& .Mui-checked': { color: `${theme?.palette?.primary?.light}` },
    },
  },
  filter: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
    '& .MuiGrid2-root': {
      padding: 0,
    },
  },
  accordion: {
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.grey[500]}`,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
  },
  textTest: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  syncButton: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  syncButtonText: { color: theme.palette.common.black },
}));

export default useStyles;
