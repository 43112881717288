import storedCollectionsSlice from './storedCollections-slice';
export const {
  setCollection,
  resetState,
  resetCollectionByLocationId,
  setDraftSublocationDates,
  reduceDraftDatesBySublocationIds,
} = storedCollectionsSlice.actions;

export default storedCollectionsSlice;
