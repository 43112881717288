import { InformationPad } from '../../../components/shared';
import { SUB_LOCATION_DETAIL_LABEL } from '../../../constants/accordionFields';
import {
  getDateFormat,
  getTimeFormatForStaticView,
  mergedTwoPhoneNumber,
  getUsersMergeString,
  isArrayWithLength,
  getTruncatedDateFormat,
} from '../../../utils/common-methods';
import YesNoIndicator from '../../../components/shared/YesNoIndicator';

const SubLocationDetailAccordion = (props) => {
  const { subLocationInfo } = props;
  let productTypeStr = '';
  if (isArrayWithLength(subLocationInfo?.productCategories)) {
    let productNames = subLocationInfo?.productCategories?.map((item) => item?.name || '');
    productTypeStr = productNames?.join(', ');
  }
  const fields = [
    {
      label: SUB_LOCATION_DETAIL_LABEL.LOCATION_TYPE,
      value: subLocationInfo?.sublocationType?.name,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.PHONE,
      value: mergedTwoPhoneNumber(subLocationInfo?.phone1, subLocationInfo?.phone2),
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.OPEN_HOURS,
      value:
        subLocationInfo?.openHours && subLocationInfo?.closeHours
          ? `${getTimeFormatForStaticView(subLocationInfo?.openHours)} - ${getTimeFormatForStaticView(
              subLocationInfo?.closeHours,
            )}`
          : null,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.PLAY_TYPE,
      value: subLocationInfo?.playType,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.LOCATION,
      value: subLocationInfo?.location?.locationName,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.MANAGER,
      value: getUsersMergeString(subLocationInfo?.users),
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.START_DATE,
      value: getDateFormat(subLocationInfo?.startDate),
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.END_DATE,
      value: getTruncatedDateFormat(subLocationInfo?.endDate),
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.SPACE_IDENTIFIER,
      value: subLocationInfo?.spaceIdentifier,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.ADDRESS1,
      value: subLocationInfo?.address1,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.ADDRESS2,
      value: subLocationInfo?.address2,
    },
    { label: SUB_LOCATION_DETAIL_LABEL.ZIPCODE, value: subLocationInfo?.code },
    {
      label: SUB_LOCATION_DETAIL_LABEL.COUNTRY,
      value: subLocationInfo?.location?.country?.name,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.STATE,
      value: subLocationInfo?.location?.state?.name,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.CITY,
      value: subLocationInfo?.location?.city?.name,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.PRODUCT_TYPE,
      value: productTypeStr || 'N/A',
    },
    { label: SUB_LOCATION_DETAIL_LABEL.STATE_TAX, value: subLocationInfo?.stateTaxRate },
    { label: SUB_LOCATION_DETAIL_LABEL.AREA_TAX_LOCATION, value: subLocationInfo?.areaTaxLocation },
    { label: SUB_LOCATION_DETAIL_LABEL.AREA_TAX, value: subLocationInfo?.areaTaxRate },
    { label: SUB_LOCATION_DETAIL_LABEL.MISC_TAX, value: subLocationInfo?.miscTax },
    {
      label: SUB_LOCATION_DETAIL_LABEL.TAX_EXEMPT_FLAG,
      value: <YesNoIndicator value={subLocationInfo?.taxExemptFlag === true} />,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.LOC_PAYS_TAX,
      value: <YesNoIndicator value={subLocationInfo?.locPaysTaxFlag === true} />,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.CALC_RENT,
      value: <YesNoIndicator value={subLocationInfo?.calculateRent === true} />,
    },
  ];
  return <InformationPad data={fields} />;
};

export default SubLocationDetailAccordion;
