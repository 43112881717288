import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-root': { padding: '0' },
    '& .MuiTableCell-root': { textAlign: 'left' },
    '& .MuiTableCell-head': { whiteSpace: 'nowrap', textAlign: 'left' },
  },
  pageName: { '& .MuiGrid2-root': { padding: 0 } },
  totalModems: { color: theme?.palette?.grey[450] },
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
    height: '100%',
  },
  searchWrap: {
    width: '100%',
    paddingRight: 24,
    '& .MuiSvgIcon-root': { color: theme?.palette?.grey[450] },
    '& .MuiInputBase-root': { borderRadius: '5px' },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.grey[100],
    },
  },
  accordion: {
    border: `1px solid ${theme?.palette?.grey[900]}`,
    borderRadius: 8,
    textAlign: 'left',
  },
  filterWrap: { margin: '0px' },
  filterContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modemAccordion: {
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
    padding: '24px 56px 18px',
    '& .MuiTypography-h3': { lineHeight: '24px' },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': { padding: '0' },
    },
  },
  modemAccordionBtnWrap: { marginTop: '38px' },
}));

export default useStyles;
