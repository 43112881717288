import depositFilterSlice from './depositFilters-slice';

export const {
  setReconciliationFilters,
  setApprovedFilters,
  resetReconciliationFiltersState,
  resetApprovedFiltersState,
  resetState,
} = depositFilterSlice.actions;

export default depositFilterSlice;
