import { DropzoneArea } from 'react-mui-dropzone';
import CheckIcon from '@mui/icons-material/Check';
import useStyles from './style';
import PropTypes from 'prop-types';

const Dropzone = (props) => {
  const {
    acceptedFiles = ['image/*'],
    onChange = () => {},
    showPreviews = true,
    showPreviewsInDropzone = false,
    useChipsForPreview = true,
    showAlerts = ['error'],
    filesLimit = 10,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <DropzoneArea
      acceptedFiles={acceptedFiles}
      onChange={onChange}
      showPreviews={showPreviews}
      showPreviewsInDropzone={showPreviewsInDropzone}
      useChipsForPreview={useChipsForPreview}
      previewChipProps={{
        avatar: <CheckIcon className={classes.icon} fontSize="small" />,
        classes: { root: classes.previewChip },
      }}
      showAlerts={showAlerts}
      filesLimit={filesLimit}
      inputProps={{ 'data-testid': 'dropzone-input' }}
      {...rest}
    />
  );
};

Dropzone.propTypes = {
  acceptedFiles: PropTypes.array,
  onChange: PropTypes.func,
  showPreviews: PropTypes.bool,
  showPreviewsInDropzone: PropTypes.bool,
  useChipsForPreview: PropTypes.bool,
  showAlerts: PropTypes.array,
  filesLimit: PropTypes.number,
};

export default Dropzone;
