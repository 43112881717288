import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { GridContainer, GridItem, Link, NoContent } from '../../components/shared';
import { routeConstants } from '../../constants/routeConstants';
import { useStyles } from './style';
import { useHasRoles } from '../../hooks';
import { ROLE } from '../../constants/roles';
import { mapMarkerIcon } from '../../assets/images/icons';

const displayName = 'Settings';

const Settings = () => {
  const classes = useStyles();
  const isSuperAdminOrAccounting = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.ACCOUNTING]);
  const isSuperAdmin = useHasRoles([ROLE.SUPER_ADMIN_IT]);
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} display="flex" alignItems="center">
          <Typography variant="h1">Settings</Typography>
        </GridItem>
      </GridContainer>
      {isSuperAdminOrAccounting ? (
        <Box className={classes.container}>
          <Container maxWidth="xl" disableGutters className={classes.root}>
            <Link to={`/${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}`}>
              {'UPDATE EXCHANGE RATES'}
            </Link>
          </Container>
          {isSuperAdmin && (
            <Container maxWidth="xl" disableGutters className={classes.root}>
              <Link to={`/${routeConstants.SETTING_ROUTE}/${routeConstants.INTERCARD_FEED_IMPORT}`}>
                {'IMPORT INTERCARD FEED'}
              </Link>
            </Container>
          )}
        </Box>
      ) : (
        <Container disableGutters maxWidth={'xl'} className={classes.root}>
          <NoContent
            icon={mapMarkerIcon}
            title="No Settings Found"
            desc={'There is no settings available right now!'}
          />
        </Container>
      )}
    </React.Fragment>
  );
};

Settings.displayName = displayName;
export default Settings;
