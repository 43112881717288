export const PENDING_LEASE_FILTER_STATUS_DATA = [
  { name: 'Quote', id: 'QUOTE' },
  { name: 'Manager Review', id: 'MANAGER_REVIEW' },
  { name: 'Follow Up', id: 'FOLLOW_UP' },
];

export const ARCHIVE_LEASE_FILTER_STATUS_DATA = [
  { name: 'Reject Lease', id: 'REJECT_LEASE' },
  { name: 'Expired', id: 'EXPIRED' },
  { name: 'Terminate', id: 'TERMINATE' },
];
