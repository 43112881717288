import PropTypes from 'prop-types';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { alpha } from '@mui/material/styles';

import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import shadows, { customShadows } from './shadow';
import { StylesProvider, createGenerateClassName } from '@mui/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'ctm',
});

// eslint-disable-next-line import/no-mutable-exports
export let theme = createTheme({
  palette,
  shape: { borderRadius: 8 },
  breakpoints,
  typography,
  shadows,
  customShadows,
});

theme = responsiveFontSizes(theme);

export default function ThemeProvider({ children }) {
  theme.components = {
    MuiGrid2: {
      styleOverrides: {
        root: {
          margin: '0',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[900_3],
          px: '12px',
          py: '4px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            paddingLeft: 32,
            paddingRight: 32,
          },
          [theme.breakpoints.up('md')]: {
            paddingLeft: 28,
            paddingRight: 28,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '&::placeholder': {
            color: '#65748B',
          },
          '& .MuiFormLabel-root.MuiInputLabel-root': {
            whiteSpace: 'normal',
          },
          '& .MuiInputBase-root': {
            color: theme.palette.common.black,
            '& .MuiSelect-nativeInput': {
              '~ .MuiSvgIcon-root': {
                right: 16,
              },
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: 16,
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.common.black,
              '&.Mui-disabled': {
                color: alpha(theme.palette.common.black, 0.26),
              },
            },
            '& .MuiSvgIcon-fontSizeSmall': {
              color: alpha(theme.palette.common.black, 0.6),
            },
            '&.Mui-disabled': {
              '& .MuiSvgIcon-root': {
                color: alpha(theme.palette.common.black, 0.26),
              },
            },
          },
          '& .MuiInputBase-input': {
            '&::placeholder': {
              color: '#65748B',
              opacity: 1,
            },
          },
          '& .MuiFormHelperText-root': {
            margin: '0',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.black, 0.12),
            borderRadius: '4px',
          },
          '&.Mui-disabled': {
            '&.MuiInputBase-formControl': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: alpha(theme.palette.common.black, 0.12),
              },
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            marginRight: 0,
            padding: 0,
            '&.Mui-disabled': {
              '& .MuiSvgIcon-root': {
                color: alpha(theme.palette.common.black, 0.26),
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'uppercase',
        },
        containedPrimary: {
          boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14)',
          padding: '10px 15px',
          '&:disabled': {
            backgroundColor: palette?.primary?.lighter,
            color: palette?.common?.white,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${palette.grey[100]}`,
          color: '#103556',
          fontWeight: 600,
          lineHeight: '24px',
          '&:hover': { border: `1px solid ${palette.grey[100]}` },
          '&:focus': { border: `1px solid ${palette.grey[100]}` },
          '& .svgIcon': {
            fill: palette.common.darkblue,
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '56px',
            maxWidth: '56px',
          },
        },
        startIcon: {
          margin: '0 8px 0 0',
          '& > *': {
            '&:nth-of-type(1)': {
              fontSize: '24px',
            },
          },
        },
        sizeSmall: {
          fontSize: 14,
          lineHeight: '20px',
          padding: '8px 24px',
        },
        sizeMedium: {
          lineHeight: '24px',
          padding: '10px 24px',
        },
        sizeLarge: {
          padding: '15px 24px',
          borderRadius: '8px',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '4px',
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 12,
          textTransform: 'uppercase',
          fontWeight: 700,
          height: 22,
          borderRadius: 4,
        },
        outlinedPrimary: {
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
        },
        outlinedSuccess: {
          backgroundColor: theme.palette.success.lighter,
          color: theme.palette.success.main,
        },
        outlinedError: {
          color: theme.palette.error.main,
        },
        filled: {
          backgroundColor: alpha(theme.palette.grey[800_2], 0.08),
        },
        deletable: {
          fontWeight: '400',
          fontSize: 14,
          lineHeight: '20px',
          height: 32,
          borderRadius: 16,
          textTransform: 'none',
          letterSpacing: '0.25px',
        },
        deleteIcon: {
          fontSize: 18,
          marginRight: 7,
          '&.MuiSvgIcon-root': {
            color: theme.palette.secondary.main,
          },
          '&:hover': {
            '&.MuiSvgIcon-root': {
              color: alpha(theme.palette.secondary.main, 0.6),
            },
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: theme.palette.grey[500_16],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: palette.common.darkblue,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: theme.palette.secondary.main,
          margin: '0',
        },
        select: {
          color: theme.palette.secondary.main,
        },
        displayedRows: {
          color: theme.palette.secondary.main,
          margin: '0',
        },
        toolbar: {
          [theme.breakpoints.up('sm')]: {
            padding: '21.5px 24px',
          },
          '& .MuiInputBase-root': {
            marginRight: '21px',
          },
        },
        actions: {
          marginLeft: '16px',
          '.MuiIconButton-root': {
            padding: '0 3px',
            color: theme.palette.secondary.main,
            '&:first-of-type': {
              paddingLeft: '0',
            },
            '&:last-child': {
              paddingRight: '0',
            },
          },
          '.MuiButtonBase-root.MuiIconButton-root.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: `0px 1px 4px ${theme.palette.grey[400_12]}`,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          margin: '0 0 34px',
        },
        li: {
          '& .MuiTypography-root': {
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0.15px',
            fontWeight: '500',
            color: alpha(theme.palette.common.darkblue, 0.6),
            '&.MuiLink-root': {
              color: theme.palette.common.darkblue,
              display: 'inherit',
            },
          },
        },
        separator: {
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: '500',
          marginLeft: '4px',
          marginRight: '4px',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: alpha(theme.palette.common.black, 0.38),
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          letterSpacing: '0.25px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      .MuiPaper-root.MuiMenu-paper{
        min-width: 200px;
        border-radius: 4px;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
        margin-top: 8px;
        .MuiMenu-list{
          padding: 12px 0;
        }
        .MuiButtonBase-root {
          padding: 8px 14px;
        }
      }
      .MuiTableCell-root > a{
        color:${theme.palette.common.black};
        &:hover{
          color: ${theme.palette.primary.main};
        }
      }
      .MuiBreadcrumbs-li a{
        color: ${theme.palette.common.darkblue};
      }
      .MuiPaper-root {
        box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
      }
      .MuiIconButton-root {
        &[aria-expanded="true"] {
          background-color: ${theme.palette.grey[600]};
          border-radius: 4px;
          color: ${theme.palette.primary.main};
        }
        
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }
      
    `,
    },
  };
  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider generateClassName={generateClassName}>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
