import { lazy, Suspense, useEffect, useMemo } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import LoadingSpinner from '../components/LoadingSpinner';
import Public from '../components/Layout/Public';
import Protected from '../components/Layout/Protected';

import NotFound from '../pages/NotFound';
import NoRolesAssign from '../pages/NoRolesAssign';

const App = lazy(() => import('../App'));

import { routeConstants } from '../constants/routeConstants';
import { intersection, isArrayWithLength } from '../utils/common-methods';
import PrivateRoutesConfig from './PrivateRoutesConfig';
import { useSelector, useDispatch } from 'react-redux';
import { LOADING_STATE } from '../constants/CommonConstants';
import ResponsiveDrawer from '../components/Layout/Drawer';
import { useIsAuthenticated } from '../hooks';
import { Modal } from '../components/shared';
import { setCurrentRoutePath } from '../redux-slice/route';

// list of routes that admin access only
const adminAccessibleRoutes = [`/${routeConstants.USERS_ROUTE}`];
export const AppRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { ui: UI, user: USER, route: ROUTE, msalStatus, error } = useSelector((state) => state);
  const { currentRoutePath } = ROUTE;
  const isAdmin = true;

  const allowedRoutes = useMemo(() => {
    const roles = UI.userRoles?.map((value) => value.title);

    return PrivateRoutesConfig.filter(({ permission }) => {
      if (!permission) return true;
      else if (!isArrayWithLength(permission)) return true;
      else return intersection(permission, roles).length;
    });
  }, [UI.userRoles]);

  useEffect(() => {
    if ((currentRoutePath === '' || !ROUTE.currentRoutePath) && allowedRoutes[0]?.path) {
      dispatch(setCurrentRoutePath(allowedRoutes[0]?.path));
    }
  }, [allowedRoutes, ROUTE]);

  const routes = useMemo(() => {
    if (!isAuthenticated || UI.loading === LOADING_STATE.LOADING) return;

    const InitialLandRouteToNavigate = currentRoutePath ? currentRoutePath : allowedRoutes[0]?.path;
    return (
      <>
        <Route index element={<Navigate to={`/${InitialLandRouteToNavigate}`} replace />} />
        {allowedRoutes.map((value, index) => (
          <Route key={index} path={value.path} element={value.component} />
        ))}
      </>
    );
  }, [isAuthenticated, UI.loading, currentRoutePath, allowedRoutes]);

  if (msalStatus?.responseLoading) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path={'/'} element={<App />}>
            <Route
              index
              element={
                <Modal open={true} customWidth="395px">
                  <LoadingSpinner containerHeight={300} />
                </Modal>
              }
            />
          </Route>
          <Route path={'*'} element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    );
  }

  if (!isAuthenticated || USER?.accessToken === '' || msalStatus?.ssoSilentStatus === LOADING_STATE.REJECTED) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path={'/'} element={<App />}>
            <Route index element={<Public />} />
          </Route>
          <Route path={'*'} element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    );
  }

  if (
    UI.loading === LOADING_STATE.LOADING ||
    UI.loading === LOADING_STATE.REJECTED ||
    (UI.loading === LOADING_STATE.FULFILLED && !isArrayWithLength(UI.userRoles))
  ) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <ResponsiveDrawer>
          <Routes>
            <Route path={'/'} element={<App />}>
              <Route
                index
                element={
                  (UI.userRoles && UI.userRoles.length === 0 && UI.loading === LOADING_STATE.FULFILLED) ||
                  UI.loading === LOADING_STATE.REJECTED ? (
                    <NoRolesAssign />
                  ) : UI.loading === LOADING_STATE.LOADING ? (
                    <LoadingSpinner />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
            </Route>
            <Route path={'*'} element={<Navigate to="/" replace />} />
          </Routes>
        </ResponsiveDrawer>
      </Suspense>
    );
  }

  if ((currentRoutePath && currentRoutePath !== '') || error.isForbiddenError || error.isParseError) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Protected isAdmin={isAdmin} adminAccessibleRoutes={adminAccessibleRoutes}>
          <Routes>
            <Route path={'/'} element={<App />}>
              {routes}
            </Route>
            <Route path={'*'} element={<NotFound />} />
          </Routes>
        </Protected>
      </Suspense>
    );
  }
};
