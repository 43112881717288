import { errorSlice } from './error-slice';

export const {
  setShowErrorModal,
  setErrorMsg,
  setIsInternalServerError,
  reset,
  setIsForbiddenError,
  setErrorTitle,
  setIsParseError,
} = errorSlice.actions;
export default errorSlice;
