import React, { useEffect, useMemo, useState } from 'react';
import ReconciliationMachineFormAccordionContent from '../ReconciliationDetail/ReconciliationMachineFormAccordionContent';
import ReactTable from '../../../components/shared/ReactTable';
import { COLUMNS } from '../../../constants/tableColumns';
import { InformationField, Accordion } from '../../../components/shared';
import MachineFormAccordionContent from './MachineFormAccordionContent';
import ApprovedMachineFormAccordionContent from '../ApprovedCollectionDetail/ApprovedMachineFormAccordionContent';
import { MESSAGE } from '../../../constants/message';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { getApprovedAssetSubtotalMap, getPlayCardApiSchemaData } from '../../../utils/collection-methods';
import PlayCardSummary from './PlayCardSummary';
import {
  checkValueNotNullUndefinedBlank,
  isAllObjValuesNullOrEmpty,
  getDateFormat,
  isAllObjectValuesNullOrZero,
  isBothObjectSame,
} from '../../../utils/common-methods';
import useStyles from './style';
import { useFormikContext } from 'formik';
import AccountSummary from './AccountSummary';
import { CURRENCY_CONSTANT, DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import SublocationDateModule from './SublocationDateModule';
import ApprovedSublocationDateModule from '../ApprovedCollectionDetail/ApprovedSublocationDateModule';
import InPageNotification from '../../../components/InPageNotification';
import { IN_PAGE_NOTIFICATION_TYPES as NOTIFICATION_TYPE } from '../../../constants/InPageNotificationTypes';
import { getLastCollectionDate } from '../../../utils/collection-methods.js';
import { ROLE } from '../../../constants/roles';
import { useHasRoles } from '../../../hooks';
import AddMissingAssets from './AddMissingAssets.jsx';
import IndependentRep from './IndependentRep.jsx';
import ApprovedIndependentRep from '../ApprovedCollectionDetail/ApprovedIndependentRep.jsx';

const SublocationMachinesForm = ({
  averageTokenValue = 0,
  sublocation: onMountSublocation,
  originalSublocation,
  isReconciliation,
  isVerified = false,
  isApproved,
  readFromDraft,
  turnOffDraft,
  locationCurrency = {},
  searchState,
  sortState = false,
}) => {
  const isSuperAdmin = useHasRoles([ROLE.SUPER_ADMIN_IT]);
  const [sublocation, setSublocation] = useState({});
  const [skip, setSkip] = useState(true);
  const [resetSummary, setResetSummary] = useState(readFromDraft);
  useEffect(() => setSublocation({ ...onMountSublocation }), [onMountSublocation]);
  const assets = useMemo(() => sublocation?.assets, [sublocation]);
  const [assetSubtotalMap, setAssetSubtotalMap] = useState({});
  const [isSublocationDirty, setIsSublocationDirty] = useState(false);
  const classes = useStyles();
  const isManagerUser = useHasRoles([ROLE.MANAGER, ROLE.DM, ROLE.REGIONAL_MANAGER, ROLE.EXECS, ROLE.VP]);
  const isAccountingAdminUser = useHasRoles([ROLE.ACCOUNTING, ROLE.SUPER_ADMIN_IT]);
  const { reconciliationWarningForModal, intercardDataForReconciliation } = useSelector(
    (state) => state.reconciliationCollection,
  );
  //The below useEffect required control drafted account summary
  useEffect(() => setResetSummary(readFromDraft), [readFromDraft]);
  // transformed assets
  const data = useMemo(
    () =>
      assets?.map((item) => {
        const lastCollectionDate = item?.lastCollectionByAsset?.dateOfReading;
        let sublocationLastCollection = getLastCollectionDate(sublocation, isReconciliation);
        const lastCollectionForSubloc = checkValueNotNullUndefinedBlank(sublocationLastCollection)
          ? getDateFormat(sublocationLastCollection)
          : 'N/A';
        const lastCollection = !lastCollectionDate
          ? lastCollectionForSubloc || 'N/A'
          : getDateFormat(item?.lastCollectionByAsset?.dateOfReading);
        let updateData = { ...item, lastCollection };
        if (isReconciliation || isApproved) {
          const costPerPlay = item.costPerPlay || '0.00';
          const clicksPerPlay = item.clicksPerPlay || 0;
          updateData.costPerPlay = costPerPlay;
          updateData.clicksPerPlay = clicksPerPlay;
        }
        if (
          isReconciliation &&
          intercardDataForReconciliation &&
          intercardDataForReconciliation[item?.id] &&
          intercardDataForReconciliation[item?.id].intercardData
        ) {
          updateData.intercardTransactions = intercardDataForReconciliation[item?.id].intercardData;
        }
        return updateData;
      }),
    [assets, isReconciliation, isApproved, intercardDataForReconciliation],
  );

  const assetColumn =
    isReconciliation || isApproved
      ? isApproved
        ? COLUMNS.RECONCILIATION_APPROVED_ACCORDION_ASSET_TABLE
        : [...COLUMNS.RECONCILIATION_APPROVED_ACCORDION_ASSET_TABLE, ...COLUMNS.CREATE_COLLECTION_ASSET_TABLE]
      : COLUMNS.CREATE_COLLECTION_ASSET_TABLE;
  const columns = [COLUMNS.EXPAND_ICON_COLUMN, ...COLUMNS.RECONCILIATION_ACCORDION_ASSET_TABLE, ...assetColumn];

  const { playCards } = useSelector((state) => state.playCard);
  const { errors, touched, initialValues, values } = !isApproved ? useFormikContext() : {};
  const getRowErrorClass = (errors, rowData, touched) => {
    const warningErrors = {};
    for (const key in reconciliationWarningForModal) {
      if (reconciliationWarningForModal[key] === true) {
        if (
          !key.includes('Token changer_revenueCollectedFromTokenChanger') &&
          !key.includes('Credit_expectedRevenue')
        ) {
          warningErrors[key] = reconciliationWarningForModal[key];
        }
      }
    }
    return Object.keys({ ...warningErrors, ...errors } || {})
      .join(' ')
      .includes(rowData?.original?.id) && Object.values(touched)?.length
      ? ' error'
      : '';
  };

  useEffect(() => {
    const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
    const exchangeRate = locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE;
    if (isApproved) {
      const subtotalMap = getApprovedAssetSubtotalMap(assets, abbreviation, exchangeRate);
      setAssetSubtotalMap(subtotalMap);
    }
  }, [isApproved, locationCurrency, assets]);

  /* We have used this hook to check if the sublocation have been updated or not. As we have location level check present and not sublocation level. */
  useEffect(() => {
    if (isReconciliation && sublocation?.id) {
      const sublocationInitialValues = Object.entries(initialValues).filter(([keys]) => {
        const sublocationId = keys.split('_')?.[2] || undefined;
        if (Number(sublocationId) === sublocation?.id) {
          return true;
        }
        return false;
      });
      const sublocationInitialValuesObject = Object.fromEntries(sublocationInitialValues);
      const sublocationValues = Object.entries(values).filter(([keys]) => {
        const sublocationId = keys.split('_')?.[2] || undefined;
        if (Number(sublocationId) === sublocation?.id) {
          return true;
        }
        return false;
      });
      const sublocationValuesObject = Object.fromEntries(sublocationValues);

      const isBothSame = isBothObjectSame(sublocationValuesObject, sublocationInitialValuesObject);
      setIsSublocationDirty(!isBothSame);
    }
  }, [values, readFromDraft]);

  const accordionComponent = ({ row }) => {
    const original = row?.original;
    if (isReconciliation) {
      return <ReconciliationMachineFormAccordionContent asset={original} skip={skip} />;
    }
    if (isApproved) {
      return (
        <ApprovedMachineFormAccordionContent
          asset={original}
          assetSubtotalMap={assetSubtotalMap}
          locationCurrency={locationCurrency}
          averageTokenValue={averageTokenValue}
        />
      );
    }
    return <MachineFormAccordionContent asset={original} />;
  };

  const playCardsData = playCards[sublocation?.id];
  const playCardStandardData = getPlayCardApiSchemaData(playCardsData);
  const showPlayCardSummary =
    (sublocation && playCardsData && !isAllObjectValuesNullOrZero(playCardStandardData)) ||
    sublocation?.isIntercardSublocation;
  const showAccountSummary = isApproved
    ? onMountSublocation &&
      onMountSublocation?.accountSumDetails &&
      !isAllObjValuesNullOrEmpty(onMountSublocation?.accountSumDetails)
    : onMountSublocation &&
      onMountSublocation?.accountNumbers &&
      !isAllObjValuesNullOrEmpty(onMountSublocation?.accountNumbers);

  if (data?.length === 0 && !showPlayCardSummary && !showAccountSummary) {
    return (
      <Accordion
        summaryTitleVariant="subtitle5"
        marginBottom={3}
        title={sublocation.name}
        content={<InformationField variant="subtitle1" value={MESSAGE.NO_ASSOCIATED_ASSET} />}
      />
    );
  }

  if (isReconciliation) {
    const defaultExpandedRows = data?.map((asset, index) => ({ [index]: true }));
    const isManagerUserAndSubmitted = isManagerUser && !isAccountingAdminUser;
    return (
      <Accordion
        accordionSummaryStyle
        noGutterAccordion
        summaryTitleVariant="subtitle5"
        defaultExpanded
        marginBottom={3}
        subComponent={
          <SublocationDateModule
            sublocation={sublocation}
            setSkip={setSkip}
            setSublocation={setSublocation}
            skip={skip}
            isReconciliation={isReconciliation}
            originalSublocation={originalSublocation}
            readFromDraft={readFromDraft}
            turnOffDraft={turnOffDraft}
          />
        }
        content={
          <Box className={classes.reconciliationAccordion}>
            <ReactTable
              data={data || []}
              columns={columns}
              expandable
              autoExpandedItems={defaultExpandedRows}
              renderRowSubComponent={accordionComponent}
              rowClassName={(rowData) => getRowErrorClass(errors, rowData, touched)}
            />
            {data?.length === 0 && (
              <Box padding={2}>
                <InformationField variant="subtitle1" value={MESSAGE.NO_ASSOCIATED_ASSET} />
              </Box>
            )}
            {sublocation.exceptions?.length
              ? sublocation.exceptions.map(
                  ({ message, displayWarning }, index) =>
                    displayWarning && (
                      <InPageNotification
                        key={`exceptionWarning${index}`}
                        type={NOTIFICATION_TYPE.WARNING}
                        message={message}
                      />
                    ),
                )
              : null}
            {isSuperAdmin && onMountSublocation.missingAssets?.length ? (
              <AddMissingAssets
                sublocationId={onMountSublocation.id}
                missingAssets={onMountSublocation.missingAssets}
              />
            ) : null}
            {showPlayCardSummary && (
              <PlayCardSummary readFromDraft={readFromDraft} sublocation={sublocation} skip={skip} />
            )}
            {!isManagerUserAndSubmitted && showAccountSummary && (
              <AccountSummary
                sublocation={onMountSublocation}
                locationCurrency={locationCurrency}
                isVerified={isVerified}
                isReconciliation={isReconciliation}
                accountSummaryDefault={onMountSublocation?.accountSumDetails}
                searchState={searchState}
                formIsDirty={isSublocationDirty}
                readFromDraft={resetSummary}
                setReadFromDraft={setResetSummary}
                sortState={sortState}
              />
            )}
            {sublocation && Object.keys(sublocation)?.length > 0 && <IndependentRep sublocation={sublocation} />}
          </Box>
        }
      />
    );
  }

  if (isApproved) {
    return (
      <Accordion
        accordionSummaryStyle
        noGutterAccordion
        summaryTitleVariant="subtitle5"
        subComponent={<ApprovedSublocationDateModule sublocation={sublocation} />}
        content={
          <Box className={classes.reconciliationAccordion}>
            <ReactTable data={data || []} columns={columns} expandable renderRowSubComponent={accordionComponent} />
            {data?.length === 0 && (
              <Box padding={2}>
                <InformationField variant="subtitle1" value={MESSAGE.NO_ASSOCIATED_ASSET} />
              </Box>
            )}
            {showPlayCardSummary && <PlayCardSummary sublocation={sublocation} isApproved={isApproved} />}
            {showAccountSummary && (
              <AccountSummary
                sublocation={sublocation}
                isApproved={isApproved}
                locationPaysSalesTax={onMountSublocation?.locationPaysSalesTax}
              />
            )}
            {sublocation && Object.keys(sublocation)?.length > 0 && (
              <ApprovedIndependentRep sublocation={sublocation} />
            )}
          </Box>
        }
      />
    );
  }

  // Only Add new collection page can read from draft
  return (
    <Accordion
      accordionSummaryStyle
      noGutterAccordion
      summaryTitleVariant="subtitle5"
      subComponent={
        <SublocationDateModule
          sublocation={sublocation}
          originalSublocation={originalSublocation}
          setSkip={setSkip}
          setSublocation={setSublocation}
          skip={skip}
          readFromDraft={readFromDraft}
          turnOffDraft={turnOffDraft}
        />
      }
      content={
        <Box className={classes.openCollectionAccordion}>
          <ReactTable
            data={data || []}
            columns={columns}
            expandable
            renderRowSubComponent={accordionComponent}
            rowClassName={(rowData) => getRowErrorClass(errors, rowData, touched)}
          />
          {data?.length === 0 && (
            <Box padding={2}>
              <InformationField variant="subtitle1" value={MESSAGE.NO_ASSOCIATED_ASSET} />
            </Box>
          )}
          {sublocation.exceptions?.length
            ? sublocation.exceptions.map(({ message }, index) => (
                <InPageNotification key={`test${index}`} type={NOTIFICATION_TYPE.WARNING} message={message} />
              ))
            : null}
          {showPlayCardSummary && (
            <PlayCardSummary sublocation={sublocation} readFromDraft={readFromDraft} skip={skip} />
          )}
        </Box>
      }
    />
  );
};

export default SublocationMachinesForm;
