import React, { useState, useMemo } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { GridContainer, GridItem, Breadcrumbs } from '../../components/shared';
import ExchangeRateTable from './ExchangeRateTable';
import BREADCRUMB_NAMES from '../../constants/breadcrumbNames';
import { routeConstants } from '../../constants/routeConstants';

const CurrencyRatesExchange = () => {
  const theme = useTheme();
  const [total, setTotal] = useState(0);
  const {
    SETTINGS: { CURRENCY_RATES, SETTINGS },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [{ text: SETTINGS, redirection: routeConstants.SETTING_ROUTE }, { text: CURRENCY_RATES }],
    [],
  );

  return (
    <React.Fragment>
      <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      <GridContainer>
        <GridItem xs={6} display="flex" alignItems="center">
          <Typography variant="h1">Currency Rates Master</Typography>
        </GridItem>
      </GridContainer>
      <Typography variant="h3" style={{ color: theme.palette.secondary.main }}>{`${
        total > 0 ? `${total} Currencies` : ''
      }`}</Typography>
      <Box mt={2} component={Paper}>
        <ExchangeRateTable onTotalChange={(value) => setTotal(value)} />
      </Box>
    </React.Fragment>
  );
};

export default CurrencyRatesExchange;
