import React from 'react';
import { Alert } from '@mui/material';
import { IN_PAGE_NOTIFICATION_TYPES } from '../../constants/InPageNotificationTypes';

/**
 * InPageNotification component
 * @param props
 * @param {String} props.type
 * @param {String} props.message
 * @param {Number} props.width
 * @return {JSX.Element}
 */
const InPageNotification = (props) => {
  // Supported types: error, warning, info, success
  const { type, message, width } = props;

  if (Object.values(IN_PAGE_NOTIFICATION_TYPES).includes(type)) {
    return (
      <Alert severity={type} sx={{ width: width ?? '100%' }}>
        {message}
      </Alert>
    );
  }
};

export default React.memo(InPageNotification);
