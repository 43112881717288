import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query({
      query: () => apiUrl.currencies,
      transformResponse: (response) => response.data.currencies,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrenciesQuery } = extendedApi;
