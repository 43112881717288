export const ROLE = {
  INDEPENDENT_REPS: 'Independent Reps',
  SUPER_ADMIN_IT: 'Super Admin/IT',
  EXECS: 'Execs',
  MANAGER: 'Manager',
  DM: 'District Manager',
  REGIONAL_MANAGER: 'Regional Manager',
  VP: 'VP Operations',
  CUST_SUPPORT: 'Cust Support',
  TECH_SUPPORT: 'Tech Support',
  MERCHANDISE: 'Merchandise/Replenishment',
  EQUIPMENT: 'Equipment/Purchasing',
  ACCOUNTING: 'Accounting',
  SALES: 'Sales',
  BUSDEV_ADMINISTRATION: 'BusDev/Administration',
  SHIPPING: 'Shipping/Receiving',
  LOGISTICS: 'Logistics',
  PARTS: 'Parts',
  HR: 'HR',
};
