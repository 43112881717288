import { Box, DialogContentText } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Button, GridContainer, GridItem, Modal, TextField } from '../../../components/shared';
import { leaseSchema } from '../../../schema/validationSchemas';

const TerminationRequestModal = (props) => {
  const { isOpen, submitClick = () => {}, onCancelClick = () => {} } = props;
  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: leaseSchema.terminationRequestSchema,
    onSubmit: (values) => {
      submitClick(values);
    },
  });

  const { errors, touched, handleSubmit, values, getFieldProps } = formik;

  return (
    <Modal
      title="Termination Request"
      titleVariant="modalHeading"
      open={isOpen}
      actionButtons={
        <Box mt={3} mb={2}>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
          <Button onClick={onCancelClick}>Cancel</Button>
        </Box>
      }
    >
      <DialogContentText variant="body2">
        Are you sure you want to terminate the lease? Please add the reason for lease termination
      </DialogContentText>
      <FormikProvider value={formik}>
        <Box mt={3}>
          <GridContainer rowSpacing={3}>
            <GridItem xs={12} md={12} pl={0} pt={0}>
              <TextField
                label="Add Reason"
                {...getFieldProps('reason')}
                value={values.reason}
                error={Boolean(touched.reason && errors.reason)}
                helperText={touched.reason && errors.reason}
              />
            </GridItem>
          </GridContainer>
        </Box>
      </FormikProvider>
    </Modal>
  );
};

export default TerminationRequestModal;
