import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  machineAccordion: {
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
    padding: '24px 56px 18px',
    '& .MuiTypography-h3': {
      lineHeight: '24px',
    },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': {
        padding: '0',
      },
    },
  },
  machineAccordionBtnWrap: {
    marginTop: '38px',
  },
}));

export default useStyles;
