import { LOCATION_OWNER_LABEL } from '../../constants/accordionFields';
import { mergedTwoPhoneNumber } from '../../utils/common-methods';
import { InformationPad } from '../shared';

const LocationOwnerDetailAccordion = ({ ownerInfo = {} }) => {
  const fields = [
    { label: LOCATION_OWNER_LABEL.NAME, value: ownerInfo?.name },
    { label: LOCATION_OWNER_LABEL.EMAIL, value: ownerInfo?.email },
    {
      label: LOCATION_OWNER_LABEL.PHONE,
      value: mergedTwoPhoneNumber(ownerInfo?.phone1, ownerInfo?.phone2),
    },
  ];

  return <InformationPad data={fields} />;
};

export default LocationOwnerDetailAccordion;
