import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  mainMenu: {
    '& .MuiPaper-root.MuiMenu-paper .MuiButtonBase-root': {
      padding: '12px 14px',
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
    },
    '&:hover:before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '47px',
      left: 0,
      top: 0,
      background: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
