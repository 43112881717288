import { Box, Paper, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GridContainer, GridItem, InformationField, InformationPad, Link } from '../../../components/shared';
import { LEASE_DETAIL_LABEL } from '../../../constants/accordionFields';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from '../Locations/style';
import { rentTypeString } from '../../../utils/common-methods';

const LeaseTableAccordion = (props) => {
  const { leaseInfo, disabled } = props;
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const sublocationLink = (id, name, locationId, isLast) => (
    <Link underline="none" to={`/${routeConstants.SUBLOCATION_ROUTE}/${id}?locationId=${locationId} `} key={id}>
      {`${name}${isLast ? '' : ', '}`}
    </Link>
  );
  const fields = useMemo(
    () => [
      {
        label: LEASE_DETAIL_LABEL.SUBLOCATION,
        value: leaseInfo?.sublocation?.map((item, index) =>
          sublocationLink(
            item?.sublocation?.id,
            item?.sublocation?.name,
            Number(params.id),
            index + 1 === leaseInfo?.sublocation?.length,
          ),
        ),
      },
      { label: LEASE_DETAIL_LABEL.ADDRESS1, value: leaseInfo?.address1 },
      { label: LEASE_DETAIL_LABEL.ADDRESS2, value: leaseInfo?.address2 },
      { label: LEASE_DETAIL_LABEL.ZIPCODE, value: leaseInfo?.postcode },
      { label: LEASE_DETAIL_LABEL.CITY, value: leaseInfo?.city },
      { label: LEASE_DETAIL_LABEL.STATE, value: leaseInfo?.state },
      { label: LEASE_DETAIL_LABEL.COUNTRY, value: leaseInfo?.country },
      { label: LEASE_DETAIL_LABEL.RENT_TYPE, value: rentTypeString(leaseInfo?.rentType) },
      { label: LEASE_DETAIL_LABEL.RENT_RATE, value: leaseInfo?.rate },
      { label: LEASE_DETAIL_LABEL.RENT_FEE, value: leaseInfo?.fee && `$${leaseInfo?.fee}` },
    ],
    [leaseInfo],
  );
  return (
    <Box
      component={Paper}
      className={classes.locationDetailAccordion}
      sx={{
        border: `1px solid ${theme.palette.grey[900]}`,
        borderRadius: '8px',
      }}
    >
      <Box>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Typography variant="h3" mb={1}>
              Lease & Rent Details
            </Typography>
          </GridItem>
        </GridContainer>

        <InformationPad data={fields} />

        {!disabled && (
          <GridContainer className={classes.locationDetailBtnWrap}>
            <GridItem xs={12} md={12}>
              <InformationField
                fontSize="14px"
                fontWeight="500"
                lineHeight="16px"
                value={
                  <Link
                    variant="link"
                    underline="none"
                    color="primary"
                    disabled={disabled}
                    to={`/${routeConstants.LEASE_ROUTE}/${leaseInfo.id}`}
                  >
                    VIEW FULL DETAILS
                  </Link>
                }
              />
            </GridItem>
          </GridContainer>
        )}
      </Box>
    </Box>
  );
};

export default LeaseTableAccordion;
