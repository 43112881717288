import { Typography } from '@mui/material';
import React from 'react';
import { GridItem } from '../../../components/shared';

const DepositDetailCommonRender = (props) => {
  const { title, child } = props;
  return (
    <>
      <GridItem xs={12} md={4} display="flex" justifyContent="start" alignItems="center">
        <Typography role="presentation">{title}</Typography>
      </GridItem>
      <GridItem xs={12} md={8} display="flex" alignItems="center">
        {child}
      </GridItem>
    </>
  );
};

export default DepositDetailCommonRender;
