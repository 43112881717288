import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip, Container, Paper, Stack, Typography } from '@mui/material';
import { Breadcrumbs } from '../../../components/shared';
import useStyles from './style';
import { useNavigate, useParams } from 'react-router-dom';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { leaseDetailsFormAction } from '../../../redux-slice/lease';
import { useDispatch } from 'react-redux';
import LeaseDetailsForm from '../../../components/LeaseDetailsForm';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { useGetLeaseByIdQuery, useUpdateLeaseByIdMutation } from '../../../services/Lease/LeaseService';
import dayjs from 'dayjs';
import { dateFormatForApi } from '../../../utils/common-methods';

const EditLease = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const leaseId = Number(params.id);
  const dispatch = useDispatch();
  const {
    LEASE: { LEASE, LEASE_DETAILS, EDIT_LEASE },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      { text: LEASE, redirection: `${routeConstants.LEASE_ROUTE}` },
      {
        text: LEASE_DETAILS,
        redirection: `${routeConstants.LEASE_ROUTE}/${leaseId}`,
      },
      { text: EDIT_LEASE },
    ],
    [],
  );
  const [modal, setModal] = useState(true);
  const { currentData: leaseDetail = {}, isLoading, isFetching } = useGetLeaseByIdQuery(leaseId);
  const [updateLease, { isLoading: updateLeaseLoading }] = useUpdateLeaseByIdMutation();

  const onUpdateClickHandler = (values) => {
    const sublocations = values?.subLocation?.map((value) => value?.id);
    const data = {
      id: leaseId,
      name: values?.leaseName,
      sublocationIds: sublocations,
      leaseType: values?.leaseType,
      status: values?.status,
      termStartDate: dateFormatForApi(values?.leaseBegins),
      termEndDate: dateFormatForApi(values?.leaseEnds),
    };

    updateLease(data).then((res) => {
      if (res.data) {
        navigate(`/${routeConstants.LEASE_ROUTE}/${leaseId}`, {
          replace: true,
        });
      }
    });
  };

  const onCancelClickHandler = () => {
    navigate(`/${routeConstants.LEASE_ROUTE}/${leaseId}`, { replace: true });
  };

  useEffect(() => {
    const subLocations = leaseDetail?.contractSublocations?.map((value) => value?.sublocation);
    dispatch(
      leaseDetailsFormAction({
        leaseName: leaseDetail?.name,
        location: {
          id: leaseDetail?.location?.id,
          name: leaseDetail?.location?.locationName,
        },
        subLocation: subLocations || [],
        city: leaseDetail?.location?.city?.id || '',
        state: leaseDetail?.location?.state?.id || '',
        zipCode: leaseDetail?.location?.postcode || '',
        address1: leaseDetail?.location?.address1 || '',
        address2: leaseDetail?.location?.address2 || '',
        country: leaseDetail?.location?.country?.id || '',
        boothInfo: '',
        space: '',
        dba: '',
        salesPerson: '',
        leaseType: leaseDetail?.leaseType || '',
        status: leaseDetail?.status || '',
        leaseBegins: leaseDetail?.termStartDate ? dayjs(leaseDetail?.termStartDate).toString() : null,
        leaseEnds: leaseDetail?.termEndDate ? dayjs(leaseDetail?.termEndDate).toString() : null,
      }),
    );
  }, []);

  const onConfirmationClickHandler = (value) => {
    switch (value) {
      case 'EDIT':
        setModal(false);
        break;
      case 'CANCEL':
        navigate(`/${routeConstants.LEASE_ROUTE}/${leaseId}`, {
          replace: true,
        });
        break;
      case 'CREATE_NEW':
        navigate(`/${routeConstants.ADD_LEASE_ROUTE}`, { replace: true });
        break;
    }
  };

  if (isLoading || isFetching) {
    return <LoadingSpinner containerHeight={400} />;
  }

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>

      <Box mb={3}>
        <Stack direction={'row'} alignItems="center" spacing={1.5}>
          <Typography variant="h1" data-testid="leaseName">
            {leaseDetail?.name}
          </Typography>
          <Chip
            label={leaseDetail?.leaseStatus}
            color={
              leaseDetail?.leaseStatus === 'PENDING'
                ? 'primary'
                : leaseDetail?.leaseStatus === 'ACTIVE'
                ? 'success'
                : 'error'
            }
            variant="outlined"
            data-testid="status"
          />
        </Stack>
      </Box>
      <Box component={Paper} pt={3} pl={3} pb={4}>
        <Box mb={2}>
          <Typography variant="h2">Lease Details</Typography>
        </Box>
        <Box>
          <LeaseDetailsForm
            isUpdate
            onUpdateHandler={onUpdateClickHandler}
            onCancelHandler={onCancelClickHandler}
            isLoading={updateLeaseLoading}
          />
        </Box>
      </Box>
      <ConfirmationModal
        isOpen={modal}
        title="Confirmation"
        msg="Are you sure you want to edit the Lease & Rent details? These changes will be applied on all assigned Sub locations."
        buttons={[
          { text: 'Edit', value: 'EDIT' },
          { text: 'Cancel', value: 'CANCEL' },
          { text: 'Create New', value: 'CREATE_NEW' },
        ]}
        onClick={onConfirmationClickHandler}
      />
    </Container>
  );
};

export default EditLease;
