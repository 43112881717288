import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  subLocation: {
    border: `1px solid ${theme?.palette?.grey[900]}`,
    borderRadius: '8px',
  },
  searchFilterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
    margin: '16px 0px 24px',
    height: '100%',
  },
  searchWrap: {
    width: '100%',
  },
  locationDetailsState: {
    '& .MuiChip-root': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  locationDetailAccordion: {
    padding: '24px 60px 18px',
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
    '& .MuiTypography-h3': {
      lineHeight: '24px',
    },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': {
        padding: '0',
      },
    },
  },
  locationDetailBtnWrap: {
    marginTop: '38px',
  },
  formCustomFields: {
    position: 'relative',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme?.palette?.error.main}`,
    },
    '& .MuiInputLabel-formControl': {
      color: theme?.palette?.error.main,
    },
    '& .MuiInputBase-root:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme?.palette?.error.main}`,
      },
    },
  },
}));

export default useStyles;
