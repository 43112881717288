import React from 'react';
import { GridContainer, GridItem, TextField } from '../../../components/shared';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { MESSAGE } from '../../../constants/message';
import { getIntercardFieldName } from '../../../utils/collection-methods';
import { FIELD_NAME } from '../../../constants/MeterTypeFieldMap';
import { isArrayWithLength } from '../../../utils/common-methods';
import { useFormikContext } from 'formik';

const IntercardFields = ({ intercardTransactions, assetId, sublocationId }) => {
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const { values } = useFormikContext();

  const intercardItems = [
    {
      fieldName: FIELD_NAME.STANDARD_PLAY,
      label: 'Standard Play',
      disabled: true,
    },
    {
      fieldName: FIELD_NAME.CASH_DEBITS,
      label: 'Cash Debits',
      disabled: true,
    },
    {
      fieldName: FIELD_NAME.POINTS,
      label: 'Points',
      disabled: true,
    },
  ];

  if (intercardTransactions && isArrayWithLength(intercardTransactions)) {
    return (
      <React.Fragment>
        <Typography variant="subtitle4">{`${MESSAGE.INTERCARD}`}</Typography>
        {intercardTransactions?.map((transaction, i) => (
          <GridContainer my={1} key={i}>
            {transaction?.assetSubTag && (
              <GridItem p={1} spacing={2} pt={1} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="subtitle4">{transaction?.assetSubTag}</Typography>
              </GridItem>
            )}
            {intercardItems.map((field, index) => {
              let fieldLabel = field.label;
              if (field.fieldName === FIELD_NAME.POINTS && transaction[FIELD_NAME.GROUP]) {
                fieldLabel = `${fieldLabel}(${transaction[FIELD_NAME.GROUP]})`;
              }
              const fieldName = getIntercardFieldName(
                assetId,
                transaction?.assetSubTag,
                sublocationId,
                field.fieldName,
                transaction?.group,
              );

              return (
                <GridItem
                  xs={12}
                  sm={3}
                  md={5}
                  lg={3}
                  p={1}
                  spacing={2}
                  px={isBelowMD ? 0 : 1}
                  pt={1}
                  display="block"
                  justifyContent={isBelowMD ? 'flex-start' : 'flex-end'}
                  alignItems="center"
                  key={index}
                >
                  <TextField
                    type="number"
                    label={fieldLabel}
                    name={fieldName}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={values[fieldName]}
                    disabled={field.disabled}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
        ))}
      </React.Fragment>
    );
  }
  return;
};

export default IntercardFields;
