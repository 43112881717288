import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CTMLogo from '../../assets/images/logo/venu_logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { ALL_DRAWER_ITEMS, DrawerConstant, COMMON_DRAWER_ITEMS } from '../../constants/DrawerConstants';
import { styles } from './style/SideNav.style';
import { useSelector } from 'react-redux';
import { getParentRoutePath, isArrayWithLength } from '../../utils/common-methods';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button } from '../shared';
import { ROLE } from '../../constants/roles';
import { useHasRoles } from '../../hooks';
const displayName = 'SideNavigation';
const propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.any,
  onScreenSelect: PropTypes.func,
  mobileOpen: PropTypes.bool,
  drawerWidth: PropTypes.number,
};

const SideNav = (props) => {
  const { classes, container, mobileOpen, onScreenSelect, showDrawer, setShowDrawer, drawerWidth } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(null);
  const ui = useSelector((state) => state.ui);
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const accessSettingsRoles = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.ACCOUNTING]);

  const primaryDrawerItems = useMemo(
    () => ALL_DRAWER_ITEMS.filter((item) => ui.mainMenuItems.map((value) => value.code).includes(item.value)),
    [ui.mainMenuItems.length, ui.userRoles.length],
  );

  useEffect(() => {
    // Current Location is the place where user select from drawer menu to navigate.
    // It consist of primary navigation from API and common navigations like settings.
    let locationPath = location.pathname;
    let currentLocation = [...primaryDrawerItems, ...COMMON_DRAWER_ITEMS].find(
      (item) => item.navigateTo === locationPath,
    );

    if (!currentLocation) {
      locationPath = getParentRoutePath(locationPath);
      currentLocation = [...primaryDrawerItems, ...COMMON_DRAWER_ITEMS].find((item) =>
        // mapping with children path and parent path
        String(item.navigateTo).includes(locationPath),
      );
    }

    if (currentLocation) {
      setSelected(locationPath);
    }
  }, [location.pathname]);

  const handleDrawerToggle = () => {
    onScreenSelect();
  };

  const onClick = (e, item) => {
    e.preventDefault();
    if (isBelowMd) handleDrawerToggle();
    if (item.navigateTo) {
      navigate(item.navigateTo);
    }
  };

  const renderDrawerItems = (item) => (
    <Box key={item.text}>
      <ListItem key={item.text} disablePadding>
        <ListItemButton
          onClick={(e) => onClick(e, item)}
          style={{
            backgroundColor: selected === item.navigateTo ? theme.palette.primary.main : theme.palette.transparent,
          }}
          className={`${classes.listItem} ${selected === item.navigateTo ? classes.listItemActive : ''}`}
        >
          <ListItemIcon
            style={{
              minWidth: 'auto',
              color: selected === item.navigateTo ? theme.palette.common.white : theme.palette.grey[500_1],
            }}
            sx={{ color: theme.palette.common.white }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            style={{ paddingLeft: 12, marginTop: 6, marginBottom: 6 }}
            primary={
              <Typography
                variant="h5"
                style={{
                  color: selected === item.navigateTo ? theme.palette.common.white : theme.palette.grey[900_1],
                }}
              >
                {item.text}
              </Typography>
            }
          />
          {item?.subNav && item?.subNav.length > 0 && (
            <ListItemIcon sx={{ color: theme.palette.grey[900_1], paddingLeft: 10 }}>
              <ExpandMoreIcon />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
    </Box>
  );

  const drawer = (
    <Box className={classes.drawerBox}>
      <Box
        className={classes.toolbarTitle}
        aria-hidden="true"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      >
        <Box component="img" src={CTMLogo} alt={'CTM Locations Logo'} className={classes.CTMLogo} />
      </Box>
      {isArrayWithLength(ui.userRoles) ? (
        <Box className={classes.drawerBoxListWrap}>
          <Box className={classes.drawerBoxList}>
            <List>{primaryDrawerItems.map((item) => renderDrawerItems(item))}</List>
            {accessSettingsRoles && (
              <>
                <Divider style={{ margin: '10px 16px', color: theme.palette.grey[100_02] }} />
                <List>{COMMON_DRAWER_ITEMS.map((item) => renderDrawerItems(item))}</List>
              </>
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            width: DrawerConstant.DRAWER_XS_WIDTH,
            backgroundColor: theme.palette.common.darkblue,
            borderRight: 'none',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        open
      >
        {showDrawer && drawer}
        <Button onClick={() => setShowDrawer(!showDrawer)} variant="contained" className={classes.drawerCollapseBtn}>
          <NavigateBeforeIcon className={showDrawer ? classes.openDrawer : classes.closeDrawer} />
        </Button>
      </Drawer>
    </Box>
  );
};

SideNav.displayName = displayName;
SideNav.propTypes = propTypes;
export default withStyles(styles)(SideNav);
