import { CURRENCY_TYPE } from './CurrencyConstants';

export const METER_TYPE = {
  BILL: 'Bill',
  CREDIT: 'Credit',
  CASH: 'Cash',
  TICKET: 'Ticket',
  COIN: 'Coin',
  TOKEN: 'Token',
  PRIZE: 'Prize',
  BONUS: 'Bonus',
  MEDALLION: 'Medallion',
  TOKEN_CHANGER: 'Token changer',
  CANDY: 'Candy',
  // reconciliation
  CASH_RECONCILIATION: 'Cash_reconciliation',
  COIN_RECONCILIATION: 'Coin_reconciliation',
  CREDIT_RECONCILIATION: 'Credit_reconciliation',
  TOKEN_RECONCILIATION: 'Token_reconciliation',
  TOKEN_CHANGER_RECONCILIATION: 'Token changer_reconciliation',
  // GAME_METER: 'GameMeter',
  // Intercard is a modem used to skip
  INTERCARD: 'Intercard',
};

export const FIELD_NAME = {
  PRIOR_READING: 'priorReading',
  CURRENT_READING: 'currentReading',
  TEST_VENDS: 'testVends',
  COINS_COLLECTED: 'coinsCollected',
  BILLS_COLLECTED: 'billsCollected',
  TOKEN_COLLECTED: 'tokensCollected',
  TOKEN_REVENUE: 'tokenRevenue',
  REVENUE_COLLECTED_FROM_TOKEN_CHANGER: 'revenueCollectedFromTokenChanger',
  CASH_AMOUNT: 'cashAmount',
  TICKET_DISPENSED: 'ticketDispensed',
  TOKEN_DISPENSED: 'tokenDispensed',
  BILLS_TAKEN_FROM_CHANGER: 'billsTakenFromChanger',
  COINS_ADDED_TO_CHANGER: 'coinsAddedToChanger',
  PRIZE_DISPENSED: 'prizeDispensed',
  REMAINING_INVENTORY: 'remainingInventory',
  MEDALLION_DISPENSED: 'medallionDispensed',
  REVENUE_IN_USD: 'revenueInUSD',
  // Intercard
  CASH_DEBITS: 'cashDebits',
  STANDARD_PLAY: 'standardPlay',
  POINTS: 'points',
  GROUP: 'group',
  // reconciliation
  SEEDLIVE_REVENUE: 'seedliveTotalRevenue', // including cash and credit seedlive revenue
  METER_ADJUST: 'meterAdjustment',
  // expected cash revenue
  EXPECTED_REVENUE: 'expectedRevenue',
  CLICKS_PER_PLAY: 'clicksPerPlay',
  COST_PER_PLAY: 'costPerPlay',
  ESTIMATED_COIN_REVENUE: 'estimatedCoinRevenue',
  PAYMENT_TYPE_FOR_TOKEN_CHANGER: 'machineAcceptedPaymentType',
  VARIANCE: 'variance',
};

export const DISPENSED_METER_TYPES = [METER_TYPE.TICKET, METER_TYPE.MEDALLION, METER_TYPE.PRIZE, METER_TYPE.TOKEN];

export const METER_TYPE_FILED_MAP = {
  [METER_TYPE.BONUS]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
  ],
  [METER_TYPE.PRIZE]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    // prizeDispensed won't be stored in db
    {
      label: 'Prize dispensed',
      name: FIELD_NAME.PRIZE_DISPENSED,
      disabled: true,
    },
    { label: 'Remaining inventory', name: FIELD_NAME.REMAINING_INVENTORY },
  ],
  [METER_TYPE.BILL]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Bills taken from changer',
      name: FIELD_NAME.BILLS_TAKEN_FROM_CHANGER,
      priceIcon: true,
    },
    {
      label: 'Coins to changer',
      name: FIELD_NAME.COINS_ADDED_TO_CHANGER,
      priceIcon: true,
    },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
  ],
  [METER_TYPE.TICKET]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    // ticketDispensed won't be stored in db
    {
      label: 'Ticket dispensed',
      name: FIELD_NAME.TICKET_DISPENSED,
      disabled: true,
    },
  ],
  [METER_TYPE.COIN]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Coins/ Quarter Collected',
      name: FIELD_NAME.COINS_COLLECTED,
      priceIcon: true,
    },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
  ],
  [METER_TYPE.TOKEN]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    { label: 'Token Collected', name: FIELD_NAME.TOKEN_COLLECTED, disabled: true },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS },
  ],
  [METER_TYPE.CASH]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Bills Collected',
      name: FIELD_NAME.BILLS_COLLECTED,
      priceIcon: true,
    },
    {
      label: 'Coins Collected',
      name: FIELD_NAME.COINS_COLLECTED,
      priceIcon: true,
    },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
  ],

  [METER_TYPE.CREDIT]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
  ],

  [METER_TYPE.MEDALLION]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Medallion dispensed',
      name: FIELD_NAME.MEDALLION_DISPENSED,
      disabled: true,
    },
  ],
  [METER_TYPE.TOKEN_CHANGER]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Revenue Collected',
      name: FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
      disabled: true,
    },
    {
      label: 'Token Dispensed',
      name: FIELD_NAME.TOKEN_DISPENSED,
      disabled: true,
    },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
    { label: 'Test changer', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
  ],
  [METER_TYPE.TOKEN_CHANGER_RECONCILIATION]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Revenue Collected',
      name: FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
      disabled: true,
    },
    {
      label: 'Seedlive Revenue',
      name: FIELD_NAME.SEEDLIVE_REVENUE,
      disabled: true,
    },
    {
      label: 'Token Dispensed',
      name: FIELD_NAME.TOKEN_DISPENSED,
      disabled: true,
    },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
    { label: 'Test changer', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
  ],
  [METER_TYPE.CANDY]: [
    {
      label: 'Coins Collected',
      name: FIELD_NAME.COINS_COLLECTED,
      priceIcon: true,
    },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
  ],

  // Reconciliation
  [METER_TYPE.CASH_RECONCILIATION]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Bills Collected',
      name: FIELD_NAME.BILLS_COLLECTED,
      priceIcon: true,
    },
    {
      label: 'Coins Collected',
      name: FIELD_NAME.COINS_COLLECTED,
      priceIcon: true,
    },

    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
    {
      label: 'Expected Cash Revenue',
      name: FIELD_NAME.EXPECTED_REVENUE,
      priceIcon: true,
      disabled: true,
      reconciliation: true,
      currencyType: CURRENCY_TYPE.USD,
    },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
    {
      label: 'Seedlive Revenue',
      name: FIELD_NAME.SEEDLIVE_REVENUE,
      currencyType: CURRENCY_TYPE.USD,
      priceIcon: false,
      reconciliation: true,
      disabled: true,
    },
    {
      label: 'Adjust',
      name: FIELD_NAME.METER_ADJUST,
      priceIcon: true,
      reconciliation: true,
    },
    {
      label: 'Variance',
      name: FIELD_NAME.VARIANCE,
      currencyType: CURRENCY_TYPE.USD,
      priceIcon: true,
      reconciliation: true,
      disabled: true,
    },
  ],
  [METER_TYPE.CREDIT_RECONCILIATION]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Est. CC Revenue',
      name: FIELD_NAME.EXPECTED_REVENUE,
      priceIcon: true,
      reconciliation: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
    {
      label: 'CC Revenue',
      name: FIELD_NAME.SEEDLIVE_REVENUE,
      currencyType: CURRENCY_TYPE.USD,
      priceIcon: false,
      reconciliation: true,
      disabled: true,
    },
    {
      label: 'Adjust',
      name: FIELD_NAME.METER_ADJUST,
      priceIcon: true,
      reconciliation: true,
    },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
  ],
  [METER_TYPE.TOKEN_RECONCILIATION]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    { label: 'Token Collected', name: FIELD_NAME.TOKEN_COLLECTED, disabled: true },
    { label: 'Test', name: FIELD_NAME.TEST_VENDS },
    {
      label: 'Token Revenue',
      name: FIELD_NAME.TOKEN_REVENUE,
      priceIcon: true,
      disabled: true,
      reconciliation: true,
    },
    {
      label: 'Token Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
  ],
  [METER_TYPE.COIN_RECONCILIATION]: [
    { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING },
    { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING },
    {
      label: 'Coins/ Quarter Collected',
      name: FIELD_NAME.COINS_COLLECTED,
      priceIcon: true,
    },

    { label: 'Test', name: FIELD_NAME.TEST_VENDS, priceIcon: true },
    {
      label: 'Est. Coin Revenue',
      name: FIELD_NAME.ESTIMATED_COIN_REVENUE,
      priceIcon: true,
      disabled: true,
      reconciliation: true,
      currencyType: CURRENCY_TYPE.USD,
    },
    {
      label: 'Revenue in USD',
      name: FIELD_NAME.REVENUE_IN_USD,
      priceIcon: true,
      disabled: true,
      currencyType: CURRENCY_TYPE.USD,
    },
  ],
  // ['GameMeter']: [
  //   { label: 'Prior Meter', name: FIELD_NAME.PRIOR_READING, disabled: true },
  //   { label: 'Current Meter', name: FIELD_NAME.CURRENT_READING }
  // ],
};

export const SHARED_FIELDS = [FIELD_NAME.PRIOR_READING, FIELD_NAME.CURRENT_READING];
export const COIN_FIELDS = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.COINS_COLLECTED,
  FIELD_NAME.REVENUE_IN_USD,
];
export const BILL_FIELDS = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.BILLS_TAKEN_FROM_CHANGER,
  FIELD_NAME.COINS_ADDED_TO_CHANGER,
  FIELD_NAME.REVENUE_IN_USD,
];
export const CASH_FIELDS = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.BILLS_COLLECTED,
  FIELD_NAME.COINS_COLLECTED,
  FIELD_NAME.REVENUE_IN_USD,
];
export const TICKET_FIELDS = [...SHARED_FIELDS, FIELD_NAME.TICKET_DISPENSED];
export const TOKEN_FIELDS = [...SHARED_FIELDS, FIELD_NAME.TEST_VENDS, FIELD_NAME.TOKEN_COLLECTED];
export const TOKEN_CHANGER_FIELDS = [
  ...SHARED_FIELDS,
  FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
  FIELD_NAME.REVENUE_IN_USD,
  FIELD_NAME.TOKEN_DISPENSED,
  FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER,
  FIELD_NAME.TEST_VENDS,
];
export const TOKEN_CHANGER_FIELDS_RECONCILIATION = [
  ...SHARED_FIELDS,
  FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
  FIELD_NAME.SEEDLIVE_REVENUE,
  FIELD_NAME.REVENUE_IN_USD,
  FIELD_NAME.TOKEN_DISPENSED,
  FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER,
  FIELD_NAME.TEST_VENDS,
];
export const PRIZE_FIELDS = [...SHARED_FIELDS, FIELD_NAME.PRIZE_DISPENSED, FIELD_NAME.REMAINING_INVENTORY];
export const MEDALLION_FIELDS = [...SHARED_FIELDS, FIELD_NAME.MEDALLION_DISPENSED];
export const CANDY_FIELDS = [FIELD_NAME.COINS_COLLECTED, FIELD_NAME.REVENUE_IN_USD];

export const CASH_FIELDS_RECONCILIATION = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.BILLS_COLLECTED,
  FIELD_NAME.COINS_COLLECTED,
  FIELD_NAME.REVENUE_IN_USD,
  FIELD_NAME.SEEDLIVE_REVENUE,
  FIELD_NAME.METER_ADJUST,
  FIELD_NAME.EXPECTED_REVENUE,
  FIELD_NAME.VARIANCE,
];

export const COIN_FIELDS_RECONCILIATION = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.COINS_COLLECTED,
  FIELD_NAME.REVENUE_IN_USD,
  FIELD_NAME.ESTIMATED_COIN_REVENUE,
];

export const TOKEN_FIELDS_RECONCILIATION = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.TOKEN_COLLECTED,
  FIELD_NAME.TOKEN_REVENUE,
  FIELD_NAME.REVENUE_IN_USD,
];

export const CREDIT_FIELDS_RECONCILIATION = [
  ...SHARED_FIELDS,
  FIELD_NAME.TEST_VENDS,
  FIELD_NAME.METER_ADJUST,
  FIELD_NAME.EXPECTED_REVENUE,
  FIELD_NAME.SEEDLIVE_REVENUE,
];

export const SORT_ORDER = [
  METER_TYPE.CASH,
  METER_TYPE.CREDIT,
  METER_TYPE.COIN,
  METER_TYPE.TOKEN,
  METER_TYPE.TICKET,
  METER_TYPE.PRIZE,
  METER_TYPE.MEDALLION,
  METER_TYPE.BONUS,
  METER_TYPE.BILL,
  METER_TYPE.TOKEN_CHANGER,
  METER_TYPE.CANDY,
];

export const NEGATIVE_VALUE_ALLOW_FIELD_LABELS = ['Adjust'];

export const REVENUE_METER_TYPES = [
  METER_TYPE.CASH,
  METER_TYPE.CASH_RECONCILIATION,
  METER_TYPE.CREDIT_RECONCILIATION,
  METER_TYPE.CREDIT,
  METER_TYPE.COIN,
  METER_TYPE.COIN_RECONCILIATION,
  METER_TYPE.TOKEN,
  METER_TYPE.CANDY,
  METER_TYPE.TOKEN_CHANGER_RECONCILIATION,
];

export const EXCLUDING_FIELD_NAMES = [
  FIELD_NAME.TICKET_DISPENSED,
  FIELD_NAME.PRIZE_DISPENSED,
  FIELD_NAME.MEDALLION_DISPENSED,
  FIELD_NAME.CASH_DEBITS,
  FIELD_NAME.STANDARD_PLAY,
  FIELD_NAME.SEEDLIVE_REVENUE,
  FIELD_NAME.ESTIMATED_COIN_REVENUE,
  FIELD_NAME.REVENUE_IN_USD,
];

export const EXCLUDING_FIELD_NAMES_FOR_INITIALIZE = [
  FIELD_NAME.GROUP,
  FIELD_NAME.POINTS,
  FIELD_NAME.CASH_DEBITS,
  FIELD_NAME.STANDARD_PLAY,
  FIELD_NAME.SEEDLIVE_REVENUE,
];

export const INTERCARD_FIELD_NAMES = [
  FIELD_NAME.CASH_DEBITS,
  FIELD_NAME.STANDARD_PLAY,
  FIELD_NAME.POINTS,
  FIELD_NAME.GROUP,
];
