import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FieldArray, FormikProvider, useFormik, getIn } from 'formik';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { GridContainer, Button, GridItem, Autocomplete, TextField } from '../../../components/shared';
import {
  useGetMetersQuery,
  useGetModemsByMachineIdQuery,
  useUpdateMetersByMachineIdMutation,
} from '../../../services/Machines/MachinesService';
import { getOptions } from '../../../utils/common-methods';
import { addMeterSchema } from '../../../schema/validationSchemas';
import useStyles from './style';
import { DENOMINATION, TYPE, METER_TYPES } from '../../../constants/MeterConstants';

const AddNewMeter = ({ setAddMeter, machineId }) => {
  const classes = useStyles();
  const params = useParams();
  const { currentData: metersResponse } = useGetMetersQuery();
  const [updateMetersApi] = useUpdateMetersByMachineIdMutation();
  const meterTypes = useMemo(() => {
    let option = getOptions(metersResponse?.data?.meters);
    return option.map((option) => ({
      ...option,
      text: `${option.text} Meter`,
    }));
  }, [metersResponse]);
  const { currentData: currentModemsData, isSuccess: modemSuccess } = useGetModemsByMachineIdQuery(Number(params.id));

  const initialFieldArray = {
    meterPosition: '',
    currentMeter: 0,
    maxCount: 0,
    description: '',
    displayName: '',
    denomination: null,
    type: null,
    tokenPayout: '',
    modemId: null,
  };

  const formik = useFormik({
    initialValues: {
      meters: { text: '', value: null },
      meterPosition: '',
      currentMeter: 0,
      description: '',
      maxCount: 0,
      modemId: { text: '', value: null },
      fieldArray: [initialFieldArray],
    },
    validationSchema: addMeterSchema,
    onSubmit: (data) => {
      const metersToUpdate = handleApiDataChange(data);
      const apiData = {
        id: machineId,
        meters: data?.modemId?.value ? [{ ...metersToUpdate[0], modemId: data?.modemId?.value }] : metersToUpdate,
      };
      updateMetersApi(apiData).then((response) => {
        if (response.data) {
          setAddMeter(false);
        }
      });
    },
  });
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;

  const modemOptions = useMemo(() => {
    const modems = currentModemsData?.data?.modems?.map((value) => ({
      text: value?.serialNumber?.trim(),
      value: value?.id,
    }));
    return modems;
  }, [currentModemsData, modemSuccess, values]);

  const handleApiDataChange = (meterData = {}) => {
    switch (meterData?.meters?.text) {
      case METER_TYPES.PRIZE_METER:
        return meterData.fieldArray?.map((prize) => ({
          meterId: meterData?.meters?.value,
          identifier: prize.meterPosition || '',
          currentAssetMeterReading: prize.currentMeter || null,
          maxCount: prize.maxCount || null,
          description: prize.description || '',
        }));
      case METER_TYPES.MEDALLION_METER:
        return meterData.fieldArray?.map((medallion) => ({
          meterId: meterData?.meters?.value,
          identifier: medallion.meterPosition || '',
          currentAssetMeterReading: medallion.currentMeter || null,
          maxCount: medallion.maxCount || null,
          displayName: medallion.displayName || '',
        }));
      case METER_TYPES.TOKEN_CHANGER_METER:
        return meterData.fieldArray?.map((value) => {
          let data = {
            meterId: meterData?.meters?.value,
            identifier: value.meterPosition || '',
            currentAssetMeterReading: value.currentMeter || null,
            maxCount: value.maxCount || null,
            denomination: value.denomination?.text || null,
            machineAcceptedPaymentType: value.type?.text || null,
            tokenPayout: value.tokenPayout || null,
          };
          if (value?.modemId?.value) {
            data = { ...data, modemId: value?.modemId?.value || null };
          }
          return data;
        });
      case METER_TYPES.CANDY_METER:
        return [
          {
            meterId: meterData?.meters?.value,
            candyMeterDescription: meterData.description || '',
          },
        ];

      default:
        return [
          {
            meterId: meterData?.meters?.value,
            identifier: meterData?.meterPosition || null,
            currentAssetMeterReading: meterData?.currentMeter || null,
            maxCount: meterData?.maxCount || null,
          },
        ];
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setFieldValue('currentMeter', 0);
      setFieldValue('maxCount', 0);
    }
  }, [values.meters]);

  const isDisabled = formik.values.meters?.value ? false : true;
  const isCommonMeterField = ![
    METER_TYPES.TOKEN_CHANGER_METER,
    METER_TYPES.PRIZE_METER,
    METER_TYPES.MEDALLION_METER,
  ].includes(values.meters?.text);

  const isCandyMeter = METER_TYPES.CANDY_METER === values.meters?.text;

  return (
    <Box>
      <FormikProvider value={formik}>
        <Card>
          <CardHeader title={<Typography variant="h2">Add Meter</Typography>} className={classes.addMeterHead} />
          <CardContent className={classes.meterCardContent}>
            <GridContainer spacing={2} alignItems="center">
              <GridItem xs={12} md={4}>
                <Autocomplete
                  label="Meters"
                  data-testid="form-elements"
                  placeholder="Select Meter"
                  value={values.meters}
                  options={meterTypes || []}
                  getOptionLabel={(option) => option.text || 'Select Meter'}
                  onChange={(e, value) => {
                    setFieldValue('meters', value);
                  }}
                  onBlur={() => formik.setFieldTouched('meters')}
                  disablePortal
                  labelProp={{ shrink: true }}
                />
              </GridItem>
              {values?.meters?.value && isCommonMeterField && !isCandyMeter && (
                <>
                  <GridItem xs={12} md={12}>
                    <Typography variant="h5">{values?.meters?.text}</Typography>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <TextField
                      label="Meter Position"
                      InputProps={{
                        'data-testid': 'form-elements',
                      }}
                      {...getFieldProps('meterPosition')}
                    />
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <TextField
                      label="Current Meter"
                      inputProps={{ maxLength: 6 }}
                      InputProps={{
                        'data-testid': 'form-elements',
                      }}
                      {...getFieldProps('currentMeter')}
                      error={Boolean(touched.currentMeter && errors.currentMeter)}
                      helperText={touched.currentMeter && errors.currentMeter}
                    />
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <TextField
                      label="Max Count"
                      InputProps={{
                        'data-testid': 'form-elements',
                      }}
                      inputProps={{ maxLength: 6 }}
                      {...getFieldProps('maxCount')}
                      error={Boolean(touched.maxCount && errors.maxCount)}
                      helperText={touched.maxCount && errors.maxCount}
                    />
                  </GridItem>
                  {[METER_TYPES.CREDIT_METER, METER_TYPES.CASH_METER].includes(values?.meters?.text) && (
                    <GridItem xs={12}>
                      <Autocomplete
                        label="Modem"
                        data-testid="form-elements"
                        value={values.modemId}
                        options={modemOptions || []}
                        getOptionLabel={(option) => option.text || ''}
                        onChange={(e, value) => {
                          setFieldValue('modemId', value);
                        }}
                        onBlur={() => formik.setFieldTouched('modemId')}
                      />
                    </GridItem>
                  )}
                </>
              )}
              {!isCommonMeterField && !isCandyMeter && (
                <FieldArray name="fieldArray" validateOnChange="true">
                  {({ push }) => (
                    <>
                      {values.fieldArray.length > 0 &&
                        values.fieldArray.map((fieldset1, index) => {
                          const meterPosition = `fieldArray.${index}.meterPosition`;
                          const maxCount = `fieldArray.${index}.maxCount`;
                          const currentMeter = `fieldArray.${index}.currentMeter`;
                          const description = `fieldArray.${index}.description`;
                          const displayName = `fieldArray.${index}.displayName`;
                          const denomination = `fieldArray.${index}.denomination`;
                          const modemId = `fieldArray.${index}.modemId`;
                          const type = `fieldArray.${index}.type`;
                          const tokenPayout = `fieldArray.${index}.tokenPayout`;
                          const touchedMaxCount = getIn(touched, maxCount);
                          const errorMaxCount = getIn(errors, maxCount);
                          const touchedCurrentMeter = getIn(touched, currentMeter);
                          const errorCurrentMeter = getIn(errors, currentMeter);
                          const touchedDenomination = getIn(touched, denomination);
                          const errorDenomination = getIn(errors, denomination);
                          const touchedType = getIn(touched, type);
                          const errorType = getIn(errors, type);
                          const touchedTokenPayout = getIn(touched, tokenPayout);
                          const errorTokenPayout = getIn(errors, tokenPayout);
                          const touchedModemSelect = getIn(touched, modemId);
                          const errorModemSelect = getIn(errors, modemId);

                          return (
                            <GridContainer key={index} className={classes.prizeMeterField} spacing={2}>
                              <GridItem xs={12} md={4}>
                                <TextField
                                  label="Meter Position"
                                  name={meterPosition}
                                  value={fieldset1.meterPosition}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  InputProps={{
                                    'data-testid': 'form-elements',
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <TextField
                                  name={maxCount}
                                  label="Max Count"
                                  value={fieldset1.maxCount}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={Boolean(touchedMaxCount && errorMaxCount)}
                                  helperText={touchedMaxCount && errorMaxCount}
                                  inputProps={{ maxLength: 6 }}
                                  InputProps={{
                                    'data-testid': 'form-elements',
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <TextField
                                  name={currentMeter}
                                  label="Current Meter"
                                  value={fieldset1.currentMeter}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={Boolean(touchedCurrentMeter && errorCurrentMeter)}
                                  helperText={touchedCurrentMeter && errorCurrentMeter}
                                  inputProps={{ maxLength: 6 }}
                                  InputProps={{
                                    'data-testid': 'form-elements',
                                  }}
                                />
                              </GridItem>
                              {values?.meters?.text === METER_TYPES.TOKEN_CHANGER_METER ? (
                                <>
                                  <GridItem xs={12} md={4}>
                                    <Autocomplete
                                      name={denomination}
                                      label="Denomination"
                                      data-testid="form-elements"
                                      placeholder="Denomination"
                                      value={fieldset1.denomination}
                                      options={DENOMINATION}
                                      onChange={(e, value) => setFieldValue(denomination, value)}
                                      onBlur={formik.handleBlur}
                                      error={Boolean(touchedDenomination && errorDenomination)}
                                      helperText={touchedDenomination && errorDenomination}
                                    />
                                  </GridItem>
                                  <GridItem xs={12} md={4}>
                                    <Autocomplete
                                      name={type}
                                      label="Type"
                                      data-testid="form-elements"
                                      placeholder="Type"
                                      value={fieldset1.type}
                                      options={TYPE}
                                      onChange={(e, value) => setFieldValue(type, value)}
                                      onBlur={formik.handleBlur}
                                      error={Boolean(touchedType && errorType)}
                                      helperText={touchedType && errorType}
                                    />
                                  </GridItem>
                                  <GridItem xs={12} md={4}>
                                    <TextField
                                      name={tokenPayout}
                                      label="Token Payout"
                                      value={fieldset1.tokenPayout}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      InputProps={{
                                        'data-testid': 'form-elements',
                                      }}
                                      error={Boolean(touchedTokenPayout && errorTokenPayout)}
                                      helperText={touchedTokenPayout && errorTokenPayout}
                                    />
                                  </GridItem>
                                  {[METER_TYPES.TOKEN_CHANGER_METER].includes(values?.meters?.text) && (
                                    <GridItem xs={12}>
                                      <Autocomplete
                                        name={modemId}
                                        label="Modem"
                                        data-testid="form-elements"
                                        placeholder="Type"
                                        value={fieldset1.modemId}
                                        options={modemOptions || []}
                                        onChange={(e, value) => setFieldValue(modemId, value)}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(touchedModemSelect && errorModemSelect)}
                                        helperText={touchedModemSelect && errorModemSelect}
                                      />
                                    </GridItem>
                                  )}
                                </>
                              ) : values?.meters?.text === METER_TYPES.PRIZE_METER ? (
                                <GridItem xs={12}>
                                  <TextField
                                    name={description}
                                    label="Description"
                                    value={fieldset1.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    InputProps={{
                                      'data-testid': 'form-elements',
                                    }}
                                  />
                                </GridItem>
                              ) : (
                                <GridItem xs={12}>
                                  <TextField
                                    name={displayName}
                                    label="Display Name"
                                    value={fieldset1.displayName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    InputProps={{
                                      'data-testid': 'form-elements',
                                    }}
                                  />
                                </GridItem>
                              )}
                            </GridContainer>
                          );
                        })}
                      <Button onClick={() => push(initialFieldArray)} p={0}>
                        <Typography variant="button">
                          {values?.meters?.text === METER_TYPES.TOKEN_CHANGER_METER
                            ? '+ NEW TOKEN CHANGER'
                            : values?.meters?.text === METER_TYPES.PRIZE_METER
                            ? '+ NEW PRIZE'
                            : '+ NEW MEDALLION'}
                        </Typography>
                      </Button>
                    </>
                  )}
                </FieldArray>
              )}
              {isCandyMeter && (
                <>
                  <GridItem xs={12}>
                    <Typography variant="h5">{values?.meters?.text}</Typography>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      label="Description"
                      InputProps={{
                        'data-testid': 'form-elements',
                      }}
                      {...getFieldProps('description')}
                    />
                  </GridItem>
                </>
              )}
            </GridContainer>
            <Box p={2} pl={1} className={classes.addMeterBtn}>
              <Button
                type="Submit"
                variant="contained"
                disabled={isDisabled}
                data-testid="add-btn"
                onClick={formik.handleSubmit}
              >
                <Typography variant="button">ADD</Typography>
              </Button>
              <Button onClick={() => setAddMeter(false)} data-testid="cancel-btn">
                <Typography variant="button">CANCEL</Typography>
              </Button>
            </Box>
          </CardContent>
        </Card>
      </FormikProvider>
    </Box>
  );
};

export default AddNewMeter;
