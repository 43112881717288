import { PaymentsOutlined, Paid, Settings, SupervisorAccount, Groups } from '@mui/icons-material';
import { routeConstants } from './routeConstants';
import { locationIcon, modelIcon, machineIcon, modemIcon, leaseIcon } from '../assets/images/icons';

export const DrawerConstant = {
  FILTER_DRAWER_MD_UP: 388,
  FILTER_DRAWER_MD_DOWN: 392,
  DRAWER_XS_WIDTH: 280,
  OPEN_DRAWER_WIDTH: 256,
  COLLAPSE_DRAWER_WIDTH: 31,
  MENU_ITEMS: {
    MENU_MAIN_USERS: 'MENU_MAIN_USERS',
    MENU_MAIN_LOCATIONS: 'MENU_MAIN_LOCATIONS',
    MENU_MAIN_LOCATION_OWNERS: 'MENU_MAIN_LOCATION_OWNERS',
    MENU_MAIN_MODELS: 'MENU_MAIN_MODELS',
    MENU_MAIN_MACHINES: 'MENU_MAIN_MACHINES',
    MENU_MAIN_COLLECTIONS: 'MENU_MAIN_COLLECTIONS',
    MENU_MAIN_MODEMS: 'MENU_MAIN_MODEMS',
    MENU_MAIN_DEPOSITS: 'MENU_MAIN_DEPOSITS',
    MENU_MAIN_LEASES: 'MENU_MAIN_CONTRACTS',
  },
  COMMON_MENU_ITEMS: {
    MENU_MAIN_SETTING: 'MENU_MAIN_SETTING',
  },
};

export const ALL_DRAWER_ITEMS = [
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_USERS,
    text: 'Users',
    icon: <SupervisorAccount />,
    navigateTo: `/${routeConstants.USERS_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_LOCATIONS,
    text: 'Locations',
    icon: locationIcon,
    navigateTo: `/${routeConstants.LOCATION_ROUTE}`,
  },

  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_MODELS,
    text: 'Models',
    icon: modelIcon,
    navigateTo: `/${routeConstants.MODELS_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_MACHINES,
    text: 'Machines',
    icon: machineIcon,
    navigateTo: `/${routeConstants.MACHINES_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_COLLECTIONS,
    text: 'Collections',
    icon: <Paid />,
    navigateTo: `/${routeConstants.COLLECTIONS_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_MODEMS,
    text: 'Modems',
    icon: modemIcon,
    navigateTo: `/${routeConstants.MODEMS_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_LOCATION_OWNERS,
    text: 'Location Owner',
    icon: <Groups />,
    navigateTo: `/${routeConstants.LOCATION_OWNERS_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_DEPOSITS,
    text: 'Deposit',
    icon: <PaymentsOutlined />,
    navigateTo: `/${routeConstants.DEPOSIT_ROUTE}`,
  },
  {
    value: DrawerConstant.MENU_ITEMS.MENU_MAIN_LEASES,
    text: 'Leases',
    icon: leaseIcon,
    navigateTo: `/${routeConstants.LEASE_ROUTE}`,
  },
];

export const COMMON_DRAWER_ITEMS = [
  {
    value: DrawerConstant.COMMON_MENU_ITEMS.MENU_MAIN_SETTING,
    text: 'Settings',
    icon: <Settings />,
    navigateTo: `/${routeConstants.SETTING_ROUTE}`,
  },
];
