import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useNavigationPrompt = (when = true, msg = '', fn = () => {}, data = {}) => {
  const navigator = useContext(NavigationContext)?.navigator;

  useEffect(() => {
    if (!when) {
      return;
    }
    const listener = ({ location }) => {
      if (window.confirm(msg)) {
        fn({ location, isOk: true, data: data });
      } else {
        fn({ location, isOk: false, data: {} });
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [when, msg, fn, data]);
};

export default useNavigationPrompt;
