import { useEffect, useMemo, useState } from 'react';
import { COLUMNS } from '../../../constants/tableColumns';
import ReactTable from '../../../components/shared/ReactTable';
import { PositionedMenu, Button, Link } from '../../../components/shared';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import { Box, Typography, Paper } from '@mui/material';
import MachineAccordion from '../../../components/MachineAccordion/MachineAccordion';
import useStyles from './style';
import { useGetMachineStatusQuery } from '../../../services/Machines/MachinesService';
import { Add, FactoryOutlined } from '@mui/icons-material';
import FIELD_TYPE from '../../../constants/fieldType';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';

const { NOT_APPLICABLE } = FIELD_TYPE;

const MachinesTable = ({ machineList = [], openAssignNew }) => {
  const classes = useStyles();
  const { currentData: assetStatusTypeResponse } = useGetMachineStatusQuery();
  const canEdit = useHasRoles([ROLE.BUSDEV_ADMINISTRATION, ROLE.SUPER_ADMIN_IT, ROLE.CUST_SUPPORT, ROLE.SALES]);

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = machineList.map((machine) => {
      const status =
        assetStatusTypeResponse?.data?.assetStatuses?.filter((status) => status?.id == machine?.statusId) || null;
      const statusTitle = status ? status[0]?.title : null;
      return {
        ...machine,
        machineId: machine.id || NOT_APPLICABLE,
        sublocationName: machine.sublocation?.name || NOT_APPLICABLE,
        modelTitle: machine?.model?.title || NOT_APPLICABLE,
        status,
        statusTitle,
      };
    });
    setTableData(data);
  }, [machineList]);

  const machineColumns = [
    COLUMNS.EXPAND_ICON_COLUMN,
    {
      Header: 'ASSET ID',
      accessor: 'machineId',
      width: 'auto',
    },
    {
      Header: 'LEGACY ASSET ID',
      accessor: 'legacyAssetId',
      width: 'auto',
    },
    {
      Header: 'MACHINE NAME',
      accessor: 'title',
      width: 'auto',
      Cell: ({ row }) => (
        <Link underline="none" to={`/${routeConstants.MACHINES_ROUTE}/${row.original?.id}`}>
          {row.original?.title || NOT_APPLICABLE}
        </Link>
      ),
    },
    ...COLUMNS.ASSIGN_SUBLOCATION_MACHINE_TABLE,
    {
      Header: () => null,
      id: 'more',
      width: 'auto',
      Cell: ({ row }) => (
        <PositionedMenu
          menuItemData={[
            {
              btnName: 'Edit',
              btnClickFunc: () =>
                navigate(`/${routeConstants.MACHINES_ROUTE}/${routeConstants.EDIT_MACHINE_ROUTE}/${row.original.id}`),
            },
          ]}
          disabled={!canEdit}
        />
      ),
    },
  ];

  const columns = useMemo(() => machineColumns, []);

  const machineDetails = ({ row }) => {
    const original = row?.original;
    return original && <MachineAccordion machineInfo={original} />;
  };

  const AddBtn = () => (
    <Box className={classes.addBtn}>
      <Button
        variant="outlined"
        data-testid="add-newMeter"
        startIcon={<Add />}
        size="large"
        onClick={() => {
          openAssignNew(true);
        }}
        disabled={!canEdit}
      >
        ASSIGN NEW
      </Button>
    </Box>
  );

  return (
    <Box component={Paper} className={classes.addMachineTable}>
      <Box className={classes.machineTableHeading}>
        <Typography variant="h2">Assigned Machines</Typography>
      </Box>
      <ReactTable
        key={'Machines'}
        data={tableData || []}
        columns={columns}
        stickyHeader
        tableHeight={500}
        expandable
        renderRowSubComponent={machineDetails}
        extraBtn={<AddBtn />}
        localFilter
        searchPlaceHolder="Search machines"
        noContentIcon={<FactoryOutlined className={classes.machineSearchIcon} />}
      />
    </Box>
  );
};

export default MachinesTable;
