import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 10px',
  },
  tableHeader: {
    '& .MuiTableCell-head': {
      backgroundColor: theme?.palette?.grey[900_2],
      color: theme?.palette?.secondary?.main,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 24 / 12,
    },
  },
  tableBody: {
    padding: 10,
    '& .MuiTableCell-body': {
      color: theme?.palette?.secondary?.main,
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
      textTransform: 'capitalize',
      borderBottomColor: theme?.palette?.grey[900],
      '& > a': {
        textDecoration: 'none',
      },
    },
  },
  buttonStyle: {
    padding: '10px',
    fontSize: '.8rem',
    height: '2rem',
  },
  selectedPage: {
    backgroundColor: '#42a5f5',
    color: '#FFFFFF',
    padding: '10px',
    fontSize: '.8rem',
    height: '2rem',
  },
  selectStyle: {
    padding: '10px',
    fontSize: '.8rem',
    height: '2rem',
    maxWidth: '12rem',
  },
  goToPage: {
    maxWidth: '5rem',
    padding: '0 10px',
  },
  showRowPerPage: {
    minWidth: 'fit-content',
    paddingRight: '10px',
  },
  selectLabel: {
    paddingLeft: '10px',
  },
  manualPagination: {
    backgroundColor: theme?.palette?.grey[200],
  },
  ul: {
    '& .MuiPaginationItem-root': {
      backgroundColor: '#fafafa',
    },
  },
  stickyHeaderCell: {
    position: 'sticky',
    top: '0',
    background: theme?.palette?.grey[900_2],
    zIndex: 1,
  },
  accordionExpanded: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: theme?.palette?.primary?.main,
      width: '4px',
      height: '100%',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      '&:nth-child(2)': {
        '& > a': {
          color: theme?.palette?.primary?.main,
        },
        '& > span': {
          color: theme?.palette?.primary?.main,
        },
      },
    },
  },
  accordionBody: {
    '&.MuiTableRow-root': {
      '& .MuiTableCell-root.MuiTableCell-body:first-of-type': {
        padding: '0 24px 24px 24px',
        borderBottom: `1px solid ${theme?.palette?.grey[900]}`,
      },
    },
  },
  searchWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  error: {
    background: 'rgba(255, 0, 0, 0.05)',
    '& td.MuiTableCell-body': {
      color: 'red',
    },
  },
}));

export default useStyles;
