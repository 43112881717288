import { createSlice } from '@reduxjs/toolkit';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { LEASE_LATEST_UPDATED } from '../../constants/SortConstants';

const initialState = {
  pendingLease: {
    ...FILTER_CONSTANTS.FILTER_DATA.LEASE.PENDING_LEASE.INITIAL_PARAMS,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LEASE_LATEST_UPDATED,
  },
  activeLease: {
    ...FILTER_CONSTANTS.FILTER_DATA.LEASE.ACTIVE_LEASE.INITIAL_PARAMS,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LEASE_LATEST_UPDATED,
  },
  archiveLease: {
    ...FILTER_CONSTANTS.FILTER_DATA.LEASE.ARCHIVE_LEASE.INITIAL_PARAMS,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LEASE_LATEST_UPDATED,
  },
};

export const leaseFilterSlice = createSlice({
  name: 'leaseFilterSlice',
  initialState,
  reducers: {
    setPendingLeaseFilters(state, action) {
      state.pendingLease[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.pendingLease[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.pendingLease[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.pendingLease.searchText = action.payload?.searchText || '';
      state.pendingLease.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.pendingLease.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.pendingLease.sort = action.payload?.sort || LEASE_LATEST_UPDATED;
    },
    setActiveLeaseFilters(state, action) {
      state.activeLease[FILTER_VALUES.LEASE_STATUS] = action.payload?.processStatus || [];
      state.activeLease[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.activeLease[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.activeLease[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.activeLease.searchText = action.payload?.searchText || '';
      state.activeLease.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.activeLease.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.activeLease.sort = action.payload?.sort || LEASE_LATEST_UPDATED;
    },
    setArchiveLeaseFilters(state, action) {
      state.archiveLease[FILTER_VALUES.LEASE_STATUS] = action.payload?.processStatus || [];
      state.archiveLease[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.archiveLease[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.archiveLease[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.archiveLease.searchText = action.payload?.searchText || '';
      state.archiveLease.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.archiveLease.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.archiveLease.sort = action.payload?.sort || LEASE_LATEST_UPDATED;
    },
    resetPendingLeaseFilters(state) {
      state.pendingLease = {
        ...initialState.pendingLease,
        searchText: state.pendingLease.searchText,
        sort: state.pendingLease.sort,
      };
    },
    resetActiveLeaseFilters(state) {
      state.activeLease = {
        ...initialState.activeLease,
        searchText: state.activeLease.searchText,
        sort: state.activeLease.sort,
      };
    },
    resetArchiveLeaseFilters(state) {
      state.archiveLease = {
        ...initialState.archiveLease,
        searchText: state.archiveLease.searchText,
        sort: state.archiveLease.sort,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default leaseFilterSlice;
