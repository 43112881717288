import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '../../constants/CommonConstants';

export const initialState = {
  loading: LOADING_STATE.IDLE,
  userRoles: [],
  mainMenuItems: [],
  uId: null,
  error: {},
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMainMenuItemsAndRoles(state, action) {
      const { mainMenuItems = [], roles } = action.payload;
      const tempUserRoles = [].concat(roles.nmRoles, roles.managementRole);
      state.mainMenuItems = mainMenuItems;
      state.userRoles = tempUserRoles;
      state.uId = tempUserRoles.length ? tempUserRoles[0]?.userRoles?.userId : null;
      state.loading = LOADING_STATE.FULFILLED;
    },
  },
});

export default uiSlice;
