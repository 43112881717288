import { useEffect } from 'react';
import { Typography, Box, Paper, Stack } from '@mui/material';
import {
  GridContainer,
  Button,
  GridItem,
  TextField,
  Select,
  Datepicker,
  Breadcrumbs,
} from '../../../components/shared';
import { useFormik } from 'formik';
import { useUpdateModemByIdMutation, useGetModemDetailsByIdQuery } from '../../../services/Modems/ModemsService';
import useStyles from './style';
import { useParams, useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import { ModemType } from '../ModemDetails/data';
import { AttachMoney } from '@mui/icons-material';
import { modemUpdateSchema } from '../../../schema/validationSchemas';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { dateFormatForApi } from '../../../utils/common-methods';

const EditModem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    MODEM: { MODEM, MODEM_DETAIL, EDIT_MODEM },
  } = BREADCRUMB_NAMES;
  const { isSuccess, currentData: currentModemDetails } = useGetModemDetailsByIdQuery(Number(id));

  useEffect(() => {
    if (isSuccess && currentModemDetails.data) {
      setValues({
        type: currentModemDetails?.data?.type || '',
        installedDate: currentModemDetails?.data.installedDate || '',
        price: currentModemDetails?.data?.price || 0,
        loginId: currentModemDetails?.data?.loginId || '',
        password: currentModemDetails?.data?.password || '',
        serialNumber: currentModemDetails?.data?.serialNumber || '',
        MACAddress: currentModemDetails?.data?.MACAddress || '',
      });
    }
  }, [isSuccess, currentModemDetails]);

  const breadcrumbData = [
    { text: MODEM, redirection: `${routeConstants.MODEMS_ROUTE}` },
    { text: MODEM_DETAIL, redirection: `${routeConstants.MODEMS_ROUTE}/${id}` },
    { text: EDIT_MODEM },
  ];

  const [updateModemApi] = useUpdateModemByIdMutation();

  const formik = useFormik({
    initialValues: {
      type: '',
      installedDate: '',
      price: 0,
      loginId: '',
      password: '',
      serialNumber: '',
      MACAddress: '',
    },
    validationSchema: modemUpdateSchema,
    onSubmit: (data) => {
      updateModemApi({
        id: id,
        type: data.type,
        installedDate: dateFormatForApi(data?.installedDate) || '',
        price: data.price || 0,
        loginId: data?.loginId || '',
        password: data?.password || '',
        serialNumber: data.serialNumber,
        MACAddress: data.MACAddress || null,
      }).then((response) => {
        if (response.data) {
          onSaveHandler();
        }
      });
    },
  });

  const onCancelHandler = () => {
    navigate(`/${routeConstants.MODEMS_ROUTE}/${id}`, { redirect: true });
  };

  const onSaveHandler = () => {
    navigate(`/${routeConstants.MODEMS_ROUTE}/${id}`, { redirect: true });
  };

  const { values, errors, touched, getFieldProps, setFieldValue, setValues } = formik;

  return (
    <Box>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs icon={'/'} variant="smallText" underline="hover" data={breadcrumbData} />
      </Box>
      <Box component={Paper} className={classes.formContent}>
        <Typography variant="h2" pt={3} pl={3}>
          Modem Details
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="Type"
                data-testid="editModemForm"
                options={ModemType}
                {...getFieldProps('type')}
                error={Boolean(touched.type && errors.type)}
                helperText={touched.type && errors.type}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="MAC Address"
                InputProps={{
                  'data-testid': 'editModemForm',
                }}
                {...getFieldProps('MACAddress')}
                error={Boolean(touched.MACAddress && errors.MACAddress)}
                helperText={touched.MACAddress && errors.MACAddress}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Price"
                InputProps={{
                  'data-testid': 'editModemForm',
                  startAdornment: <AttachMoney className={classes.attachMoneyIcon} />,
                }}
                {...getFieldProps('price')}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Login ID"
                InputProps={{
                  'data-testid': 'editModemForm',
                }}
                {...getFieldProps('loginId')}
                error={Boolean(touched.loginId && errors.loginId)}
                helperText={touched.loginId && errors.loginId}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Password"
                InputProps={{
                  'data-testid': 'editModemForm',
                }}
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Serial Number"
                InputProps={{
                  'data-testid': 'editModemForm',
                }}
                {...getFieldProps('serialNumber')}
                error={Boolean(touched.serialNumber && errors.serialNumber)}
                helperText={touched.serialNumber && errors.serialNumber}
              />
            </GridItem>
            <GridItem xs={12} md={12}>
              <Datepicker
                label="Installed Date"
                value={values.installedDate ? new Date(values.installedDate) : null}
                data-testid="editModemForm"
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => {
                  setFieldValue('installedDate', newValue);
                }}
                onBlur={() => formik.setFieldTouched('installedDate')}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="space-around" pl={4}>
            <GridItem xs={12} md={12}>
              <Stack direction={'row'} className={classes.btn}>
                <Stack direction={'row'}>
                  <Button type="Submit" variant="contained">
                    <Typography variant="button">UPDATE</Typography>
                  </Button>
                  <Button onClick={onCancelHandler}>
                    <Typography variant="button">CANCEL</Typography>
                  </Button>
                </Stack>
              </Stack>
            </GridItem>
          </GridContainer>
        </form>
      </Box>
    </Box>
  );
};

export default EditModem;
