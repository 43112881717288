import { useEffect } from 'react';

const useRefreshAndTabClosePrompt = (when = true, fn = () => {}) => {
  useEffect(() => {
    if (!when) {
      return;
    }
    const unloadCallback = (event) => {
      event.preventDefault();
      fn();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback, false);

    return () => {
      window.removeEventListener('beforeunload', unloadCallback, false);
    };
  }, [when, fn]);
};

export default useRefreshAndTabClosePrompt;
