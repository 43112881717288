import PropTypes from 'prop-types';

const displayName = 'Yes-No Indicator';
const propTypes = {
  value: PropTypes.bool,
};

const YesNoIndicator = ({ value }) => (value === true || value === false ? (value === true ? 'YES' : 'NO') : null);

YesNoIndicator.displayName = displayName;
YesNoIndicator.propTypes = propTypes;
export default YesNoIndicator;
