import { InformationPad } from '../../../components/shared';
import { formatPhNo10Digit } from '../../../utils/common-methods';

const LocationOwnerDetailView = ({ info }) => {
  const fields = [
    { label: 'Business Name', value: info?.name },
    { label: 'Type', value: info?.type?.name },
    { label: 'Address1', value: info?.address1 },
    { label: 'Address2', value: info?.address2 },
    { label: 'Country', value: info?.country?.name },
    { label: 'State', value: info?.state?.name },
    { label: 'City', value: info?.city?.name },
    { label: 'Postal Code', value: info?.postcode },
    { label: 'Phone1', value: formatPhNo10Digit(info?.phone1) },
    { label: 'Phone2', value: formatPhNo10Digit(info?.phone2) },
    { label: 'Email', value: info?.email },
  ];

  return <InformationPad data={fields} />;
};

export default LocationOwnerDetailView;
