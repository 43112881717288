import { useSelector } from 'react-redux';
import { isArrayWithLength } from '../utils/common-methods';

const useHasRoles = (permittedRoles) => {
  const ui = useSelector((state) => state.ui);
  const { userRoles } = ui;

  return Boolean(
    isArrayWithLength(userRoles.map((value) => value.title).filter((value) => permittedRoles.includes(value))),
  );
};

export default useHasRoles;
