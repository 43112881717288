export const ERROR_TITLE = {
  ACCOUNT_DEACTIVATED: 'Account Deactivated',
};

export const ERROR_MESSAGE = {
  // 4xx status error messages
  FORBIDDEN:
    'Unauthorized request. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.',
  ACCOUNT_DEACTIVATED: 'Your account is deactivated, please contact administrator.',
};
