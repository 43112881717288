import { GridContainer, GridItem, InformationField, InformationPad } from '../../../components/shared';
import { Box, Paper } from '@mui/material';
import { Link } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from './style';
const ModemAccordion = ({ modemInfo }) => {
  const classes = useStyles();
  if (modemInfo) {
    modemInfo = { ...modemInfo, price: modemInfo?.price || 0 };
  }
  const fields = [
    { label: 'Price', value: `$ ${modemInfo?.price}` },
    { label: 'Login ID', value: modemInfo?.loginId },
  ];
  return (
    <Box component={Paper} className={classes.modemAccordion}>
      <InformationPad data={fields} />
      <GridContainer className={classes.modemAccordionBtnWrap}>
        <GridItem xs={12} md={12}>
          <InformationField
            fontSize="14px"
            fontWeight="500"
            lineHeight="16px"
            value={
              <Link underline="none" color="primary" to={`/${routeConstants.MODEMS_ROUTE}/${modemInfo.id}`}>
                VIEW FULL DETAILS
              </Link>
            }
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
};

export default ModemAccordion;
