import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getModels: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.model}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${page || DEFAULT_PAGE}&${searchParams}`;
        }
        return `${apiUrl.model}`;
      },
      providesTags: ['Models'],
    }),
    addModel: build.mutation({
      query: (data) => ({
        url: `${apiUrl.model}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Models'],
    }),
    getModelCategory: build.query({
      query: () => `${apiUrl.modelCategory}`,
    }),
    getModelTypes: build.query({
      query: () => `${apiUrl.modelType}`,
    }),
    getGameCategories: build.query({
      query: () => `${apiUrl.gameCategories}`,
    }),
    getModelTheme: build.query({
      query: () => `${apiUrl.modelTheme}`,
    }),
    getModelManufacturer: build.query({
      query: () => `${apiUrl.modelManufacturer}`,
    }),
    getModelDetailsById: build.query({
      query: (id) => `${apiUrl.model}/${id}`,
      providesTags: ['Model'],
    }),
    updateModelById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.model}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Model', 'Models'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetModelsQuery,
  useLazyGetModelsQuery,
  useGetModelCategoryQuery,
  useGetModelTypesQuery,
  useGetGameCategoriesQuery,
  useLazyGetModelCategoryQuery,
  useGetModelThemeQuery,
  useLazyGetModelThemeQuery,
  useGetModelManufacturerQuery,
  useLazyGetModelManufacturerQuery,
  useGetModelDetailsByIdQuery,
  useLazyGetModelDetailsByIdQuery,
  useUpdateModelByIdMutation,
  useAddModelMutation,
} = extendedApi;
