import { METER_TYPE, FIELD_NAME } from '../constants/MeterTypeFieldMap';
import {
  calculateDifferenceBetweenTwoFields,
  checkNumberIsNaNAndInfinity,
  isValueValid,
  roundOffValue,
} from './common-methods';
import { MESSAGE } from '../constants/message';
import { RECONCILIATION_WARNING_COMPARING_VALUE } from '../constants/CommonConstants';
import {
  getDispensedValue,
  getExpectedCashRevenue,
  getRevenueInLocalCurrency,
  getRevenueInUSD,
  getTokenChangerRevenueAndDispensedValue,
  getTokenRevenue,
} from './collection-methods';

/**
 * Get Approved calculated field value
 *
 * @param {object} Object: {meterMapElement, assetMeter, clicksPerPlay, costPerPlay, meterTypeName, averageTokenValue}
 * @returns {number} fieldValue
 */
export const getApprovedCalculatedFieldValue = (props) => {
  const { meterMapElement, assetMeter, clicksPerPlay, costPerPlay, meterTypeName, averageTokenValue, exchangeRate } =
    props;
  const meterMapElementName = meterMapElement?.name;
  let fieldValue = assetMeter[meterMapElementName] ?? 0;
  const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
  const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
  const dispensedValue = getDispensedValue(Number(currentReading), Number(priorReading));
  switch (meterMapElementName) {
    case FIELD_NAME.TICKET_DISPENSED:
      fieldValue = dispensedValue;
      break;
    case FIELD_NAME.PRIZE_DISPENSED:
      fieldValue = dispensedValue;
      break;
    case FIELD_NAME.MEDALLION_DISPENSED:
      fieldValue = dispensedValue;
      break;
    case FIELD_NAME.ESTIMATED_COIN_REVENUE: {
      const calculated = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
      const calculatedInUSD = getRevenueInUSD(calculated, exchangeRate);
      fieldValue = assetMeter[FIELD_NAME.ESTIMATED_COIN_REVENUE] ?? calculatedInUSD;
      break;
    }
    case FIELD_NAME.TOKEN_DISPENSED:
      if (meterTypeName === METER_TYPE.TOKEN_CHANGER_RECONCILIATION) {
        const { tokenDispensed } = getTokenChangerRevenueAndDispensedValue(currentReading, priorReading, assetMeter);
        fieldValue = tokenDispensed;
      }
      break;
    case FIELD_NAME.TOKEN_REVENUE:
      if (meterTypeName === METER_TYPE.TOKEN_RECONCILIATION) {
        const tokenRevenue = assetMeter[FIELD_NAME.TOKEN_REVENUE];
        fieldValue = roundOffValue(getRevenueInLocalCurrency(tokenRevenue, exchangeRate));
      }
      break;
    case FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER:
      if (meterTypeName === METER_TYPE.TOKEN_CHANGER_RECONCILIATION) {
        const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(currentReading, priorReading, assetMeter);
        fieldValue = revenueCollected;
      }
      break;
    case FIELD_NAME.REVENUE_IN_USD: {
      if (meterTypeName === METER_TYPE.TOKEN_RECONCILIATION) {
        fieldValue = getTokenRevenue(averageTokenValue, assetMeter[FIELD_NAME.TOKEN_COLLECTED]);
        break;
      }
      if (meterTypeName === METER_TYPE.TOKEN_CHANGER_RECONCILIATION) {
        const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(currentReading, priorReading, assetMeter);
        const revenueInUSD = getRevenueInUSD(revenueCollected, exchangeRate);
        fieldValue = revenueInUSD;
        break;
      }
      const value = getRevenueValue(meterTypeName, assetMeter);
      fieldValue = getRevenueInUSD(value, exchangeRate);
      break;
    }
  }
  return fieldValue;
};

/**
 * Get revenue value for special meter types
 *
 * @param {String} meterTypeName
 * @param {Object} assetMeter
 * @returns
 */
export const getRevenueValue = (meterTypeName, assetMeter) => {
  let value = 0;
  switch (meterTypeName) {
    case METER_TYPE.CANDY:
      value = Number(assetMeter[FIELD_NAME.COINS_COLLECTED]);
      break;
    case METER_TYPE.BILL:
      value =
        Number(assetMeter[FIELD_NAME.BILLS_TAKEN_FROM_CHANGER]) + Number(assetMeter[FIELD_NAME.COINS_ADDED_TO_CHANGER]);
      break;
    case METER_TYPE.CASH_RECONCILIATION:
      value =
        Number(assetMeter[FIELD_NAME.BILLS_COLLECTED]) +
        Number(assetMeter[FIELD_NAME.COINS_COLLECTED]) +
        Number(assetMeter[FIELD_NAME.METER_ADJUST]);
      break;
    case METER_TYPE.COIN_RECONCILIATION:
      value = Number(assetMeter[FIELD_NAME.COINS_COLLECTED]);
      break;
    // no default really required here
  }
  return value;
};

/**
 * Get error and message for approved collections
 *
 * @param {Object} props {meterMapElement, assetMeter, clicksPerPlay, costPerPlay, meterTypeName, exchangeRate}
 * @returns {Object} error and message
 */
export const getApprovedErrorAndMessage = (props) => {
  const { meterMapElement, assetMeter, clicksPerPlay, costPerPlay, meterTypeName, exchangeRate } = props;
  const meterMapElementName = meterMapElement?.name;
  let message;
  let error = false;
  const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
  const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];

  switch (meterMapElementName) {
    case FIELD_NAME.CURRENT_READING: {
      error = Number(currentReading) < Number(priorReading);
      if (error) {
        message = MESSAGE.CURRENT_METER_ERROR;
      }
      break;
    }
    case FIELD_NAME.EXPECTED_REVENUE: {
      if (meterTypeName === METER_TYPE.CREDIT) {
        const estCCRevenue = assetMeter[FIELD_NAME.EXPECTED_REVENUE];
        const ccRevenue = assetMeter[FIELD_NAME.SEEDLIVE_REVENUE];
        const difference = calculateDifferenceBetweenTwoFields(ccRevenue, estCCRevenue);
        error = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
        if (error) {
          message = MESSAGE.CREDIT_METER_ESTIMATED_REVENUE_ERROR;
        }
      }
      break;
    }
    case FIELD_NAME.SEEDLIVE_REVENUE: {
      if (meterTypeName === METER_TYPE.CREDIT_RECONCILIATION) {
        const ccRevenue = assetMeter[FIELD_NAME.SEEDLIVE_REVENUE];
        error = !isValueValid(ccRevenue) || checkNumberIsNaNAndInfinity(ccRevenue) === 0;
        if (error) {
          message = MESSAGE.SEEDLIVE_VALUE_ERROR;
        }
      }
      break;
    }
    case FIELD_NAME.BILLS_COLLECTED: {
      if (meterTypeName === METER_TYPE.CASH_RECONCILIATION) {
        const { cashReconciliationError, cashReconciliationMsg } = getCashReconciliationApprovedError(
          assetMeter,
          exchangeRate,
        );
        message = cashReconciliationMsg;
        error = cashReconciliationError;
      }
      break;
    }
    case FIELD_NAME.COINS_COLLECTED: {
      if (meterTypeName === METER_TYPE.CASH_RECONCILIATION) {
        const { cashReconciliationError, cashReconciliationMsg } = getCashReconciliationApprovedError(
          assetMeter,
          exchangeRate,
        );
        message = cashReconciliationMsg;
        error = cashReconciliationError;
      }
      if (meterTypeName === METER_TYPE.COIN_RECONCILIATION) {
        let coinsCollected = assetMeter[FIELD_NAME.COINS_COLLECTED];
        const calculated = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
        const estimatedRevenue =
          assetMeter[FIELD_NAME.ESTIMATED_COIN_REVENUE] ?? getRevenueInUSD(calculated, exchangeRate);
        coinsCollected = getRevenueInUSD(coinsCollected, exchangeRate);
        const difference = calculateDifferenceBetweenTwoFields(estimatedRevenue, coinsCollected);
        error = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
        if (error) {
          message = MESSAGE.COIN_ESTIMATED_COIN_REVENUE_ERROR;
        }
      }
      break;
    }
    case FIELD_NAME.TOKEN_COLLECTED: {
      if (meterTypeName === METER_TYPE.TOKEN_RECONCILIATION) {
        const tokenCollected = assetMeter[FIELD_NAME.TOKEN_COLLECTED];
        const difference = calculateDifferenceBetweenTwoFields(currentReading, priorReading);
        error = difference !== checkNumberIsNaNAndInfinity(tokenCollected);
        if (error) {
          message = MESSAGE.TOKEN_COLLECTED_ERROR;
        }
      }
    }
  }
  return { message, error };
};

/**
 * Get error and message for cash reconciliation
 *
 * @param {Object} assetMeter
 * @param {Number} exchangeRate
 * @returns
 */
export const getCashReconciliationApprovedError = (assetMeter, exchangeRate) => {
  let message;
  const billsCollected = assetMeter[FIELD_NAME.BILLS_COLLECTED];
  const coinsCollected = assetMeter[FIELD_NAME.COINS_COLLECTED];
  const expectedCashRevenue = assetMeter[FIELD_NAME.EXPECTED_REVENUE];
  const sum = getRevenueInUSD(
    checkNumberIsNaNAndInfinity(billsCollected) + checkNumberIsNaNAndInfinity(coinsCollected),
    exchangeRate,
  );

  const difference = calculateDifferenceBetweenTwoFields(sum, expectedCashRevenue);
  const error = difference > RECONCILIATION_WARNING_COMPARING_VALUE;
  if (error) {
    message = MESSAGE.CASH_METER_EXPECTED_REVENUE_ERROR;
  }
  return { cashReconciliationError: error, cashReconciliationMsg: message };
};
