import { GridContainer, GridItem, InformationField } from '../index';
import { useTheme } from '@mui/material';

const InformationPad = ({
  data = [],
  headingColumn = { xs: 12, sm: 4, lg: 2 },
  titleColumn = { xs: 12, sm: 8, lg: 10 },
}) => {
  const theme = useTheme();
  if (data.length === 0) return;

  return data?.map((el, index) => (
    <GridContainer data-testid="info-pad" key={index} spacing={2} px={3}>
      <GridItem xs={headingColumn?.xs} sm={headingColumn?.sm} lg={headingColumn?.lg}>
        <InformationField value={el.label} variant="h4" color={theme.palette.secondary.main} />
      </GridItem>
      <GridItem xs={titleColumn?.xs} sm={titleColumn?.sm} lg={titleColumn?.lg}>
        <InformationField value={el.value} variant="subtitle1" />
      </GridItem>
    </GridContainer>
  ));
};

export default InformationPad;
