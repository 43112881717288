export const locationIcon = (
  <svg width="24" height="24" className="svgIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 11.5C14.3283 11.5 14.6534 11.4353 14.9567 11.3097C15.26 11.1841 15.5356 10.9999 15.7678 10.7678C15.9999 10.5356 16.1841 10.26 16.3097 9.95671C16.4353 9.65339 16.5 9.3283 16.5 9C16.5 8.33696 16.2366 7.70107 15.7678 7.23223C15.2989 6.76339 14.663 6.5 14 6.5C13.337 6.5 12.7011 6.76339 12.2322 7.23223C11.7634 7.70107 11.5 8.33696 11.5 9C11.5 9.3283 11.5647 9.65339 11.6903 9.95671C11.8159 10.26 12.0001 10.5356 12.2322 10.7678C12.7011 11.2366 13.337 11.5 14 11.5ZM14 2C17.86 2 21 5.13 21 9C21 14.25 14 22 14 22C14 22 7 14.25 7 9C7 7.14348 7.7375 5.36301 9.05025 4.05025C10.363 2.7375 12.1435 2 14 2ZM5 9C5 13.5 10.08 19.66 11 20.81L10 22C10 22 3 14.25 3 9C3 5.83 5.11 3.15 8 2.29C6.16 3.94 5 6.33 5 9Z" />
  </svg>
);

export const modelIcon = (
  <svg width="24" height="24" className="svgIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.01" d="M0 0H24V24H0V0Z" />
    <path d="M19.9303 3.00091C19.4171 3.02194 18.9308 3.23294 18.5675 3.59187C18.2043 3.9508 17.991 4.43139 17.9695 4.93853C17.9638 5.36294 18.0933 5.77836 18.3394 6.12618C18.5858 6.47385 18.9365 6.73621 19.342 6.87619V10.0627H17.0327V5.67063C17.0327 5.21377 16.8491 4.77571 16.5222 4.45265C16.1953 4.12976 15.752 3.94819 15.2897 3.94819H3.74294H3.74309C3.28079 3.94819 2.83752 4.12976 2.5106 4.45265C2.18372 4.77569 2.00014 5.21377 2.00014 5.67063V19.2776C1.99421 19.7361 2.17597 20.1776 2.50407 20.5019C2.83217 20.8263 3.27896 21.0057 3.74309 20.9999H15.2899H15.2897C15.752 20.9999 16.1953 20.8184 16.5222 20.4954C16.8491 20.1725 17.0327 19.7343 17.0327 19.2776V13.4214H20.6493V6.87631C21.0455 6.73799 21.3881 6.4812 21.6302 6.14179C21.8722 5.80238 22.0015 5.39705 22 4.98179C22.0003 4.44504 21.7802 3.93112 21.39 3.55777C20.9999 3.18442 20.4731 2.98346 19.9303 3.00107L19.9303 3.00091ZM8.12202 8.94319H10.8889V14.2826H8.12202V8.94319ZM3.9826 8.94319H6.74936V14.2826H3.9826V8.94319ZM15.0283 18.8254H3.9826V15.919H15.0283V18.8254ZM15.0283 14.2826H12.2614V8.94319H15.0283V14.2826Z" />
  </svg>
);

export const machineIcon = (
  <svg width="24" height="24" className="svgIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.01" d="M0 0H24V24H0V0Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6C24 4.89714 23.1029 4 22 4C20.8971 4 20 4.89714 20 6C20 6.87314 20.5632 7.61514 21.3449 7.88747C21.3383 7.9243 21.3333 7.96132 21.3333 8.00001V16H20V8.66668C20 8.29837 19.7016 8.00001 19.3333 8.00001H16.6667V7.33334C16.6667 6.96504 16.3683 6.66668 16 6.66668H13.7719C13.2152 5.09339 11.7078 4.00001 10 4.00001C8.29223 4.00001 6.78476 5.09339 6.22805 6.66668H4C3.63169 6.66668 3.33333 6.96504 3.33333 7.33334V8.00001H0.666666C0.298362 8.00001 0 8.29837 0 8.66668V19.3333C0 19.7016 0.298362 20 0.666666 20H19.3333C19.7016 20 20 19.7016 20 19.3333V17.3333H22C22.3683 17.3333 22.6667 17.035 22.6667 16.6667V8.00001C22.6667 7.96132 22.6616 7.92431 22.6551 7.88747C23.4367 7.61515 24 6.87314 24 6H24ZM22 7C21.4487 7 21 6.55134 21 6C21 5.44866 21.4487 5 22 5C22.5513 5 23 5.44866 23 6C23 6.55134 22.5513 7 22 7ZM13.9997 11H16.6663C17.0346 11 17.333 11.2984 17.333 11.6667V15.6667C17.333 16.035 17.0346 16.3333 16.6663 16.3333H13.9997C13.6314 16.3333 13.333 16.035 13.333 15.6667V11.6667C13.333 11.2984 13.6314 11 13.9997 11ZM11.3333 11H8.66667C8.29836 11 8 11.2984 8 11.6667V15.6667C8 16.035 8.29836 16.3333 8.66667 16.3333H11.3333C11.7016 16.3333 12 16.035 12 15.6667V11.6667C12 11.2984 11.7016 11 11.3333 11ZM3.33366 11H6.00032C6.36863 11 6.66699 11.2984 6.66699 11.6667V15.6667C6.66699 16.035 6.36863 16.3333 6.00032 16.3333H3.33366C2.96535 16.3333 2.66699 16.035 2.66699 15.6667V11.6667C2.66699 11.2984 2.96535 11 3.33366 11Z"
    />
  </svg>
);

export const modemIcon = (
  <svg width="24" height="24" className="svgIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.84987 19.5389C2.35657 19.5389 1.92493 19.3539 1.55496 18.9839C1.18499 18.6139 1 18.1823 1 17.689V11.307C1 10.8137 1.18499 10.382 1.55496 10.0121C1.92493 9.64209 2.35657 9.4571 2.84987 9.4571H17.0938V4H18.9437V9.4571H22.1501C22.6434 9.4571 23.0751 9.64209 23.445 10.0121C23.815 10.382 24 10.8137 24 11.307V17.689C24 18.1823 23.815 18.6139 23.445 18.9839C23.0751 19.3539 22.6434 19.5389 22.1501 19.5389H2.84987ZM22.1501 17.689V11.307H2.84987V17.689H22.1501ZM7.10456 14.5134C7.10456 14.1434 6.98123 13.8351 6.73458 13.5885C6.48794 13.3418 6.17962 13.2185 5.80965 13.2185C5.43968 13.2185 5.13137 13.3418 4.88472 13.5885C4.63807 13.8351 4.51475 14.1434 4.51475 14.5134C4.51475 14.8834 4.63807 15.1917 4.88472 15.4383C5.13137 15.685 5.43968 15.8083 5.80965 15.8083C6.17962 15.8083 6.48794 15.685 6.73458 15.4383C6.98123 15.1917 7.10456 14.8834 7.10456 14.5134ZM11.6676 14.5134C11.6676 14.1434 11.5442 13.8351 11.2976 13.5885C11.0509 13.3418 10.7426 13.2185 10.3727 13.2185C10.0027 13.2185 9.69437 13.3418 9.44772 13.5885C9.20107 13.8351 9.07775 14.1434 9.07775 14.5134C9.07775 14.8834 9.20107 15.1917 9.44772 15.4383C9.69437 15.685 10.0027 15.8083 10.3727 15.8083C10.7426 15.8083 11.0509 15.685 11.2976 15.4383C11.5442 15.1917 11.6676 14.8834 11.6676 14.5134ZM14.9357 15.8083C15.3056 15.8083 15.6139 15.685 15.8606 15.4383C16.1072 15.1917 16.2306 14.8834 16.2306 14.5134C16.2306 14.1434 16.1072 13.8351 15.8606 13.5885C15.6139 13.3418 15.3056 13.2185 14.9357 13.2185C14.5657 13.2185 14.2574 13.3418 14.0107 13.5885C13.7641 13.8351 13.6408 14.1434 13.6408 14.5134C13.6408 14.8834 13.7641 15.1917 14.0107 15.4383C14.2574 15.685 14.5657 15.8083 14.9357 15.8083Z" />
  </svg>
);

export const leaseIcon = (
  <svg width="24" height="24" className="svgIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.7 5.33333V20.3333H18.3V22H4.7C3.765 22 3 21.25 3 20.3333V5.33333H4.7ZM14.05 7.83333H18.725L14.05 3.25V7.83333ZM8.1 2H14.9L20 7V17C20 17.925 19.2435 18.6667 18.3 18.6667H8.1C7.1565 18.6667 6.4 17.9167 6.4 17V3.66667C6.4 2.74167 7.1565 2 8.1 2ZM15.75 15.3333V13.6667H8.1V15.3333H15.75ZM18.3 12V10.3333H8.1V12H18.3Z" />
  </svg>
);

export const sortIcon = (
  <svg width="24" height="24" className="svgIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 21L14 17H17V7H14L18 3L22 7H19V17H22M2 19V17H12V19M2 13V11H9V13M2 7V5H6V7H2Z" />
  </svg>
);

export const mapMarkerIcon = (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.0001 10.8307C37.8667 10.8307 42.6667 15.7057 42.6667 21.6641C42.6667 27.3516 37.0667 36.5599 32.0001 43.0599C26.9334 36.2891 21.3334 27.3516 21.3334 21.6641C21.3334 15.7057 26.1334 10.8307 32.0001 10.8307ZM32.0001 5.41406C23.2001 5.41406 16.0001 12.7266 16.0001 21.6641C16.0001 33.8516 32.0001 51.4557 32.0001 51.4557C32.0001 51.4557 48.0001 33.5807 48.0001 21.6641C48.0001 12.7266 40.8001 5.41406 32.0001 5.41406ZM32.0001 16.2474C29.0667 16.2474 26.6667 18.6849 26.6667 21.6641C26.6667 24.6432 29.0667 27.0807 32.0001 27.0807C34.9334 27.0807 37.3334 24.6432 37.3334 21.6641C37.3334 18.6849 34.9334 16.2474 32.0001 16.2474ZM53.3334 51.4557C53.3334 57.4141 43.7334 62.2891 32.0001 62.2891C20.2667 62.2891 10.6667 57.4141 10.6667 51.4557C10.6667 47.9349 13.8667 44.9557 18.9334 42.7891L20.5334 45.2266C17.8667 46.5807 16.0001 48.2057 16.0001 50.1016C16.0001 53.8932 23.2001 56.8724 32.0001 56.8724C40.8001 56.8724 48.0001 53.8932 48.0001 50.1016C48.0001 48.2057 46.1334 46.5807 43.2001 45.2266L44.8001 42.7891C50.1334 44.9557 53.3334 47.9349 53.3334 51.4557Z"
      fill="#2CA2DF"
    />
  </svg>
);
