import React from 'react';
import { TextField as MUITextField } from '@mui/material';

const TextField = (props) => {
  const { restrictNegativeValue = false, ...others } = props;
  const skipKeys = ['e', 'E', '+'];
  if (restrictNegativeValue) {
    skipKeys.push('-');
  }
  return (
    <MUITextField
      {...others}
      onKeyDown={(e) => {
        if (e.target.type === 'number' && skipKeys.includes(e.key)) {
          e.preventDefault();
        }
      }}
    />
  );
};

export default TextField;
