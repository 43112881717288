import React, { useMemo, useEffect, useState } from 'react';
import { routeConstants } from '../../../constants/routeConstants';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { Breadcrumbs } from '../../../components/shared';
import LocationUpdateDetailView from '../LocationDetails/LocationUpdateDetailView';
import { Box, Stack, Typography, Chip, Container } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useGetLocationDetailsByIdQuery } from '../../../services/Location/LocationService';
import useStyles from '../Locations/style';
import LoadingSpinner from '../../../components/LoadingSpinner';

const EditLocation = () => {
  const { id } = useParams();
  const classes = useStyles();
  const {
    LOCATION: { LOCATION, LOCATION_DETAIL, EDIT_LOCATION_DETAIL },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [
      { text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` },
      {
        text: LOCATION_DETAIL,
        redirection: `${routeConstants.LOCATION_ROUTE}/${Number(id)}`,
      },
      { text: EDIT_LOCATION_DETAIL },
    ],
    [id],
  );

  const {
    isSuccess,
    currentData: currentLocationDetails,
    isLoading,
    isFetching,
  } = useGetLocationDetailsByIdQuery({ id: Number(id) });
  const [locationDetails, setLocationDetails] = useState(null);

  useEffect(() => {
    if (currentLocationDetails) {
      setLocationDetails(currentLocationDetails.data);
    }
  }, [currentLocationDetails, isSuccess]);

  if (isLoading || isFetching) {
    return <LoadingSpinner containerHeight={400} />;
  }

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>
      {isSuccess && locationDetails && (
        <React.Fragment>
          <Box mb={3}>
            <Stack direction={'row'} spacing={2} alignItems="center" pb={1}>
              <Typography variant="h1" data-testid="locationName">
                {locationDetails?.locationName}
              </Typography>
              <Chip
                label={locationDetails?.status ? 'Active' : 'Inactive'}
                color={locationDetails?.status ? 'success' : 'error'}
                variant="outlined"
                className={classes.locationDetailsState}
              />
            </Stack>
          </Box>
          <Box mb={3}>
            <LocationUpdateDetailView locationDetails={locationDetails} />
          </Box>
        </React.Fragment>
      )}
    </Container>
  );
};

export default EditLocation;
