import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  signInButton: {
    color: alpha(theme.palette.common.black, 0.57),
    borderColor: alpha(theme.palette.common.black, 0.38),
    textTransform: 'none',
    letterSpacing: 'unset',
    minWidth: '345px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '264px',
    },
    '&:hover': {
      borderColor: alpha(theme.palette.common.black, 0.38),
    },
    '&.MuiButton-outlinedSizeMedium': {
      padding: '14px 24px',
      borderRadius: 10,
      [theme.breakpoints.down('sm')]: {
        padding: '9px 24px',
        borderRadius: '8px',
      },
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
}));
export default useStyles;
