import { Radio, RadioGroup as MUIRadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import useStyles from './style';

const RadioGroup = (props) => {
  const {
    id,
    formLabel,
    labelPlacement = 'start',
    radioGrpClassName,
    data,
    disabled = false,
    color = 'primary',
    size = 'medium',
    icon = <RadioButtonUnchecked />,
    checkedIcon = <RadioButtonChecked />,
    fullWidth,
    ...others
  } = props;
  const classes = useStyles();

  return (
    <>
      <FormLabel id={id}>{formLabel}</FormLabel>
      <MUIRadioGroup aria-labelledby={id} {...others} className={radioGrpClassName}>
        {data &&
          data?.map(({ label, value, name }, index) => (
            <FormControlLabel
              className={fullWidth ? classes.fullWidth : ''}
              key={`${label}-${name && name}-${index}`}
              name={name}
              value={value}
              control={<Radio size={size} color={color} disabled={disabled} icon={icon} checkedIcon={checkedIcon} />}
              label={label}
              labelPlacement={labelPlacement}
            />
          ))}
      </MUIRadioGroup>
    </>
  );
};

export default RadioGroup;
