import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formContent: {
    padding: '8px 8px 8px 8px',
    '& .MuiInputBase-root': { borderRadius: '4px' },
    backgroundColor: theme?.palette?.common?.white,
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
    '& .MuiGrid2-root': { padding: 8 },
    '& .MuiPickersPopper-root': {
      inset: '0px -50px auto auto',
    },
  },
}));

export default useStyles;
