export const ACCOUNT_FIELDS = {
  ACCOUNT_NUMBER: 'accountNumber',
  NAME: 'name',
  DESCRIPTION: 'description',
  DEBIT: 'debit',
  CREDIT: 'credit',
  DISABLED_FIELDS: 'disabledFields',
};

export const ACCOUNT_TYPES = {
  GROSS_SALES: 'GROSS_SALES',
  DEFAULT_GROSS_SALES: 'GROSS SALES',
  CREDIT_CARD: 'CREDIT_CARD',
  DEFAULT_CREDIT_CARD: 'CREDIT CARD',
  CASH_DEPOSIT: 'CASH_DEPOSIT',
  SALES_TAX: 'SALES TAX',
  REFUNDS: 'REFUNDS',
  VARIABLE_RENT: 'RENT VARIABLE',
  ACCOUNT_RECEIVABLE: 'A/R',
  ELAUT_ACCOUNT: 'A/R FOR ELAUT',
  NAMCO_ACCOUNT: 'CREDIT CARD FOR NAMCO',
};

// If adding more ACCOUNT_TYPES, we need to update this array as well for calculation
export const NOT_AR_TYPES = [
  'GROSS SALES',
  'CREDIT CARD',
  'CASH DEPOSIT',
  'SALES TAX',
  'REFUNDS',
  'RENT VARIABLE',
  'RENT',
];

export const INITIAL_ACCOUNT_SUMMARY_STATE = [
  {
    [ACCOUNT_FIELDS.ACCOUNT_NUMBER]: null,
    [ACCOUNT_FIELDS.NAME]: '',
    [ACCOUNT_FIELDS.DESCRIPTION]: '',
    [ACCOUNT_FIELDS.DEBIT]: 0,
    [ACCOUNT_FIELDS.CREDIT]: 0,
  },
  {
    [ACCOUNT_FIELDS.ACCOUNT_NUMBER]: null,
    [ACCOUNT_FIELDS.NAME]: '',
    [ACCOUNT_FIELDS.DESCRIPTION]: '',
    [ACCOUNT_FIELDS.DEBIT]: 0,
    [ACCOUNT_FIELDS.CREDIT]: 0,
  },
  {
    [ACCOUNT_FIELDS.ACCOUNT_NUMBER]: null,
    [ACCOUNT_FIELDS.NAME]: '',
    [ACCOUNT_FIELDS.DESCRIPTION]: '',
    [ACCOUNT_FIELDS.DEBIT]: 0,
    [ACCOUNT_FIELDS.CREDIT]: 0,
  },
];
