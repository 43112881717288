import { Box, Button, Paper, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import SearchBar from '../../../components/SearchBar';
import AssignNewMachineTable from './AssignMachineTable';
import useStyles from './style';
import { useUpdateSubLocationMachineByIdMutation } from '../../../services/Sublocation/SublocationService';
import { isArrayWithLength } from '../../../utils/common-methods';
import { useUpdateMachineByIdMutation } from '../../../services/Machines/MachinesService';

const AssignNewMachine = ({ sublocationId, openAssignNew, machineList = [] }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [updateSublocationMachineApi] = useUpdateSubLocationMachineByIdMutation();
  const [updateMachineApi] = useUpdateMachineByIdMutation();
  const [preSelectedRows, setPreSelectedRows] = useState({});

  const searchHandler = (params) => {
    setSearchText(params);
  };

  const onReset = () => {
    setSearchText('');
  };

  useEffect(() => {
    if (isArrayWithLength(machineList)) {
      let preSelectedRow = {};
      machineList?.forEach((machine) => {
        preSelectedRow = { ...preSelectedRow, [machine?.id]: true };
      });
      setRows({ ...preSelectedRow });
      setPreSelectedRows({ ...preSelectedRow });
    }
  }, []);

  const onSelect = (row, checked) => {
    setRows((prev) => ({ ...prev, [row.id]: checked }));
  };

  const onSaveHandler = () => {
    let apiData = [];
    let removedMachinesData = [];
    for (const key in rows) {
      const updateMachineRemoved = Boolean(preSelectedRows[key] && !rows[key]);
      if (updateMachineRemoved) {
        removedMachinesData.push(key);
      }
      if (rows[key] && !updateMachineRemoved && !preSelectedRows[key]) {
        apiData.push(key);
      }
    }
    if (removedMachinesData?.length !== 0) {
      removedMachinesData?.map((key) => {
        updateMachineApi({
          id: key,
          subLocationId: null,
        });
      });
    }
    if (apiData?.length !== 0) {
      updateSublocationMachineApi({
        id: sublocationId,
        assetIds: apiData,
      }).then((response) => {
        if (response.data) {
          openAssignNew(false);
        }
      });
    }
    openAssignNew(false);
  };

  const onCancelHandler = () => {
    openAssignNew(false);
  };

  const isSaveDisabled = useMemo(() => {
    let currentRowSelection = { ...rows };
    let trueValuesOfRowSelection = {};
    Object.keys(currentRowSelection)?.map((item) => {
      if (currentRowSelection[item] || (preSelectedRows[item] && !currentRowSelection[item])) {
        trueValuesOfRowSelection = { ...trueValuesOfRowSelection, [item]: currentRowSelection[item] };
      }
    });
    return Boolean(JSON.stringify(trueValuesOfRowSelection) === JSON.stringify(preSelectedRows));
  }, [rows]);

  return (
    <Box component={Paper} className={classes.assignNewMachineBox}>
      <Box className={classes.assignNewMachineTitle}>
        <Typography variant="h2">Assign New Machine</Typography>
      </Box>
      <Box className={classes.machineSearch}>
        <SearchBar onSearch={searchHandler} onReset={onReset} placeholder="Search machines" />
      </Box>
      <AssignNewMachineTable
        sublocationId={sublocationId}
        selectedRows={rows}
        searchParams={searchText}
        onSelect={onSelect}
      />
      <Box className={classes.btnWrap}>
        <Button variant="contained" onClick={onSaveHandler} disabled={isSaveDisabled}>
          SAVE
        </Button>
        <Button onClick={onCancelHandler}>CANCEL</Button>
      </Box>
    </Box>
  );
};

export default AssignNewMachine;
