import { useMemo } from 'react';
import { FILTER_VALUES } from '../constants/FilterConstants';
import { isArrayWithLength } from '../utils/common-methods';

const useSearchFilterParam = (props) => {
  const { filterKey, filterParams = {}, sort = '', searchText = '', extraParam = '' } = props;
  const { FILTER } = filterKey;
  let filterParamString = '';
  const searchFilterParam = useMemo(() => {
    Object.keys(FILTER).map((key) => {
      const paramKey = FILTER[key].value;
      if (paramKey === FILTER_VALUES.DATE_RANGE) {
        const fromDate = filterParams[paramKey].from;
        const toDate = filterParams[paramKey].to;
        const { rangeKey } = FILTER[key];
        filterParamString =
          filterParamString +
          `${fromDate ? `${rangeKey}DateFrom=${fromDate}&` : ''}${toDate ? `${rangeKey}DateTo=${toDate}&` : ''}`;
      } else {
        const value = isArrayWithLength(filterParams?.[paramKey])
          ? filterParams?.[paramKey]?.map((item) => item?.id) || ''
          : filterParams?.[paramKey]?.value || '';

        filterParamString = filterParamString + `${paramKey}=${value}&`;
      }
    });
    filterParamString = filterParamString.length > 0 ? filterParamString.slice(0, -1) : '';
    const filterArr = [
      filterParamString,
      `${searchText ? `q=${searchText}` : ''}`,
      `${sort ? `sort=${sort}` : ''}`,
      extraParam,
    ].filter((value) => value);

    const newSearchParams = filterArr.join('&');
    return newSearchParams;
  }, [filterParams, searchText, sort]);

  return searchFilterParam;
};

export default useSearchFilterParam;
