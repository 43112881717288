import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getCurrencyExchange: build.query({
      query: () => `${apiUrl.exchangeRate}`,
      providesTags: ['exchangeRate'],
    }),
    addCurrencyExchange: build.mutation({
      query: (data) => ({
        url: `${apiUrl.exchangeRate}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['exchangeRate'],
    }),
    getCurrencyExchangeById: build.query({
      query: (id) => `${apiUrl.exchangeRate}/${id}`,
      providesTags: ['exchangeRate'],
    }),
    updateCurrencyExchangeById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.exchangeRate}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['exchangeRate'],
    }),
    deleteCurrencyExchangeById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.exchangeRate}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['exchangeRate'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrencyExchangeQuery,
  useGetCurrencyExchangeByIdQuery,
  useUpdateCurrencyExchangeByIdMutation,
  useAddCurrencyExchangeMutation,
  useDeleteCurrencyExchangeByIdMutation,
} = extendedApi;
