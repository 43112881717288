import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  reconciliation: {
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.SUBLOCATION_ID]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
  approved: {
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.SUBLOCATION_ID]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const depositFilterSlice = createSlice({
  name: 'depositFilter',
  initialState,
  reducers: {
    setReconciliationFilters(state, action) {
      state.reconciliation[FILTER_VALUES.DATE_RANGE] = action.payload?.[FILTER_VALUES.DATE_RANGE] || {
        from: '',
        to: '',
      };
      state.reconciliation[FILTER_VALUES.SUBLOCATION_ID] = action.payload?.[FILTER_VALUES.SUBLOCATION_ID] || [];
      state.reconciliation.searchText = action.payload?.searchText || '';
      state.reconciliation.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.reconciliation.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    setApprovedFilters(state, action) {
      state.approved[FILTER_VALUES.DATE_RANGE] = action.payload?.[FILTER_VALUES.DATE_RANGE] || { from: '', to: '' };
      state.approved[FILTER_VALUES.SUBLOCATION_ID] = action.payload?.[FILTER_VALUES.SUBLOCATION_ID] || [];
      state.approved.searchText = action.payload?.searchText || '';
      state.approved.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.approved.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetReconciliationFiltersState(state) {
      state.reconciliation = { ...initialState.reconciliation, searchText: state.reconciliation.searchText };
    },
    resetApprovedFiltersState(state) {
      state.approved = { ...initialState.approved, searchText: state.approved.searchText };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default depositFilterSlice;
