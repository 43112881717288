import { NavigateNext } from '@mui/icons-material';
import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Breadcrumbs } from '../../../components/shared';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetSublocationByIdQuery } from '../../../services/Sublocation/SublocationService';
import useStyles from '../Locations/style';
import SubLocationUpdateView from './SubLocationUpdateView';

const EditSubLocation = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const {
    currentData: subLocationDetailsResponse,
    isLoading,
    isFetching,
  } = useGetSublocationByIdQuery({ id: Number(id) });
  const { data: detailsResponse } = subLocationDetailsResponse ?? {};
  const {
    LOCATION: { LOCATION, LOCATION_DETAIL, SUB_LOCATION_DETAIL, EDIT_SUB_LOCATION_DETAIL },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      { text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` },
      {
        text: LOCATION_DETAIL,
        redirection: `${routeConstants.LOCATION_ROUTE}/${Number(searchParams.get('locationId'))}`,
      },
      {
        text: SUB_LOCATION_DETAIL,
        redirection: `${routeConstants.SUBLOCATION_ROUTE}/${Number(id)}?locationId=${Number(
          searchParams.get('locationId'),
        )}`,
      },
      { text: EDIT_SUB_LOCATION_DETAIL },
    ],
    [id],
  );

  if (isLoading || isFetching) {
    return <LoadingSpinner containerHeight={400} />;
  }
  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>
      <Box my={4}>
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <Typography variant="h1">{detailsResponse?.name}</Typography>
          <Chip
            label={detailsResponse?.status ? 'Active' : 'Inactive'}
            color={detailsResponse?.status ? 'success' : 'error'}
            variant="outlined"
          />
        </Stack>
      </Box>
      <Box>
        <SubLocationUpdateView subLocationDetails={detailsResponse} />
      </Box>
    </Container>
  );
};

export default EditSubLocation;
