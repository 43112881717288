import React, { useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment, Link, Paper, Stack, Typography } from '@mui/material';
// import { Image } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { Button, Datepicker, GridContainer, GridItem, TextField } from '../../../components/shared';
import DepositDetailCommonRender from './DepositDetailCommonRender';
import { ROLE } from '../../../constants/roles';
import { routeConstants } from '../../../constants/routeConstants';
import { useHasRoles } from '../../../hooks';
import { dateFormatForApi, roundOffValue } from '../../../utils/common-methods';
import { useUpdateDepositByIdMutation } from '../../../services/Deposit/DepositService';
import useStyles from '../style';
import { MESSAGE } from '../../../constants/message';
import FIELD_TYPE from '../../../constants/fieldType';

const DepositDetailCommonComponent = (props) => {
  const { isApproved = false, depositDetails = {} } = props;
  const navigate = useNavigate();
  const classes = useStyles();

  const isSuperAdminOrAccounting = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.ACCOUNTING]);
  const [subLocationList, setSubLocationList] = useState([]);
  const [tempSubLocationList, setTempSubLocationList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dateOfDeposit, setDateOfDeposit] = useState(new Date());
  const [tempDateOfDeposit, setTempDateOfDeposit] = useState(new Date());
  const [isDateError, setIsDateError] = useState(false);
  const [dateMessage, setDateMessage] = useState('');

  const [updateDeposit] = useUpdateDepositByIdMutation();

  useEffect(() => {
    const { depositDate, depositDetails: depositLocationDetails = [] } = depositDetails;
    const sublocation = depositLocationDetails
      .map((item) => {
        const { id, sublocation, cashDeposited } = item;
        return {
          id: id,
          sublocationName: sublocation?.name?.trim() || '',
          sublocationId: sublocation?.id,
          amount: cashDeposited,
          cashOutStanding: '0',
        };
      })
      .sort((a, b) => b.amount - a.amount);

    setSubLocationList(sublocation);
    setDateOfDeposit(depositDate);
  }, [depositDetails]);

  const totalAmount = useMemo(() => {
    const values = Object.values(subLocationList.map((item) => item.amount));
    return roundOffValue(values.reduce((prevValue, currentValue) => Number(prevValue) + Number(currentValue), 0));
  }, [subLocationList]);

  const disableUpdate = useMemo(() => totalAmount === 0 || isDateError, [totalAmount, isDateError, dateOfDeposit]);

  const onUpdateSubLocationAmount = (sublocationId, newValue) => {
    let tempArr = subLocationList;
    let selectedLocationIndex = tempArr?.findIndex((value) => value?.id === sublocationId);
    let valueTwoDecimal = newValue
      ?.toString()
      ?.split('.')
      ?.map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
      .join('.');
    if (selectedLocationIndex !== -1) {
      tempArr?.splice(selectedLocationIndex, 1, {
        ...tempArr[selectedLocationIndex],
        amount: valueTwoDecimal,
      });
    }
    setSubLocationList([...tempArr]);
  };

  const onEditPress = () => {
    setTempSubLocationList([...subLocationList]);
    setTempDateOfDeposit(dateOfDeposit);
    setIsEdit(true);
  };

  const onCancelEditPress = () => {
    setSubLocationList([...tempSubLocationList]);
    setDateOfDeposit(tempDateOfDeposit);
    setTempSubLocationList([]);
    setTempDateOfDeposit(new Date());
    setIsEdit(false);
    setIsDateError(false);
  };

  const onUpdatePress = () => {
    const { id } = depositDetails;
    const args = {
      id: id,
      depositDate: dateFormatForApi(dateOfDeposit),
      sublocations: subLocationList.map((item) => ({
        sublocationId: item.sublocationId,
        cashDeposited: item.amount || '0',
      })),
    };

    updateDeposit(args)
      .then((res) => {
        if (res.data) {
          console.log('Deposit Updated Successfully');
        }
      })
      .finally(() => {
        setTempSubLocationList([]);
        setTempDateOfDeposit(new Date());
        setIsEdit(false);
      });
  };

  const onApprovePress = () => {
    const { id } = depositDetails;
    const args = {
      id: id,
      status: true,
    };

    updateDeposit(args).then((res) => {
      if (res.data) {
        navigate(`/${routeConstants.DEPOSIT_ROUTE}?selectedTab=3`, {
          replace: true,
        });
      }
    });
  };

  const onCancelPress = () => {
    navigate(`/${routeConstants.DEPOSIT_ROUTE}?selectedTab=2`, {
      replace: true,
    });
  };

  const onDateChange = async (value) => {
    Yup.date(MESSAGE.INVALID_DATE)
      .typeError(MESSAGE.INVALID_DATE)
      .label('Date of Deposit')
      .max(dayjs().format('MM/DD/YYYY'))
      .validate(value)
      .then((res) => {
        if (res) {
          setDateMessage('');
          setIsDateError(false);
          setDateOfDeposit(value);
        } else {
          setDateMessage('Enter a valid date in the format of MM/DD/YYYY');
          setIsDateError(true);
        }
      })
      .catch((e) => {
        const message = e.errors?.[0] || 'Enter a valid date in the format of MM/DD/YYYY';
        setDateMessage(message.includes('Invalid Date') ? 'Enter a valid date in the format of MM/DD/YYYY' : e.errors);
        setIsDateError(true);
      });
  };

  return (
    <Box p={2} mt={2} component={Paper}>
      <GridContainer direction={'column'}>
        <GridContainer>
          <GridItem mb={2} display={isApproved ? 'none' : 'block'} xs={12}>
            <Stack justifyContent={'flex-end'} direction={'row'}>
              {isEdit ? (
                <Stack direction={'row'} spacing={2}>
                  <Button disabled={disableUpdate} onClick={onUpdatePress} variant="contained">
                    Update
                  </Button>
                  <Button onClick={onCancelEditPress} color={'error'}>
                    Cancel
                  </Button>
                </Stack>
              ) : (
                <Button onClick={onEditPress} variant="contained">
                  Edit
                </Button>
              )}
            </Stack>
          </GridItem>
          <DepositDetailCommonRender
            key={'DateOfDeposit'}
            title={'Date of Deposit'}
            child={
              <Datepicker
                name={'dateOfDeposit'}
                data-testid="datepicker"
                value={dateOfDeposit}
                onChange={onDateChange}
                inputFormat={'MM/DD/YYYY'}
                maxDate={new Date()}
                PopperProps={{ placement: 'bottom-end' }}
                disabled={!isEdit || isApproved}
                error={isDateError}
                helperText={isDateError ? dateMessage : ''}
              />
            }
          />
        </GridContainer>

        <GridItem xs={12}>
          <Typography>Sub Location List</Typography>
        </GridItem>
        {/* commenting as from backend cash outstanding is not yet implemented */}
        {/* <GridContainer alignItems={'center'}>
          <GridItem xs={6} md={8} />
          <GridItem xs={6} md={4}>
            <Typography>{'Cash Outstanding'}</Typography>
          </GridItem>
        </GridContainer> */}
        {subLocationList.map((item, index) => (
          <GridContainer spacing={1} key={index} direction={'row'} mt={1}>
            <GridItem xs={12} md={4} display="flex" justifyContent="start" alignItems="center">
              <Typography role="presentation">{item.sublocationName}</Typography>
            </GridItem>
            <GridItem xs={12} md={8} display="flex" alignItems="center">
              {/* Use this instead when implement cash outstanding */}
              {/* <GridItem xs={6} md={4} display="flex" alignItems="center"> */}
              <TextField
                name="amount"
                placeholder={'0'}
                type="number"
                disabled={!isEdit || isApproved}
                value={item.amount}
                onChange={(e) => onUpdateSubLocationAmount(item.id, e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                restrictNegativeValue
              />
            </GridItem>

            {/* commenting as from backend cash outstanding is not yet implemented */}
            {/* <GridItem xs={6} md={4}>
                <TextField
                  name="amount"
                  placeholder={'0'}
                  disabled
                  value={item.cashOutStanding}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </GridItem> */}
          </GridContainer>
        ))}
        <GridContainer mt={2}>
          <DepositDetailCommonRender
            key={'TotalCashDeposited'}
            title={'Total Cash Deposited'}
            child={
              <TextField
                name="totalCashDeposited"
                disabled
                value={totalAmount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            }
          />
        </GridContainer>
        <GridContainer mt={2}>
          <GridItem xs={12} md={4} display="flex" justifyContent="start" alignItems="center">
            <Typography role="presentation">{'Receipts'}</Typography>
          </GridItem>
          <GridItem xs={12} md={8} display="flex" alignItems="center">
            <GridContainer>
              <Stack direction="row" gap={2} flexWrap="wrap">
                {depositDetails?.depositReceipts?.length > 0
                  ? depositDetails?.depositReceipts?.map((value) => {
                      const imageName = value?.imageName?.split('/');
                      const extractImageName = imageName[imageName?.length - 1]
                        ?.split('?')[0]
                        ?.split('_')[1]
                        ?.split('%20')
                        ?.join(' ');
                      return (
                        <Link
                          href={value?.imageName}
                          download
                          title={extractImageName}
                          target="_blank"
                          rel="noreferrer"
                          key={value.id}
                        >
                          <Box className={classes.imageWrapper}>
                            <img
                              src={value?.imageName}
                              alt={extractImageName}
                              width={270}
                              height={180}
                              style={{ objectFit: 'cover' }}
                            />
                          </Box>
                        </Link>
                      );
                    })
                  : FIELD_TYPE.NOT_APPLICABLE}
              </Stack>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridContainer>
      {isSuperAdminOrAccounting && !isApproved && !isEdit && (
        <Stack direction={'row'} spacing={2} mt={2}>
          <Button onClick={onApprovePress} type={'submit'} variant="contained">
            APPROVE
          </Button>
          <Button onClick={onCancelPress} color={'error'}>
            CANCEL
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default DepositDetailCommonComponent;
