import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    marginTop: 20,
    paddingBottom: 20,
  },
  root: {
    borderRadius: '2px',
    padding: '20px 20px 0px 20px',
    backgroundColor: theme.palette.common.white,
  },
}));
