import locationFilterSlice from './locationFilter-slice';
export const {
  setActiveLocationFilters,
  setArchiveLocationFilters,
  resetState,
  resetActiveLocationFilters,
  resetArchiveLocationFilters,
} = locationFilterSlice.actions;

export default locationFilterSlice;
