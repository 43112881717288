import { FormControlLabel, Checkbox } from '@mui/material';

const CheckBox = (props) => {
  const { label, labelPlacement = 'start', formClassName, ...rest } = props;
  return (
    <FormControlLabel
      className={formClassName}
      control={<Checkbox {...rest} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

export default CheckBox;
