import React, { useEffect, useMemo, useState } from 'react';
import { ControlledTooltips, GridContainer, GridItem, InformationField } from '../../../components/shared';
import { Comment, Info } from '@mui/icons-material';
import { Typography, IconButton, useMediaQuery, useTheme, Divider, Box } from '@mui/material';
import ChildField from '../AddCollection/ChildField';
import { FIELD_NAME, METER_TYPE_FILED_MAP } from '../../../constants/MeterTypeFieldMap';
import { MESSAGE } from '../../../constants/message';
import {
  getSummaryContent,
  sortAssetMeters,
  updateMeterNameForReconciliation,
} from '../../../utils/collection-methods';
import useStyles from '../style';
import SublocationSummaryField from './SublocationSummaryField';
import IntercardFields from '../AddCollection/IntercardFields';
import { getMeterLabel } from '../../../utils/collection-methods';
import { useSelector } from 'react-redux';
import { CommentBox } from '../../../components/CommentBox';
import { useFormikContext } from 'formik';
import { isArrayWithLength } from '../../../utils/common-methods';
import Modal from '../../../components/shared/Modal/Modal';
import ReportMachineModelBody from '../AddCollection/ReportMachineModelBody';

const ReconciliationMachineFormAccordionContent = ({ asset, skip }) => {
  const { assetMeters, intercardTransactions } = asset;
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const classes = useStyles();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { subTotalRevenues, locationCurrency } = useSelector((state) => state.reconciliationCollection);
  const sortedAssetMeters = useMemo(() => {
    const rawData = [...assetMeters];
    return sortAssetMeters(rawData);
  }, [asset]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [isIssueReported, setIsIssueReported] = useState(false);

  useEffect(() => {
    if (values) {
      const fieldName = `report_${asset.subLocationId}_${asset.id}`;
      const reportedValueFromFormikContext = values[fieldName];
      setIsIssueReported(reportedValueFromFormikContext?.status);
    }
  }, [asset, showReportModal]);

  const openCommentHandler = () => {
    setShowCommentModal(true);
  };

  const content = useMemo(
    () => getSummaryContent(subTotalRevenues, asset?.subLocationId, asset?.id, locationCurrency),
    [subTotalRevenues, asset],
  );

  const closeCommentBoxHandler = (commentData) => {
    if (values[`comment_${asset.subLocationId}_${asset.id}`]?.length !== commentData?.length) {
      setFieldValue(`comment_${asset.subLocationId}_${asset.id}`, commentData);
    }
    setShowCommentModal(false);
  };

  const submitCommentHandler = (v) => {
    setFieldValue(`comment_${asset.subLocationId}_${asset.id}`, v);
  };

  const onResolveReportedIssueHandler = (v) => {
    setFieldValue(`report_${asset.subLocationId}_${asset.id}`, v);
    setShowReportModal(false);
  };
  return (
    <React.Fragment>
      {assetMeters && assetMeters.length == 0 && (
        <InformationField my={1} pl={2} variant="subtitle1" value={MESSAGE.COLLECTION_NO_METERS || ''} />
      )}
      <IntercardFields
        intercardTransactions={intercardTransactions}
        assetId={asset?.id}
        sublocationId={asset?.subLocationId}
      />
      {assetMeters && assetMeters.length > 0 && (
        <React.Fragment>
          <GridContainer pb={1} id={asset?.id}>
            {sortedAssetMeters?.map((assetMeter, index) => {
              const { meter } = assetMeter;
              const meterLabel = getMeterLabel(assetMeter, locationCurrency);
              let meterName = meter?.name;
              meterName = updateMeterNameForReconciliation(meterName, true);
              return (
                <GridItem
                  sm={12}
                  md={6}
                  px={isBelowMD ? 0 : 1}
                  pt={1}
                  display="block"
                  justifyContent={isBelowMD ? 'flex-start' : 'flex-end'}
                  alignItems="center"
                  key={index}
                >
                  <GridItem px={1}>
                    <Typography variant="subtitle4"> {meterLabel}</Typography>
                  </GridItem>
                  <GridContainer key={meter?.id}>
                    <ChildField
                      assetId={asset?.id}
                      assetMeterId={assetMeter?.id}
                      assetMeter={assetMeter}
                      sublocationId={asset?.subLocationId}
                      meterName={meter?.name}
                      meterId={meter?.id}
                      fields={METER_TYPE_FILED_MAP[meterName]}
                      costPerPlay={asset[FIELD_NAME.COST_PER_PLAY]}
                      clicksPerPlay={asset[FIELD_NAME.CLICKS_PER_PLAY]}
                      isReconciliation
                      skip={skip}
                    />
                  </GridContainer>
                </GridItem>
              );
            })}
          </GridContainer>
          <Divider />
          <GridContainer pl={2} my={1.5}>
            {isIssueReported && (
              <GridItem xs={12} sm={2} lg={1.2}>
                <ControlledTooltips tooltipValue={MESSAGE.REPORT_MACHINE_INFO_RECONCILIATION}>
                  <Box display="flex" alignItems="center" onClick={() => setShowReportModal(true)}>
                    <Info fontSize="small" color="warning" />
                    <Typography variant="p4" pl={1}>
                      {MESSAGE.RECONCILIATION_TOOLTIP_NAME}
                    </Typography>
                  </Box>
                </ControlledTooltips>
              </GridItem>
            )}
            <GridItem xs={12} sm={1} md={0.5} lg={0.5} px={1}>
              <IconButton
                px={1}
                className={classes.comment}
                onClick={openCommentHandler}
                sx={{
                  color: showCommentModal ? theme.palette.primary.main : theme.palette.secondary.main,
                }}
              >
                <Comment />
              </IconButton>
            </GridItem>
            <GridItem xs={12} sm={isIssueReported ? 3.5 : 5} lg={isIssueReported ? 3.3 : 5.5}>
              {showCommentModal && (
                <CommentBox
                  handleClose={closeCommentBoxHandler}
                  handleSubmit={submitCommentHandler}
                  initialCommentData={values[`comment_${asset.subLocationId}_${asset.id}`]}
                  data={{
                    titleVariant: 'h3',
                    title: MESSAGE.RECONCILIATION_COMMENT_TITLE,
                    fieldName: `comment_${asset.subLocationId}_${asset.id}`,
                  }}
                />
              )}
            </GridItem>
            <GridItem mt={isBelowSm ? 1 : 0} xs={12} sm={6.5} md={6} lg={6}>
              {isArrayWithLength(content) && <SublocationSummaryField content={content} />}
            </GridItem>
          </GridContainer>
          <Modal
            title={MESSAGE.REPORT_MACHINE_BTN_NAME}
            open={showReportModal}
            onClose={() => setShowReportModal(false)}
            closeIcon={true}
          >
            <ReportMachineModelBody
              assetId={asset?.id}
              assetName={asset?.title}
              asset={asset}
              isReconciliation={true}
              onClose={() => setShowReportModal(false)}
              reportedIssue={setIsIssueReported}
              isIssueReported={isIssueReported}
              onConfirm={onResolveReportedIssueHandler}
            />
          </Modal>
        </React.Fragment>
      )}
      {(!assetMeters || assetMeters?.length === 0) && asset?.noCollectionReason && (
        <>
          <GridContainer pl={2} my={1.5}>
            {isIssueReported && (
              <GridItem xs={12} sm={2} lg={1.2}>
                <ControlledTooltips tooltipValue={MESSAGE.REPORT_MACHINE_INFO_RECONCILIATION}>
                  <Box display="flex" alignItems="center" onClick={() => setShowReportModal(true)}>
                    <Info fontSize="small" color="warning" />
                    <Typography variant="p4" pl={1}>
                      {MESSAGE.RECONCILIATION_TOOLTIP_NAME}
                    </Typography>
                  </Box>
                </ControlledTooltips>
              </GridItem>
            )}
            <GridItem xs={12} sm={1} md={0.5} lg={0.5} px={1}>
              <IconButton
                px={1}
                className={classes.comment}
                onClick={openCommentHandler}
                sx={{
                  color: showCommentModal ? theme.palette.primary.main : theme.palette.secondary.main,
                }}
              >
                <Comment />
              </IconButton>
            </GridItem>
            <GridItem xs={12} sm={isIssueReported ? 3.5 : 5} lg={isIssueReported ? 3.3 : 5.5}>
              {showCommentModal && (
                <CommentBox
                  handleClose={closeCommentBoxHandler}
                  handleSubmit={submitCommentHandler}
                  initialCommentData={values[`comment_${asset.subLocationId}_${asset.id}`]}
                  data={{
                    titleVariant: 'h3',
                    title: MESSAGE.RECONCILIATION_COMMENT_TITLE,
                    fieldName: `comment_${asset.subLocationId}_${asset.id}`,
                  }}
                />
              )}
            </GridItem>
            <GridItem mt={isBelowSm ? 1 : 0} xs={12} sm={6.5} md={6} lg={6}>
              {isArrayWithLength(content) && <SublocationSummaryField content={content} />}
            </GridItem>
          </GridContainer>
          <Modal
            title={MESSAGE.REPORT_MACHINE_BTN_NAME}
            open={showReportModal}
            onClose={() => setShowReportModal(false)}
            closeIcon={true}
          >
            <ReportMachineModelBody
              assetId={asset?.id}
              assetName={asset?.title}
              asset={asset}
              isReconciliation={true}
              onClose={() => setShowReportModal(false)}
              onConfirm={onResolveReportedIssueHandler}
              reportedIssue={setIsIssueReported}
              isIssueReported={isIssueReported}
            />
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default ReconciliationMachineFormAccordionContent;
