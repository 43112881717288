import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { AppRoutes } from './routes';
import { store, persistor } from './store';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import ThemeProvider from './theme';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from '../src/pages/Error';

/**
 * Due to 'react-router-dom' v6.0-6.6 removed useBlocker and usePrompt hooks, need to use unstable_HistoryRouter instead of BrowserRouter and install history package to enable navigator.block to work
 * Reference: https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 */
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ErrorModal from './components/ErrorModal/ErrorModal';
import SessionTimeout from './pages/SessionTimeout/SessionTimeout';

const history = createBrowserHistory({ window });

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <MsalProvider instance={msalInstance}>
            <HistoryRouter history={history}>
              <ErrorBoundary>
                <AppRoutes />
                <ErrorModal />
                <SessionTimeout />
              </ErrorBoundary>
            </HistoryRouter>
          </MsalProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
