import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Accordion, Breadcrumbs, PositionedMenu } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from './style';
import LocationOwnerDetailView from './LocationOwnerDetailView';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLocationOwnerByIdQuery } from '../../../services/Location/LocationService';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';

const LocationOwnerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentData: currentLocationOwner, isSuccess, isError, isLoading } = useGetLocationOwnerByIdQuery(Number(id));
  const {
    LOCATION_OWNER: { LOCATION_OWNER, LOCATION_OWNER_DETAIL },
  } = BREADCRUMB_NAMES;
  const [locationOwnerDetails, setLocationOwnerDetails] = useState(null);
  const classes = useStyles();
  const breadcrumbData = [
    { text: LOCATION_OWNER, redirection: routeConstants.LOCATION_OWNERS_ROUTE },
    { text: LOCATION_OWNER_DETAIL },
  ];

  useEffect(() => {
    if (currentLocationOwner) {
      setLocationOwnerDetails(currentLocationOwner);
    }
  }, [currentLocationOwner, isSuccess]);

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs icon={<>/</>} variant="body2" underline="hover" data={breadcrumbData} />
      </Box>
      {isLoading && !isError && !isSuccess && <Typography>Loading</Typography>}
      {isError && !isLoading & !isSuccess && <Typography>Unable to retrieve this location</Typography>}
      {locationOwnerDetails && (
        <>
          <Box mb={3}>
            <Typography variant="h1" data-testid="locationOwnerName">
              {locationOwnerDetails.name}
            </Typography>
          </Box>

          <Box mb={3}>
            <Accordion
              defaultExpanded
              marginBottom={3}
              title={'Location Owner Details'}
              content={<LocationOwnerDetailView info={locationOwnerDetails} />}
              subComponent={
                <PositionedMenu
                  menuItemData={[
                    {
                      btnName: 'Edit',
                      btnClickFunc: () =>
                        navigate(
                          `/${routeConstants.LOCATION_OWNERS_ROUTE}/${routeConstants.EDIT_LOCATION_OWNER}/${id}`,
                        ),
                    },
                  ]}
                />
              }
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default LocationOwnerDetails;
