import React, { useRef, useState } from 'react';
import MUIButton from '@mui/material/Button';
import MUIButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useSelector } from 'react-redux';

/**
 * Button is a reusable component that renders a button with optional dropdown menu functionality.
 * It accepts several props to customize its behavior and appearance.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Node} props.children - The content to display inside the button.
 * @param {string[]} [props.permittedRoles] - An optional array of role titles. If provided, only the users with these roles can see the button, if not provided, the button will be shown to everyone regardless of their role.
 * @param {Object[]} props.items - An array of objects representing dropdown menu items. Each item should have a `label` and an optional `onClick` function. If an onClick function is not provided for an item, that item will be disabled.
 * @param {Boolean} props.disabled - The property to disable the button. If `disabled=true` is provided, the entire button will be disabled. If it is not provided but there is no onClick event for the main button, the main button will be disabled but the split button arrow will still be functional for expanding other button options.
 * @param {rest} props.other - Additional props to be passed to the root button element.
 *
 * @returns {ReactNode} The rendered button component.
 *
 * @example
 * import Button from './path-to-this-file'; // Update the path
 *
 * // Regular Button
 * <Button
 *   disabled={disabledSubmit}
 *   onClick={() => handleSubmitForm()}
 *   variant="contained"
 *   data-testid="test-id-used-in-testing"
 *   sx={{ padding: '10px 51px' }}
 * >
 *   Regular Button
 * </Button>
 *
 * @example
 * import Button from './path-to-this-file'; // Update the path
 *
 * // Split Button
 * <Button
 *   variant="contained"
 *   color="primary"
 *   data-testid="test-id-used-in-testing"
 *   sx={{ padding: '10px 51px' }}
 *   items={[
 *     { label: 'Option 1', onClick: () => { console.log('Option 1 clicked!') } },
 *     { label: 'Option 2', onClick: () => { console.log('Option 2 clicked!') } },
 *     { label: 'Option 3', onClick: () => { console.log('Option 3 clicked!') } },
 *   ]}
 * >
 *   Split Button
 * </Button>
 */
const Button = (props) => {
  const { children, permittedRoles = [], items = [], ...other } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const ui = useSelector((state) => state.ui);
  const { userRoles } = ui;

  // If permittedRoles=[ROLE.SUPER_ADMIN_IT] => button will appear only for that role of users
  // If permittedRoles=[] => button will show for all roles
  // If we are not passing permittedRoles => button will show for all roles
  if (permittedRoles.length > 0 && !permittedRoles.some((role) => userRoles.includes(role))) {
    return null;
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if (items[index].onClick) items[index].onClick();
  };

  const toggleOpenState = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const renderMenuItem = (option, index) => (
    <MenuItem
      key={option.label}
      disabled={!option.onClick}
      selected={index === selectedIndex}
      onClick={(event) => handleMenuItemClick(event, index)}
    >
      {option.label}
    </MenuItem>
  );

  const renderPopper = () => (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom"
      modifier={[]}
      style={{ width: anchorRef?.current?.clientWidth }}
      modifiers={[
        {
          name: 'flip',
          options: {
            altBoundary: true,
            fallbackPlacements: [],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            tether: false,
          },
        },
      ]}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {items.map(renderMenuItem)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  if (items.length === 0) {
    return <MUIButton {...other}>{children}</MUIButton>;
  } else {
    return (
      <React.Fragment>
        <MUIButtonGroup ref={anchorRef} aria-label="split button">
          <MUIButton onClick={items[selectedIndex]?.onClick} disabled={other.disabled || !other.onClick} {...other}>
            {children}
          </MUIButton>
          <MUIButton
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            sx={other.variant === 'text' ? { borderLeft: '1px solid red' } : {}}
            {...other}
            // overrides onClick in other props
            onClick={toggleOpenState}
          >
            <ArrowDropDownIcon />
          </MUIButton>
        </MUIButtonGroup>
        {renderPopper()}
      </React.Fragment>
    );
  }
};
export default Button;
