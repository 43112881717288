import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    '& .MuiPaper-root': {
      '&.MuiAccordion-root': {
        boxShadow: 'none',
        borderBottom: '1px solid #E6E8EF',
        '&.Mui-expanded': {
          boxShadow: `0px 1px 4px ${theme.palette.grey[400_12]}`,
          borderBottom: 'none',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          width: '32px',
          height: '32px',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'rotate(0deg)',
          transition: 'unset',
          color: theme.palette.secondary.main,
          '&.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        },
      },
      '& .MuiAccordionSummary-root': {
        padding: '20px 24px 20px 20px',
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '16px 24px 28px',
      '& .MuiGrid2-root': {
        padding: 0,
      },
      '& .MuiGrid2-container': {
        marginBottom: '16px',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    '& .next-btn-wrapper': {
      margin: 0,
      height: 0,
      '& button.MuiButton-root': {
        visibility: 'hidden',
        height: 0,
        padding: 0,
      },
    },
  },
  //   leaseDetailsFormWrapper: {
  //     '& .next-btn-wrapper': {
  //       margin: 0,
  //       height: 0,
  //       '& button.MuiButton-root': {
  //         visibility: 'hidden',
  //         height: 0,
  //         padding: 0,
  //       },
  //     },
  //   },
}));

export default useStyles;
