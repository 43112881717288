import { makeStyles } from '@mui/styles';
import palette from '../../../theme/palette';
const useStyles = makeStyles(() => ({
  previewChip: {
    minWidth: 130,
    maxWidth: 210,
    justifyContent: 'space-between',
    '& .MuiChip-deleteIcon': {
      fontSize: '24px',
    },
    '& .MuiChip-avatar': {
      width: '20px ',
      height: '20px ',
    },
  },
  icon: {
    backgroundColor: palette.success.main,
    borderRadius: '50%',
  },
}));

export default useStyles;
