import React from 'react';
import PropTypes from 'prop-types';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import './style/SignOutButton.scss';

const displayName = 'SignOutButton';
const propTypes = {
  classes: PropTypes.object.isRequired,
  onLogoutPress: PropTypes.func.isRequired,
};

const SignOutButton = (props) => {
  const { onLogoutPress } = props;

  const handleLogout = () => {
    onLogoutPress();
  };

  return (
    <>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>{<LogoutOutlinedIcon />}</ListItemIcon>
        <ListItemText primary={'Sign Out'} />
      </ListItemButton>
    </>
  );
};

SignOutButton.displayName = displayName;
SignOutButton.prototype = propTypes;
export default SignOutButton;
