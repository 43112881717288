import { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

import FIELD_TYPE from '../../../constants/fieldType';
import {
  COLUMNS,
  USER_DETAILS_DYNAMIC_COLUMNS,
  USER_DETAILS_DYNAMIC_SUB_LOC_TABLE,
} from '../../../constants/tableColumns';
import {
  GridContainer,
  GridItem,
  Button,
  InformationPad,
  PositionedMenu,
  ControlledTooltips,
  Link,
} from '../../../components/shared';
import ReactTable from '../../../components/shared/ReactTable';
import ConfirmationModal from '../../../components/ConfirmationModel';
import { USER_LOCATION_DETAIL_LABEL } from '../../../constants/accordionFields';
import useStyles from './style';
import {
  formatPhNo10Digit,
  getControlledTooltipValueAndCellValue,
  isArrayWithLength,
} from '../../../utils/common-methods';
import { PinDropOutlined } from '@mui/icons-material';
import { routeConstants } from '../../../constants/routeConstants';
import { MESSAGE } from '../../../constants/message';
import { useDeleteSubLocationByIdMutation } from '../../../services/Sublocation/SublocationService';
import { useDeleteLocationByIdMutation } from '../../../services/Location/LocationService';

const { NOT_APPLICABLE } = FIELD_TYPE;

export const UserAssignedLocation = ({
  assignedLocation,
  disabled,
  onAddNewLocation = () => {},
  removeSubLocationFromUser = () => {},
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const [showRemoveSubLocationModal, setShowRemoveSubLocationModal] = useState(false);
  const [showDeleteSubLocationModal, setShowDeleteSubLocationModal] = useState(false);
  const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
  const [activeLocationId, setActiveLocationId] = useState(null);
  const [activeLocationName, setActiveLocationName] = useState(null);
  const [activeSubLocationId, setActiveSubLocationId] = useState(null);
  const [activeSubLocationName, setActiveSubLocationName] = useState(null);
  const [deleteSubLocationApi] = useDeleteSubLocationByIdMutation();
  const [deleteLocationApi] = useDeleteLocationByIdMutation();

  const USER_DETAILS_SUB_LOC_COLUMNS = useMemo(() => {
    const dynamicColumns = USER_DETAILS_DYNAMIC_SUB_LOC_TABLE(isBelowMD);
    const col = [
      ...dynamicColumns,
      {
        Header: () => null,
        id: 'more',
        Cell: ({ row }) => (
          <PositionedMenu
            menuItemData={[
              {
                btnName: 'Edit Sub-location',
                btnClickFunc: () => editSubLocation(row.original.id),
              },
              {
                btnName: 'Remove Sub-location',
                btnClickFunc: () => confirmRemoveSubLocation(row.original.id, row.original.subLocationName),
                btnTypeStyle: 'warningBtn',
              },
              {
                btnName: 'Delete Sub-location',
                btnClickFunc: () => confirmDeleteSubLocation(row.original.id, row.original.subLocationName),
                btnTypeStyle: 'dangerBtn',
              },
            ]}
            // disabled={disabled}
          />
        ),
      },
    ];
    return col;
  }, [assignedLocation, isBelowMD]);
  const COLUMN_SUBLOC_NAME = {
    Header: 'SUB LOCATION',
    accessor: 'tooltipValue',
    width: 'auto',
    Cell: ({ row }) => (
      <ControlledTooltips tooltipValue={row.original?.tooltipValue}>
        <Box>{row.original?.subLocationDisplay}</Box>
      </ControlledTooltips>
    ),
  };
  const COLUMN_LOCATION_NAME = {
    Header: 'LOCATION NAME',
    accessor: 'locationName',
    width: 'auto',
    Cell: ({ row }) => (
      <Link underline="none" to={`/${routeConstants.LOCATION_ROUTE}/${row.original?.id}`}>
        <Box>{row.original?.locationName}</Box>
      </Link>
    ),
  };
  const USER_DETAILS_COLUMNS = useMemo(() => {
    const dynamicColumns = USER_DETAILS_DYNAMIC_COLUMNS(isBelowMD);
    const col = [
      COLUMNS.EXPAND_ICON_COLUMN,
      COLUMN_LOCATION_NAME,
      COLUMN_SUBLOC_NAME,
      ...dynamicColumns,
      {
        Header: () => null,
        id: 'more',
        Cell: ({ row }) => (
          <PositionedMenu
            menuItemData={[
              {
                btnName: 'Edit Location',
                btnClickFunc: () => editLocation(row.original.id),
              },
              {
                btnName: 'Delete Location',
                btnClickFunc: () => confirmDeleteLocation(row.original.id, row.original.locationName),
                btnTypeStyle: 'dangerBtn',
              },
            ]}
            // disabled={disabled}
          />
        ),
      },
    ];
    return col;
  }, [assignedLocation, isBelowMD]);

  const classes = useStyles();

  const refreshAssignedLocationIDs = (locationId) => {
    const ids = [];
    assignedLocation.map((item) => {
      const { sublocations } = item;
      if (isArrayWithLength(sublocations)) {
        sublocations.map((item) => {
          if (locationId && item.locationId === locationId) {
            ids.push(item.id);
          } else if (!locationId) {
            ids.push(item.id);
          }
        });
      }
      return [];
    });
    return ids;
  };

  const editLocation = (locationId) => {
    navigate(`/${routeConstants.LOCATION_ROUTE}/${locationId}`, {
      redirect: true,
    });
  };

  const editSubLocation = (subLocationId) => {
    navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${subLocationId}`, {
      redirect: true,
    });
  };

  const confirmRemoveSubLocation = (subLocationId, name) => {
    setActiveSubLocationId(subLocationId);
    setActiveSubLocationName(name);
    setShowRemoveSubLocationModal(true);
  };

  const confirmDeleteLocation = (locationId, name) => {
    setActiveLocationId(locationId);
    setActiveLocationName(name);
    setShowDeleteLocationModal(true);
  };

  const confirmDeleteSubLocation = (subLocationId, name) => {
    setActiveSubLocationId(subLocationId);
    setActiveSubLocationName(name);
    setShowDeleteSubLocationModal(true);
  };

  const onDeleteLocation = async () => {
    let subLocationIds = refreshAssignedLocationIDs(activeLocationId);
    await onRemoveSubLocation(subLocationIds);
    const response = await deleteLocationApi(activeLocationId);
    if (response.data) {
      setActiveLocationId(null);
      setShowDeleteLocationModal(false);
      // Update the UI or perform any necessary actions after successful deletion
    }
  };

  const onDeleteSubLocation = async () => {
    await onRemoveSubLocation(activeSubLocationId);
    const response = await deleteSubLocationApi(activeSubLocationId);
    if (response.data) {
      setActiveSubLocationId(null);
      setShowDeleteSubLocationModal(false);
      // Update the UI or perform any necessary actions after successful deletion
    }
  };

  const onRemoveSubLocation = (subLocationId) => {
    const ids = refreshAssignedLocationIDs();
    if (isArrayWithLength(subLocationId)) {
      removeSubLocationFromUser(ids.filter((item) => !subLocationId.includes(item)));
    } else {
      removeSubLocationFromUser(ids.filter((item) => item !== subLocationId));
    }
  };

  const detailLocationComponent = ({ row }) => {
    const { original = {} } = row;
    const {
      owner = { name: '' },
      postcode = NOT_APPLICABLE,
      phone1 = NOT_APPLICABLE,
      phone2 = NOT_APPLICABLE,
      currency = NOT_APPLICABLE,
      address2 = NOT_APPLICABLE,
      locationType = NOT_APPLICABLE,
      country = NOT_APPLICABLE,
      state = NOT_APPLICABLE,
      city = NOT_APPLICABLE,
      sublocations = [],
    } = original;

    const subLocationData = sublocations?.map((item) => {
      const { name, ...rest } = item;
      return {
        subLocationName: name || NOT_APPLICABLE,
        city: city,
        state: state,
        postcode: postcode,
        ...rest,
      };
    });

    const userLocationDispFields = [
      { label: USER_LOCATION_DETAIL_LABEL.OWNER, value: owner?.name },

      { label: USER_LOCATION_DETAIL_LABEL.PHONE1, value: phone1 ? formatPhNo10Digit(phone1) : NOT_APPLICABLE },
      { label: USER_LOCATION_DETAIL_LABEL.PHONE2, value: phone2 ? formatPhNo10Digit(phone2) : NOT_APPLICABLE },
      { label: USER_LOCATION_DETAIL_LABEL.CURRENCY, value: currency?.name },
      {
        label: USER_LOCATION_DETAIL_LABEL.LOCATION_TYPE,
        value: locationType?.name,
      },
      {
        label: USER_LOCATION_DETAIL_LABEL.ADDRESS2,
        value: address2,
      },
      { label: USER_LOCATION_DETAIL_LABEL.COUNTRY, value: country || NOT_APPLICABLE },
      { label: USER_LOCATION_DETAIL_LABEL.STATE, value: state || NOT_APPLICABLE },
      { label: USER_LOCATION_DETAIL_LABEL.CITY, value: city || NOT_APPLICABLE },
    ];

    return (
      <Box className={classes.userSubLocationWrap}>
        <Box className={classes.userSubLocationInfo}>
          <InformationPad data={userLocationDispFields} />
        </Box>
        <GridContainer className={classes.userSubLocationTableWrap} direction="column">
          <GridItem className={classes.assignSubLocText}>
            <Typography variant="h3">Assigned Sub Locations</Typography>
          </GridItem>
          <GridItem>
            <ReactTable key={'SubLocations'} data={subLocationData} columns={USER_DETAILS_SUB_LOC_COLUMNS} hideHeader />
          </GridItem>
        </GridContainer>
      </Box>
    );
  };

  const viewSubLoc = useCallback((subLoc) => {
    const sublocationName = subLoc.map((item) => item?.name);
    return getControlledTooltipValueAndCellValue(sublocationName);
  }, []);

  const data = useMemo(
    () =>
      assignedLocation.map((item) => {
        const { sublocations, locationName, postcode, city, state, country, ...rest } = item;
        const { tooltipValue, cellValue } = viewSubLoc(sublocations || []);
        return {
          ...rest,
          sublocations,
          locationName: locationName || NOT_APPLICABLE,
          subLocationDisplay: cellValue,
          tooltipValue: tooltipValue,
          address1: item?.address1 || NOT_APPLICABLE,
          postcode: postcode || NOT_APPLICABLE,
          country: country?.name || NOT_APPLICABLE,
          state: state?.name || NOT_APPLICABLE,
          city: city?.name || NOT_APPLICABLE,
        };
      }),
    [assignedLocation],
  );

  const AddNewBtn = () => (
    <Box className={classes.addBtn}>
      <Button
        onClick={onAddNewLocation}
        variant="outlined"
        size="large"
        data-testid="add-location-btn"
        disabled={disabled}
        startIcon={<Add />}
      >
        ADD NEW
      </Button>
    </Box>
  );

  return (
    <Box className={classes.userAssignedLocWrap}>
      <Paper>
        <GridContainer className={classes.userAssignedLocHead}>
          <GridItem xs={6} display="flex" alignItems="center">
            <Typography variant="h2" className={classes.pageName}>
              Assigned Locations
            </Typography>
          </GridItem>
        </GridContainer>
        <Box className={classes.userAssignedLocTable}>
          <ReactTable
            key={'Locations'}
            data={data}
            columns={USER_DETAILS_COLUMNS}
            stickyHeader
            expandable
            localFilter
            renderRowSubComponent={detailLocationComponent}
            noContentIcon={<PinDropOutlined sx={{ color: theme.palette.primary.main }} />}
            searchPlaceHolder="Search by location, address and zipcode"
            extraBtn={<AddNewBtn />}
          />
        </Box>
      </Paper>
      <ConfirmationModal
        key={'delete-location-modal'}
        isOpen={showDeleteLocationModal}
        title={`${MESSAGE.DELETE_LOCATION.replace(
          'LOCATION',
          `LOCATION: ${activeLocationName ?? ''.toString().toUpperCase()}`,
        )}`}
        msg={MESSAGE.DELETE_LOCATION_DESCRIPTION.replace(
          '%name',
          activeLocationName ? activeLocationName.toString().toUpperCase() : '',
        )}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={(value) => {
          if (value === MESSAGE.DELETE) onDeleteLocation(activeLocationId);
          else setShowDeleteLocationModal(false);
        }}
      />
      <ConfirmationModal
        key={'delete-sublocation-modal'}
        isOpen={showDeleteSubLocationModal}
        title={`${MESSAGE.DELETE_SUBLOCATION.replace(
          'SUB-LOCATION',
          `SUB-LOCATION: ${activeSubLocationName ?? ''.toString().toUpperCase()}`,
        )}`}
        msg={MESSAGE.DELETE_SUBLOCATION_DESCRIPTION.replace(
          '%name',
          activeSubLocationName ? activeSubLocationName.toString().toUpperCase() : '',
        )}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={(value) => {
          if (value === MESSAGE.DELETE) onDeleteSubLocation(activeSubLocationId);
          else setShowDeleteSubLocationModal(false);
        }}
      />
      <ConfirmationModal
        key={'remove-sublocation-modal'}
        isOpen={showRemoveSubLocationModal}
        title={`${MESSAGE.REMOVE_SUBLOCATION.replace(
          'SUB-LOCATION',
          `SUB-LOCATION: ${activeSubLocationName ?? ''.toString().toUpperCase()}`,
        )}`}
        msg={MESSAGE.REMOVE_SUBLOCATION_DESCRIPTION.replace(
          '%name',
          activeSubLocationName ? activeSubLocationName.toString().toUpperCase() : '',
        )}
        buttons={[
          { text: MESSAGE.REMOVE, value: MESSAGE.REMOVE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={(value) => {
          if (value === MESSAGE.REMOVE) onRemoveSubLocation(activeSubLocationId);
          else setShowRemoveSubLocationModal(false);
        }}
      />
    </Box>
  );
};

export default UserAssignedLocation;
