import { Autocomplete as MUIAutocomplete } from '@mui/material';
import TextField from '../TextField';
import { createFilterOptions } from '@mui/material';
import { Chip } from '@mui/material';
import { useCallback } from 'react';

const Autocomplete = ({
  label = '',
  value,
  name = '',
  labelProp = {},
  options = [],
  helperText = '',
  error = false,
  loading,
  getOptionLabel = (option) => option.text || '',
  onChange = () => {},
  ...others
}) => {
  const renderOption = (props, option) => (
    <li {...props} key={option?.value || option?.id}>
      {option?.text || option?.name}
    </li>
  );
  const defaultOption = [
    {
      text: 'No option',
      value: 'No option',
      name: 'No option',
      id: 'No option',
    },
  ];
  const filter = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option?.text || option?.name || '',
  });

  return (
    <MUIAutocomplete
      data-testid="autocomplete"
      value={value}
      name={name}
      freeSolo
      clearOnBlur
      forcePopupIcon
      options={options.length !== 0 ? options : loading ? [] : defaultOption}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) =>
        (option.text || option.name) &&
        (option?.text?.toLowerCase() == 'no option' || option.name?.toLowerCase() == 'no option')
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (loading) {
          return options;
        }
        if (!filtered.length) {
          return defaultOption;
        }
        return filtered;
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} error={error} helperText={helperText} InputLabelProps={labelProp} />
      )}
      renderOption={renderOption}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (typeof option === 'object') {
            return <Chip label={option.name || option.text} key={index} {...getTagProps({ index })} />;
          }
        })
      }
      onChange={useCallback((e, value) => {
        if (
          Array.isArray(value) &&
          typeof value[value.length - 1] === 'object' &&
          value[value.length - 1].constructor === Object
        ) {
          onChange(e, value);
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          onChange(e, value);
        } else if (!value[value?.length - 1]) {
          onChange(e, value);
        }
      })}
      loading={loading}
      {...others}
    />
  );
};

export default Autocomplete;
