import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    height: '100vh',
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.primary.darker,
    },
  },
  loginLeftPanelCol: {
    backgroundColor: theme.palette.primary.darker,
  },
  loginLeftPanelInner: {
    height: '100%',
    paddingBottom: '27px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 0,
      marginBottom: '-34px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  loginLeftPanelContent: {
    padding: '111px 62px 43px 20%',
    [theme.breakpoints.down('lg')]: {
      padding: '105px 32px 34px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '69px 16px 21px',
    },
    '& .MuiTypography-root': {
      '&.MuiTypography-p6': {
        color: alpha(theme.palette.common.white, 0.67),
        [theme.breakpoints.down('lg')]: {
          fontSize: 24,
          lineHeight: '24px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
          lineHeight: '16px',
        },
      },
    },
    '& .MuiTypography-h2': {
      fontSize: 42,
      lineHeight: '52px',
      fontWeight: '900',
      color: theme.palette.common.white,
      letterSpacing: 'normal',
      [theme.breakpoints.down('lg')]: {
        fontSize: 40,
        lineHeight: '50px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        lineHeight: '28px',
        maxWidth: '194px',
        marginBottom: 0,
      },
    },
  },
  loginLeftPanelImgWrap: {
    height: '65%',
    position: 'relative',
  },
  loginLeftPanelMobImgWrap: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
  loginLeftPanelImg: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  loginLeftPanelMobImg: {
    [theme.breakpoints.down('lg')]: {
      maxHeight: '407px',
      marginLeft: '-11px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '309px',
      margin: '0 auto',
    },
  },
  loginRightPanel: {
    padding: '32px 32px 32px 137px',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      padding: '69px 64px 103px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px -1px 13px rgba(16, 53, 86, 0.3);',
      borderRadius: '40px 40px 0 0',
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '55px 42px 89px',
      borderRadius: '24px 24px 0 0',
    },
  },
  loginRightGridItem: {
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.primary.darker,
    },
  },
  loginLogo: {
    height: 92,
    [theme.breakpoints.down('lg')]: {
      height: 62,
      marginBottom: 20,
    },
    [theme.breakpoints.down('sm')]: {
      height: 41,
    },
  },
  loginRightPanelTitle: {
    color: theme.palette.primary.darker,
    whiteSpace: 'normal',
    [theme.breakpoints.down('lg')]: {
      fontSize: 32,
      lineHeight: '42px',
      maxWidth: '338px',
      margin: '0 auto 42px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '32px',
      margin: '0 auto 28px',
      maxWidth: '290px',
    },
  },
}));
export default useStyles;
