import { useState, useEffect, useRef, useTransition } from 'react';
import { Box } from '@mui/material';
import { TextField } from '../../components/shared';
import { MESSAGE } from '../../constants/message';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './style';

export default function SearchBar({
  onSearch,
  searchText = '',
  resetFilter = () => {},
  onReset = () => {},
  placeholder = 'Search',
  isEnterDisabled = false,
}) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState(searchText);
  const [isValid, setIsValid] = useState(true);
  const [errMessage, setErrMessage] = useState('');
  const searchRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const [, startTransition] = useTransition();

  useEffect(() => {
    setSearchParams(searchText);
    setFocused(searchText.length > 0 ? true : false);
  }, [searchText]);

  useEffect(() => {
    if (isEnterDisabled) {
      setIsValid(true);
    } else {
      const invalid = typeof searchParams === 'string' && searchParams.length < 3 && searchParams.length > 0;
      setIsValid(!invalid);
    }
  }, [searchParams]);

  useEffect(() => {
    setErrMessage(isValid ? '' : MESSAGE.INVALID_SEARCH_MESSAGE);
  }, [isValid]);

  useEffect(() => {
    setFocused(searchRef.current === document.activeElement);
  }, []);

  const onSearchHandler = (e) => {
    if (e.key === 'Enter') {
      setFocused(searchRef.current === document.activeElement);
      e.preventDefault();
      if (isValid && searchParams !== '') {
        setErrMessage('');
        onSearch(searchParams);
      }
      if (isValid && searchParams === '') {
        setErrMessage(MESSAGE.INVALID_SEARCH_MESSAGE);
      }
    }
  };

  const onChangeHandler = (e) => {
    searchRef.current.focus();
    setFocused(searchRef.current === document.activeElement);
    setSearchParams(e.target.value);
    if (e.target.value === '') {
      resetFilter();
    }
    if (isEnterDisabled) {
      startTransition(() => {
        onSearch(e.target.value);
      });
    }
  };

  const resetHandler = () => {
    setSearchParams('');
    onReset();
    setFocused(false);
    resetFilter();
    setErrMessage('');
    setIsValid(true);
    if (isEnterDisabled) {
      startTransition(() => {
        onSearch('');
      });
    }
  };

  const closeIcon = focused && (
    <IconButton type="button" className={classes.searchIconButton} aria-label="reset" onClick={resetHandler}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <Box className={`${classes.searchWrapper}`}>
      <TextField
        label=""
        variant="outlined"
        className={classes.searchInput}
        placeholder={placeholder}
        InputProps={{
          // disableUnderline: true,
          'aria-label': 'Search',
          'data-testid': 'SearchInput',
          startAdornment: <SearchIcon />,
          endAdornment: closeIcon,
        }}
        value={searchParams}
        onChange={onChangeHandler}
        onKeyPress={isEnterDisabled ? () => {} : onSearchHandler}
        error={!isEnterDisabled && (!isValid || (focused && isValid && searchParams === ''))}
        helperText={isEnterDisabled ? '' : errMessage}
        inputRef={searchRef}
      />
    </Box>
  );
}
