import React from 'react';
import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandLess, KeyboardArrowRight } from '@mui/icons-material';
import useStyles from './style';

const Accordion = ({
  defaultExpanded = false,
  customizedTitle,
  title = '',
  content = '',
  showMore = true,
  subComponent,
  accordionSummaryStyle,
  summaryTitleVariant = 'h2',
  noGutterAccordion,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  return (
    <MUIAccordion
      className={`${noGutterAccordion ? classes.noGutterAccordion : ''}`}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <ExpandLess className={classes.expandIcon} />
          ) : (
            <KeyboardArrowRight className={classes.expandIcon} />
          )
        }
        className={`${classes.summary} ${accordionSummaryStyle ? classes.collectionAccordionSummary : ''}`}
      >
        {!customizedTitle && (
          <Typography ml={2} data-testid="accordionTitle" variant={summaryTitleVariant} className={classes.title}>
            {title}
            {showMore && subComponent}
          </Typography>
        )}
        {customizedTitle && title}
      </AccordionSummary>
      <AccordionDetails data-testid="accordionContent" className={classes.details}>
        {content}
      </AccordionDetails>
    </MUIAccordion>
  );
};

export default React.memo(Accordion);
