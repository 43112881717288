import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ReactTable, Link, NoContent, ControlledTooltips } from '../../../components/shared';
import { COLUMNS } from '../../../constants/tableColumns';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetAllCollectionsQuery } from '../../../services/CollectionService/CollectionService';
import { COLLECTION_RECONCILIATION_APPROVED } from '../../../constants/CommonConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getControlledTooltipValueAndCellValue, roundOffValue } from '../../../utils/common-methods';
import { useDispatch } from 'react-redux';
import { setApprovedCollectionFilters } from '../../../redux-slice/collectionFilters';

const ApprovedCollectionTable = ({ searchParams, approvedCollection }) => {
  const [currentPage, setCurrentPage] = useState(approvedCollection?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(approvedCollection?.rowsPerPage);
  const dispatch = useDispatch();

  const viewProductTypes = useCallback((productTypeNames) => {
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(productTypeNames);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  const transactionLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`${routeConstants.APPROVED_COLLECTION_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useGetAllCollectionsQuery({
    searchParams,
    page: currentPage,
    perPage: perPageNumber,
    status: COLLECTION_RECONCILIATION_APPROVED,
  });

  const tableData = useMemo(() => {
    if (isSuccess && latestData) {
      const rawData = latestData?.collections;
      return rawData.map((data) => {
        const transformedData = { ...data };
        transformedData.locationName = transactionLink(data.transactionId, data?.location?.locationName);
        transformedData.locationType = data?.location?.locationType?.name;
        transformedData.locationRevenue = roundOffValue(data?.locationRevenue);
        transformedData.productTypes = viewProductTypes(data?.productTypes);
        return transformedData;
      });
    }
  }, [isSuccess, latestData]);

  const totalCount = useMemo(() => {
    if (isSuccess && latestData) {
      return latestData?.pagination?.total ?? 0;
    }
    return 0;
  }, [isSuccess, latestData]);

  useEffect(() => {
    setCurrentPage(approvedCollection?.pageNumber);
    setPerPageNumber(approvedCollection?.rowsPerPage);
  }, [searchParams]);

  const pageChangeHandler = (currentPage) => {
    dispatch(
      setApprovedCollectionFilters({ ...approvedCollection, pageNumber: currentPage, rowsPerPage: perPageNumber }),
    );
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(setApprovedCollectionFilters({ ...approvedCollection, rowsPerPage: newPerPage, pageNumber: currentPage }));
    setPerPageNumber(newPerPage);
  };

  const approvedCollectionColumns = useMemo(() => COLUMNS.APPROVED_COLLECTION_TABLE, []);

  return (
    <>
      {isLoading && !isError && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Collection Found"
          desc={(isError && error?.data?.message) || <>There are no collection present with this filter.</>}
        />
      )}
      {!isLoading && !isError && tableData && (
        <ReactTable
          data={tableData}
          columns={approvedCollectionColumns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
        />
      )}
    </>
  );
};

export default ApprovedCollectionTable;
