import React, { useEffect } from 'react';
import SublocationMachinesForm from './SublocationMachinesForm';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTotalCollected,
  setCollectionCollected,
  setTransformedSublocations,
  setTotalTokenCollected,
  setTotalTicketDispensed,
  setTotalPrizeDispensed,
  setFormikData,
  setTotalMedallionDispensed,
} from '../../../redux-slice/newcollection';
import {
  getCollectionCollected,
  getRevenueInLocalCurrency,
  getTotalCollected,
  getTotalMedallionDispensed,
  getTotalPrizeDispensed,
  getTotalTicketDispensed,
  getTotalTokenCollected,
  getTransformedData,
} from '../../../utils/collection-methods';
import { isArrayWithLength } from '../../../utils/common-methods';
import { FILTER_VALUES } from '../../../constants/FilterConstants';
import { DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import { NoContent } from '../../../components/shared';

const displayName = 'CollectionForm';
const CollectionForm = React.forwardRef(
  (
    {
      location,
      allSublocations,
      initialStateAndScheme,
      onConfirm,
      filterParams,
      readFromDraft,
      noSublocation,
      isSearch,
      turnOffDraft,
    },
    ref,
  ) => {
    const { initialState, scheme } = initialStateAndScheme;
    const dispatch = useDispatch();
    const { totalCollected, totalCollectedInUSD, summary, locationCurrency } = useSelector(
      (state) => state.newCollection,
    );
    const { playCards } = useSelector((state) => state.playCard);
    const formik = useFormik({
      initialValues: initialState,
      validationSchema: scheme,
      enableReinitialize: true,
      onSubmit: () => {
        onConfirm();
      },
    });

    useEffect(() => {
      ref.current = formik;
      if (formik.values && Object.values(formik.values).length > 0) {
        let total = 0;
        let totalInUSD = 0;
        let totalTicketDispensed = 0;
        let totalPrizeDispensed = 0;
        let totalTokenCollected = 0;
        let totalMedallionDispensed = 0;
        const exchangeRate = locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE;
        for (const key in playCards) {
          const adjustedPlaycardSales = playCards[key]?.adjustedPlaycardSales || 0;
          const adjustedPlaycardSalesInLocalCurrency = adjustedPlaycardSales
            ? getRevenueInLocalCurrency(adjustedPlaycardSales, exchangeRate)
            : 0;
          total += adjustedPlaycardSalesInLocalCurrency;
          totalInUSD += adjustedPlaycardSales;
        }

        if (formik.values && Object.values(formik.values).length > 0) {
          Object.entries(formik.values).map(([key, value]) => {
            const { total: newTotal, totalInUSD: newTotalInUSD } = getTotalCollected(
              total,
              totalInUSD,
              key,
              value,
              exchangeRate,
            );
            total = newTotal;
            totalInUSD = newTotalInUSD;
            totalTicketDispensed = getTotalTicketDispensed(totalTicketDispensed, key, value);
            totalPrizeDispensed = getTotalPrizeDispensed(totalPrizeDispensed, key, value);
            totalMedallionDispensed = getTotalMedallionDispensed(totalMedallionDispensed, key, value);
            totalTokenCollected = getTotalTokenCollected(totalTokenCollected, key, value);
          });
          if (totalCollected !== total || totalCollectedInUSD !== totalInUSD) {
            dispatch(setTotalCollected({ total, totalInUSD }));
          }
          if (summary.totalTokenCollected !== totalTokenCollected) {
            dispatch(setTotalTokenCollected(totalTokenCollected));
          }
          if (summary.totalTicketDispensed !== totalTicketDispensed) {
            dispatch(setTotalTicketDispensed(totalTicketDispensed));
          }
          if (summary.totalMedallionDispensed !== totalMedallionDispensed) {
            dispatch(setTotalMedallionDispensed(totalMedallionDispensed));
          }
          if (summary.totalPrizeDispensed !== totalPrizeDispensed) {
            dispatch(setTotalPrizeDispensed(totalPrizeDispensed));
          }
          if (Object.values(formik.values).some((el) => el !== '')) {
            const { transformedSublocations, formikData } = getTransformedData(formik.values, allSublocations);
            dispatch(setTransformedSublocations(transformedSublocations));
            dispatch(setFormikData(formikData));
            dispatch(setCollectionCollected(getCollectionCollected(transformedSublocations)));
          }
        }
      }
    }, [formik.values, playCards, locationCurrency]);

    useEffect(() => {
      if (formik?.errors && Object.values(formik.errors).length > 0) {
        ref.current = formik;
      }
    }, [formik.errors]);

    if (noSublocation && isSearch) {
      return (
        <NoContent title="No Asset found" desc={<>No results found, please try changing the search criteria.</>} />
      );
    }

    if (location?.sublocations && location?.sublocations.length > 0) {
      return (
        <FormikProvider value={formik} validateOnChange={false} validateOnBlur={true}>
          {location?.sublocations
            ?.filter((value) => {
              if (isArrayWithLength(filterParams[FILTER_VALUES.COLLECTION_SUBLOCATION])) {
                const selectedSubLocationIds =
                  filterParams[FILTER_VALUES.COLLECTION_SUBLOCATION]?.map((item) => item.id) || [];
                return selectedSubLocationIds.includes(value.id);
              }
              return true;
            })
            .map((sublocation) => (
              <SublocationMachinesForm
                key={sublocation?.id}
                sublocation={sublocation}
                originalSublocation={allSublocations?.find((el) => el.id === sublocation?.id)}
                readFromDraft={readFromDraft}
                turnOffDraft={turnOffDraft}
              />
            ))}
        </FormikProvider>
      );
    }
  },
);

CollectionForm.displayName = displayName;
export default CollectionForm;
