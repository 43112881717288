export const routeConstants = {
  USERS_ROUTE: 'users',
  USER_ROUTE: 'user',
  EDIT_USER_ROUTE: 'edit-user',
  COLLECTIONS_ROUTE: 'collections',
  ADD_COLLECTIONS_ROUTE: 'add-collection',
  RECONCILIATION_COLLECTION_ROUTE: 'reconciliation',
  APPROVED_COLLECTION_ROUTE: 'approved',
  MODELS_ROUTE: 'model',
  EDIT_MODELS_ROUTE: 'edit-model',
  ADD_MODEL: 'add-model',
  MACHINES_ROUTE: 'machines',
  LOCATION_ROUTE: 'location',
  ADD_LOCATION: 'add-location',
  SUBLOCATION_ROUTE: 'sublocation',
  EDIT_LOCATION_ROUTE: 'edit-location',
  EDIT_SUB_LOCATION_ROUTE: 'edit-sub-location',
  EDIT_REMIT_ROUTE: 'edit-remit',
  LOCATION_OWNERS_ROUTE: 'location-owners',
  ADD_NEW_LOCATION_OWNER: 'add-location-owner',
  EDIT_LOCATION_OWNER: 'edit-location-owner',
  SETTING_ROUTE: 'setting',
  MODEMS_ROUTE: 'modems',
  EDIT_MACHINE_ROUTE: 'edit-machine',
  DEPOSIT_ROUTE: 'deposit',
  APPROVED_DETAIL_DEPOSIT: 'approved-deposit',
  RECONCILIATION_DETAIL_DEPOSIT: 'reconciliation-deposit',
  ADD_MODEM: 'add-modem',
  EDIT_MODEM_ROUTE: 'edit-modem',
  LEASE_ROUTE: 'lease',
  ADD_LEASE_ROUTE: 'add-lease',
  EDIT_LEASE_ROUTE: 'edit-lease',
  ADD_MACHINE_ROUTE: 'add-machine',
  CURRENCY_RATES_EXCHANGE_ROUTE: 'currency-rates-exchange',
  EDIT_LEASE_AND_RENT: 'edit-lease-and-rent',
  ERROR: 'error',
  INTERCARD_FEED_IMPORT: 'import-intercard-feed',
};
