import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '../TextField';

const Datepicker = (props) => {
  const { size = 'normal', error = false, helperText = '', ...others } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        {...others}
        renderInput={(params) => <TextField size={size} {...params} error={error} helperText={helperText} />}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
