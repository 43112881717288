import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  successText: {
    textAlign: 'center',
    padding: 0,
    letterSpacing: '0.25px',
  },
}));

export default useStyles;
