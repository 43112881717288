import { Box } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import SearchBar from '../../../components/SearchBar';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import DepositTable from '../CommonComponent/DepositTable';
import useStyles from '../style';
import { useSearchFilterParam } from '../../../hooks';
import { useLazyGetDepositsSubLocationsQuery } from '../../../services/Deposit/DepositService';
import { useDispatch, useSelector } from 'react-redux';
import { resetApprovedFiltersState, setApprovedFilters } from '../../../redux-slice/depositFilters';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';

const ApprovedDeposit = () => {
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const dispatch = useDispatch();
  const { approved } = useSelector((state) => state.depositFilter);
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.DEPOSIT.INITIAL_PARAMS);

  const [getSubLocations] = useLazyGetDepositsSubLocationsQuery();

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.SUBLOCATION_ID:
        return getSubLocations;

      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.DEPOSIT.FILTER.map((item) => {
        const api = getAPI(item.value);

        return {
          ...item,
          api,
        };
      }),
    [],
  );

  useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
      [FILTER_VALUES.SUBLOCATION_ID]: [],
    };

    if (
      approved[FILTER_VALUES.DATE_RANGE] &&
      approved[FILTER_VALUES.DATE_RANGE]?.from &&
      approved[FILTER_VALUES.DATE_RANGE]?.to
    ) {
      storedFilterParams[FILTER_VALUES.DATE_RANGE] = approved[FILTER_VALUES.DATE_RANGE];
    }
    if (approved[FILTER_VALUES.SUBLOCATION_ID] && approved[FILTER_VALUES.SUBLOCATION_ID]?.length > 0) {
      storedFilterParams[FILTER_VALUES.SUBLOCATION_ID] = approved[FILTER_VALUES.SUBLOCATION_ID];
    }
    setSearchText(approved.searchText);
    setFilterParams(storedFilterParams);
  }, [approved]);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.DEPOSIT,
    filterParams,
    searchText,
    extraParam: 'status=true',
  });

  const applyFilterHandler = (filterValues) => {
    dispatch(
      setApprovedFilters({ ...approved, ...filterValues, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }),
    );
    setFilterParams(filterValues);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.DEPOSIT.INITIAL_PARAMS);
    dispatch(resetApprovedFiltersState());
  };

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setApprovedFilters({ ...approved, searchText: params, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setApprovedFilters({ ...approved, searchText: '', pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }),
    );
  };

  return (
    <>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar onSearch={searchHandler} onReset={onReset} searchText={searchText} />
        </Box>
        <Box px={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={(v) => {
              applyFilterHandler(v);
            }}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.DEPOSIT.INITIAL_PARAMS).length}
          />
        </Box>
      </Box>
      <DepositTable isApproved searchParams={searchParams} tab="approved" approved={approved} />
    </>
  );
};

export default ApprovedDeposit;
