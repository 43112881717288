import { Box, Paper, Typography } from '@mui/material';
import { Autocomplete, Button, Datepicker, GridContainer, GridItem, TextField } from '../../../components/shared';
import { useFormik } from 'formik';
import { ModemType } from '../ModemDetails/data';
import { useGetMachinesQuery } from '../../../services/Machines/MachinesService';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import { useState } from 'react';
import { useDebounce } from '../../../hooks';
import { useAddModemMutation } from '../../../services/Modems/ModemsService';
import { AddNewModemSchema } from '../../../schema/validationSchemas';
import { dateFormatForApi } from '../../../utils/common-methods';

const AddModem = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [addModemApi, { isSuccess: addModemSuccess }] = useAddModemMutation();

  const { currentData: assetData } = useGetMachinesQuery({
    searchParams: `id=${useDebounce(inputValue, 600)}`,
    perPage: 10,
    page: 1,
  });
  const AssetIds = assetData?.assets.map((item) => ({
    text: item.title ? `${item.id} (${item.title})` : String(item.id),
    value: item.id,
  }));

  if (addModemSuccess) {
    navigate(`/${routeConstants.MODEMS_ROUTE}`);
  }

  const formik = useFormik({
    initialValues: {
      type: { text: '', value: '' },
      installedDate: '',
      assetId: { text: '', value: null },
      loginId: '',
      password: '',
      serialNumber: '',
    },
    validationSchema: AddNewModemSchema,
    onSubmit: (data) => {
      addModemApi({
        type: data.type?.value,
        installedDate: dateFormatForApi(data?.installedDate) || '',
        assetId: data.assetId?.value || null,
        loginId: data?.loginId || '',
        password: data?.password || '',
        serialNumber: data.serialNumber,
      });
      navigate(`/${routeConstants.MODEMS_ROUTE}`, { redirect: true });
    },
  });
  const { values, errors, touched, getFieldProps, setFieldValue, handleSubmit } = formik;

  const onCancelHandler = () => {
    navigate(`/${routeConstants.MODEMS_ROUTE}`, { redirect: true });
  };

  const handleAssetIdSearch = (value) => {
    setInputValue(value.split(' ')[0]);
  };
  return (
    <Box>
      <Box>
        <Typography variant="h1" pb={3}>
          Add Modem
        </Typography>
      </Box>
      <Box component={Paper} className={classes.formContent}>
        <form onSubmit={handleSubmit}>
          <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
            <GridItem xs={12} md={6}>
              <Autocomplete
                label="Type"
                data-testid="addModemFields"
                value={values.type}
                options={ModemType || []}
                getOptionLabel={(option) => option.text || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('type', value);
                }}
                onBlur={() => formik.setFieldTouched('type')}
                error={Boolean(touched.type?.value && errors.type?.value)}
                helperText={touched.type?.value && errors.type?.value}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Datepicker
                label="Installed Date"
                value={values.installedDate ? new Date(values.installedDate) : null}
                InputProps={{
                  'data-testid': 'addModemFields',
                }}
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => setFieldValue('installedDate', newValue)}
                onBlur={() => formik.setFieldTouched('installedDate')}
                error={Boolean(touched.installedDate && errors.installedDate)}
                helperText={touched.installedDate && errors.installedDate}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Autocomplete
                label="Asset ID"
                data-testid="addModemFields"
                value={values.assetId}
                options={AssetIds || []}
                getOptionLabel={(option) => option.text || ''}
                onChange={(e, value) => {
                  setFieldValue('assetId', value);
                }}
                onInputChange={(e, newValue) => {
                  handleAssetIdSearch(newValue);
                }}
                onBlur={() => formik.setFieldTouched('assetId')}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Login ID"
                {...getFieldProps('loginId')}
                error={Boolean(touched.loginId && errors.loginId)}
                helperText={touched.loginId && errors.loginId}
                inputProps={{
                  'data-testid': 'addModemFields',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Password"
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                inputProps={{
                  'data-testid': 'addModemFields',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Serial Number"
                {...getFieldProps('serialNumber')}
                error={Boolean(touched.serialNumber && errors.serialNumber)}
                helperText={touched.serialNumber && errors.serialNumber}
                inputProps={{
                  'data-testid': 'addModemFields',
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="space-around" pl={4}>
            <GridItem xs={12} md={12}>
              <Button type="Submit" variant="contained" data-testid="addModemBtn">
                <Typography variant="button">CREATE</Typography>
              </Button>
              <Button onClick={onCancelHandler}>
                <Typography variant="button">CANCEL</Typography>
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </Box>
    </Box>
  );
};

export default AddModem;
