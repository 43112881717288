import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '28px',
    padding: '24px 0px 0px 28px',
    '& .MuiPickersPopper-root': {
      inset: '0px -50px auto auto',
    },
  },
  breadcrumbContainer: { padding: '0px 0px 32px 0px' },
  formContent: {
    '& .MuiInputBase-root': { borderRadius: '4px' },
    backgroundColor: theme?.palette?.common?.white,
    border: '0px 1px 4px #64748b',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
    '& .MuiGrid2-root': { padding: 8 },
    '& .MuiPickersPopper-root': { inset: '0px -50px auto auto' },
  },
  attachMoneyIcon: { fontSize: 16 },
  btn: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

export default useStyles;
