import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import SearchBar from '../../../components/SearchBar';
import LocationOwnersTable from './LocationOwnersTable';
import { Box } from '@mui/material';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS from '../../../constants/FilterConstants';
import { useSearchFilterParam } from '../../../hooks';
import {
  resetActiveLocationOwnerFilters,
  setActiveLocationOwnerFilters,
} from '../../../redux-slice/locationOwnerFilters';

const ActiveTab = (props) => {
  const { onTotalLocationOwnersCount } = props;
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.LOCATION_OWNER.INITIAL_PARAMS);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeLocationOwner } = useSelector((state) => state.locationOwnerFilter);

  useEffect(() => {
    setSearchText(activeLocationOwner.searchText);
  }, [activeLocationOwner]);

  const filterData = useMemo(
    () =>
      FILTER_DATA.LOCATION_OWNER.FILTER.map((item) => ({
        ...item,
      })),
    [],
  );

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.LOCATION_OWNER,
    filterParams,
    searchText,
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setActiveLocationOwnerFilters({
        ...activeLocationOwner,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setActiveLocationOwnerFilters({
        ...activeLocationOwner,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const applyFilterHandler = (filterValues) => {
    setFilterParams(filterValues);
    dispatch(
      setActiveLocationOwnerFilters({
        ...activeLocationOwner,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.LOCATION_OWNER.INITIAL_PARAMS);
    dispatch(resetActiveLocationOwnerFilters());
  };

  return (
    <>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            placeholder="Search location Owners"
            onSearch={searchHandler}
            onReset={onReset}
            searchText={searchText}
          />
        </Box>
        <Box className={classes.filterWrapper}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={(v) => {
              applyFilterHandler(v);
            }}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.LOCATION_OWNER.INITIAL_PARAMS).length}
          />
        </Box>
      </Box>
      <LocationOwnersTable
        searchParams={searchParams}
        onTotalLocationOwner={onTotalLocationOwnersCount}
        tab="active"
        activeLocationOwner={activeLocationOwner}
      />
    </>
  );
};

export default ActiveTab;
