import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { useGetIntercardProcessesQuery } from '../../services/Intercard/IntercardSerice';
import { INTERCARD_TRACKINGS_TABLE, COLUMNS } from '../../constants/tableColumns';
import { getFullName } from '../../utils/common-methods';
import LoadingSpinner from '../../components/LoadingSpinner';
import { NoContent, ReactTable } from '../../components/shared';
import { Box } from '@mui/material';
import useStyles from './style';
import IntercardAccordion from './IntercardAccordion';

const IntercardTrackingsTable = () => {
  const styles = useStyles();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useGetIntercardProcessesQuery({
    searchParams: '',
    page: currentPage,
    perPage: perPageNumber,
  });

  const intercardColumns = INTERCARD_TRACKINGS_TABLE();
  const columns = [COLUMNS.EXPAND_ICON_COLUMN, ...intercardColumns];
  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData.data.intercardTrackings.map((intercard) => {
        const { firstName, lastName } = intercard?.createdByUser || {};
        const createdByName = getFullName(firstName, lastName);
        const transformProcessData = { ...intercard, createdBy: createdByName };
        return transformProcessData;
      });
      setTableData(data);
      let total = latestData?.pagination?.total || 0;
      setTotalCount(total);
    } else if (isError) {
      setTotalCount(0);
    }
  }, [isError, isSuccess, latestData]);
  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  const detailLocationComponent = ({ row }) => {
    const original = row?.original;
    return original && <IntercardAccordion intercardInfo={original} />;
  };

  return (
    <Box className={styles.intercardTable}>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Intercard Process Found"
          desc={(isError && error?.data?.message) || <>There are no intercard processes present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          expandable
          renderRows
          renderRowSubComponent={detailLocationComponent}
        />
      )}
    </Box>
  );
};
export default IntercardTrackingsTable;
