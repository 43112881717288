import { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import AssignNewMachineTable from './MachineTable';
import useStyles from './style';
import { useUpdateModemByIdMutation } from '../../../services/Modems/ModemsService';

const AssignNewAsset = ({ setAssignNewMachine, modemId }) => {
  const classes = useStyles();
  const [updateModemApi] = useUpdateModemByIdMutation();
  const [searchText, setSearchText] = useState('');
  const [machineData, setMachineData] = useState({});

  const onSetSelectedRows = (row) => {
    setMachineData(row[0]);
  };

  const searchHandler = (params) => {
    setSearchText(params);
  };

  const onReset = () => {
    setSearchText('');
  };

  const onSaveHandler = () => {
    updateModemApi({
      id: modemId,
      assetId: machineData?.id,
    }).then((response) => {
      if (response.data) {
        setAssignNewMachine(false);
      }
    });
  };

  const onCancelHandler = () => {
    setAssignNewMachine(false);
  };
  return (
    <Box component={Paper} className={classes.assignMachine}>
      <Box className={classes.assignNewMachineTitle}>
        <Typography variant="h2">Assign New Machine</Typography>
      </Box>
      <Box className={classes.machineSearch}>
        <SearchBar onSearch={searchHandler} onReset={onReset} />
      </Box>
      <AssignNewMachineTable onSelect={onSetSelectedRows} searchParams={searchText} />
      <Box className={classes.btnWrap}>
        <Button variant="contained" onClick={onSaveHandler}>
          SAVE
        </Button>
        <Button onClick={onCancelHandler}>CANCEL</Button>
      </Box>
    </Box>
  );
};

export default AssignNewAsset;
