import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 0 0 24px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0 3px 46px',
    },
  },
  timeLineMainWrapper: {
    padding: 0,
    margin: 0,
  },
  timeLineItemWrapper: {
    '&::before': {
      flex: 0,
      padding: 0,
    },
  },
  timeLineContentWrapper: {
    padding: '15px 4px 0 24px',
    marginBottom: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '14px 0 0 24px',
    },
    '& .MuiGrid2-container': {
      '& .MuiGrid2-root': {
        paddingRight: '24px',
      },
    },
  },
  iconWrapper: {
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    margin: '8px 0',
    boxShadow: 'none',
  },
  fadeIconWrapper: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.38),
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    margin: '8px 0',
  },
  successIconWrapper: {
    backgroundColor: theme.palette.success.dark,
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    margin: '8px 0',
    '& .MuiBox-root': {
      display: 'flex',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },

  iconText: {
    color: theme.palette.common.white,
    marginTop: '2px',
  },
  titleText: {
    color: alpha(theme.palette.secondary.main, 0.38),
  },
  timeLineConnector: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export default useStyles;
