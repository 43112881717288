import { useEffect, useState, useCallback } from 'react';
import { Link, NoContent } from '../../../components/shared';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import { COLUMNS } from '../../../constants/tableColumns';
import { useGetMachinesQuery } from '../../../services/Machines/MachinesService';
import ReactTable from '../../../components/shared/ReactTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { routeConstants } from '../../../constants/routeConstants';
import { Box } from '@mui/material';
import useStyles from './style';

const AssignNewMachineTable = ({ searchParams, onSelect }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [tableData, setTableData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);

  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
    isFetching,
  } = useGetMachinesQuery(
    {
      searchParams: `q=${searchFilterData}`,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );

  const titleLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`/${routeConstants.MACHINES_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );
  const columns = [...COLUMNS.ASSIGN_MACHINE_TABLE];

  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData.assets?.map((asset) => {
        const sublocationName = asset?.sublocation?.name;
        const modelTitle = asset?.model?.title;
        const transformMachine = {
          ...asset,
          sublocationName,
          modelTitle,
        };
        transformMachine.title = titleLink(asset?.id, asset?.title);
        return transformMachine;
      });
      setTableData(data);
      let total = latestData.pagination?.total ?? 0;
      setTotalCount(total);
    }
  }, [isSuccess, latestData]);
  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(DEFAULT_PAGE);
    setSkipCall(false);
  }, [searchParams]);

  return (
    <Box className={classes.assignMachineTableWrapper}>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Machine Found"
          desc={(isError && error?.data?.message) || <>There are no machine present with this filter.</>}
        />
      )}
      {!isLoading && !isFetching && !isError && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          renderRows
          rowSelect
          isSingleSelect={true}
          onSetSelectedRows={onSelect}
        />
      )}
    </Box>
  );
};

export default AssignNewMachineTable;
