import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { GridContainer, GridItem } from '../../components/shared';
import CTMLogo from '../../assets/images/logo/venu_logo.svg';
import CTMMobLogo from '../../assets/images/logo/logo.png';
import loginImg from '../../assets/images/login-img.png';
import loginMobImg from '../../assets/images/login-img-mobile.png';
import loginTabImg from '../../assets/images/login-img-tablet.png';
import SignInButton from '../../components/SignInButton';
import useStyles from './style';

const displayName = 'Login';

const Login = () => {
  const classes = useStyles();
  return (
    <>
      <GridContainer className={classes.loginWrapper}>
        <GridItem xs={12} lg={4} className={classes.loginLeftPanelCol}>
          <Stack className={classes.loginLeftPanelInner} justifyContent="space-between">
            <Box>
              <Stack className={classes.loginLeftPanelContent}>
                <Box display={{ sm: 'flex', lg: 'none' }}>
                  <img src={CTMMobLogo} alt={'Login In Img'} className={classes.loginLogo} />
                </Box>
                <Typography variant="h2" mb={1}>
                  Easier, Faster and Convenient
                </Typography>
                <Typography variant="p6">Collections now made easy!</Typography>
              </Stack>
            </Box>
            <Box className={classes.loginLeftPanelImgWrap} display={{ xs: 'none', lg: 'block' }}>
              <img src={loginImg} alt={'Login In Img'} className={classes.loginLeftPanelImg} />
            </Box>
            <Box className={classes.loginLeftPanelMobImgWrap} display={{ xs: 'none', sm: 'flex', lg: 'none' }}>
              <img src={loginTabImg} alt={'Login In Img'} className={classes.loginLeftPanelMobImg} />
            </Box>
            <Box className={classes.loginLeftPanelMobImgWrap} display={{ xs: 'flex', sm: 'none' }}>
              <img src={loginMobImg} alt={'Login In Mob Img'} className={classes.loginLeftPanelMobImg} />
            </Box>
          </Stack>
        </GridItem>
        <GridItem className={classes.loginRightGridItem} xs={12} lg={8}>
          <Stack className={classes.loginRightPanel} justifyContent="center">
            <Box mb={6} display={{ xs: 'none', lg: 'block' }}>
              <img src={CTMLogo} alt={'CTM Logo'} className={classes.loginLogo} />
            </Box>
            <Typography variant="h1" color="inherit" mb={3} noWrap className={classes.loginRightPanelTitle}>
              Welcome to VENU<sup>+</sup> Collection Application
            </Typography>
            <Box>
              <SignInButton />
            </Box>
          </Stack>
        </GridItem>
      </GridContainer>
    </>
  );
};

Login.displayName = displayName;
export default Login;
