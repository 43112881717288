import { METER_TYPE, FIELD_NAME } from '../constants/MeterTypeFieldMap';
import { getFieldName, getRevenueInUSD } from './collection-methods';
import { calculateDifferenceBetweenTwoFields, checkNumberIsNaNAndInfinity } from './common-methods';

// Methods for reconciliation warning
/**
 * Get current reading and prior reading field names and values
 *
 * @param {String} meterName
 * @param {String} sublocationId
 * @param {String} assetId
 * @param {String} meterId
 * @param {String} assetMeterId
 * @param {Object} values
 * @returns {Object}
 */
export const getReadingFieldValues = (meterName, sublocationId, assetId, meterId, assetMeterId, values) => {
  const currentReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.CURRENT_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const priorReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.PRIOR_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const priorReading = values ? values[priorReadingFieldName] : 0;
  const currentReading = values ? values[currentReadingFieldName] : 0;
  return { priorReading, currentReading, currentReadingFieldName, priorReadingFieldName };
};

/**
 * Get difference of coinsCollected - estimatedCoinRevenue of COIN and coinsCollected fieldName
 *
 * @param {String} sublocationId
 * @param {String} assetId
 * @param {String} meterId
 * @param {String} assetMeterId
 * @param {Object} values
 * @param {number} exchangeRate
 * @returns {Object}
 */
export const getCoinMeterDifference = (sublocationId, assetId, meterId, assetMeterId, values, exchangeRate) => {
  const coinCollectedFieldName = getFieldName(
    METER_TYPE.COIN,
    FIELD_NAME.COINS_COLLECTED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const estimatedCoinRevenueFieldName = getFieldName(
    METER_TYPE.COIN,
    FIELD_NAME.ESTIMATED_COIN_REVENUE,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const coinsCollected = getRevenueInUSD(values[coinCollectedFieldName] ?? 0, exchangeRate);
  const estimatedCoinRevenue = values[estimatedCoinRevenueFieldName] ?? 0;
  const difference = calculateDifferenceBetweenTwoFields(estimatedCoinRevenue, coinsCollected);
  return { difference, coinCollectedFieldName };
};

/**
 * Get difference of creditRevenue - estimated credit revenue of Credit
 *
 * @param {String} sublocationId
 * @param {String} assetId
 * @param {String} meterId
 * @param {String} assetMeterId
 * @param {Object} values
 * @returns
 */
export const getCreditMeterDifference = (sublocationId, assetId, meterId, assetMeterId, values) => {
  const creditCardRevenueFieldName = getFieldName(
    METER_TYPE.CREDIT,
    FIELD_NAME.SEEDLIVE_REVENUE,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const estimatedCreditCardRevenueFieldName = getFieldName(
    METER_TYPE.CREDIT,
    FIELD_NAME.EXPECTED_REVENUE,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const creditCardRevenue = values[creditCardRevenueFieldName] ?? 0;
  const estimatedCreditCardRevenue = values[estimatedCreditCardRevenueFieldName] ?? 0;

  const difference = calculateDifferenceBetweenTwoFields(creditCardRevenue, estimatedCreditCardRevenue);
  return { difference, estimatedCreditCardRevenueFieldName };
};

/**
 * Get difference of creditRevenue - estimated credit revenue of Credit
 *
 * @param {*} sublocationId
 * @param {*} assetId
 * @param {*} meterId
 * @param {*} assetMeterId
 * @param {*} values
 * @param {*} exchangeRate
 * @returns
 */
export const getCashMeterDifference = (sublocationId, assetId, meterId, assetMeterId, values, exchangeRate) => {
  const billsCollectedFieldName = getFieldName(
    METER_TYPE.CASH,
    FIELD_NAME.BILLS_COLLECTED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const coinsCollectedFieldName = getFieldName(
    METER_TYPE.CASH,
    FIELD_NAME.COINS_COLLECTED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const expectedCashRevenueFieldName = getFieldName(
    METER_TYPE.CASH,
    FIELD_NAME.EXPECTED_REVENUE,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const coinsCollected = values ? values[coinsCollectedFieldName] : 0;
  const billsCollected = values ? values[billsCollectedFieldName] : 0;
  const expectedCashRevenue = values ? values[expectedCashRevenueFieldName] : 0;
  const sum = getRevenueInUSD(
    checkNumberIsNaNAndInfinity(coinsCollected) + checkNumberIsNaNAndInfinity(billsCollected),
    exchangeRate,
  );
  const difference = calculateDifferenceBetweenTwoFields(sum, expectedCashRevenue);
  return { difference, billsCollectedFieldName, coinsCollectedFieldName };
};

export const getTokenChangerDifference = (sublocationId, assetId, meterId, assetMeterId, values, exchangeRate) => {
  const tokenChangerFieldName = getFieldName(
    METER_TYPE.TOKEN_CHANGER,
    FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const seedLiveFieldName = getFieldName(
    METER_TYPE.TOKEN_CHANGER,
    FIELD_NAME.SEEDLIVE_REVENUE,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const revenueCollected = getRevenueInUSD(values[tokenChangerFieldName], exchangeRate) ?? 0;
  const seedLiveRevenue = values[seedLiveFieldName] ?? 0;
  const difference = calculateDifferenceBetweenTwoFields(seedLiveRevenue - revenueCollected);
  return { difference, tokenChangerFieldName, seedLiveFieldName };
};

export const getOnlyHasCreditAndTokenChangerEstCCRevenueWarning = (reconciliationWarningForModal) => {
  const warningObjects = {};
  const creditTokenCCRevenueWarning = {};

  Object.entries(reconciliationWarningForModal).forEach(([key, value]) => {
    const meterType = key?.split('_')[0];
    const fieldName = key?.split('_')[1];
    if (
      value &&
      [METER_TYPE.CREDIT, METER_TYPE.TOKEN_CHANGER].includes(meterType) &&
      [FIELD_NAME.EXPECTED_REVENUE, FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER].includes(fieldName)
    ) {
      creditTokenCCRevenueWarning[key] = value;
    }
    if (value) {
      warningObjects[key] = value;
    }
  });
  return Object.values(warningObjects).length === Object.values(creditTokenCCRevenueWarning).length;
};
