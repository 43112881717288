import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import UserAddNewLocationFilter from './UserAddNewLocationFilter';
import useStyles from './style/UserAddNewLocation.style';
import UserAddNewLocationTable from './UserAddNewLocationTable';
import { removeItemOnceFromArray } from '../../../utils/common-methods';

const UserAddNewLocation = ({
  userAssignedLocation = [],
  onCancelPress = () => {},
  addSubLocationToUser = () => {},
}) => {
  const [searchParams, setSearchParams] = useState('');
  const [hideFilterButton, setHideFilterButton] = useState(false);
  const [locationCheckBox, setLocationCheckBox] = useState({});
  const [locationIndeterminate, setLocationIndeterminate] = useState({});
  const [subLocationCheckBox, setSubLocationCheckBox] = useState({});
  const classes = useStyles();

  const onLocationCheckboxChange = (row, checked) => {
    setLocationIndeterminate((prev) => ({ ...prev, [row.id]: false }));
    setLocationCheckBox((prev) => ({ ...prev, [row.id]: checked }));
    const newSubLocationObj = {};
    row.sublocations.map((item) => {
      newSubLocationObj[item.id] = checked;
    });
    setSubLocationCheckBox((prev) => ({
      ...prev,
      [row.id]: newSubLocationObj,
    }));
  };

  const onSubLocationCheckboxChange = (row, checked, tableData) => {
    const subLocationObj = {
      ...subLocationCheckBox[row.locationId],
      [row.id]: checked,
    };
    setSubLocationCheckBox((prev) => ({
      ...prev,
      [row.locationId]: subLocationObj,
    }));
    setLocationIndeterminateChange(row, subLocationObj, tableData);
  };

  const setLocationIndeterminateChange = (row, subLocationObj, tableData) => {
    const isAllSubLocationCheck = Object.values(subLocationObj).every((value) => value);
    const isAllSubLocationUnCheck = Object.values(subLocationObj).every((value) => value === false);
    const newIndeterminateObj = {
      [row.locationId]: isAllSubLocationCheck === isAllSubLocationUnCheck,
    };
    setLocationIndeterminate((prev) => ({ ...prev, ...newIndeterminateObj }));
    if (isAllSubLocationCheck) {
      onLocationCheckboxChange(tableData.filter((item) => item.id === row.locationId)[0], true);
    }
    if (isAllSubLocationUnCheck) {
      onLocationCheckboxChange(tableData.filter((item) => item.id === row.locationId)[0], false);
    }
  };

  const onApplyFilterSearch = (searchFilter) => {
    setSearchParams(searchFilter);
    setHideFilterButton(true);
  };

  const onSaveLocationClicked = () => {
    const unAssignAssignedSubLoc = [];
    const selectedLocations = [];
    for (const locationId in subLocationCheckBox) {
      const sublocations = subLocationCheckBox[locationId];
      for (const subLocationId in sublocations) {
        if (sublocations[subLocationId]) {
          selectedLocations.push(Number(subLocationId));
        }
        if (userAssignedLocation.map((item) => item.id).includes(Number(subLocationId))) {
          if (!sublocations[subLocationId]) {
            unAssignAssignedSubLoc.push(Number(subLocationId));
          } else {
            removeItemOnceFromArray(unAssignAssignedSubLoc, subLocationId);
          }
        }
      }
    }

    const subLocationsWithAlreadyAssignedLocation = selectedLocations;
    for (const index in userAssignedLocation) {
      const sublocationId = userAssignedLocation[index].id;
      if (!subLocationsWithAlreadyAssignedLocation.includes(sublocationId))
        subLocationsWithAlreadyAssignedLocation.push(sublocationId);
    }
    const subLocationsWithUnAssignedLocation = subLocationsWithAlreadyAssignedLocation.filter(
      (item) => !unAssignAssignedSubLoc.includes(item),
    );
    addSubLocationToUser(subLocationsWithUnAssignedLocation);
  };

  return (
    <>
      <Box className={classes.root}>
        <Typography variant={'h2'} marginBottom={'24px'}>
          {'Assign New Location'}
        </Typography>
        <UserAddNewLocationFilter applyFilterSearch={onApplyFilterSearch} onCancelPress={onCancelPress} />
        {hideFilterButton && (
          <UserAddNewLocationTable
            searchParams={searchParams}
            userAssignedLocation={userAssignedLocation}
            onCancelAssignLocationTable={onCancelPress}
            onSaveLocationClicked={onSaveLocationClicked}
            locationCheckBox={locationCheckBox}
            locationIndeterminate={locationIndeterminate}
            subLocationCheckBox={subLocationCheckBox}
            setSubLocationCheckBox={setSubLocationCheckBox}
            onLocationCheckboxChange={onLocationCheckboxChange}
            onSubLocationCheckboxChange={onSubLocationCheckboxChange}
            setLocationIndeterminateChange={setLocationIndeterminateChange}
          />
        )}
      </Box>
    </>
  );
};

export default UserAddNewLocation;
