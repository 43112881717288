import { PinDropOutlined } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { ReactTable } from '../../../components/shared';
import { COLUMNS, LEASE_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { useGetLocationDetailsByIdQuery } from '../../../services/Location/LocationService';
import { getDateFormat, rentTypeString } from '../../../utils/common-methods';

import LeaseAccordion from './LeaseAccordion';

const AssignLeaseTable = (props) => {
  const { onSelect } = props;
  const params = 'includeContracts=true';
  const [searchParams] = useSearchParams();
  const locationId = Number(searchParams.get('locationId'));

  const {
    currentData: currentLocationDetails,
    isLoading,
    isFetching,
  } = useGetLocationDetailsByIdQuery({ id: locationId, searchParams: params });
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));

  const columns = useMemo(() => {
    const dynamicColumns = LEASE_TABLE_DYNAMIC(isBelowMD);
    const serializedActiveColumn = [COLUMNS.EXPAND_ICON_COLUMN, ...dynamicColumns];
    return serializedActiveColumn;
  }, [isBelowMD]);
  const autoCapitalizedStatus = (status) => {
    switch (status) {
      case 'QUOTE':
        return 'Quote';
      case 'MANAGER_REVIEW':
        return 'Manager Review';
      case 'FOLLOW_UP':
        return 'Follow Up';
      case 'ONGOING':
        return 'Ongoing';
      case 'REJECT_LEASE':
        return 'Rejected';
      case 'EXPIRED':
        return 'Expired';
      case 'TERMINATE':
        return 'Terminated';
      default:
        return 'N/A';
    }
  };

  const feeAndRateConditionalRendering = (value) => {
    switch (value?.rentType) {
      case 'RATE_ONLY':
      case 'BENCHMARK_RENT':
        return {
          rentRate: `${value?.rate}%`,
        };
      case 'FEE_ONLY':
      case 'FEE_ONLY_ANNUAL':
      case 'FEE_ONLY_YEAR':
      case 'FEE_PER_PIC':
      case 'FEE_ONLY_AFTER_BENCHMARK':
      case 'FEE_ONLY_SLIDING_BENCHMARK':
        return {
          rentFee: `$${value?.fee}`,
        };
      case 'RATE_PLUS_FEE':
      case 'COMBO':
      case 'RATE_FEE_HIGHER':
      case 'SUM':
      case 'NA':
        return {
          rentRate: `${value?.rate}%`,
          rentFee: `$${value?.fee}`,
        };

      default:
        return {};
    }
  };
  const transformedData = useMemo(() => {
    const contracts = currentLocationDetails?.data?.contracts;
    const tableBodyTransformed = contracts?.map((value) => ({
      id: value?.id,
      name: value?.name,
      status: autoCapitalizedStatus(value?.processStatus),
      startDate: value?.termStartDate ? getDateFormat(value?.termStartDate) : 'N/A',
      endDate: value?.termEndDate ? getDateFormat(value?.termEndDate) : 'N/A',
      sublocation: value?.contractSublocations,
      leaseType: value?.leaseType,
      leaseStatus: value?.status,
      rentType: rentTypeString(value?.rentType),
      ...feeAndRateConditionalRendering(value),
    }));
    return tableBodyTransformed;
  }, [currentLocationDetails]);

  const leaseAccordion = ({ row }) => {
    const original = row?.original;
    return original && <LeaseAccordion leaseInfo={original} />;
  };
  if (isLoading || isFetching) {
    return <LoadingSpinner containerHeight={300} />;
  }
  return (
    <ReactTable
      data={transformedData}
      columns={columns}
      stickyHeader
      tableHeight={500}
      expandable
      renderRowSubComponent={leaseAccordion}
      localFilter
      noContentIcon={<PinDropOutlined sx={{ color: theme.palette.primary.main }} />}
      searchPlaceHolder="Search by lease name & status"
      extraBtn={<></>}
      isSingleSelect
      rowSelect
      onSetSelectedRows={onSelect}
    />
  );
};

export default AssignLeaseTable;
